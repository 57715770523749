<template>
	<el-dialog
	v-model="isShow"
	:title="type == 1?'微信支付':'支付宝支付'"
	width="25%">
		<view class="vipz-zf">
			<el-row>
				<el-col v-if="!isS" :span="24">
					<div class="vipz-zfi" v-html="con"></div>
					<div v-if="type == 2" class="zftext">
						前往支付宝支付中...
					</div>
				</el-col>
				
				<el-col v-if="isS" :span="24">
					<img src="../../../static/user/zfcg.png">
					<div class="zf-c">
						支付成功
					</div>
				</el-col>
			</el-row>
		</view>
	</el-dialog>
</template>

<script>
	import { beforeUnmount } from 'vue'
	import Com from "@/request/common.js";
	import {cGet} from "@/request/http-util.js";
	import { ElMessage } from 'element-plus';
	export default{
		name:"TanVipZf",
		props:{
			con:String
		},
		data(){
			return {
				orderid:0,
				con:"",
				type:1,
				isS:false,
				isShow:false,
				userInfo:{}
			}
		},
		beforeUnmount() {
			clearInterval(window.timer);
		},
		created() {
			this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
		},
		methods:{
			open(orderid,con,num){
				this.isShow = true;
				this.orderid = orderid;
				this.con = con;
				this.type = num;
				this.isS = false;
				
				clearInterval(window.timer);
				window.timer = setInterval(()=>{
					this.getOrderState();
				},3000);
				
				if (num == 2) {
					setTimeout(()=> {
						var form = document.getElementById('alipaysubmit');
						form.submit();
					},500)
				}
			},
			//获取订单状态
			getOrderState() {
				cGet("CheckOrder",{orderNum:this.orderid},false,false).then(res => {
					if (res.code == 1) {
						this.isS = true;
						clearInterval(window.timer);
					}
				})
			},
		}
	}
</script>

<style lang="less" scoped="scoped">
	.vipz-zf {
		text-align: center;
		padding: 60px 0;
		img {
			width: 60px;
			height: 60px;
			cursor: pointer;
		}
		
		.vipz-zfi {
			width: 100%;
			text-align: center;
		}
		
		.zf-c {
			width: 100%;
			text-align: center;
			margin: 15px 0;
			color: #fff;
			font-size: 16px;
		}
		.zftext {
			color:#fff;
		}
	}
</style>
