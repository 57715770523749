<template>
	<el-dialog
	v-model="isfriend"
	custom-class="dialog1"
	title="好友列表"
	height="70%"
	width="25%">
		<div class="g-c-cont">
			<el-table :data="friendList" height="100%" style="width: 100%;">
				<el-table-column prop="avatar" width="70" align= "center" label="头像" >
					<template #default="scope">
						<img :src="scope.row.avatar?scope.row.avatar:img1" />
					</template>
				</el-table-column>
				<el-table-column prop="playCount" width="50" align= "center" label="等级" >
					 <template #default="scope">
						 <img class="dj-img" src="../../../static/dj.png" />
						 <span class="dj-span">{{scope.row.level}}</span>
					 </template>
				</el-table-column>
				<el-table-column prop="name" width="70" align= "center" label="名称" />
				<el-table-column prop="status" width="70" align= "center" label="状态">
					<template #default="scope">
						<span v-if="scope.row.isonline" class="green">在线</span>
						<span v-if="!scope.row.isonline">离线</span>
					</template>
				</el-table-column>
				<el-table-column label="" align= "center" >
					  <template #default="scope">
						<button :class="btnArr.indexOf(scope.row.id) != -1?'orgz-btn org-btn':'org-btn'" @click="yqClick(scope.row.id)">邀请入队</button>
					  </template>
				</el-table-column>
			</el-table>
		</div>
	</el-dialog>
</template>

<script>
	import img1 from '../../../static/head.jpg'
	import {cGet} from "@/request/http-util.js";
	import { ElMessage } from 'element-plus';
	export default{
		name:"TanXgfwq",
		data(){
			return {
				img1:img1,
				btnArr:[],
				friendList:[],
				teamId:0,
				isfriend:false,
				userInfo:{}
			}
		},
		created() {
			this.teamId = localStorage.getItem("teamId")?localStorage.getItem("teamId"):0;
			this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
		},
		methods:{
			open(){
				this.isfriend = true;
				this.yqhy();
			},
			//邀请组队
			yqClick(id) {
				if (this.teamId <= 0) {
					ElMessage("您还没有队伍！");
					return false;
				} 
				
				if (this.btnArr.indexOf(id) != -1) {
					ElMessage("此用户已被邀请过！");
					return false;
				}
				
				var par = {
					id:this.userInfo.id,
					toId:id,
					teamId:this.teamId
				};
				
				cGet("Invite"+(localStorage.getItem("isLeft") != 1?"":"_dy"),par).then(res => {
					if (res.code == 1){
						this.btnArr.push(id);
						ElMessage("邀请成功！");
					}
				})
				
			},
			//邀请好友
			yqhy() {
				var par = {
					id :this.userInfo.id,
					gameId:localStorage.getItem("gameId")
				}
				var _this = this;
				cGet("getmyfrind",par,false,false).then(res => {
					if (res.code == 1) {
						_this.friendList = res.data.myflist;
					}
				})
			},
			subBtn() {
				this.$store.commit("tmChange");
				this.$store.commit("rcChange");
			},
		}
	}
</script>

<style lang="less" scoped="scoped">
	.dialog1 {
		height: 70%;
	}
	.g-c-cont {
		    height: 100%;
		.dj-img {
			width: 1.1rem !important;
			height: 1.1rem !important;
			border-radius: 0;
		}
	}
	.org-btn {
		background: #ff5602;
		border: 1px solid #676666;
		width: 5.8rem;
		height: 2rem;
		line-height: 2rem;
		color: #efefef;
		border-radius: 5px;
		cursor: pointer;
	}
	
	.orgz-btn {
		background: #676666 !important;
	}
</style>
