<template>
  <div class="usercenter">
    <Head></Head>
	<Left></Left>
	
	<div class="contain-user">
		<Top></Top>
		<div class="user-con">
			<el-table :data="tableData"  style="width: 100%" height="100%"
			element-loading-background="rgba(0, 0, 0, 0.8)"
			v-loading="loading">
				<el-table-column prop="cont" label="积分说明" />
				<el-table-column prop="addtime" label="操作时间" />
				<el-table-column prop="score" label="明细" >
					<template #default="scope">
						<span v-if="scope.row.jf > 0" class="green">+{{scope.row.jf}}</span>
						<span v-else>{{scope.row.jf}}</span>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>
  </div>
</template>
<script>
// @ is an alias to /src
import Head from '@/components/HeadUser.vue'
import Left from '@/components/LeftUser.vue'
import Top from '@/components/TopUser.vue'

import { cGet } from "@/request/http-util.js";
import { timeChange } from "@/request/common.js";
import { ElMessage } from 'element-plus'
export default {
	name: 'UserCenter',
	components: {
		Head,
		Left,
		Top
	}, 
	data(){
		return {
			tableData:[],
			loading:false
		}
	},
	created() {
		this.getListInfo();
	},
	methods:{
		//获取积分
		getListInfo() {
			var _this = this;
			this.loading = true;
			cGet("getjf",{},true,false).then(res => {
				this.loading = false;
				if (res.code == 1) {
					res.jflist = res.jflist.filter(function(item,i){
						item['addtime'] = timeChange(item['addtime']);
						return item;
					})
					_this.tableData = res.jflist; 
				}
			})
		}
		
	},
}
</script>

<style lang="less" scoped="scoped">
	
	.usercenter {
		text-align: left;
		.top {
			img{width: 100%;}
		}
		.user-con {
			
		}
	}
	
	.user-con::v-deep {
		.el-table {
			width: 70% !important;
			background-color: #2F2F2F !important;
			text-align: center;
			border: 1px solid #494949 !important;
		}
		.el-table::before {
		    display: none;
		}
		.el-table th.el-table__cell {
		    background-color: #2F2F2F;
			padding: 16px 0;
			text-align: center;
			color: #fff;
			border-bottom: 1px solid #494949;
		}
		.el-table td.el-table__cell{
			padding: 15px 0;
			text-align: center;
			color:#A7A7A7;
			background-color: #181818;
			border-bottom: 1px solid #494949;
		}
		.el-table__body-wrapper {
			background-color: #181818 !important;
		}
		.el-table tr:hover td {
			background-color: #282828 !important;
		}
	}
</style>
