<template>
	<div class="addtan" 
	element-loading-background="rgba(0, 0, 0, 0.8)"
	v-loading="loading">
		<div class="tan-top">
			{{isRoom > 1?"创建房间":"创建队伍"}}
			<!--img src="../../static/close.png" /-->
		</div>
		<div class="tan-con">
			<el-row>
				<el-col v-if="isLeft == 1" class="con-title" :span="3">
					游戏模式：
				</el-col>
				<el-col v-if="isLeft == 1" class="con-nr" :span="21">
					<el-radio v-for="(item,i) in MODEL" v-model="norm" :label="i">{{item}}</el-radio>
				</el-col>
				<el-col v-if="norm == 1" class="con-title" :span="3">
					等级：
				</el-col>
				<el-col v-if="norm == 1" class="con-nr" :span="21">
					<el-select v-model="level" placeholder="Select">
					    <el-option
					      v-for="(item,i) in LEVEL"
					      :key="i"
					      :label="item"
					      :value="i">
					    </el-option>
					</el-select>
				</el-col>
				<el-col class="con-title" :span="3">
					服务器：
				</el-col>
				<el-col class="con-nr" :span="21">
					<el-select v-model="serve" placeholder="Select">
					    <el-option
					      v-for="(item,i) in SERVE"
					      :key="i"
					      :label="item"
					      :value="i">
					    </el-option>
					</el-select>
				</el-col>
				<el-col class="con-title" :span="3">
					模式：
				</el-col>
				<el-col class="con-nr" :span="21">
					<el-radio v-for="(item,i) in NORM" v-model="model" :label="i">{{item}}</el-radio>
				</el-col>
				<el-col class="con-title" :span="3">
					备注：
				</el-col>
				<el-col class="con-nr" :span="21">
					<el-input
					    v-model="note"
					    maxlength="200"
					    show-word-limit
					    type="textarea"/>
				</el-col>
				<el-col class="con-title" :span="3">
					
				</el-col>
				<el-col class="con-nr" :span="21">
					<button @click="subBtn">确认</button>
				</el-col>
			</el-row>
			
		</div>
	</div>
</template>

<script>
	import {cPost,cGet} from "@/request/http-util.js";
	import Com from "@/request/common.js";
	export default {
		name:'AddTeam',
		props:{
			gameId:Number
		},
		computed:{
			isLeft() {
				return this.$store.state.isLeft;
			}
		},
		data() {
			return {
				LEVEL:Com.LEVEL,
				SERVE:Com.SERVE,
				MODEL:Com.MODEL,
				NORM:Com.NORM,
				
				loading:false,
				gameId:0,
				level:0,
				serve:0,
				model:0,
				norm:-1,
				note:'',
				userInfo:{},
				isRoom:1
			}
		},
		created() {
			this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
			this.gameId = localStorage.getItem("gameId");
			this.isRoom = this.$route.query.room?this.$route.query.room:1;
			if (this.isLeft == 1) this.norm = 0;
		},
		methods:{
			subBtn() {
				if (this.isLeft != 1) {
					var par = {
						id:this.userInfo.id,
						gameId:this.gameId,
						//level:this.level,
						serve:this.serve,
						model:this.model,
						note:this.note
						//norm:this.norm
					};
					if (this.isRoom == 2) par['creatroom']=1;
					var _this = this;
					this.loading = true;
					cGet("CreateTeamHasRoom",par,true,false).then(res => {
						this.loading = false;
						if (res.code == 1) {
							localStorage.removeItem("roomId");
							localStorage.setItem("teamId",res.data.teamId);
							this.$router.push({name:'GameDetail'});
						}
					})
				} else {
					var par = {
						id:this.userInfo.id,
						gameId:this.gameId,
						serve:this.serve,
						lv:this.level,
						norm:this.norm,
						model:this.model,
						note:this.note
					};
					var _this = this;
					this.loading = true;
					cGet("createam_dy",par,true,false).then(res => {
						this.loading = false;
						if (res.code == 1) {
							localStorage.removeItem("roomId");
							localStorage.setItem("teamId",res.data.teamId);
							if (this.norm == 0) {
								localStorage.setItem("md",1);
								this.$router.push({name:'TeamList'});
							} else {
								localStorage.setItem("md",2);
								this.$router.push({name:'TeamList'});
							}
							
						}
					})
				}
				
			}
		}
	}
</script>

<style lang="less" scoped="scoped">
	.addtan {
		position: absolute;
		left: 15%;
		width: 70%;
		top: 12%;
		background: rgba(32,32,32,.6);
		border-radius: 5px;
		border:1px solid #676666;
		padding-bottom: 4rem;
		
		.tan-top {
			text-align: left;
			color: #f4f4f4;
			font-size: 1.1rem;
			background-image: linear-gradient( #161515, #2b2a2a);
			height: 3.2rem;
			line-height: 3.2rem;
			box-sizing: border-box;
			padding-left: 5%;
			position: relative;
			
			img {
				width: 1.4rem;
				height: 1.4rem;
				position: absolute;
				top: 25%;
				right: 5%;
			}
		}
		
		.tan-con {
			width: 90%;
			margin-top:3rem;
			margin-left: 5%;
			font-size: 1.1rem;
			color:#e9e9ea;
			text-align: left;
			.con-title,.con-nr {
				margin-bottom: 1.5rem;
				line-height: 43px;
				button{
					height: 2.6rem;
					line-height: 2.6rem;
					width: 10rem;
					background-color: #07b43c;
					color:#e9e9ea;
					border:none;
					letter-spacing: 3px;
					margin-top:2rem;
					border-radius: 2px;
				}
				
			}
		}
	}
	
	.tan-con::v-deep {
		
	}
</style>
