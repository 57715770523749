<template>
  <div class="usercenter">
    <Head></Head>
	<Left></Left>
	
	<div class="contain-user">
		<Top></Top>
		<div class="button">
			<el-button type="success"  size="medium">绝地求生战绩</el-button>
			<!--el-select v-model="sid" @change="sjChange" class="m-2" placeholder="请选择赛季" size="large">
			    <el-option
			      v-for="item in sList"
			      :key="item.value"
			      :label="item.label+' 赛季'"
			      :value="item.value">
			    </el-option>
			</el-select-->
		</div>
		<div 
		element-loading-background="rgba(0, 0, 0, 0.8)"
		v-loading="loading"
		class="user-con">
			<div class="btns">
				<el-button @click="mChange(1)" size="small" :type="mshow == 1?'success':'info'">TPP</el-button>
				<el-button @click="mChange(2)" size="small" :type="mshow == 2?'success':'info'">FPP</el-button>
			</div>
			
			<el-row :gutter="20">
				<el-col :span="6">
					<div class="pm-l">
						<div class="pm-l-top">
							<img src="../../../static/qiang.png">
							<span>竞技四排</span>
						</div>
						<div class="pm-l-mid" style="height: 65px;line-height: 24px;">
							<span style="margin-top: 10px;display: inline-block;">RP: {{pE(four,'currentRankPoint')}}</span><br>
							<span>段位: 
								<template v-if="four && four.currentTier">
									{{pw[four.currentTier.tier]}} {{pw1[four.currentTier.subTier]}}
								</template>
								<template v-else>0</template>
							</span>
							<div>
								总场数：{{pE(four,'roundsPlayed')}} <br>
								吃鸡数：{{pE(four,'wins')?pE(four,'wins'):0}}
							</div>
						</div>
						<div v-if="four" class="pm-l-bot">
							<div class="pm-l-botl">
								<div class="pm-l-bot1" style="height: 65px;line-height: 18px;">
									K/D<br>
									{{numC1(four.kills,four.deaths)}}
								</div>
								<div class="pm-l-bot3">
									<span>吃鸡率</span>
									<span class="fr">{{numC1(four.wins,four.roundsPlayed,2)}}%</span>
								</div>
								<div class="pm-l-bot2">
									<span>前十率</span>
									<span class="fr">{{numC1(four.top10Ratio,1,2)}}%</span>
								</div>
								<div class="pm-l-bot3">
									<span>最近一周击杀数 </span>
									<span class="fr">{{four.weeklyKills}}</span>
								</div>
							</div>        
							<div v-if="four" class="pm-l-botr">
								<div class="pm-l-bot1" style="height: 65px;line-height: 18px;">
									场均伤害<br>
									{{numC1(four.damageDealt,four.roundsPlayed)}}
								</div>
								<div class="pm-l-bot3">
									<span>前十数</span>
									<span class="fr">{{parseInt(four.roundsPlayed*four.top10Ratio)}}</span>
									
								</div>             
								<div class="pm-l-bot2">
									<span>最长存活时间</span>
									<span class="fr">{{numC3(four.longestTimeSurvived,1)}}m</span>
								</div>
								<div class="pm-l-bot3">
									<span>最近一周胜利数 </span>
									<span class="fr">{{four.weeklyWins}}</span>
								</div>
							</div>
						</div>
						
						<div v-else style="height: 215px;line-height: 215px;" class="pm-l-bot wzj">
							暂无战绩
						</div>
					</div>
				</el-col>
				<el-col :span="6">
					<div class="pm-l">
						<div class="pm-l-top">
							<img src="../../../static/user/zhu-1.png">
							<span>单排</span>
						</div>
						<div class="pm-l-mid">
							<span>

								<template v-if="one">
									{{one.roundsPlayed}} 场
								</template>
								<template v-else>0 场</template>
							</span>
						</div>
						<div v-if="one" class="pm-l-bot">
							<div class="pm-l-botl">
								<div class="pm-l-bot1">
									K/D<br>
									{{numC1(one.kills,one.losses)}}
								</div>
								<div class="pm-l-bot3">
									<span>胜%</span>
									<span class="fr">{{numC1(one.wins,one.losses,2)}}%</span>
								</div>
								<div class="pm-l-bot2">
									<span>最远距离击杀</span>
									<span class="fr">{{one.longestKill}}m</span>
								</div>
								<!--div class="pm-l-bot3">
									<span>平均排名</span>
									<span class="fr">#{{numC2(one.RankPoints)}}</span>
								</div-->
								<div class="pm-l-bot3">
									<span>KDA </span>
									<span class="fr">{{numC1(one.kills+one.assists,one.losses)}}</span>
								</div>
							</div>        
							<div class="pm-l-botr">
								<div class="pm-l-bot1">
									场均伤害<br>
									{{numC1(one.damageDealt,one.roundsPlayed)}}
								</div>
								<div class="pm-l-bot3">
									<span>TOP 10%</span>
									<span class="fr">{{numC1(one.top10s,one.roundsPlayed)}}%</span>
								</div>            
								<div class="pm-l-bot2">
									<span>爆头</span>
									<span class="fr">{{numC1(one.headshotKills,one.kills,2)}}%</span>
								</div>
								<!--div class="pm-l-bot3">
									<span>平均生存时间</span>
									<span class="fr">{{numC3(one.TimeSurvived,one.RoundsPlayed)}}min</span>
								</div-->
								<div class="pm-l-bot3">
									<span>最多击杀 </span>
									<span class="fr">{{one.roundMostKills}}</span>
								</div>
							</div>
						</div>
						<div v-else style="height: 215px;line-height: 215px;" class="pm-l-bot wzj">
							暂无战绩
						</div>
					</div>
				</el-col>
				<el-col :span="6">
					<div class="pm-l">
						<div class="pm-l-top">
							<img src="../../../static/user/zhu-2.png">
							<span>双排</span>
						</div>
						<div class="pm-l-mid">
							<span>
								<template v-if="two">
									{{two.roundsPlayed}} 场
								</template>
								<template v-else>0 场</template>
							</span>
						</div>
						<div v-if="two" class="pm-l-bot">
							<div class="pm-l-botl">
								<div class="pm-l-bot1">
									K/D<br>
									{{numC1(two.kills,two.losses)}}
								</div>
								<div class="pm-l-bot3">
									<span>胜%</span>
									<span class="fr">{{numC1(two.wins,two.losses,2)}}%</span>
								</div>
								<div class="pm-l-bot2">
									<span>最远距离击杀</span>
									<span class="fr">{{two.longestKill}}m</span>
								</div>
								<!--div class="pm-l-bot3">
									<span>平均排名</span>
									<span class="fr">#{{numC2(two.RankPoints)}}</span>
								</div-->
								<div class="pm-l-bot3">
									<span>KDA </span>
									<span class="fr">{{numC1(two.kills+two.assists,two.losses)}}</span>
								</div>
							</div>        
							<div class="pm-l-botr">
								<div class="pm-l-bot1">
									场均伤害<br>
									{{numC1(two.damageDealt,two.roundsPlayed)}}
								</div>
								<div class="pm-l-bot3">
									<span>TOP 10%</span>
									<span class="fr">{{numC1(two.top10s,two.roundsPlayed,2)}}%</span>
								</div>            
								<div class="pm-l-bot2">
									<span>爆头</span>
									<span class="fr">{{numC1(two.headshotKills,two.kills,2)}}%</span>
								</div>
								<!--div class="pm-l-bot3">
									<span>平均生存时间</span>
									<span class="fr">{{numC3(two.TimeSurvived,two.RoundsPlayed)}}min</span>
								</div-->
								<div class="pm-l-bot3">
									<span>最多击杀 </span>
									<span class="fr">{{two.roundMostKills}}</span>
								</div>
							</div>
						</div>
						<div v-else style="height: 215px;line-height: 215px;" class="pm-l-bot wzj">
							暂无战绩
						</div>
					</div>
				</el-col>
				<el-col :span="6">
					<div class="pm-l">
						<div class="pm-l-top">
							<img src="../../../static/user/zhu-3.png">
							<span>四排</span>
						</div>
						<div class="pm-l-mid">
							<span>
								<template v-if="three">
									{{three.roundsPlayed}} 场
								</template>
								<template v-else>0 场</template>
							</span>
						</div>
						<div v-if="three" class="pm-l-bot">
							<div class="pm-l-botl">
								<div class="pm-l-bot1">
									K/D<br>
									{{numC1(three.kills,three.losses)}}
								</div>
								<div class="pm-l-bot3">
									<span>胜%</span>
									<span class="fr">{{numC1(three.wins,three.losses,2)}}%</span>
								</div>
								<div class="pm-l-bot2">
									<span>最远距离击杀</span>
									<span class="fr">{{three.longestKill}}m</span>
								</div>
								<!--div class="pm-l-bot3">
									<span>平均排名</span>
									<span class="fr">#{{numC2(three.RankPoints)}}</span>
								</div-->
								<div class="pm-l-bot3">
									<span>KDA </span>
									<span class="fr">{{numC1(three.kills+three.assists,three.losses)}}</span>
								</div>
							</div>        
							<div class="pm-l-botr">
								<div class="pm-l-bot1">
									场均伤害<br>
									{{numC1(three.damageDealt,three.roundsPlayed)}}
								</div>
								<div class="pm-l-bot3">
									<span>TOP 10%</span>
									<span class="fr">{{numC1(three.top10s,three.roundsPlayed,2)}}%</span>
								</div>            
								<div class="pm-l-bot2">
									<span>爆头</span>
									<span class="fr">{{numC1(three.headshotKills,three.kills,2)}}%</span>
								</div>
								<!--div class="pm-l-bot3">
									<span>平均生存时间</span>
									<span class="fr">{{numC3(three.TimeSurvived,three.RoundsPlayed)}}min</span>
								</div-->
								<div class="pm-l-bot3">
									<span>最多击杀 </span>
									<span class="fr">{{three.roundMostKills}}</span>
								</div>
							</div>
						</div>
						
						<div v-else style="height: 215px;line-height: 215px;" class="pm-l-bot wzj">
							暂无战绩
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
	</div>
  </div>
</template>
<script>
// @ is an alias to /src
import Head from '@/components/HeadUser.vue'
import Left from '@/components/LeftUser.vue'
import Top from '@/components/TopUser.vue'

import icon from '../../../static/user/user-3.png';

import { ElMessage } from 'element-plus'
import {cGet} from "@/request/http-util.js";
export default {
	name: 'UserCenter',
	components: {
		Head,
		Left,
		Top
	}, 
	data(){
		return {
			loading:false,
			userInfo:{},
			sList:[],
			sid:'',
			GameModeStats:{},
			GameModeSw:{},
			GameModeSw1:{},
			mshow:1,
			one:{},
			two:{},
			three:{},
			four:{},
			five:{},
			pw:{
				"Bronze":"青铜",
				"Silver":"白银",
				"Gold":"黄金",
				"Platinum":"铂金",
				"Diamond":"钻石",
				"Master":"大师"
			},
			pw1:{
				1:"I",
				2:"II",
				3:"III",
				4:"IV",
				5:"V"
			}
		}
	},
	created() {
		this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
		this.showAct1();
	},
	methods:{
		showAct1(){

			cGet('GetPUBGData',{id:this.userInfo.id},false,false).then(res => {
				this.loading = false;
				if(res.code == 1) {
					if((Object.keys(res.data)).length > 0) {
						res.data.lifetimeStats = JSON.parse(res.data.lifetimeStats);
						res.data.SeasonStats = JSON.parse(res.data.SeasonStats);
						res.data.RankedSeasonStats = JSON.parse(res.data.RankedSeasonStats);
						
						if (typeof(res.data.RankedSeasonStats.data.attributes.rankedGameModeStats.squad) != 'undefined')
						this.four = res.data.RankedSeasonStats.data.attributes.rankedGameModeStats.squad;
						if (typeof(res.data.lifetimeStats.data.attributes.gameModeStats.solo) != 'undefined')
						this.one = res.data.lifetimeStats.data.attributes.gameModeStats.solo;
						if (typeof(res.data.lifetimeStats.data.attributes.gameModeStats.duo) != 'undefined')
						this.two = res.data.lifetimeStats.data.attributes.gameModeStats.duo;
						if (typeof(res.data.lifetimeStats.data.attributes.gameModeStats.squad) != 'undefined')
						this.three = res.data.lifetimeStats.data.attributes.gameModeStats.squad;
						
						if (typeof(res.data.lifetimeStats.data.attributes.gameModeStats) != 'undefined')
						this.GameModeStats = res.data.lifetimeStats.data.attributes.gameModeStats;
						if (typeof(res.data.RankedSeasonStats.data.attributes.rankedGameModeStats) != 'undefined')
						this.GameModeSw = res.data.RankedSeasonStats.data.attributes.rankedGameModeStats;
						
						if (typeof(res.data.SeasonStats.data.attributes.gameModeStats) != 'undefined')
						this.GameModeSw1 = res.data.SeasonStats.data.attributes.gameModeStats;
						
						var sss = res.data.SeasonStats.data.attributes.gameModeStats.squad;
						
						this.four.weeklyWins = sss?sss.weeklyWins:0;
						this.four.weeklyKills = sss?sss.weeklyKills:0;
						this.four.longestTimeSurvived = sss?sss.longestTimeSurvived:0;
					
						
					} else {
						this.tips = "获取游戏数据超时，请重试";
					}
				} else {
					this.tips = "未找到该游戏数据";
				}
			})
		},
		
		sjChange(){
			this.loading = true;
			this.showAct();
		},
		
		//模式改变
		mChange(num) {
			this.mshow = num;
			if (num == 1) {
				this.one = this.GameModeStats.solo;
				this.two = this.GameModeStats.duo;
				this.three = this.GameModeStats.squad;
				// 返回结果里可能不带
				if (typeof(this.GameModeSw.squad) != 'undefined')
				this.four = this.GameModeSw.squad;
				
				// console.log("two: top10S :" +  this.two.top10S);
				// console.log("two: roundsPlayed :" +  this.two.roundsPlayed);
				
				this.four.weeklyWins = this.GameModeSw1.squad?this.GameModeSw1.squad.weeklyWins:0;
				this.four.weeklyKills = this.GameModeSw1.squad?this.GameModeSw1.squad.weeklyKills:0;
				this.four.longestTimeSurvived = this.GameModeSw1.squad?this.GameModeSw1.squad.longestTimeSurvived:0;

			} else {
				this.one = this.GameModeStats.solofpp;
				this.two = this.GameModeStats.duofpp;
				this.three = this.GameModeStats.squadfpp;
				// 返回结果里可能不带
				if (typeof(this.GameModeSw.squadfpp) != 'undefined')
				this.four = this.GameModeSw.squadfpp;
				
				console.log(this.GameModeSw1.squadfpp);
				this.four.weeklyWins = this.GameModeSw1.squadfpp?this.GameModeSw1.squadfpp.weeklyWins:0;
				this.four.weeklyKills = this.GameModeSw1.squadfpp?this.GameModeSw1.squadfpp.weeklyKills:0;
				this.four.longestTimeSurvived = this.GameModeSw1.squadfpp?this.GameModeSw1.squadfpp.longestTimeSurvived:0;
			}
		},
		//
		pE(str,k,num=1){
			if (typeof(str) != 'undefined' && str) {
				return typeof(str[k]) != 'undefined' && str[k]?str[k]:"";
			} else {
				
				return num==1?0:"-";
			}
		},
		
	
		//两数相除
		numC1(one,two,state = 1) {

			if (state == 1) {
				if (two == 0) return one.toFixed(2);
				return (one/two).toFixed(2);
			} else {
				if (one == 0) return 0.00;
				if (two == 0) return 100.00;
				return ((one/two)*100).toFixed(2);
			}
		},
		numC2(one) {
			if (!one) return 0.00;
			return one.toFixed(2);
		},
		//两数相除

		numC3(one,two) {

			if (!one) return 0.00;
			return ((one/two)/60).toFixed(2);
		}
	},
}
</script>

<style lang="less" scoped="scoped">
	
	.usercenter {
		text-align: left;
		.btns {
			margin-bottom:20px;
		}
		.wzj {
			text-align: center;
			color: #fff;
		}
		.top {
			img{width: 100%;}
		}
		.button {
			margin:20px 0 40px;
		}
		.el-select {
			width: 140px;
			margin-left: 20px;
			position: relative;
			top: 1px;
		}
		.user-con {
			height: calc(100% - 360px);
			padding: 40px 60px;
			position: relative;
			
			.pm-l-mid div{
			    float: right;
			    margin-right: 10px;
			    margin-top: -24px;
			    font-size: 16px;
			    color: #fff;
			}
			
			.el-row {
				height: calc(100% - 62px);
				
				.el-col {
					height: 100%;
					.pm-l {
						width: 100%;
						border:1px solid #6b6b6b;
						border-radius: 8px;
						overflow: hidden;
					}
					
					.pm-l-top {
						height: 50px;
						line-height: 50px;
						text-align: left;
						width: 100%;
						background-image: linear-gradient(#161515, #2b2a2a);
						img {margin-left: 20px;position: relative;top:4px}
						span {color:#fff;margin-left: 20px;font-size: 16px;}
					}
					
					.pm-l-mid {
						height: 50px;
						line-height: 50px;
						text-align: left;
						border-bottom: 1px solid #6b6b6b;
						span {color:#fff;margin-left: 20px;font-size: 16px;}
					}
					
					.pm-l-bot{
						width: 100%;
						
						.pm-l-botl {
							width: 50%;
							float: left;
							border-right: 1px solid #6b6b6b;
							box-sizing: border-box;
						}
						.pm-l-botr {
							width: 50%;
							float: left;
						}
						
						.pm-l-bot1 {
							height: 80px;
							line-height: 25px;
							width: 100%;
							box-sizing: border-box;
							padding: 15px 25px 0;
							font-size: 14px;
							color: #fff;
							overflow: hidden;
							text-overflow:ellipsis;
							white-space: nowrap;
						}
						
						.pm-l-bot2 {
							height: 50px;
							line-height: 50px;
							width: 100%;
							box-sizing: border-box;
							padding: 0 25px;
							font-size: 12px;
							color: #7c7d7f;
							overflow: hidden;
							text-overflow:ellipsis;
							white-space: nowrap;
						}
						.pm-l-bot3 {
							height: 50px;
							line-height: 50px;
							width: 100%;
							box-sizing: border-box;
							padding: 0 25px;
							font-size: 12px;
							color: #7c7d7f;
							background-color: #2b2b2b;
							overflow: hidden;
							text-overflow:ellipsis;
							white-space: nowrap;
						}
						
						.fr {float: right;}
					}
					
					
				}
			}
			
		}
		
		
	}
</style>
