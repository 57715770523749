<template>
  <div class="start">
    <Head></Head>
	<Left></Left>
	
	<div class="contain" 
	element-loading-background="rgba(0, 0, 0, 0.8)"
	v-loading="loading">
		<div class="top">
			<span>房间玩家</span>
			<div class="seach">
				<el-input v-model="keyword"
				      placeholder="搜索玩家"
				      class="input-with-select">
				  <template #append>
					<el-button @click="sBtn">搜索</el-button>
				  </template>
				</el-input>
			</div>
			<button @click="jsyxBtn" v-if="roominfo.leaderId == userInfo.id" class="jsyxBtn">结束游戏</button>
			<button @click="tcfjBtn" v-else class="jsyxBtn">退出房间</button>
		</div>
		<div class="con">
			<template v-for="(ite,ind) in dwlist">
				<el-row :class="ind%2 == 0?'el-row-bg':''">
					<el-col v-for="(item,index) in ite"  :span="8">
						<div class="list-con">
							<div class="list-con-head">
								<img class="headimg" :src="item.avatar?item.avatar:img1" />
								<img class="headicon" src="../../static/qz.png" />
								<span>{{index+1}}</span>
							</div>
							<div class="list-c-l" style="padding-top: 1rem; margin-left: 5px;">
								{{item.pubgName}}<span>已玩:</span> {{item.playCount}}次 <span>等级:</span>{{item.lv?item.lv:0}}
							</div>
							<div v-if="item.id != userInfo.id" class="list-c-l">
								<img v-if="jb.indexOf(item.id) == -1" @click="jbBtn(item.id)" src="../../static/start-2.png" />
								<img v-if="hy.indexOf(item.id) == -1" @click="hyBtn(item.id)" src="../../static/start-4.png" />
								<img v-if="dz.indexOf(item.id) == -1" @click="dzBtn(item.id)" src="../../static/start-1.png" />
								<img v-if="td.indexOf(item.id) == -1" @click="tdBtn(item.id)" src="../../static/start-3.png" />
							</div>
						</div>
					</el-col>
				</el-row >
			</template>
			
		</div>
	</div>
  </div>
</template>
<script>
// @ is an alias to /src
import { beforeUnmount } from 'vue'
import Head from '@/components/Head.vue'
import Left from '@/components/Left.vue'
import img1 from '../../static/head.jpg'
import { ElMessage } from 'element-plus'
import {reSet} from "@/request/common-api.js";
import {cPost,cGet} from "@/request/http-util.js";
export default {
	name: 'GameDetail',
	components: {
		Head,
		Left
	}, 
	computed: {
	  isFollow () {
	    return this.$store.state.isToRc;
	  },
	  isFollow1 () {
	    return this.$store.state.isToTm;
	  }
	},
	watch: {
	  isFollow (newVal, oldVal) {
	    this.$router.push({name:'GameRoom'});
	  },
	  isFollow1 (newVal, oldVal) {
	    this.$router.push({name:'GameDetail'});
	  }
	},
	data(){
		return {
			teamId:0,
			roomId:0,
			gameId:0,
			keyword:"",
			img1:img1,
			dwlist:[],
			hy:[],
			jb:[],
			dz:[],
			td:[],
			ed:0,
			loading:false,
			roominfo:{},
			userInfo:{}
		}
	},
	beforeUnmount() {
		clearInterval(window.timer);
	},
	created () {
		this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
		this.teamId = localStorage.getItem("teamId")?localStorage.getItem("teamId"):0;
		this.roomId = localStorage.getItem("roomId")?localStorage.getItem("roomId"):0;
		this.gameId = localStorage.getItem("gameId")?localStorage.getItem("gameId"):0;
		
		if (this.teamId == 0) {
			this.$store.commit("totmChange");
		}
		
		if (this.roomId == 0) {
			this.$store.commit("torcChange");
		}
		
		this.init();
	},
	methods:{
		init() {
			this.getMember(1);
			
			clearInterval(window.timer);
			window.timer = setInterval(()=>{
				this.getMember();
			},5000);
		},
		//获取大厅玩家
		getMember(num = 0) {
			var _this = this; 
			if (num > 1) this.loading = true;
			//获取房间信息
			cGet("getroominfo",{id:this.roomId,skey:this.keyword},this.loading,false).then(res => {
				if (num > 1) this.loading = false;
				if (res.code == 1) {
					_this.dwlist = [];
					var acc = [];
					res.data.dwlist.filter(function(item,index) { 
						if (item.rylist.length > 0) {
							
							item.rylist.filter(function(ite,inde) {
								acc.push(ite);
							})
						} 
						if(acc.length == 3) {
							_this.dwlist.push(acc);
							acc = [];
						}
					})
					_this.dwlist.push(acc);
					console.log(_this.dwlist);
					_this.roominfo = res.data.roominfo;
					/*if (res.data.roominfo.roomstate == 3||res.data.roominfo.roomstate == 2) {
						this.$router.push('gameDetail');
					}*/
				}
			})
		},
		//添加好友
		hyBtn(id){
			if (id == this.userInfo.id) {
				ElMessage("请勿添加自己为好友！");
				return false;
			}
			this.loading = true;
			cGet("addfriend",{fuid:id},true,false).then(res => {
				this.loading = false;
				if (res.code == 1) {
					ElMessage("邀请发送成功，等待同意");
					this.hy.push(id);
				}
			})
		},
		//举报
		jbBtn(id) {
			if (id == this.userInfo.id) {
				ElMessage("请勿举报自己！");
				return false;
			}
			this.loading = true;
			cGet("ReportOne",{beReporter:id},true,false).then(res => {
				this.loading = false;
				if (res.code == 1) {
					ElMessage("举报成功");
					this.jb.push(id);
				}
			})
		},
		//点赞
		dzBtn(id) {
			this.loading = true;
			cGet("DoLike",{likeManId:id},true,false).then(res => {
				this.loading = false;
				if (res.code == 1) {
					ElMessage("您点了个赞");
					this.dz.push(id);
				}
			})
		},
		//不想同队
		tdBtn(id) {
			this.loading = true;
			cGet("DontLike",{dontLikeManId:id},true,false).then(res => {
				this.loading = false;
				if (res.code == 1) {
					ElMessage("设定成功，后续将减少您与他的匹配几率");
					this.td.push(id);
				}
			})
		},
		//结束游戏
		jsyxBtn() {
			this.loading = true;
			cGet("EndPubg",{roomId:this.roomId},true,false).then(res => {
				this.loading = false;
				reSet().then(res => {
					localStorage.setItem("gameId",this.gameId)
					
					this.$router.push("gameDetail");
				})
			})
			
		},
		tcfjBtn(){
			reSet().then(res => {
				localStorage.setItem("gameId",this.gameId)
				this.$router.push("gameDetail");
			})
		},
		//搜索
		sBtn() {
			if (!this.keyword) {
				ElMessage("搜索条件不能为空！");
				return false;
			}
			this.getMember(1);
		}
	},
}
</script>

<style lang="less" scoped="scoped">
	.start {
		width: 100%;
		height: 100%;
		margin:0;
		padding: 0;
	}
	.con {
		width: 100%;
		height: calc(100% - 10rem);
		background-color: rgba(32, 32, 32, 0.6);
		border: 1px solid #676767;
		border-radius: 5px;
		margin-top: 5rem;
		box-sizing: border-box;
		padding: 3rem 0rem;
		
		.el-row {
			padding: 1rem 2rem;
		}
		
		.el-row-bg {
			background-color: rgba(125,125,125,.3);
		}
		.list-con {
			width: 100%;
			position: relative;
			height: 7.2rem;
			.list-con-head {
				position: absolute;
				left: 0;
				top: 0;
				width: 5.2rem;
				height: 5.2rem;
				border-radius: 50%;
				overflow: hidden;
				margin: 1rem;
				.headimg {width: 100%;height: 100%;}
				.headicon{top: .3rem; position: absolute; right: .2rem;}
				span {
					position: absolute;
					top: .9rem;
					right: .5rem;
					color: #fff;
					font-size: .8rem;
				}
			}
			.list-c-l {
				width: 100%;
				box-sizing: border-box;
				padding-left: 7.4rem;
				text-align: left;
				color: #fff;
				margin-bottom: .6rem;
				font-size: 1rem;
				span {
					color:#999;
					margin-left: 1rem;
				}
				img {
					cursor: pointer;
					margin-right: .4rem;
				}
			}
		}
	}
	.top {
		position: relative;
		top: 2rem;
		left: 0;
		width: 100%;
		height: 3rem;
		span {
			position: absolute;
			top: 0rem;
			left: 50%;
			margin-left: -95px;
			font-size: 2.2rem;
			font-weight: 600;
			color: #fff;
			letter-spacing: 2px;
			background: linear-gradient(to right, #ada59b, #d1d0cf);
			  -webkit-background-clip: text;
			  color: transparent;
		}
		
		.seach {
			width: 20rem;
			top: .2rem;
			position: relative;
		}
		
		.jsyxBtn {
			background-color: #ff5500;
			height: 2rem;
			width: 6rem;
			text-align: center;
			color: #fff;
			border-radius: 4px;
			margin-top: -1.6rem;
			border: none;
			line-height: 2rem;
			position: absolute;
			right: 0;
			bottom: .3rem;
		}
	}
	
	.start::v-deep {
		.el-input-group__append {
			background-color: #07b43c !important;
			border:none !important;
			.el-button {
				
				height: 2.4rem;
				width: 5rem;
				color: #fff !important;
			}
		}
		
	}
</style>
