<template>
  <div class="mt-table">
    <el-row>
      <el-col :span="8">
        <el-row>
          <el-col :span="24">
            <div class="div-white">比赛名称</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <div class="div-red">{{ matchDetail.name }}</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <div class="div-white">比赛类型</div>
          </el-col>
          <el-col :span="12">
            <div class="div-white">服务器</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <div class="div-red">{{ matchDetail.matchType }}</div>
          </el-col>
          <el-col :span="12">
            <div class="div-red">{{ matchDetail.serverType }}</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <div class="div-white">比赛模式</div>
          </el-col>
          <el-col :span="12">
            <div class="div-white">参加比赛队伍数</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <div class="div-red">
              {{ matchDetail.gameMode == 0 ? "单人" : "战队" }}({{
                matchDetail.gamePeople
              }}人)
            </div>
          </el-col>
          <el-col :span="12">
            <div class="div-red">{{ matchDetail.gameTeamNum }}队</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <div class="div-white">比赛场次</div>
          </el-col>
          <el-col :span="12">
            <div class="div-white">比赛时间</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <div class="div-red">{{ matchDetail.gameNum }}</div>
          </el-col>
          <el-col :span="12">
            <div class="div-red">{{ matchDetail.gameStartTime }}</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <div class="div-white">小比赛时长</div>
          </el-col>
          <el-col :span="12">
            <div class="div-white">小比赛间隔</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <div class="div-red">{{ matchDetail.gameTime }}分钟</div>
          </el-col>
          <el-col :span="12">
            <div class="div-red">{{ matchDetail.gameDert }}分钟</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-button v-if="job!=3" style="background-color:#07b43c" @click="clickSetPosition"> 设置落伞点 </el-button>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <div>落伞点在比赛开始前12小时内无法修改</div>
          </el-col>
        </el-row>
        <el-row style="margin-top:30px">
          <el-col :span="12">
            <el-button type="primary" @click="clickOK" v-if="showBtn">  确定  </el-button>
          </el-col>
          <el-col :span="12">
            <el-button type="primary" @click="clickCancel" v-if="showBtn">  取消  </el-button>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="16">
     
     
        <div
          id="par"
          v-on:mousemove="getCoordinates($event)"
          v-on:mouseup="mouseDivUp"
          :class="matchDetail.gameMap == '1' ? 'div-border2' : 'div-border'"
        >
         
          <!-- <img
            v-if="matchDetail.gameMap == '1'"
            class="bg"
            alt="l"
            src="../../../static/map2.jpg"
          />
          <img
            v-if="matchDetail.gameMap == '2'"
            class="bg"
            alt="l"
            src="../../../static/map.jpg"
          /> -->
        
        <div v-for="(item, index) in battlePosList" :key="index">
          <div
            :class="
              index % 3 == 0 ? 'div-b' : index % 3 == 1 ? 'div-g' : 'div-y'
            "
            :style="{ left: item.left, top: item.top }"
          ></div>
        </div>

          <p
          id="birth"
          class="p-pos"
          :style="{ left: myPos.left, top: myPos.top }"
          v-on:mouseup="mouseUp"
        >
          落伞点
        </p>
        </div>
        
        
       
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "TeamBirth",
  props: {
    matchDetail: {},
    battlePosList: [],
    myPos: {},
    job: {},
  },
  emits: ["setBirthPosition"],
  data() {
    return {
      isLeaveDiv: true,
      iscanMove: this.job,
      showBtn:false,
    };
  },
  watch: {},
  computed: {},
  created() {},
  methods: {
    getCoordinates(e) {
      if (!this.isLeaveDiv && this.iscanMove != 3) {
       
      var birth = document.getElementById("birth");
      var par = document.getElementById("par");
       
       birth.style.left = e.clientX - par.getBoundingClientRect().left -20+ "px";
       birth.style.top = e.clientY - par.getBoundingClientRect().top -50+ "px";
        
      }
    },
    mouseUp() {
      // var birth = document.getElementById("birth");
      // console.log(birth.style.left, birth.style.top);
      // this.$emit("setBirthPosition", birth.style.left, birth.style.top);
      this.isLeaveDiv = !this.isLeaveDiv;
      this.showBtn = true;
      
    },
    mouseDivUp() {
      this.isLeaveDiv = !this.isLeaveDiv;
      this.showBtn = true;
    },
    clickSetPosition() {
      this.isLeaveDiv = false;
    },
    clickOK() {
      var birth = document.getElementById("birth");
      console.log(birth.style.left, birth.style.top);
      this.showBtn = false;
      this.$emit("setBirthPosition", birth.style.left, birth.style.top);
    },
    clickCancel() {
      this.showBtn = false;
      this.$emit("closeBirthDialog");
    },
  },
};
</script>

<style scoped="scoped" lang="less">
.mt-table {
  height: 900px;
  width: 1500px;
  position: relative;
   .el-input-group__append button.el-button {
    background-color:rgb(230, 88, 130);
  }
  // ::v-deep .el-range-input {
  //   background: black;
  //   color: white;
  // }
}

// /*最外层透明*/
// ::v-deep .el-table,
// ::v-deep .el-table__expanded-cell {
//   background-color: transparent !important;
// }
// /* 表格内背景颜色 */
// ::v-deep .el-table th,
// ::v-deep .el-table tr,
// ::v-deep .el-table td {
//   background-color: transparent !important;
//   // border: 0px;
// }

// .el-table::before {
//   height: 0px;
// }
// .el-table--border::after {
//   width: 0px;
// }
// .el-table--border {
//   border: none;
// }

.div-white {
  color: white;
  font-size: large;
}

.div-red {
  color: rgb(230, 88, 130);
  font-size: large;
}

.div-border2 {
  width: 1200px;
  height: 1200px;
  background-image: url(../../../static/map2.jpg);
	background-size: cover;
}

.div-border {
  width: 1200px;
  height: 1200px;
  background-image: url(../../../static/map.jpg);
	background-size: cover;
}

.bg {
  position:relative;
  width: 600px;
  height: 600px;
  left: 0px;
  top:0px;
}

.p-pos {
  position: relative;
  color: red;
  font-weight: bold;
  float:left
}

.div-b {
  background-color: blue;
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
}
.div-g {
  background-color: green;
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
}
.div-y {
  background-color: yellow;
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
}

.el-button--primary { //需要更改的按钮类型
  background: #07b43c !important;
  border-color: #07b43c !important;
}
//移入时按钮样式
.el-button--primary:hover {
  background: #07b43c !important;
  border-color: #07b43c !important;
  color: #FFF !important;
}
</style>
