<template>
  <div
    class="gamelist"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    v-loading="loading"
  >
    <div class="gamelist-c">
      <div class="g-top">战队搜索</div>
      <div class="t-con">
        <div class="g-c-top">
          <button
            @click="btChange(1)"
            :class="isShow == 1 ? 'ctop-button' : ''"
          >
            战队荣誉榜
          </button>
          <button
            @click="btChange(2)"
            :class="isShow == 2 ? 'ctop-button' : ''"
          >
            战队搜索
          </button>
          <button
            @click="btChange(3)"
            :class="isShow == 3 ? 'ctop-button' : ''"
          >
            玩家搜索
          </button>
        </div>

        <div v-if="isShow == 1" class="s-c-con">
          <div class="t-rank-div">TPP比赛荣誉</div>
          <div
            v-for="(item, index) in tppArray"
            :key="index"
            class="s-con-list-special"
          >
            <div>
              <TeamMatch :matchInfo="item[0]"></TeamMatch>
            </div>
            <div v-if="item.length == 3 || item.length == 2">
              <TeamMatch :matchInfo="item[1]"></TeamMatch>
            </div>
            <div v-if="item.length == 3">
              <TeamMatch :matchInfo="item[2]"></TeamMatch>
            </div>
          </div>

          <div class="t-rank-div">FPP比赛荣誉</div>
          <div
            v-for="(item, index) in fppArray"
            :key="index"
            class="s-con-list-special"
          >
            <div>
              <TeamMatch :matchInfo="item[0]"></TeamMatch>
            </div>
            <div v-if="item.length == 3 || item.length == 2">
              <TeamMatch :matchInfo="item[1]"></TeamMatch>
            </div>
            <div v-if="item.length == 3">
              <TeamMatch :matchInfo="item[2]"></TeamMatch>
            </div>
          </div>
        </div>

        <div v-if="isShow == 2" class="t-message-con">
          <div class="t-cash">
            <el-input
              v-model="inputBattle"
              placeholder="输入战队信息"
              class="input-search"
            >
              <template #append>
                <el-button @click="clickSearchTeam"
                  ><el-icon><search /></el-icon
                ></el-button>
              </template>
            </el-input>
          </div>
          <el-table class="t-cash-table" height="clac(90)" :data="teamlist">
            <!-- <el-table-column prop="teamID" align="center" label="ID">
              <template #default="scope">
                {{ scope.row.teamID ? scope.row.teamID : "" }}
              </template>
            </el-table-column> -->
             <el-table-column prop="logoUrl" align="center" label="战队logo">
              <template #default="scope">
                  <el-image
                    class="user-img"
                    :src="scope.row.logoUrl ? scope.row.logoUrl : img1"
                  ></el-image>
                  </template>
             </el-table-column>
            <el-table-column prop="teamName" align="center" label="战队名称">
              <template #default="scope">
                {{ scope.row.name ? scope.row.name : "" }}
              </template>
            </el-table-column>

            <!-- <el-table-column prop="teamLeader" align="center" label="战队队长">
              <template #default="scope">
                {{ scope.row.teamLeader ? scope.row.teamLeader : "" }}
              </template>
            </el-table-column>

            <el-table-column
              prop="teamLeader2"
              align="center"
              label="战队副队长"
            >
              <template #default="scope">
                {{ scope.row.teamLeader2 ? scope.row.teamLeader2 : "" }}
              </template>
            </el-table-column> -->
            <!-- <el-table-column prop="Score" align="center" label="战队积分">
              <template #default="scope">
                {{ scope.row.totalScore > 0 ? scope.row.totalScore : "0" }}
              </template>
            </el-table-column> -->
          </el-table>
        </div>

        <div v-if="isShow == 3" class="t-message-con">
          <div class="t-cash">
            <el-input
              v-model="inputPlayer"
              placeholder="输入玩家信息"
              class="input-search"
            >
              <template #append>
                <el-button @click="clickSearchPlayer"
                  ><el-icon><search /></el-icon
                ></el-button>
              </template>
            </el-input>
          </div>

          <el-table class="t-cash-table" height="clac(90)" :data="playerlist">
            <el-table-column prop="avatar" align="center" label="头像">
              <template #default="scope">
                <!-- <ComHeadImg
                  :id="scope.row.gameId"
                  :tf="scope.row.tpporfpp"
                  :img="scope.row.avatar ? scope.row.avatar : img1"
                  :isTop="2"
                  style="margin-left:1px;margin-right:1px"
                ></ComHeadImg> -->
                 <el-image
                    class="user-img"
                    :src="scope.row.avatar ? scope.row.avatar : img1"
                  ></el-image>
              </template>
            </el-table-column>
            <el-table-column prop="name" align="center" label="名称">
              <template #default="scope">
                {{ scope.row.name ? scope.row.name : "" }}
              </template>
            </el-table-column>
            <el-table-column
              prop="status"
              align="center"
              label="Pubg名称"
            >
            <template #default="scope">
                {{ scope.row.pubgName ? scope.row.pubgName : "" }}
              </template>
            </el-table-column>
            <el-table-column label="">
              <template #default="scope">
                <button @click="clickJoinMember(scope.row.id)">邀请</button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import imghead from "../../../static/head.jpg";
import img1 from "../../../static/teamlist-1.png";
import img2 from "../../../static/teamlist-2.png";
import { Search } from "@element-plus/icons";
import ComHeadImg from "@/components/common/ComHeadImg.vue";
import TeamMatch from "./TeamMatch.vue";
import { cGet,cPost } from "@/request/http-util.js";
import { ElMessage } from "element-plus";
export default {
  name: "TeamSearch",
  components: {
    Search,
    ComHeadImg,
    TeamMatch,
  },
  props: {
    gameId: Number,
  },
  data() {
    return {
      isShow: 1,
      inputTeam: "",
      inputPlayer: "",
      inputBattle: "",
      playerlist: [],
      teamlist: [],
      loading: false,
      teamId: 0,
      imghead: imghead,
      img1: img1,
      img2: img2,
      //   gameId: 0,
      img: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      matchInfoList: [],
      specialMatchInfoList: [],
      mber: [],
      userInfo: {},
      tppArray: [],
      fppArray: [],
    };
  },
  created() {
    this.init();
  },
  beforeUnmount() {
    // clearInterval(window.timer1);
  },
  methods: {
    init() {
      this.getMatchResult();
    },
    initMatchArray(originArr, resultArr){
      for (var i = 0; i < originArr.length; i++) {
        if (i + 1 > 2 && (i + 1) % 3 == 0) {
          var tmplist = [
            originArr[i - 2],
            originArr[i - 1],
            originArr[i],
          ];
          resultArr.push(tmplist);
        }
      }

      if (originArr.length % 3 == 1) {
        var tmplist2 = [
          originArr[originArr.length - 1],
        ];
        resultArr.push(tmplist2);
      } else if (originArr.length % 3 == 2) {
        var tmplist3 = [
          originArr[originArr.length - 2],
          originArr[originArr.length - 1],
        ];
        resultArr.push(tmplist3);
      }
    },
    //获取荣誉榜
    getMatchResult(){
  var userInfo = JSON.parse(localStorage.getItem("userInfo"));
      var teamid = localStorage.getItem("battleid");
      var par = {
        teamId: teamid,
        pageNum: 0,
        pageSize: 1000,
        memberId:userInfo.id,
      };
      var getURL = "app/matches/PUBG/Manager/getTeamSuccessByTeamId";
      var __this = this;
      cGet(getURL, par, true, false).then((res) => {
        if (res.code == 10000) {
          __this.matchInfoList = res.result.tpparray;
          __this.specialMatchInfoList = res.result.fpparray;

          __this.tppArray = [];
          __this.initMatchArray(__this.matchInfoList, __this.tppArray);
          __this.fppArray = [];
          __this.initMatchArray(__this.specialMatchInfoList, __this.fppArray);
        } else {
          ElMessage(res.message);
        }
      });
     
    },
    //列表切换
    btChange(num) {
      this.isShow = num;
      if (num == 1) {
       this.getMatchResult();
      }
    },
    radioChange(val) {
      if (val == "充值") {
        this.isdepoist = true;
      } else {
        this.isdepoist = false;
      }
    },
    commandValue(type, command) {
      return {
        type: type,
        command: command,
      };
    },
    dropdownClick(item) {
      const { type, command } = item;
      if (type === "baoming") {
        alert(1 + command);
      } else if (type === "quxiaobao") {
        alert(2 + command);
      } else if (type === "guanzhu") {
        alert(3 + command);
      }
    },
    clickSearchTeam() {
      var userInfo = JSON.parse(localStorage.getItem("userInfo"));
      var par = {
        key: this.inputBattle,
        pageNum: 0,
        pageSize: 1000,
        currentMemberId:userInfo.id,
      };
      var getURL = "app/gameteam/manager/queryTeam";
      var __this = this;
      cGet(getURL, par, true, false).then((res) => {
        if (res.code == 10000) {
          
          __this.teamlist = res.result;
        } else {
          ElMessage(res.message);
        }
      });
    },
    clickSearchPlayer() {
      var userInfo = JSON.parse(localStorage.getItem("userInfo"));
      var par = {
        key: this.inputPlayer,
        pageNum: 0,
        pageSize: 1000,
        currentMemberId:userInfo.id,
      };
      var getURL = "app/gameteam/manager/queryMember";
      var __this = this;
      cGet(getURL, par, true, false).then((res) => {
        if (res.code == 10000) {
          __this.playerlist = res.result;
        } else {
          ElMessage(res.message);
        }
      });
    },
    clickJoinMember(val) {
        var userInfo = JSON.parse(localStorage.getItem("userInfo"));
        var teamid = localStorage.getItem("battleid");
      var par = {
        teamId:teamid,
        fromId:userInfo.id,
        toId:val,
        type:1,
      };
      
      var getURL = "app/gameteam/manager/sendMemberRequest";
      cPost(getURL, par, true, false).then((res) => {
        ElMessage(res.message);
        if (res.code == 10000) {
            // this.updateBattleInfo();
        }
      });
    },
  },
};
</script>

<style scoped="scoped" lang="less">
.gamelist {
  width: 100%;
  height: 100%;
  position: relative;
}

.gamelist-c {
  height: calc(100%);
  margin: 0 auto;
  background-color: rgba(32, 32, 32, 0.6);
  border-radius: 5px;
  border: 1px solid #676666;
  box-sizing: border-box;
  position: relative;
}

.g-top {
  text-align: left;
  color: #f4f4f4;
  font-size: 1.1rem;
  background-image: linear-gradient(#161515, #2b2a2a);
  height: 3.2rem;
  line-height: 3.2rem;
  box-sizing: border-box;
  padding-left: 1.5rem;
}

.t-bot {
  width: 100%;
  left: 0;

  position: absolute;
  bottom: 0;
}
.tbutton {
  margin-top: 1em;
  background: #2f2f2f;
  border: 1px solid #676666;
  width: 100%;
  height: 2.7rem;
  line-height: 2.7rem;
  color: #efefef;
  letter-spacing: 2px;
  border-radius: 5px;
  cursor: pointer;

  img {
    position: relative;
    top: 0.4rem;
    height: 1.4rem;
  }
}
.t-con {
  padding: 0 0.6rem;
  margin-top: 2rem;
  height: 80%;
  .g-c-top {
    text-align: left;
    border-bottom: 1px solid #808080;
    button {
      background: #1f1e1f;
      color: #fff;
      padding: 0.4rem 1.2rem;
      border-left: 1px solid #808080;
      border-top: 1px solid #808080;
      border-right: 1px solid #808080;
      border-bottom: none;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      margin-right: 10px;
    }
    .ctop-button {
      background: #07b43c;
    }
  }

  .pl0 {
    padding-left: 0 !important;
  }
  .pr0 {
    padding-right: 0 !important;
  }
  .s-c-con {
    height: 100%;
    overflow-y: auto;
    position: relative;

    .el-col {
      box-sizing: border-box;
      padding: 5%;
      .g-con-list1 {
        border: 1px solid #363637;
        border-radius: 20px;
        overflow: hidden;
        .gcon_top {
          height: 2.4rem;
          background: #161617;
          line-height: 2.4rem;
          color: #fff;
          .active {
            color: #07b43c;
            overflow: hidden;
          }
        }
        .gcon_con {
          width: 100%;
          background: #222224;
          overflow: hidden;
          .gcon_con-l {
            width: 50%;
            float: left;
            box-sizing: border-box;
            padding: 5%;
            img {
              width: 90%;
              margin-left: 5%;
              width: 3.6rem;
              height: 3.6rem;
              border-radius: 50%;
            }
          }
          .text {
            width: 100%;
            color: #fff;
            height: 1.2rem;
            line-height: 1.2rem;
            font-size: 0.6rem;
          }
        }
      }
    }
  }

  .s-con-list {
    border: 1px solid #676666;
    width: 99.5%;
    position: relative;
    // border-radius: 5px;
    overflow: hidden;
    margin-bottom: 2rem;
    height: 11em;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .s-con-list-special {
    border: 1px solid #676666;
    width: 99.5%;
    position: relative;
    // border-radius: 5px;
    overflow: hidden;
    margin-bottom: 2rem;
    height: 14em;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  // .s-con-list div {
  //   width: 100%;
  //   height: 80em;
  // }

  .t-message-con {
    height: 100%;
    // overflow-y: auto;
    position: relative;
  }
  .t-rank-message {
    margin-top: 1em;
    height: 45%;
    // background: white;
    .t-message-b {
      margin-top: 0.5em;
      margin-left: 19em;
    }
  }

  .t-message-table {
    margin: 0%;
    width: 100%;
    height: 100%;
  }

  /*最外层透明*/
  ::v-deep .el-table,
  ::v-deep .el-table__expanded-cell {
    background-color: transparent !important;
  }
  /* 表格内背景颜色 */
  ::v-deep .el-table th,
  ::v-deep .el-table tr,
  ::v-deep .el-table td {
    background-color: transparent !important;
    // border: 0px;
  }

  .el-table::before {
    height: 0px;
  }
  .el-table--border::after {
    width: 0px;
  }
  .el-table--border {
    border: none;
  }

  .t-message-img {
    width: 2em;
    height: 1.5em;
  }

  .t-message-div {
    margin-left: 3em;
    margin-top: -2em;
  }

  .t-cash {
    height: 10%;
    width: 100%;
  }
  .t-cash-div {
    font-size: 25px;
    color: white;
  }
  .t-cash-table {
    width: 100%;
    height: 90%;
    color: white;
  }

  .input-search {
    margin-top: 3px;
  }
  .t-match-div {
    width: 100%;
    color: white;
    background: #07b43c;
  }

  .t-match-item {
    width: 50%;
    height: 55em;
  }

  .t-rank-div {
    margin-top: 1em;
    background: #07b43c;
    color: white;
    font-size: 20px;
    height: 1.5em;
  }

  .user-img {
    width: 4em;
    height: 4em;
    border-radius: 50%;
  }
}
</style>
