<template>
	<div class="top">
		<img src="../../static/user/user-2.png" />
		<el-upload
		    class="avatar-uploader"
		    :action="url"
		    :show-file-list="false"
		    :on-success="handleAvatarSuccess"
		    :before-upload="beforeAvatarUpload"
		  >
		    <img v-if="userInfo.avatar" :src="userInfo.avatar" class="avatar" />
			<span @click="headBtn" class="iconfont">&#xe663;</span>
		  </el-upload>
		  
		<span class="name">{{userInfo&&userInfo.name?userInfo.name:userInfo.phone}}</span>
		<span class="time">创建时间: {{createTime}}</span>
	</div>
</template>

<script>
	import { ElMessage } from 'element-plus'
	import { timeChange } from '../request/common.js'
	export default{
		data(){
			return {
				userInfo:{},
				createTime:'暂无',
				url:""
			}
		},
		created() {
			this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
			this.url = "/ccc?act=edittx&token="+localStorage.getItem('token');
			this.createTime = timeChange(this.userInfo.createTime);
			console.log(this.userInfo);
		},	
		methods:{
			handleAvatarSuccess(res) {
				if(res.code == 1) {
					this.userInfo.avatar = res.imgs;
					localStorage.setItem("userInfo",JSON.stringify(this.userInfo));
					this.$store.commit("headChange");
				} else {
					ElMessage("上传失败");
				}
			}
		}
	}
</script>

<style lang="less" scoped="scoped">
	.top {
		background-color: #0C0C0C;
		position: relative;
		span {
			position: absolute;
			left: 112px;
			color: #fff;
			bottom: 65px;
			font-size: 25px;
			z-index: 15;
			cursor: pointer;
		}
		.name {
			left: 240px;
			top: 60px;
			font-weight: 600;
		}
		.time {
			left: 240px;
			font-size: 12px;
			top: 105px;
			background: #1e1e1e;
			border: 1px solid #2e2e2e;
			border-radius: 5px;
			color: #bbb;
			display: inline-block;
			height: 25px;
			line-height: 25px;
			width: 180px;
			text-align: center;
		}
		.avatar {
			position: absolute;
			top: 43px;
			border-radius: 50%;
			height: 102px;
			width: 102px;
			left: 112px
		}
		img{
			height: 190px;
		}
	}
</style>
