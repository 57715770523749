<template>
	<el-dialog
	v-model="isShow"
	custom-class="dialogbd"
	:title="show==1?'绑定游戏里的名称':'修改信息'"
	@close="fwqEnd"
	width="25%">
		<el-row>
			<el-col class="xg-top" :span="24">
				<img class="user-head" :src="userInfo.avatar?userInfo.avatar:img1"/>
				<span>{{userInfo.name?userInfo.name:"××××"}}</span>
			</el-col>
			<el-col v-if="show == 1" class="con-title" :span="6">
				当前游戏ID
			</el-col>
			<el-col v-if="show == 1" class="con-nr" :span="18">
				<div class="con-nr-div">
					<el-input v-model="gamecount" placeholder="" />
				</div><br>
				<span>请输入绝地求生名称 (请保持大小写一致)</span>
			</el-col>
			<el-col v-if="show != 1" class="con-title" :span="6">
				服务器
			</el-col>
			<el-col v-if="show != 1" class="con-nr" :span="18">
				<el-select v-model="serve" placeholder="Select">
				    <el-option
				      v-for="(item,i) in SERVE"
				      :key="i"
				      :label="item"
				      :value="i">
				    </el-option>
				</el-select>
			</el-col>
			<el-col v-if="show != 1" class="con-title" :span="6">
				等级
			</el-col>
			<el-col v-if="show != 1" class="con-nr" :span="18">
				<el-select v-model="level" placeholder="Select">
				    <el-option
				      v-for="(item,i) in LEVEL"
				      :key="i"
				      :label="item"
				      :value="i">
				    </el-option>
				</el-select>
			</el-col>
			<el-col v-if="show != 1" class="con-title" :span="6">
				模式
			</el-col>
			<el-col v-if="show != 1" class="con-nr" :span="18">
				<el-radio v-for="(item,i) in NORM" v-model="model" :label="i">{{item}}</el-radio>
			</el-col>
			<el-col class="con-title" :span="6">
				
			</el-col>
			<el-col class="con-nr" :span="18">
				<el-popconfirm  
				v-if="show == 1" 
				confirmButtonText="确定修改"
				cancelButtonText="取消绑定"
				@confirm="subBtn"
				:title="'用户名绑定后不可修改，您绑定的账号是('+gamecount+')'">
				    <template #reference>
				      <el-button>确认</el-button>
				    </template>
				</el-popconfirm>
				<el-button v-if="show != 1" @click="subBtn()" size="medium" type="primary">确认</el-button>
			</el-col>
		</el-row>
	</el-dialog>
</template>

<script>
	import img1 from '../../../static/head.jpg'
	import Com from "@/request/common.js";
	import {cGet} from "@/request/http-util.js";
	import { ElMessage } from 'element-plus';
	export default{
		name:"TanXgfwq",
		props:{
			show:Number
		},
		data(){
			return {
				img1:img1,
				LEVEL:Com.LEVEL,
				SERVE:Com.SERVE,
				NORM:Com.NORM,
				level:0,
				serve:0,
				model:0,
				gamecount:'',
				isShow:false,
				userInfo:{}
			}
		},
		created() {
			this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
		},
		methods:{
			open(){
				this.isShow = true;
				this.getInfo();
			},
			getInfo() {
				var par = {
					gameid:localStorage.getItem("gameId")
				};
				cGet("getmygameinfo",par,false,false).then(res => {
					if (res.code == 1){
						this.level = res.data.mygame.lv?parseInt(res.data.mygame.lv):0;
						this.serve = res.data.mygame.ser?parseInt(res.data.mygame.ser):0;
						this.model = res.data.mygame.tpporfpp?parseInt(res.data.mygame.tpporfpp):0;
					}
				})
			},
			subBtn() {

				if (this.show != 1) {
					var par = {
						lv:this.level,
						ser:this.serve,
						tpporfpp:this.model,
						gameId:localStorage.getItem("gameId")
					};
					
					cGet("changegame",par).then(res => {
						if (res.code == 1){
							if(this.show != 3)ElMessage("修改成功！");
							this.isShow = false;
							this.fwqEnd();
						}
						
					})
				}
				
				if (this.show == 1) {
					
					if (!this.gamecount) {
						ElMessage("游戏ID不能为空！");
						return ;
					}
					
					cGet("BindPubg",{name:this.gamecount}).then(res => {
						if (res.code == 1){
							if(this.show == 1) this.$emit("enterG");
							localStorage.setItem("GameData",JSON.stringify(res.data.GameData));
							this.isShow = false;
						}
					})
				}

			},
			
			fwqEnd() {
				if(this.show != 1)this.$parent.fwqEnd();
			}
		}
	}
</script>

<style lang="less" scoped="scoped">
	.dialogbd {
		height: 50%;
	}
	.xg-top {
		height: 3.2rem;
		margin-bottom: 1rem;
		line-height: 3.2rem;
		text-align: center;
		.user-head{
			    border-radius: 50%;
			    width: 3.2rem;
			    position: absolute;
			    left: 50%;
			    margin-left: -5.2rem;
			    height: 3.2rem;	
		}
		span{
			margin-left: 2rem;
		}
	}
	
	
	.con-title {
		line-height: 2.4rem;
		margin-bottom: 1.5rem;
	}
	
	.con-nr {
		text-align: left;
		margin-bottom: 1.5rem;
		.con-nr-div {display: inline-block;}
		button{
			height: 2rem;
			width: 10rem;
			background-color: #07b43c;
			color:#e9e9ea;
			border:none;
			letter-spacing: 3px;
			border-radius: 2px;
		}
	}
</style>
