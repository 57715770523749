<template>
	<el-popover
	    :width="450"
		@show="showAct"
		@hide="hideAct"
		placement="left-start"
		popper-class="imgHeadPoper"
	    trigger="hover">
	    <template #reference>
			<img :class="isTop==1?'head-img': (isTop==2 ? 'userlist-img' : '')" :src="img" />
	    </template>
		
		<div
		element-loading-background="rgba(0, 0, 0, 0.8)"
		v-loading="loading"
		class="user-con">
		<div class="btns">
			<el-button @click="mChange(1)" size="small" :type="mshow == 1?'success':'info'">TPP</el-button>
			<el-button @click="mChange(2)" size="small" :type="mshow == 2?'success':'info'">FPP</el-button>
		</div>
			<el-row :gutter="30">
				<el-col :span="24">
					<div class="pm-l">
						<div class="pm-l-top">
							<img src="../../../static/qiang.png">
							<span>竞技四排</span>
						</div>
						<div class="pm-l-mid" style="height: 65px;line-height: 24px;">
							<span style="margin-top: 10px;display: inline-block;">RP: {{pE(four,'currentRankPoint')}}</span><br>
							<span>段位: 
								<template v-if="four && four.currentTier">
									{{pw[four.currentTier.tier]}} {{pw1[four.currentTier.subTier]}}
								</template>
								<template v-else>0</template>
							</span>
							<div>
								总场数：{{pE(four,'roundsPlayed')}}  <br>
								吃鸡数：{{pE(four,'wins')?pE(four,'wins'):0}}
							</div>
						</div>
						<div v-if="four" class="pm-l-bot">
							<div class="pm-l-botl">
								<div class="pm-l-bot1" style="height: 65px;line-height: 18px;">
									K/D<br>
									{{numC1(four.kills,four.deaths)}}
								</div>
								<div class="pm-l-bot3">
									<span>吃鸡率</span>
									<span class="fr">{{numC1(four.wins,four.roundsPlayed,2)}}%</span>
								</div>
								<div class="pm-l-bot2">
									<span>前十率</span>
									<span class="fr">{{numC1(four.top10Ratio,1,2)}}%</span>
								</div>
								<div class="pm-l-bot3">
									<span>爆头率 </span>
									<span class="fr">{{numC1(four.headshotKillRatio,1,2)}}%</span>
								</div>
							</div>        
							<div v-if="four" class="pm-l-botr">
								<div class="pm-l-bot1" style="height: 65px;line-height: 18px;">
									场均伤害<br>
									{{numC1(four.damageDealt,four.roundsPlayed)}}
								</div>
								<div class="pm-l-bot3">
									<span>前十数</span>
									<span class="fr">{{parseInt(four.roundsPlayed*four.top10Ratio)}}</span>
									
								</div>             
								<div class="pm-l-bot2">
									<span>场均存活时间</span>
									<span class="fr">{{numC3(four.avgSurvivalTime,1)}}m</span>
								</div>
								<div class="pm-l-bot3">
									<span>场均伤害量 </span>
									<span class="fr">{{numC1(four.damageDealt,four.roundsPlayed)}}</span>
								</div>
							</div>
						</div>
						
						<div v-else style="height: 215px;line-height: 215px;" class="pm-l-bot wzj">
							暂无战绩
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
	</el-popover>
</template>

<script>
	import imgHead from '../../../static/head.jpg'
	import {cGet} from "@/request/http-util.js";
	
	export default{
		name:"ComHeadImg",
		props:{
			isTop:Number,
			id:Number,
			img:String,
			tf:Number
		},
		data() {
			return {
				gameId:0,
				loading:false,
				imgHead:imgHead,
				one:[],
				two:[],
				three:[],
				four:[],
				GameModeStats:{},
				GameModeSw:{},
				mshow:1,
				pw:{
					"Bronze":"青铜",
					"Silver":"白银",
					"Gold":"黄金",
					"Platinum":"铂金",
					"Diamond":"钻石",
					"Master":"大师"
				},
				pw1:{
					1:"I",
					2:"II",
					3:"III",
					4:"IV",
					5:"V"
				},
				tips:'加载中...'
			}
		},
		created() {
			this.gameId = localStorage.getItem("gameId");
			console.log(this.tf);
		},
		methods:{
			showAct(){
				/*if (!localStorage.getItem('PubgSeason') ) {
					cGet('GetPubgSeasonList',{},false,false).then(res => {
						if (res.code == 1) {
							var sid = "";
							res.data.filter(item => {
								sid = item.value;
							});
							localStorage.setItem('PubgSeason',sid);
							this.getData();
						}
					})
				} else {*/
					this.mshow = 1
					this.getData();
				//}
			},
			getData(){
				this.tips = '加载中...';

					cGet('GetPUBGData',{seasonId:localStorage.getItem('PubgSeason'),id:this.id},false,false).then(res => {
					if(res.code == 1) {
						res.data.SeasonStats = JSON.parse(res.data.SeasonStats);
						res.data.RankedSeasonStats = JSON.parse(res.data.RankedSeasonStats);
						
						if((Object.keys(res.data)).length > 0) {
							if (typeof(res.data.RankedSeasonStats.data.attributes.rankedGameModeStats.squad) != 'undefined')
							this.four = res.data.RankedSeasonStats.data.attributes.rankedGameModeStats.squad;
							
							if (typeof(res.data.SeasonStats.data.attributes.gameModeStats.squad) != 'undefined')
							this.three = res.data.SeasonStats.data.attributes.gameModeStats.squad;
							
							if (typeof(res.data.SeasonStats.data.attributes.gameModeStats) != 'undefined')
							this.GameModeStats = res.data.SeasonStats.data.attributes.gameModeStats;
							if (typeof(res.data.RankedSeasonStats.data.attributes.rankedGameModeStats) != 'undefined')
							this.GameModeSw = res.data.RankedSeasonStats.data.attributes.rankedGameModeStats;
							
							this.four.weeklyWins = this.three?this.three.weeklyWins:0;
							this.four.weeklyKills = this.three?this.three.weeklyKills:0;
							this.four.longestTimeSurvived = this.three?this.three.longestTimeSurvived:0;
							
						} else {
							this.tips = "未找到该游戏数据";
						}
					} else {
						this.tips = "未找到该游戏数据";
					}
				})
			},
			
			//模式改变
			mChange(num) {
				this.mshow = num;
				if (num == 1) {
					this.three = this.GameModeStats.squad;
					this.four = this.GameModeSw.squad;
				} else {
					this.three = this.GameModeStats.squadfpp;
					this.four = this.GameModeSw.squadfpp;
				}
				
				this.four.weeklyWins = this.three?this.three.weeklyWins:0;
				this.four.weeklyKills = this.three?this.three.weeklyKills:0;
				this.four.longestTimeSurvived = this.three?this.three.longestTimeSurvived:0;
			},
			
			hideAct(){
				this.tips = '加载中...';
				this.gameData = {};
			},
			//
			pE(str,k,num=1){
				if (typeof(str) != 'undefined' && str) {
					return typeof(str[k]) != 'undefined'?str[k]:"";
				} else {
					return num==1?0:"-";
				}
			},
			//两数相除
			numC1(one,two,state = 1) {
				if (state == 1) {
					if (two == 0) return one.toFixed(2);
					return (one/two).toFixed(2);
				} else {
					if (one == 0) return 0.00;
					if (two == 0) return 100.00;
					return ((one/two)*100).toFixed(2);
				}
			},
			numC2(one) {
				if (!one) return 0.00;
				return one.toFixed(2);
			},
			//两数相除
			
			numC3(one,two) {
			
				if (!one) return 0.00;
				return ((one/two)/60).toFixed(2);
			}
		}
	}
</script>

<style lang="less" scoped="scoped">
	.headimgT {
		height: 200px;
		line-height: 200px;
		width: 100%;
		text-align: center;
	}
		.btns {
			margin-bottom:30px;
		}
	.wzj {
		text-align: center;
		color: #fff;
	}
	
	.head-img {
		width: 30px;
		height: 30px;
		border-radius: 50%;
		position: absolute;
		top: 11px;
	}

	.userlist-img {
		width: 4rem;
		height: 4rem;
		border-radius: 50%;
		position: absolute;
		top: 5px;
		left: 20px
	}
	
	.user-con {
		height: 100%;
		padding: 10px;
		overflow-x: hidden;
		position: relative;
		
		.pm-l-mid div{
		    float: right;
		    margin-right: 10px;
		    margin-top: -24px;
		    font-size: 16px;
		    color: #fff;
		}
		.el-row {
			height: calc(100% - 62px);
			
			.el-col {
				height: 100%;
				.pm-l {
					width: 100%;
					border:1px solid #6b6b6b;
					border-radius: 8px;
					overflow: hidden;
				}
				
				.pm-l-top {
					height: 50px;
					line-height: 50px;
					text-align: left;
					width: 100%;
					background-image: linear-gradient(#161515, #2b2a2a);
					img {margin-left: 20px;position: relative;top:4px}
					span {color:#fff;margin-left: 20px;font-size: 16px;}
				}
				
				.pm-l-mid {
					height: 50px;
					line-height: 50px;
					text-align: left;
					border-bottom: 1px solid #6b6b6b;
					span {color:#fff;margin-left: 20px;font-size: 16px;}
				}
				
				.pm-l-bot{
					width: 100%;
					
					.pm-l-botl {
						width: 50%;
						float: left;
						border-right: 1px solid #6b6b6b;
						box-sizing: border-box;
					}
					.pm-l-botr {
						width: 50%;
						float: left;
					}
					
					.pm-l-bot1 {
						height: 80px;
						line-height: 25px;
						width: 100%;
						box-sizing: border-box;
						padding: 15px 25px 0;
						font-size: 16px;
						color: #fff;
						overflow: hidden;
						text-overflow:ellipsis;
						white-space: nowrap;
					}
					
					.pm-l-bot2 {
						height: 50px;
						line-height: 50px;
						width: 100%;
						box-sizing: border-box;
						padding: 0 25px;
						font-size: 14px;
						color: #7c7d7f;
						overflow: hidden;
						text-overflow:ellipsis;
						white-space: nowrap;
					}
					.pm-l-bot3 {
						height: 50px;
						line-height: 50px;
						width: 100%;
						box-sizing: border-box;
						padding: 0 25px;
						font-size: 14px;
						color: #7c7d7f;
						background-color: #2b2b2b;
						overflow: hidden;
						text-overflow:ellipsis;
						white-space: nowrap;
					}
					
					.fr {float: right;}
				}
				
				
			}
		}
		
	}

</style>
