<template>
  <div class="game">
    <Head></Head>
    <Left></Left>

    <div class="contain">
      <span class="top">联盟平台</span>
      <el-row v-if="isHaveLeague" gutter="24">
        <el-col :span="8">
          <LeagueManager
            :leagueInfo="leagueInfo"
          ></LeagueManager>
        </el-col>
        <el-col :span="8">
          <LeagueChat></LeagueChat>
        </el-col>
       <el-col :span="8">
          <LeagueMatch></LeagueMatch>
        </el-col>
      </el-row>
      <el-row v-if="!isHaveLeague" gutter="24">
        <el-col :span="8">
          <LeagueCreate></LeagueCreate>
        </el-col>
        <el-col :span="8">
          <LeagueJoin></LeagueJoin>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import Head from "@/components/Head.vue";
import Left from "@/components/Left.vue";
import LeagueManager from "@/components/leagueplatform/LeagueManager.vue";
import LeagueChat from "@/components/leagueplatform/LeagueChat.vue";
import LeagueMatch from "@/components/leagueplatform/LeagueMatch.vue";
import LeagueCreate from "@/components/leagueplatform/LeagueCreate.vue";
import LeagueJoin from "@/components/leagueplatform/LeagueJoin.vue";
export default {
  name: "LeaguePlatform",
  components: {
    Head,
    Left,
    LeagueManager,
    LeagueChat,
    LeagueMatch,
    LeagueCreate,
    LeagueJoin,
  },

  computed: {
    // isFollow() {
    //   return this.$store.state.isTm; //需要监听的数据
    // },
    // isFollow1() {
    //   return this.$store.state.isToRc; //需要监听的数据
    // },
  },
  watch: {
    // isFollow(newVal, oldVal) {
    //   this.uC();
    // },
    // isFollow1(newVal, oldVal) {
    //   this.$router.push({ name: "GameRoom" });
    // },
  },
  data() {
    return {
      isShow: 1,
      gameName: localStorage.getItem("gameName"),
      gameId: localStorage.getItem("gameId"),
      isHaveLeague:false,
      leagueInfo:{},

    };
  },
   created() {
    var battleID = localStorage.getItem("leagueid");
    
    if(battleID){
      this.isHaveLeague = true;
      this.leagueInfo = JSON.parse(localStorage.getItem("leagueInfo"))
    } 
  },
  methods: {
    
  },
};
</script>

<style lang="less" scoped="scoped">
.contain {
  padding: 20px 0;
}
.game {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
.top {
  position: absolute;
  top: 2rem;
  left: 50%;
  margin-left: -95px;
  font-size: 2.2rem;
  font-weight: 600;
  color: #fff;
  letter-spacing: 2px;
  background: linear-gradient(to right, #ada59b, #d1d0cf);
  -webkit-background-clip: text;
  color: transparent;
}

.el-row {
  width: 96%;
  margin-left: 2% !important;
  height: calc(100% - 10rem);
  margin-top: 6.5rem;
  .el-col {
    height: 100%;
  }
}

::v-deep {
  .el-dialog {
    .el-dialog__title {
      color: #fff;
    }
    .el-dialog__body span {
      color: #fff;
    }
    .qdBtn {
      background-color: #07b43c;
      border-color: #07b43c;
      color: #fff;
    }
    .qxBtn {
      background-color: #ff5602;
      border-color: #ff5602;
      color: #fff;
    }
  }
}
</style>
