<template>
  <div class="">
    <div>{{countDownTime}}</div>
  </div>
</template>
<script>
export default {
    props: {
     endTimeProp:String,
  },
  data() {
    return {
        endTime:this.endTimeProp,
        countDownTime: '',
    }
  },
  created() {
     this.countDown();
  },
  methods: {
  //倒计时
    countDownFun(time) {

      let startTime = new Date(); //当前时间
      let end = new Date(time); //结束时间
      // console.log(end)
      let result = parseInt((end - startTime) / 1000); //计算出豪秒
      let d = parseInt(result / (24 * 60 * 60)); //用总共的秒数除以1天的秒数
      let h = parseInt((result / (60 * 60)) % 24); //精确小时，用去余
      let m = parseInt((result / 60) % 60); //剩余分钟就是用1小时等于60分钟进行趣余
      let s = parseInt(result % 60);
      //当倒计时结束时，改变内容
      if (result <= 0) {
        return "倒计时结束";
      }
      if (h < 10) {
        h = "0" + h;
      }
      if (s < 10) {
        s = "0" + s;
      }
      if (h == 0 && m == 0) {
        return "比赛开始时间" + s + "秒";
      } else if (h == 0) {
        return "比赛开始时间" + m + "分" + s + "秒";
      } else if(d == 0) {
        return "比赛开始时间" + h + "时" + m + "分" + s + "秒";
      } else {
        return "比赛开始时间" + d + "天" + h + "时" + m + "分" + s + "秒";
      }
    },
    
    // 定时器
    // 页面多个倒计时 归零时清除
    countDown() {
        let that = this;
        var timer = setInterval(()=>{
            that.countDownTime = that.countDownFun(that.endTime);

            if (that.countDownTime == "倒计时结束") {
                clearInterval(timer);
            }
            
             
        }, 1000);
    }
    
  }
};
</script>
<style scoped lang="less">
</style>