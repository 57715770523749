<template>
  <div class="teamsingleuser">
    <el-tooltip
      class="item"
      effect="light"
      placement="top"
      :content="tipContent"
    >
      <el-dropdown
        trigger="click"
        placement="bottom-end"
        @command="dropdownClick"
      >
        <el-image class="teamuser-img" :src="userInfo.member.avatar">
        </el-image>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item
              :command="commandValue('shezhi')"
              v-if="userCaption == 1 && user.job != 1 && user.job != 2"
              >设置副队长</el-dropdown-item
            >
            <el-dropdown-item
              :command="commandValue('quxiao')"
              v-if="userCaption == 1 && user.job == 2"
              >取消副队长</el-dropdown-item
            >
            <el-dropdown-item :command="commandValue('chat')"
              >私聊</el-dropdown-item
            >
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </el-tooltip>
    <div class="teamuser-text">{{ userInfo.member.name }}</div>
    <el-button
      v-if="userCaption == 1 || userCaption == 2"
      class="teamuser-btn"
      type="primary"
      circle
      size="mini"
      @click="clickVoice"
      ><el-icon
        ><Mute v-if="user.silentFlg == 1"></Mute>
        <Mic v-if="user.silentFlg == 0"></Mic> </el-icon
    ></el-button>
  </div>
</template>

<script>
import { Mic } from "@element-plus/icons";
import { Mute } from "@element-plus/icons";
import { cPostParam, cGet } from "@/request/http-util.js";
import { ElMessage, ElMessageBox } from "element-plus";

export default {
  name: "TeamSingleUser",
  props: {
    userInfo: {},
    userJob: Number,
    battleID: String,
  },
  components: {
    Mute,
    Mic,
  },
  data() {
    return {
      user: this.userInfo,
      tipContent: "",
      userCaption: this.userJob,
      teamID: this.battleID,
      job: this.userJob,
      img: "./image/u22.png",
    };
  },
  computed() {
    //   userMemberList(){
    //       return this.userList;
    //   },
  },
  created() {
    if (this.user.job == 1) {
      this.tipContent = "队长";
    } else if (this.user.job == 2) {
      this.tipContent = "副队长";
    } else {
      this.tipContent = "队员";
    }
  },
  methods: {
    commandValue(type, command) {
      return {
        type: type,
        command: command,
      };
    },
    dropdownClick(item) {
      const { type, command } = item;
      if (type === "shezhi") {
        ElMessageBox.confirm("您确定要设置副队长吗?", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            var data = {
              job: 2,
              memberId: this.user.member.id,
              silentFlg: 1,
              teamId: this.teamID,
            };
            var userInfo = JSON.parse(localStorage.getItem("userInfo"));
            var par = {
              manageMemberId: userInfo.id,
            };

            var getURL = "app/gameteam/manager/setSecondTeamLeader";
            cPostParam(getURL, par, data, true, false).then((res) => {
              ElMessage(res.message);
              if (res.code == 10000) {
                this.updateBattleInfo();
              }
            });
          })
          .catch(() => {});
      } else if (type === "quxiao") {
        ElMessageBox.confirm("您确定要取消副队长吗?", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            var data = {
              job: 2,
              memberId: this.user.member.id,
              silentFlg: 1,
              teamId: this.teamID,
            };
            var userInfo = JSON.parse(localStorage.getItem("userInfo"));
            var par = {
              manageMemberId: userInfo.id,
            };

            var getURL = "app/gameteam/manager/releaseSecondTeamLeader";
            cPostParam(getURL, par, data, true, false).then((res) => {
              ElMessage(res.message);
              if (res.code == 10000) {
                this.updateBattleInfo();
              }
            });
          })
          .catch(() => {});
      }
      else if(type == "chat") {
        var userInfo = JSON.parse(localStorage.getItem("userInfo"));
        if(userInfo.id == this.user.member.id) {
          return;
        }
        var chatList = this.$store.state.chatList;
        chatList.push({name:this.user.member.name, key:this.user.member.id});
        this.$store.commit("chatListChange", chatList);
      }
    },
    updateBattleInfo() {
      var battleID = localStorage.getItem("battleid");
      var getURL = "app/gameteam/manager/getTeamByTeamId/";
      var par = {
        teamId: battleID,
      };

      cGet(getURL, par, true, false).then((res) => {
        if (res.code == 10000) {
          if (res.result) {
            localStorage.setItem("battleinfo", JSON.stringify(res.result));
          }
        } else {
          ElMessage(res.message);
        }
        this.$store.commit("memberChage");
      });
    },
    clickVoice() {
      var data = {
        job: this.job,
        memberId: this.user.member.id,
        silentFlg: this.user.silentFlg == 0 ? 1 : 0,
        teamId: this.teamID,
      };
      var userInfo = JSON.parse(localStorage.getItem("userInfo"));
      var par = {
        manageMemberId: userInfo.id,
      };
      var getURL = "app/gameteam/manager/changeSilentFlg";
      cPostParam(getURL, par, data, true, false).then((res) => {
        ElMessage(res.message);
        if (res.code == 10000) {
          if (this.user.silentFlg == 0) {
        this.user.silentFlg = 1;
      } else if (this.user.silentFlg == 1) {
        this.user.silentFlg = 0;
      }
          this.updateBattleInfo();
        }
      });
    },
  },
};
</script>

<style scoped="scoped" lang="less">
.teamsingleuser {
  height: 100%;
  width: 100%;
  position: relative;
}

.teamuser-img {
  margin-top: 0.5rem;
  width: 6em;
  height: 6em;
  border-radius: 50%;
}

.teamuser-btn {
  position: absolute;
  top: 5em;
  left: 7em;
  z-index: 10;
}

.teamuser-text {
  width: 100%;
  font-size: 18px;
  color: white;
}
</style>
