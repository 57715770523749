<template>
  <div class="usercenter">
    <Head></Head>
	<Left></Left>
	
	<div class="contain-user">
		<Top></Top>
		<div class="user-con">
			<div class="suggest">
				<div class="top">
					我要反馈
				</div>
				<div class="mid">
					<el-row>
						<el-col :span="11">
							<span>昵&nbsp;&nbsp;&nbsp;&nbsp;称:</span>
							<input v-model="nickname" />
						</el-col>
						<el-col :offset="2" :span="11">
							<span>电&nbsp;&nbsp;&nbsp;&nbsp;话:</span>
							<input v-model="phone" />
						</el-col>
						<el-col :span="11">
							<span>邮&nbsp;&nbsp;&nbsp;&nbsp;箱:</span>
							<input v-model="email" />
						</el-col>
						<el-col :offset="2" :span="11">
							<span>验证码:</span>
							<input v-model="code" />
							<Yan class="yam" :identifyCode="identifyCode" @click="Refresh"></Yan>
						</el-col>
						<el-col :span="24">
							<el-input class="textarea" maxlength="250" show-word-limit type="textarea" v-model="textarea" />
						</el-col>
					</el-row>
				</div>
				
				<el-button @click="tjBtn" type="primary">提 交</el-button><el-button>重 置</el-button>
			</div>
		</div>
	</div>
  </div>
</template>
<script>
// @ is an alias to /src
import Head from '@/components/HeadUser.vue'
import Left from '@/components/LeftUser.vue'
import Top from '@/components/TopUser.vue'
import Yan from '@/components/common/ComYzm.vue'

import { cGet } from "@/request/http-util.js";
import { ElMessage } from 'element-plus'
export default {
	name: 'UserSuggest',
	components: {
		Head,
		Left,
		Yan,
		Top
	}, 
	data(){
		return {
			nickname:"",
			phone:"",
			email:"",
			code:"",
			textarea:"",
			identifyCode:"",
			identifyCodes:['1','2','3','4','5','6','7','8','9','0']
		}
	},
	created() {
		this.identifyCode = "";
		this.makeCode(this.identifyCodes, 4);
	},
	methods:{
		tjBtn() {
			
			if (!this.email || !this.phone) {
				ElMessage("联系方式不能为空");
				return false;
			}
			
			if (this.code != this.identifyCode) {
				ElMessage("验证码错误");
				this.Refresh();
				return false;
			}
					
			var par  = {
				nickname:this.nickname,
				phone:this.phone,
				email:this.email,
				cont:this.textarea
			}
			
			cGet("userfk",par).then(res => {
				if (res.code == 1) {
					ElMessage("提交成功");
					this.nickname = '';
					this.phone = '';
					this.email = '';
					this.textarea = '';
					this.code = '';
				}
			})
		},
		Refresh() {
			this.makeCode(this.identifyCodes, 4);
		},
		randomNum(min, max) {
		  return Math.floor(Math.random() * (max - min) + min);
		},
		refreshCode() {
		  this.identifyCode = "";
		  this.makeCode(this.identifyCodes, 4);
		},
		makeCode(o, l) {
			this.identifyCode = "";
		  for (let i = 0; i < l; i++) {
			this.identifyCode += this.identifyCodes[this.randomNum(0, 9)];
		  }
		}
	},
}
</script>

<style lang="less" scoped="scoped">
	
	.usercenter {
		text-align: left;
		.top {
			img{width: 100%;}
		}
		.user-con {
			.suggest {
				width: 70%;
				.top {
					font-size: 16px;
					color:#fff;
					padding: 20px 0 15px;
				}
				
				.mid {
					.el-col {
						position: relative;
						margin-bottom: 15px;
						input {
							height: 40px;
							line-height: 40px;
							width: 100%;
							background: #232323;
							border-radius: 4px;
							border:1px solid #434343;
							color: #FCFCFC;
							box-sizing: border-box;
							padding-left: 80px;
						}
						
						input:hover {
							border:1px solid #434343;
						}
						
						span {
							position: absolute;
							left: 20px;
							top: 10px;
							color:#FCFCFC;
						}
						
						.textarea {
							box-sizing: border-box;
							height: auto;
							line-height: 22px;
						}
						
						.yam {
							position: absolute;
							right: 0px;
							top: 0px;
						}
					}
					
				}
				
				.el-button {
					margin-right:20px;
				}
			}
		}
	}
</style>
