<template>
  <div
    class="gamelist"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    v-loading="loading"
  >
    <div
      style="
        color: white;
        font-size: 40px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 30rem;
        height: 10rem;
        text-align: center;
        line-height: 50px;
      "
      v-if="!isShowCreateVue"
    >
      联盟体量大于16支队伍后可申请创建比赛权限
    </div>
    <div class="gamelist-c" v-if="isShowCreateVue">
      <div class="g-top">比赛管理</div>
      <div class="t-con">
        <div class="m-c-top">
          <button
            @click="btChange(1)"
            :class="isShow == 1 ? 'ctop-button' : ''"
          >
            创建比赛
          </button>
          <button
            @click="btChange(2)"
            :class="isShow == 2 ? 'ctop-button' : ''"
          >
            申请管理
          </button>
          <button
            @click="btChange(3)"
            :class="isShow == 3 ? 'ctop-button' : ''"
          >
            比赛进行
          </button>
          <button
            @click="btChange(4)"
            :class="isShow == 4 ? 'ctop-button' : ''"
          >
            比赛数据
          </button>
        </div>

        <div v-if="isShow == 1" class="t-message-con">
          <div class="m-cr-con">
            <el-row>
              <div class="creatediv">比赛名称</div>
              <el-checkbox
                style="margin-top: -5px; margin-left: 10px"
                @change="clickLeagueCheck"
                v-model="checkLeagueModel"
                label="联盟"
              />
            </el-row>
            <el-row>
              <el-input v-model="gameInputName" placeholder="" />
            </el-row>
            <el-row>
              <el-col :span="12">
                <div class="creatediv">比赛类型</div>
              </el-col>
              <el-col :span="12">
                <div class="createright">服务器</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-select class="cselect" v-model="typevalue" placeholder="">
                  <el-option
                    v-for="item in typelist"
                    :key="item"
                    :label="item"
                    :value="item"
                  />
                </el-select>
              </el-col>
              <el-col :span="12">
                <el-select class="create-input" v-model="servervalue" placeholder="">
                  <el-option
                    v-for="item in serverlist"
                    :key="item"
                    :label="item"
                    :value="item"
                  />
                </el-select>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <div class="creatediv">比赛模式</div>
              </el-col>
              <el-col :span="12">
                <div class="createright">比赛队伍数</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">
                <el-select class="cselect" v-model="modellistovalue" placeholder="">
                  <el-option
                    v-for="item in modellisto"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-col>
              <el-col :span="6">
                <el-select class="cselect" v-model="modellisttvalue" placeholder="">
                  <el-option
                    v-for="item in modellistt"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-col>
              <el-col :span="12">
                <el-input
                  class="create-input"
                  v-model="teamlistvalue"
                  onkeyup="this.value=this.value.replace(/\D/g,'')"
                  onafterpaste="this.value=this.value.replace(/\D/g,'')"
                  placeholder=""
                  @change="changeTeamlistValue"
                />
                <!-- <el-select v-model="teamlistvalue" placeholder="">
                  <el-option
                    v-for="item in teamlist"
                    :key="item"
                    :label="item"
                    :value="item"
                  />
                </el-select> -->
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <div class="creatediv">比赛场次</div>
              </el-col>
              <el-col :span="12">
                <div class="createright">比赛币</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-select
                  class="cselect"
                  v-model="roundlistvalue"
                  placeholder=""
                >
                  <el-option
                    v-for="item in roundlist"
                    :key="item"
                    :label="item"
                    :value="item"
                  />
                </el-select>
              </el-col>
              <el-col :span="12">
                <el-select class="create-input" v-model="moneylistvalue" placeholder="">
                  <el-option
                    v-for="item in moneylist"
                    :key="item"
                    :label="item"
                    :value="item"
                  />
                </el-select>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <div class="creatediv">比赛时间</div>
              </el-col>
              <el-col :span="12">
                <div class="createright">小比赛时长(分钟)</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-config-provider :locale="zhCn">
                  <el-date-picker
                    v-model="matchstarttime"
                    type="datetime"
                    placeholder=""
                    format="YYYY.MM.DD-HH:mm:ss"
                    style="width: 100%"
                  />
                </el-config-provider>
              </el-col>
              <el-col :span="12">
                <el-input
                  class="create-input"
                  v-model="oncetimevalue"
                  onkeyup="this.value=this.value.replace(/\D/g,'')"
                  onafterpaste="this.value=this.value.replace(/\D/g,'')"
                  placeholder=""
                  @change="changeoncetimevalue"
                />
                <!-- <el-select v-model="oncetimevalue" placeholder="">
                  <el-option
                    v-for="item in oncetimelist"
                    :key="item"
                    :label="item"
                    :value="item"
                  />
                </el-select> -->
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <div class="creatediv">PUBG游戏玩家(指定关联一位参赛者)</div>
              </el-col>
              <el-col :span="12">
                <div class="createright">小比赛间隔(分钟)</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-input
                  style="width:100%"
                  v-model="gamePUBGPlayerName"
                  placeholder=""
                />
              </el-col>
              <el-col :span="12">
                <el-input
                  class="create-input"
                  v-model="intervalvalue"
                  onkeyup="this.value=this.value.replace(/\D/g,'')"
                  onafterpaste="this.value=this.value.replace(/\D/g,'')"
                  placeholder=""
                  @change="changeintervalvalue"
                />
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <div class="creatediv">比赛积分方式</div>
              </el-col>
              <el-col :span="12">
                <div class="createright">小比赛时间</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-row>
                  <el-col :span="6">
                    <div class="createwhite">排名</div>
                  </el-col>
                  <el-col :span="18">
                    <el-button @click="clickRankDetail">积分详细</el-button>
                  </el-col>

                  <!-- <el-checkbox
                    v-model="checkModel"
                    label="排名"
                    disabled="true"
                  /> -->
                  <!-- <el-button
                    @click="clickRankDetail"
                    class="sbutton"
                    size="mini"
                    >积分详细</el-button
                  > -->
                  <!-- <el-checkbox v-model="checked4" label="生存时间" /> -->
                </el-row>
                <el-row class="mrow">
                  <el-col :span="6">
                    <div class="createwhite">杀人</div>
                  </el-col>
                  <el-col :span="18">
                    <el-button @click="clickKillDetail">积分详细</el-button>
                  </el-col>
                  <!-- <el-checkbox
                    v-model="checkModel"
                    label="杀人"
                    disabled="true"
                  /> -->

                  <!-- <el-button
                    @click="clickKillDetail"
                    class="sbutton"
                    size="mini"
                    >积分详细</el-button
                  > -->
                  <!-- <el-checkbox v-model="checked4" label="助攻" /> -->
                </el-row>
                <el-row>
                  <!-- <el-checkbox v-model="checked3" label="伤害" />
                  <el-checkbox v-model="checked4" label="行驶距离" /> -->
                  <el-radio v-model="radiomap" label="1"
                    >艾伦格Erangel</el-radio
                  >
                  <el-radio v-model="radiomap" label="2">沙漠Miramar</el-radio>
                </el-row>
                <el-row>
                  <el-checkbox v-model="checkMap" label="标记落伞点" />
                </el-row>
              </el-col>
              <el-col :span="12">
                
                <!-- <el-select v-model="intervalvalue" placeholder="">
                  <el-option
                    v-for="item in intervallist"
                    :key="item"
                    :label="item"
                    :value="item"
                  />
                </el-select> -->
                <el-button @click="clickTimeDetail"> 详细比赛时间 </el-button>
                <div class="createright">比赛Logo</div>
                <el-upload
                  class="upload-demo"
                  :action="uploadLogoUrl"
                  :limit="1"
                  :on-preview="handlePreview"
                  :on-success="uploadSuccess"
                  :file-list="fileList"
                  :before-upload="beforeAvatarUpload"
                  list-type="picture"
                >
                  <el-button>请上传赛事Logo</el-button>
                </el-upload>
              </el-col>
            </el-row>
            <el-row>
              <div class="creatediv">比赛简介</div>
            </el-row>
            <el-row>
              <el-input
                v-model="gametextarea"
                :rows="1"
                type="textarea"
                placeholder=""
              />
            </el-row>
            <el-row>
              <el-button type="primary" class="cbutton" @click="clickCreate"
                >确定</el-button
              >
            </el-row>
          </div>
        </div>
        <div v-if="isShow == 2" class="t-message-con">
          <div class="c-c-con">
            <el-table
              class="t-message-bordertable"
              height="clac(100%)"
              ref="createMatchTable"
              :data="createMatchList"
              :show-header="false"
              highlight-current-row
              @row-click="handleCurrentChange"
              :row-style="rowStyle"
              :header-cell-style="{ color: '#fff' }"
            >
              <el-table-column label="消息">
                <template v-slot="scope">
                  <div v-if="scope.row.id == currentRowId">
                    {{ scope.row.name }}-----已加入：{{ currentJoinNumber }}
                  </div>
                  <div v-if="scope.row.id != currentRowId">
                    {{ scope.row.name }}
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <!-- <div
              class="c-pre-list"
              v-for="(item, index) in battlelist"
              :key="index"
            >
              <div>
                <BattleTeam :matchInfo="item[0]"></BattleTeam>
              </div>
              <div
                v-if="
                  item.length == 5 ||
                  item.length == 4 ||
                  item.length == 3 ||
                  item.length == 2
                "
              >
                <BattleTeam :matchInfo="item[1]"></BattleTeam>
              </div>
              <div
                v-if="item.length == 5 || item.length == 4 || item.length == 3"
              >
                <BattleTeam :matchInfo="item[2]"></BattleTeam>
              </div>
              <div v-if="item.length == 5 || item.length == 4">
                <BattleTeam :matchInfo="item[3]"></BattleTeam>
              </div>
              <div v-if="item.length == 5">
                <BattleTeam :matchInfo="item[4]"></BattleTeam>
              </div>
            </div> -->
          </div>
          <div class="c-message">
            <el-table
              class="t-message-bordertable"
              height="clac(100%)"
              :data="messagereaddata"
              :show-header="false"
              :cell-style="{ color: '#fff' }"
              :header-cell-style="{ color: '#fff' }"
            >
              <el-table-column width="50px">
                <el-image class="t-message-img" :src="img"></el-image>
              </el-table-column>
              <el-table-column label="消息">
                <template v-slot="scope">
                  <div v-if="scope.row.mode == 1">
                    {{ scope.row.teamName }}申请加入比赛
                  </div>
                  <div v-if="scope.row.mode == 0">
                    {{ scope.row.personName }}申请加入比赛
                  </div>
                </template>
              </el-table-column>
              <el-table-column>
                <template v-slot="scope">
                  <el-button size="mini" v-if="scope.row.status == -1"
                    >已拒绝</el-button
                  >
                  <el-button
                    size="mini"
                    v-if="scope.row.status == 0"
                    @click="clickDisAgree(scope.row)"
                    >拒绝</el-button
                  >
                  <el-button
                    size="mini"
                    v-if="scope.row.status == 1"
                    type="primary"
                    >已接受</el-button
                  >
                  <el-button
                    size="mini"
                    v-if="scope.row.status == 0"
                    type="primary"
                    @click="clickAgree(scope.row)"
                    >接受</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div v-if="isShow == 3" class="t-message-con">
          <el-table
            class="t-message-table"
            height="clac(100%)"
            :data="matchinglist"
            :show-header="false"
            :cell-style="{ color: '#fff' }"
            :header-cell-style="{ color: '#fff' }"
          >
            <el-table-column label="消息">
              <template v-slot="scope">
                <el-row>
                  <!-- <el-col :span="2">
                    <el-image class="m-message-img" :src="img"></el-image>
                  </el-col> -->
                  <el-col :span="10">
                    <div>{{ scope.row.defMatchName }}</div>
                  </el-col>
                  <el-col :span="14">
                    <el-config-provider :locale="zhCn">
                      <el-date-picker
                        v-model="scope.row.gameTime"
                        type="datetime"
                        placeholder=""
                        format="YYYY.MM.DD-HH:mm:ss"
                        readonly="true"
                      />
                    </el-config-provider>
                  </el-col>
                  <!-- <el-col :span="3">
                    <el-button size="small" >修改</el-button>
                  </el-col> -->
                </el-row>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div v-if="isShow == 4" class="t-message-con">
          <el-table
            class="t-message-table"
            height="clac(100%)"
            :data="matchlist"
            :show-header="false"
            :cell-style="{ color: '#fff' }"
            :header-cell-style="{ color: '#fff' }"
          >
            <el-table-column label="消息" align="center">
              <template v-slot="scope">
                <el-dropdown
                  trigger="click"
                  placement="bottom-end"
                  @command="dropdownClick"
                >
                  <div style="color: white">
                    {{ scope.row.defMatchName }} {{ scope.row.gameTime }} 第{{
                      scope.row.index
                    }}场
                  </div>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item
                        :command="commandValue('xiugai', scope.row)"
                        >修改</el-dropdown-item
                      >
                      <!-- <el-dropdown-item
                        :command="commandValue('queren', scope.row)"
                        >确认</el-dropdown-item
                      > -->
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
  <el-dialog v-model="dialogKillVisible" title="积分详细">
    <ScoreTable :dataScore="killScore" :labelArray="labelArray"></ScoreTable>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogKillVisible = false">取消</el-button>
        <el-button type="primary" @click="dialogKillVisible = false"
          >确定</el-button
        >
      </span>
    </template>
  </el-dialog>
  <el-dialog v-model="dialogRankVisible" title="积分详细">
    <ScoreTable
      :dataScore="rankTempScore"
      :labelArray="ranklabelArray"
    ></ScoreTable>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogRankVisible = false">取消</el-button>
        <el-button type="primary" @click="dialogRankVisible = false"
          >确定</el-button
        >
      </span>
    </template>
  </el-dialog>
  <el-dialog v-model="dialogTimeVisible" title="详细比赛时间">
    <MatchTimeTable
      :matchTimeDetailList="matchDetailTimeList"
      @changeTimeList="changeGameTimeList"
    ></MatchTimeTable>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogTimeVisible = false">取消</el-button>
        <el-button type="primary" @click="dialogTimeVisible = false"
          >确定</el-button
        >
      </span>
    </template>
  </el-dialog>
  <el-dialog v-model="dialogDetailVisible" title="比赛详细">
    <MatchDetail
      ref='matchdetail'
      :matchDetailList="matchDetailList"
      :inputDisable="inputDisable"
    ></MatchDetail>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogDetailVisible = false">取消</el-button>
        <el-button type="primary" @click="clickChangeMatchDetail"
          >确定</el-button
        >
      </span>
    </template>
  </el-dialog>
  <el-dialog v-model="dialogPreviewVisible" title="预览">
    <div calss="teammatch">
      <el-image
        style="width: 50em; height: 40em"
        :src="logoPreview"
        fit="fill"
      ></el-image>
    </div>
  </el-dialog>
</template>

<script>
import { timeFormat } from "@/request/common.js";
import imghead from "../../../static/head.jpg";
import img1 from "../../../static/teamlist-1.png";
import img2 from "../../../static/teamlist-2.png";
import BattleTeam from "@/components/matchplatform/BattleTeam.vue";
import { Search } from "@element-plus/icons";
import ScoreTable from "@/components/matchplatform/ScoreTable.vue";
import MatchTimeTable from "@/components/matchplatform/MatchTimeTable.vue";
import { cPost, cGet, cPostParam } from "@/request/http-util.js";
import { ElMessage } from "element-plus";
import MatchDetail from "./MatchDetail.vue";
import Com from "@/request/http-util.js";
import zhCn from "element-plus/lib/locale/lang/zh-cn";
import bus from "@/bus/mittbus.js";

export default {
  name: "MatchCreate",
  components: {
    BattleTeam,
    Search,
    ScoreTable,
    MatchTimeTable,
    MatchDetail,
  },
  props: {
    gameId: Number,
    isShowCreateVue: Boolean,
  },
  data() {
    return {
      zhCn,
      logoPreview: "",
      dialogPreviewVisible: false,
      dialogDetailVisible: false,
      inputDisable: false,
      matchDetailList: [],
      currentJoinNumber: 0,
      currentRowId: 0,
      createMatchList: [],
      logoID: "",
      gameInputName: "",
      gamePUBGPlayerName: '',
      typelist: ["FPP", "TPP"],
      typevalue: "TPP",
      serverlist: ["亚服", "东南亚服", "美服", "澳服", "欧服", "俄服"],
      servervalue: "亚服",
      modellisto: [
        { label: "单人", value: 0 },
        { label: "战队", value: 1 },
      ],
      modellistt: [
        { label: "四人组队", value: 4 },
        { label: "双人组队", value: 2 },
        { label: "单人", value: 1 },
      ],
      modellistovalue: 1,
      modellisttvalue: 4,
      checkModel: true,
      checkLeagueModel: false,
      gametextarea: "",
      matchDetailTimeList: [],
      labelArray: ["杀人数", "积分"],
      ranklabelArray: ["排名", "积分"],
      killScore: [{ rank: "每杀一个人", score: "0" }],
      rankScore: [
        { rank: "1", score: "0" },
        { rank: "2", score: "0" },
        { rank: "3", score: "0" },
        { rank: "4", score: "0" },
        { rank: "5", score: "0" },
        { rank: "6", score: "0" },
        { rank: "7", score: "0" },
        { rank: "8", score: "0" },
        { rank: "9", score: "0" },
        { rank: "10", score: "0" },
        { rank: "11", score: "0" },
        { rank: "12", score: "0" },
        { rank: "13", score: "0" },
        { rank: "14", score: "0" },
        { rank: "15", score: "0" },
        { rank: "16", score: "0" },
        { rank: "17", score: "0" },
        { rank: "18", score: "0" },
        { rank: "19", score: "0" },
        { rank: "20", score: "0" },
        { rank: "21", score: "0" },
        { rank: "22", score: "0" },
        { rank: "23", score: "0" },
        { rank: "24", score: "0" },
      ],
      rankTempScore: [],
      dialogKillVisible: false,
      dialogRankVisible: false,
      dialogTimeVisible: false,
      checkMap: true,
      teamlist: [
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
      ],
      teamlistvalue: "",
      roundlist: ["10", "9", "8", "7", "6", "5", "4", "3", "2", "1"],
      roundlistvalue: "10",
      moneylist: [
        "50",
        "30",
        "20",
        "15",
        "10",
        "8",
        "5",
        "3",
        "2",
        "1",
        "免费",
      ],
      moneylistvalue: "免费",
      matchstarttime: "",
      oncetimelist: ["30", "25", "20", "15"],
      oncetimevalue: "",
      intervallist: ["20", "15", "10", "5", "3"],
      intervalvalue: "5",
      messagereaddata: [],
      isShow: 1,
      loading: false,
      teamId: 0,
      imghead: imghead,
      img1: img1,
      img2: img2,
      //   gameId: 0,
      img: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      matchinglist: [],
      matchlist: [],
      userlist: [],
      mber: [],
      userInfo: {},
      uploadLogoUrl: "",
      radiomap: "1",
      modifyMatchIndex:'',
      modifyMatchId:'',
    };
  },
  created() {
    this.init();
  },
  beforeUnmount() {
    // clearInterval(window.timer1);
  },
  methods: {
    init() {
      var userInfo = JSON.parse(localStorage.getItem("userInfo"));
      this.uploadLogoUrl =
        Com.baseURL +
        "/game/v1.0/app/gameteam/manager/uploadLogo?manageMemberId=" +
        userInfo.id +
        "&type=3";
      // this.getMemberCreate();
    },
    getMemberCreate() {
      var userInfo = JSON.parse(localStorage.getItem("userInfo"));
      var par = {
        memberId: userInfo.id,
      };
      var data = {};
      var getURL = "app/matches/UIControl/checkMemberPermission";
      var __this = this;
      cPostParam(getURL, par, data, true, false).then((res) => {
        if (res.code == 10000) {
          __this.isShowCreateVue = res.result;
        }
      });
    },
    //列表切换
    btChange(num) {
      this.isShow = num;
      if (num == 2) {
        var userInfo = JSON.parse(localStorage.getItem("userInfo"));
        var par = {
          memberId: userInfo.id,
          pageNum: 0,
          pageSize: 1000,
        };

        var getURL = "app/matches/DefMatch/getDefMatchesPageByMember";
        var __this = this;
        cGet(getURL, par, true, false).then((res) => {
          if (res.code == 10000) {
            __this.createMatchList = res.result;
            if (__this.createMatchList.length > 0) {
              __this.setCurrent(__this.createMatchList[0]);
            }
          } else {
            ElMessage(res.message);
          }
        });
      } else if (num == 3) {
        this.getPUBGingData();
      } else if (num == 4) {
        this.clickGetMatchData();
      }
    },
    //获取比赛进行PUBG数据
    getPUBGingData() {
      var userInfo = JSON.parse(localStorage.getItem("userInfo"));
      var par = {
        memberId: userInfo.id,
        pageNum: 0,
        pageSize: 1000,
      };
      var getURL = "app/matches/PUBG/Manager/getRunningPUBGMatchesByCreatId";
      var __this = this;
      cGet(getURL, par, true, false).then((res) => {
        if (res.code == 10000) {
          __this.matchinglist = [];
          __this.matchinglist = res.result;
        } else {
          ElMessage(res.message);
        }
      });
    },
    radioChange(val) {
      if (val == "充值") {
        this.isdepoist = true;
      } else {
        this.isdepoist = false;
      }
    },
    commandValue(type, command) {
      return {
        type: type,
        command: command,
      };
    },
    dropdownClick(item) {
      const { type, command } = item;
      if (type === "xiugai") {
        var par = {
          index: command.index,
          defMatchId: command.defMatchId,
        };
        var getURL = "app/matches/PUBG/Manager/getPUBGMatchesByDefMatchId";
        cGet(getURL, par, true, false).then((res) => {
          if (res.code == 10000) {
            this.matchDetailList = [];
            this.matchDetailList = res.result;
            this.inputDisable = false;
            this.dialogDetailVisible = true;
            this.modifyMatchIndex = command.index;
            this.modifyMatchId = command.defMatchId;
            this.$refs.matchdetail.changeDetail(this.matchDetailList);
          } else {
            ElMessage(res.message);
          }
        });
      } else if (type === "queren") {
        alert(2 + command);
      }
    },
    clickRankDetail() {
      if (this.rankTempScore.length != this.teamlistvalue) {
        this.rankTempScore = [];
        for (var i = 0; i < this.teamlistvalue; i++) {
          this.rankTempScore.push({ rank: i + 1, score: "0" });
        }
      }

      this.dialogRankVisible = true;
    },
    clickKillDetail() {
      this.dialogKillVisible = true;
    },
    clickTimeDetail() {
      this.caculateTime();
      this.dialogTimeVisible = true;
    },
    caculateTime() {
      var startDate = new Date(this.matchstarttime);
      var endDate = new Date(this.matchstarttime);
      endDate.setMinutes(endDate.getMinutes() + parseInt(this.oncetimevalue));
      this.matchDetailTimeList = [];

      for (var i = 1; i <= this.roundlistvalue; i++) {
        var startDateTmp = new Date(startDate);
        var endDateTmp = new Date(endDate);
        var timeDate = [timeFormat(startDateTmp), timeFormat(endDateTmp)];
        var matchTime = {
          mactchID: i,
          timeDate: timeDate,
          interval: this.intervalvalue,
          onceTime: this.oncetimevalue,
        };
        this.matchDetailTimeList.push(matchTime);
        startDate.setMinutes(
          startDate.getMinutes() +
            parseInt(this.intervalvalue) +
            parseInt(this.oncetimevalue)
        );
        endDate.setMinutes(
          endDate.getMinutes() +
            parseInt(this.intervalvalue) +
            parseInt(this.oncetimevalue)
        );
      }
    },
    clickCreate() {
      if (this.gameInputName.length == 0) {
        ElMessage("请输入比赛名称!");
        return;
      }

      if (this.teamlistvalue.length == 0) {
        ElMessage("请输入比赛队伍数!");
        return;
      }

      if (this.matchstarttime.length == 0) {
        ElMessage("请输入比赛开始时间!");
        return;
      }

      if (this.oncetimevalue.length == 0) {
        ElMessage("请输入小比赛时长!");
        return;
      }

      if (this.matchDetailTimeList.length == 0) {
        this.caculateTime();
      }
      var userInfo = JSON.parse(localStorage.getItem("userInfo"));
      var par =
        this.logoID.length > 0
          ? {
              name: this.gameInputName,
              gamePUBGPlayerName: this.gamePUBGPlayerName,
              matchType: this.typevalue,
              serverType: this.servervalue,
              gameMode: this.modellistovalue,
              gamePeople: this.modellisttvalue,
              gameTeamNum: parseInt(this.teamlistvalue),
              gameNum: parseInt(this.roundlistvalue),
              gameBill:
                this.moneylistvalue == "免费" ? "0" : this.moneylistvalue,
              gameStartTime: timeFormat(this.matchstarttime),
              gameTime: parseInt(this.oncetimevalue),
              gameDert: parseInt(this.intervalvalue),
              timeList: JSON.stringify(this.matchDetailTimeList),
              gameKillItems: JSON.stringify(this.killScore),
              gameRankItems: JSON.stringify(this.rankScore),
              gameLogo:
                Com.baseURL + "/game/v1.0/app/gameteam/manager" + this.logoID,
              memberId: userInfo.id,
              gameDesc: this.gametextarea,
              gameMap: this.radiomap,
              useGameMapPos: this.checkMap,
            }
          : {
              name: this.gameInputName,
              gamePUBGPlayerName: this.gamePUBGPlayerName,
              matchType: this.typevalue,
              serverType: this.servervalue,
              gameMode: this.modellistovalue,
              gamePeople: this.modellisttvalue,
              gameTeamNum: parseInt(this.teamlistvalue),
              gameNum: parseInt(this.roundlistvalue),
              gameBill:
                this.moneylistvalue == "免费" ? "0" : this.moneylistvalue,
              gameStartTime: timeFormat(this.matchstarttime),
              gameTime: parseInt(this.oncetimevalue),
              gameDert: parseInt(this.intervalvalue),
              timeList: JSON.stringify(this.matchDetailTimeList),
              gameKillItems: JSON.stringify(this.killScore),
              gameRankItems: JSON.stringify(this.rankScore),
              memberId: userInfo.id,
              gameDesc: this.gametextarea,
              gameMap: this.radiomap,
              useGameMapPos: this.checkMap,
            };

      this.loading = true;
      var getURL = "app/matches/DefMatch/create";
      cPost(getURL, par, true, false).then((res) => {
        ElMessage(res.message);
        this.loading = false;
        if (res.code == 10000) {
          this.gameInputName = "";
          bus.emit("refreshMatchList", true);
        }
      });
    },
    changeGameTimeList(val) {
      this.matchDetailTimeList = val;
    },
    uploadSuccess(response, uploadFile, uploadFiles) {
      if (response.code == 10000) {
        this.logoID = response.result.url;
      }
    },
    handleCurrentChange(row) {
      if (this.currentRowId === row.id) {
        return;
      }

      this.currentRowId = row.id;
      this.setCurrent(row);
    },
    setCurrent(row) {
      this.$refs.createMatchTable.setCurrentRow(row);
      this.currentRowId = row.id;
      var userInfo = JSON.parse(localStorage.getItem("userInfo"));

      var par = {
        matchId: row.id,
        memberId: userInfo.id,
        pageNum: 0,
        pageSize: 1000,
      };

      var getURL = "app/matches/DefMatchManage/getMatchManagesPageByMatch";
      var __this = this;
      cGet(getURL, par, true, false).then((res) => {
        if (res.code == 10000) {
          if (res.result) {
            (__this.currentJoinNumber = res.result.curOrder),
              (__this.messagereaddata = []);
            __this.messagereaddata = res.result.defMatchOrderDTOS;
          }
        } else {
          ElMessage(res.message);
        }
      });
    },
    rowStyle({ row, rowIndex }) {
      if (this.currentRowId === row.id) {
        // 此处返回选中行的样式对象，按需设置
        let stylejson = {};
        stylejson.background = "#e0838f"; // 背景颜色
        // 也可以修改文字颜色
        stylejson.color = "red";
        return stylejson;
      }
    },
    clickAgree(row) {
      var data = {
        id: row.id,
        matchId: row.matchId,
        mode: row.mode,
        orderId: row.orderId,
        status: 1,
      };
      var userInfo = JSON.parse(localStorage.getItem("userInfo"));
      var par = {
        memberId: userInfo.id,
      };
      var getURL = "app/matches/DefMatchOrder/update";

      cPostParam(getURL, par, data, true, false).then((res) => {
        if (res.code == 10000) {
          row.status = 1;
          this.currentJoinNumber += 1;
          // this.setCurrent(row);
        } else {
          ElMessage(res.message);
        }
      });
    },
    clickDisAgree(row) {
      var data = {
        id: row.id,
        matchId: row.matchId,
        mode: row.mode,
        orderId: row.orderId,
        status: -1,
      };
      var userInfo = JSON.parse(localStorage.getItem("userInfo"));
      var par = {
        memberId: userInfo.id,
      };
      var getURL = "app/matches/DefMatchOrder/update";
      cPostParam(getURL, par, data, true, false).then((res) => {
        if (res.code == 10000) {
          row.status = -1;
          // this.setCurrent(row);
        } else {
          ElMessage(res.message);
        }
      });
    },
    clickGetMatchData() {
      var userInfo = JSON.parse(localStorage.getItem("userInfo"));
      var par = {
        memberId: userInfo.id,
        pageNum: 0,
        pageSize: 1000,
      };
      var getURL = "app/matches/PUBG/Manager/getPUBGMatchesByMemberId";
      var __this = this;
      cGet(getURL, par, true, false).then((res) => {
        if (res.code == 10000) {
          __this.matchlist = [];
          __this.matchlist = res.result;
        } else {
          ElMessage(res.message);
        }
      });
    },
    clickChangeMatchDetail() {
      var userInfo = JSON.parse(localStorage.getItem("userInfo"));
      var par = {
        memberId: userInfo.id,
        defMatchId: this.matchDetailList.defMatchId,
        index: this.matchDetailList.defMatchIndex,
      };
      var data = {
        pubgMatchesDTO: this.matchDetailList,
      };
      var getURL = "app/matches/PUBG/Manager/updatePUBGMatchesByDefMatchId";
      cPostParam(getURL, par, data, true, false).then((res) => {
        ElMessage(res.message);
      });
      this.dialogDetailVisible = false;
    },
    beforeAvatarUpload(rawFile) {
      if (
        rawFile.type !== "image/jpeg" &&
        rawFile.type !== "image/jpg" &&
        rawFile.type !== "image/png" &&
        rawFile.type !== "image/glf"
      ) {
        ElMessage.error("请选择正确的图片格式!");
        return false;
      } else if (rawFile.size / 1024 / 1024 > 10) {
        ElMessage.error("图片不能超过10MB!");
        return false;
      }
      return true;
    },
    handlePreview(file) {
      this.dialogPreviewVisible = true;
      this.logoPreview = file.url;
    },
    changeTeamlistValue() {
      if (this.modellisttvalue == 4) {
        if (parseInt(this.teamlistvalue) < 10) {
          this.teamlistvalue = 10;
        }
        if (parseInt(this.teamlistvalue) > 25) {
          this.teamlistvalue = 25;
        }
      } else if (this.modellisttvalue == 2) {
        if (parseInt(this.teamlistvalue) < 10) {
          this.teamlistvalue = 10;
        }
        if (parseInt(this.teamlistvalue) > 50) {
          this.teamlistvalue = 50;
        }
      } else if (this.modellisttvalue == 1) {
        if (parseInt(this.teamlistvalue) < 10) {
          this.teamlistvalue = 10;
        }
        if (parseInt(this.teamlistvalue) > 100) {
          this.teamlistvalue = 100;
        }
      }
    },
    changeoncetimevalue() {
      if (parseInt(this.oncetimevalue) < 5) {
        this.oncetimevalue = 5;
      }
      if (parseInt(this.oncetimevalue) > 30) {
        this.oncetimevalue = 30;
      }
    },
    changeintervalvalue() {
      if (parseInt(this.intervalvalue) < 1) {
        this.intervalvalue = 1;
      }
      if (parseInt(this.intervalvalue) > 30) {
        this.intervalvalue = 30;
      }
    },
    clickLeagueCheck() {
      var leagueName = localStorage.getItem("leaguename");
      if (this.checkLeagueModel) {
        if (leagueName && leagueName.length > 0) {
          if (this.gameInputName.length > 0) {
            if (this.gameInputName.indexOf(leagueName + "-") != 0) {
              this.gameInputName = leagueName + "-" + this.gameInputName;
            }
          } else {
            this.gameInputName = leagueName + "-";
          }
        }
      } else {
        if (leagueName && leagueName.length > 0) {
          if (this.gameInputName.length > 0) {
            if (this.gameInputName.indexOf(leagueName + "-") == 0) {
              var splitFirst = this.gameInputName.split(leagueName + "-");
              this.gameInputName = splitFirst.join("");
            }
          }
        }
      }
    },
  },
};
</script>

<style scoped="scoped" lang="less">
.gamelist {
  width: 100%;
  height: 100%;
  position: relative;
}

.gamelist-c {
  height: calc(100%);
  margin: 0 auto;
  background-color: rgba(32, 32, 32, 0.6);
  border-radius: 5px;
  border: 1px solid #676666;
  box-sizing: border-box;
  position: relative;
}

.g-top {
  text-align: left;
  color: #f4f4f4;
  font-size: 1.1rem;
  background-image: linear-gradient(#161515, #2b2a2a);
  height: 3.2rem;
  line-height: 3.2rem;
  box-sizing: border-box;
  padding-left: 1.5rem;
}

.t-bot {
  width: 100%;
  left: 0;

  position: absolute;
  bottom: 0;
}
.tbutton {
  margin-top: 1em;
  background: #2f2f2f;
  border: 1px solid #676666;
  width: 100%;
  height: 2.7rem;
  line-height: 2.7rem;
  color: #efefef;
  letter-spacing: 2px;
  border-radius: 5px;
  cursor: pointer;

  img {
    position: relative;
    top: 0.4rem;
    height: 1.4rem;
  }
}
.t-con {
  padding: 0 0.6rem;
  margin-top: 2rem;
  height: 80%;
  .m-c-top {
    text-align: left;
    border-bottom: 1px solid #808080;
    button {
      background: #1f1e1f;
      color: #fff;
      padding: 0.4rem 1.2rem;
      border-left: 1px solid #808080;
      border-top: 1px solid #808080;
      border-right: 1px solid #808080;
      border-bottom: none;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      margin-right: 10px;
    }
    .ctop-button {
      background: #07b43c;
    }
  }

  .pl0 {
    padding-left: 0 !important;
  }
  .pr0 {
    padding-right: 0 !important;
  }
  .m-cr-con {
    height: 100%;
    overflow-y: auto;
    position: relative;
    .el-row {
      .creatediv {
        color: #6e716f;
        margin-top: 0.5em;
        text-align: left;
      }
      .createright {
        color: #6e716f;
        margin-top: 0.5em;
        margin-left: 1em;
        text-align: left;
      }
      .createwhite {
        color: white;
        margin-top: 0.5em;
        margin-left: 1em;
        text-align: left;
        font-size: 15px;
      }
      .cbutton {
        margin-top: 0.5em;
        width: 100%;
      }
      .mrow {
        margin-top: 0.5em;
      }
      .sbutton {
        margin-left: 0.5em;
      }
      .cselect {
        width: 100%;
      }
    }
  }

  .g-con-list {
    width: 100%;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 2rem;
    height: 7vw;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    .bgimg {
      width: 100%;
      height: 100%;
    }
    .p11 {
      color: #fff;
      position: absolute;
      left: 12%;
      top: 30%;
      margin-top: -13.5px;
      font-size: 1.3rem;
      width: 85%;
      text-align: left;
    }
    .p12 {
      color: #fff;
      position: absolute;
      left: 12%;
      top: 60%;
      margin-top: -13.5px;
      font-size: 1.3rem;
      width: 85%;
      text-align: left;
    }
    .zz {
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: rgba(32, 32, 32, 0.3);
      top: 0;
    }
    button {
      background: none;
      border: none;
      padding: 0.25rem 1rem;
      color: #fff;
      letter-spacing: 2px;
      border-radius: 5px;
      cursor: pointer;
      position: absolute;
      right: 12%;
      top: 50%;
      font-size: 1.2rem;
      margin-top: -15.5px;
      span {
        color: #07b43c;
      }
    }
  }

  .g-con-list div {
    width: 100%;
    height: 55em;
  }

  .t-message-con {
    height: 100%;
    // overflow-y: auto;
    position: relative;
  }
  .t-message {
    margin-top: 1em;
    margin-left: 1em;
    margin-right: 1em;
    height: 45%;
    // background: white;
    .t-message-b {
      margin-top: 0.5em;
      margin-left: 19em;
    }
  }

  .t-message-table {
    margin: 0%;
    width: 100%;
    height: 100%;
  }

  .t-message-bordertable {
    margin: 0%;
    width: 100%;
    height: 100%;
    border: white 1px solid;
  }

  /*最外层透明*/
  ::v-deep .el-table,
  ::v-deep .el-table__expanded-cell {
    background-color: transparent !important;
  }
  /* 表格内背景颜色 */
  ::v-deep .el-table th,
  ::v-deep .el-table tr,
  ::v-deep .el-table td {
    // background-color: transparent !important;
    // border: 0px;
    background-color: black;
  }

  // .el-table__body tr.current-row > td {
  //   background-color: red !important;
  //   /* color: #f19944; */ /* 设置⽂字颜⾊，可以选择不设置 */
  // }
  // .el-table--enable-row-hover .el-table__body tr:hover > td {
  //   background-color: green;
  //   /* color: #f19944; */ /* 设置⽂字颜⾊，可以选择不设置 */
  // }
  /* 用来设置当前页面element全局table 鼠标移入某行时的背景色*/
  // .el-table__body tr:hover > td {
  //    background-color: pink !important;
  //   // color: dodgerblue;  /* 设置文字颜色，可以选择不设置 */
  // }

  // /* 用来设置当前页面element全局table 选中某行时的背景色*/
  // .el-table__body tr.current-row > td {
  //     background-color: #add8e6 !important;
  //     // color: coral; /* 设置文字颜色，可以选择不设置 */
  // }

  // .el-table::before {
  //   height: 0px;
  // }
  // .el-table--border::after {
  //   width: 0px;
  // }
  // .el-table--border {
  //   border: none;
  // }

  .m-message-img {
    width: 2em;
    height: 2em;
    background: #07b43c;
  }

  .t-message-div {
    margin-left: 3em;
    margin-top: -2em;
  }

  .m-cash {
    margin-top: 0.5em;
    height: 10%;
    width: 100%;
  }
  .t-cash-div {
    font-size: 25px;
    color: white;
  }

  .t-match-div {
    width: 100%;
    color: white;
    background: #07b43c;
  }

  .m-check-table {
    color: white;
    width: 100%;
  }

  .t-rank-div {
    margin-top: 1em;
    background: #07b43c;
    color: white;
    font-size: 20px;
    height: 1.5em;
  }

  .m-cash-table {
    width: 100%;
    height: 90%;
  }

  .t-message-img {
    width: 2em;
    height: 1.5em;
  }

  .t-message-div {
    margin-left: 3em;
    margin-top: -2em;
  }

  .c-pre-list {
    // border: 1px solid #676666;
    width: 99.5%;
    position: relative;
    // border-radius: 5px;
    overflow: hidden;
    margin-bottom: 0.5rem;
    height: 8em;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }

  .c-c-con {
    height: 40%;
    overflow-y: auto;
    position: relative;
    margin-top: 0.5em;
    margin-left: 1em;
    margin-right: 1em;
  }

  .c-message {
    margin-top: 1em;
    margin-left: 1em;
    margin-right: 1em;
    height: 60%;
    // background: white;
    .t-message-b {
      margin-top: 0.5em;
      margin-left: 19em;
    }
  }

  .el-button--primary {
    //需要更改的按钮类型
    background: #07b43c !important;
    border-color: #07b43c !important;
  }
  //移入时按钮样式
  .el-button--primary:hover {
    background: #07b43c !important;
    border-color: #07b43c !important;
    color: #fff !important;
  }

  .teammatch {
    height: 100%;
    width: 100%;
    line-height: 100%;
    vertical-align: middle;
  }

  .teammatch-img {
    display: inline-block;
    height: 8em;
    width: 10em;
    vertical-align: middle;
  }

  .create-input {
    margin-left: 6%;
    width: 94%;
    // margin-right: 6%;
  }
}
</style>
