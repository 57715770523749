<template>
	<div class="login">
		<div class="con1">
			<span @click="close" class="close icon iconfont icon-guanbi"></span>
			<div class="top">Steam绑定</div>
			<div class="mid">
				<el-row>
					<el-col class="title_tip" :span="24">
						此游戏需要绑定Steam，请加速steam商店绑定账号
					</el-col>
					<el-col class="bd" :span="24">
						<button @click="steamBtn">绑定 Steam 账号</button>
					</el-col>
					<el-col class="bts" :span="24">
						提示：账号绑定以后永久无法解绑，请仔细核对所登录的账户。
					</el-col>
				</el-row>
			</div>
		</div>
	</div>
</template>

<script>
	import {cPost,cGet} from "@/request/http-util.js";
	import { ElMessage } from 'element-plus'
	export default {
	  name: 'Login',
	  data(){
		  return {
			  dm:{}
		  }
	  },
	  created() {
		  
	  },
	  methods:{
		  //绑定steam 
		  steamBtn() {
			  cGet("SteamLogin",{}).then(res => {
				  if (res.code == 1) {
					  window.location.href = res.data;
				  }
			  })
		  },
		  close() {
			  this.$parent.bclose();
		  }
	  }
	}
</script>

<style scoped="scoped" lang="less">
	.login {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background:rgba(0,0,0,.1);
		z-index: 11;
		.close {
			position: absolute;
			right: 20px;
			top: 17px;
			color: #fff;
		}

	}
	
	.con1 {
		width: 480px;
		height: 240px;
		background: #fff;
		position: absolute;
		top: 50%;
		left: 50%;
		margin-left: -240px;
		margin-top: -140px;
		border-radius: 12px;
		overflow: hidden;
		
		.top {
			width: 100%;
			background: #6dba44;
			color: #fff;
			text-align: center;
			height: 50px;
			line-height: 50px;
			font-size: 18px;
			letter-spacing: 6px;
			font-weight: 600;
		}
		
		.mid {
			margin-top: 35px;
			.mimg {
				width: 100%;
				height: auto;
			}
			
			.title {
				color: #6dba44;
				text-align: center;
				font-size: 24px;
				letter-spacing: 4px;
				font-weight: 600;
				margin-top: 30px;
				margin-bottom: 5px;
			}
			
			.title_tip {
				color: #333;
				text-align: center;
				margin-bottom: 10px;
				font-size: 16px;
			}
			
			.bd {
				text-align: center;
				font-size: 16px;
				font-weight: 600;
				color: #6dba44;
				margin-bottom:10px;
			}
			
			.bd button{
				height: 34px;
				padding:0 30px;
				background: #6dba44;
				border: none;
				color: #fff;
				border-radius: 5px;
				margin-top: 10px;
				letter-spacing: 2px;
			}
			
			.bts {
				font-size: 12px;
				color: #a1a1a1;
				text-align: center;
				color:red;
			}
			
			.el-row {
				width: calc(100% - 70px);
				margin-left: 35px;
				text-align: left;
	
			}
		}
	}
	
	::v-deep{
		.el-select .el-input__inner{
			width: 100%;
			height: 36px;
			border: 1px solid #dfdfdf;
			background: #f2f2f2;
			margin-bottom: 5px;
			border-radius: 5px;
			box-sizing: border-box;
			padding: 0 12px;
			color: #999;
		}
		
		.el-select .el-input__icon{
			color:#6dbb44 !important;
		}
	}
	

</style>
