<template>
	<div class="gamelist" 
	element-loading-background="rgba(0, 0, 0, 0.8)"
	v-loading="loading">
		<div class="gamelist-c">
			<div class="g-top">
				房间列表
			</div>
			<div class="g-con">
				<div class="g-con-list">
					<img src="../../../static/gamedetail-2.png" />
					<p>决战到黎明</p>
					<button @click="naToRoom">加入房间</button>
				</div>
				<div class="g-con-list">
					<img src="../../../static/gamedetail-2.png" />
					<p>决战到黎明</p>
					<button @click="naToRoom">加入房间</button>
				</div>
				<div class="g-con-list">
					<img src="../../../static/gamedetail-2.png" />
					<p>决战到黎明</p>
					<button @click="naToRoom">加入房间</button>
				</div>
				<div class="g-con-list">
					<img src="../../../static/gamedetail-2.png" />
					<p>决战到黎明</p>
					<button @click="naToRoom">加入房间</button>
				</div>
				<div class="g-con-list">
					<img src="../../../static/gamedetail-2.png" />
					<p>决战到黎明</p>
					<button @click="naToRoom">加入房间</button>
				</div>
				<div class="g-con-list">
					<img src="../../../static/gamedetail-2.png" />
					<p>决战到黎明</p>
					<button @click="naToRoom">加入房间</button>
				</div>
			</div>
		</div>
		<div class="g-bot">
			<button >
				<img src="../../../static/gamedetail-4.png" />
				随机匹配加入小队
			</button>
		</div>
	</div>
</template>

<script>
	import {cPost,cGet} from "@/request/http-util.js";
	import { ElMessage } from 'element-plus'
	export default{
		name:"Gamelist",
		data() {
			return {
				loading:false
			}
		},
		created() {
			this.getGameList();
		},
		methods:{
			getGameList () {
				this.loading = true;
				cGet("GetGameMemberList",{},true,false).then(res => {
					this.loading = false;
					if (res.code == 1) {
						
					}
					console.log(res);
				})
			},
			naToRoom() {
				this.$router.push({name:"GameRoom"});
			}
		}
	}
</script>

<style scoped="scoped" lang="less">
	.gamelist {
		width: 100%;
		height: 100%;
		position: relative;
	}
	
	.gamelist-c {
		height: calc(100% - 5rem);
		margin: 0 auto;
		background-color: rgba(0,0,0,.5);
		border-radius: 5px;
		border:1px solid #676666;
		box-sizing: border-box;
		position: relative;
	}
	
	.g-top {
		text-align: left;
		color: #f4f4f4;
		font-size: 1.1rem;
		background-image: linear-gradient( #161515, #2b2a2a);
		height: 3.2rem;
		line-height: 3.2rem;
		box-sizing: border-box;
		padding-left: 1.5rem;
	}
	
	.g-bot {
		width: 100%;
		left: 0;
		position: absolute;
		bottom: 0;
		button {
			background: #2f2f2f;
			border: 1px solid #676666;
			width: 100%;
			height: 2.7rem;
			line-height: 2.7rem;
			color: #efefef;
			letter-spacing: 2px;
			border-radius: 5px;
			cursor: pointer;
			
			img {
				position: relative;
				top: .4rem;
				height: 1.4rem;
			}
		}
	}
	
	.g-con {
		height: 74%;
		overflow-y: auto;
		position: relative;
		padding: 0 2rem;
		margin-top: 2rem;
		
		.g-c-top {
			text-align: left;
			button {
				background: #1f1e1f;
				color: #fff;
				padding: .4rem 1.2rem;
				border-left: 2px solid #808080;
				border-top: 2px solid #808080;
				border-right: 2px solid #808080;
				border-bottom: none;
				border-top-left-radius: 5px;
				border-top-right-radius: 5px;
				margin-right: 10px;
			}
			.ctop-button {
				background: #131213;
			}
		}
		    
		.g-con-list {
			width: 100%;
			position: relative;
			border-radius: 5px;
			overflow: hidden;
			margin-bottom: 15px;
			height: 6.7rem;
			img {
				width: 100%;
				height:100%;
			}
			p {
				color: #fff;
				position: absolute;
				left: 15%;
				top: 50%;
				margin-top:-13.5px;
				font-size: 1.3rem;
			}
			button {
				background: #07b43c;
				border: none;
				padding: .25rem 1rem;
				color: #fff;
				letter-spacing: 2px;
				border-radius: 5px;
				cursor: pointer;
				position: absolute;
				right: 12%;
				top: 50%;
				margin-top:-13.5px;
			}
		}
	}
</style>
