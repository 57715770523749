<template>
  <div class="md">
  <div class="m-detail-list" v-for="(item, index) in showList"
            :key="index">
      
                    <div>
              <MatchDetailTable :matchInfo="item[0]" :inputDisable="inputDisable"></MatchDetailTable>
            </div>
            <div v-if="item.length == 4 || item.length == 3 ||item.length==2">
              <MatchDetailTable :matchInfo="item[1]" :inputDisable="inputDisable"></MatchDetailTable>
            </div>
            <div v-if="item.length == 4 || item.length == 3">
              <MatchDetailTable :matchInfo="item[2]" :inputDisable="inputDisable"></MatchDetailTable>
            </div>
             <div v-if="item.length == 4">
              <MatchDetailTable :matchInfo="item[3]" :inputDisable="inputDisable"></MatchDetailTable>
            </div>
          </div>
  </div>
</template>

<script>
import MatchDetailTable from "@/components/matchplatform/MatchDetailTable.vue";

export default {
  name: "MatchDetail",
   components: {
    MatchDetailTable,
  },
  props: {
    matchDetailList: [],
    inputDisable: Boolean,
  },
  data() {
    return {
      matchList: this.matchDetailList,
      showList:[],
    };
  },
  computed() {
    console.log('123456');
    //   userMemberList(){
    //       return this.userList;
    //   },
    // {mactchID:i, startTime:startDate, endTime:endDate, interval:this.intervalvalue, onceTime:this.oncetimevalue};
  },
  created() {
    this.changeDetail(this.matchList);
  },
  methods: {
    changeValue(val) {

        console.log(val);
    },
    changeDetail(val) {
      this.matchList = val;
      this.showList = [];
      for (var i = 0; i < this.matchList.teamMembers.length; i++) {
        if (i + 1 > 3 && (i + 1) % 4 == 0) {
          var tmplist = [
            this.matchList.teamMembers[i - 3],
            this.matchList.teamMembers[i - 2],
            this.matchList.teamMembers[i - 1],
            this.matchList.teamMembers[i],
          ];
          this.showList.push(tmplist);
        }
      }

      if (this.matchList.teamMembers.length % 4 == 1) {
        var tmplist2 = [
          this.matchList.teamMembers[this.matchList.teamMembers.length - 1],
        ];
        this.showList.push(tmplist2);
      } else if (this.matchList.teamMembers.length % 4 == 2) {
        var tmplist3 = [
          this.matchList.teamMembers[this.matchList.teamMembers.length - 2],
          this.matchList.teamMembers[this.matchList.teamMembers.length - 1],
        ];
        this.showList.push(tmplist3);
      }
      else if (this.matchList.teamMembers.length % 4 == 3) {
        var tmplist4 = [
          this.matchList.teamMembers[this.matchList.teamMembers.length - 3],
          this.matchList.teamMembers[this.matchList.teamMembers.length - 2],
          this.matchList.teamMembers[this.matchList.teamMembers.length - 1],
        ];
        this.showList.push(tmplist4);
      }
    },
  },
};
</script>

<style scoped="scoped" lang="less">
.md {
  height: 60vh;
  width: 100%;
  position: relative;
  ::v-deep .el-range-input {
    background: black;
    color: white;
  }
}

/*最外层透明*/
::v-deep .el-table,
::v-deep .el-table__expanded-cell {
  background-color: transparent !important;
}
/* 表格内背景颜色 */
::v-deep .el-table th,
::v-deep .el-table tr,
::v-deep .el-table td {
  background-color: transparent !important;
  // border: 0px;
}

.el-table::before {
  height: 0px;
}
.el-table--border::after {
  width: 0px;
}
.el-table--border {
  border: none;
}

  .m-detail-list {
    // border: 1px solid #676666;
    width: 99.5%;
    position: relative;
    // border-radius: 5px;
    overflow: hidden;
    margin-bottom: 1rem;
    // margin-left: 2rem;
    height: 22.5em;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
</style>
