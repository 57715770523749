<template>
  <div class="usercenter">
    <Head></Head>
	<Left></Left>
	
	<div element-loading-background="rgba(0, 0, 0, 0.8)"
	 v-loading="loading"  class="contain-user">
		<Top></Top>
		<div class="user-con">
			<div class="list">
				<div class="list-con1">账户信息</div>
				<div class="list-con2">{{userInfo.phone}}</div>
			</div>
			<div class="list">
				<div class="list-con1">密码</div>
				<div class="list-con2"><input v-model="pass" placeholder="不修改则留空"/><!--span>编辑</span--></div>
			</div>
			<div class="list">
				<div class="list-con1">请输入确认密码</div>
				<div class="list-con2"><input v-model="cpass" placeholder="不修改密码则留空"/><!--span>编辑</span--></div>
			</div>

			<el-button size="mini" @click="editBtn" type="success">确 认</el-button>
		</div>
	</div>
  </div>
</template>
<script>
// @ is an alias to /src
import Head from '@/components/HeadUser.vue'
import Left from '@/components/LeftUser.vue'
import Top from '@/components/TopUser.vue'

import { cGet } from "@/request/http-util.js";
import { ElMessage } from 'element-plus'
export default {
	name: 'UserCenter',
	components: {
		Head,
		Left,
		Top
	}, 
	data(){
		return {
			userInfo:{},
			pass:"",
			loading:false,
			cpass:""
		}
	},
	created() {
		this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
		cGet("GetInfo",{gameId:localStorage.getItem('gameId')},false,false).then(res => {
			if (res.code == 1 ) {
				this.userInfo = res.data.me;
				localStorage.setItem("userInfo",JSON.stringify(res.data.me));
			}
		})
		console.log(this.userInfo);
	},
	methods:{
		editBtn(){
			
			if (this.pass && this.pass != this.cpass) {
				ElMessage("两次密码输入不一致");
				return false;
			}
			
			var par  = {
				pass:this.pass
			};
			
			cGet("editme",par).then(res => {
				if (res.code == 1) { 
					ElMessage("修改成功");
				}
			})
		}
	},
}
</script>

<style lang="less" scoped="scoped">
	
	.usercenter {
		text-align: left;
		.user-con {
			
			.list{
			    width: 70%;
			    border-bottom: 1px solid #4A4A4A;
			    color: #fff;
				height: 50px;
				margin-bottom: 10px;
				box-sizing: border-box;
				padding: 0 10px;
				.list-con1 {
					width: 160px;
					float: left;
					line-height: 50px;
					text-align: left;
				}
				
				.list-con2 {
					line-height: 50px;
					float: left;
					width: calc(100% - 160px);
					height: 100%;
					text-align: left;
					span {
						float: right;
						color:#9A9A9A;
					}
					input {
						background: none;
						border: none;
						color: #fff;
						padding: 10px 0;
					}
				}
				
			}
		}
	}
	
	.usercenter::v-deep {
		.el-button {
			padding: 2px 40px;
			margin-top: 40px;
		}
	}
</style>
