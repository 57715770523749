<template>
  <div
    class="gamelist"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    v-loading="loading"
  >
    <div class="gamelist-c">
      <div class="g-top">创建联盟</div>
      <div class="create-div">
        <el-row :gutter="20">
          <span class="create-span">联盟名称：</span>
          <el-input v-model="battleName" placeholder="" class="create-input" />
        </el-row>
        <el-row :gutter="20" style="margin-top: 2%">
          <span class="create-span">联盟描述：</span>
          <el-input
            v-model="battleDes"
            :autosize="{ minRows: 2, maxRows: 4 }"
            type="textarea"
            placeholder=""
            class="create-input"
          />
        </el-row>
        <el-row :gutter="20" style="margin-top: 2%">
          <el-upload
            class="create-btn"
            :action="uploadLogoUrl"
            :limit="1"
            :on-exceed="handleExceed"
            :on-success="uploadSuccess"
            :file-list="fileList"
            :before-upload="beforeAvatarUpload"
            list-type="picture"
          >
            <el-button type="primary">请上传联盟Logo</el-button>
          </el-upload>
        </el-row>
        <el-row :gutter="20" style="margin-top: 2%">
          <el-button class="create-btn" type="primary" @click="clickCreate"
            >创建联盟</el-button
          >
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { cPost } from "@/request/http-util.js";
import Com from "@/request/http-util.js";
import { ElMessage } from "element-plus";

export default {
  name: "MatchCreate",
  components: {},
  props: {
    gameId: Number,
  },
  data() {
    return {
      battleName: "",
      battleDes: "",
      logoID: "",
      uploadLogoUrl: "",
    };
  },
  created() {
    this.init();
  },
  beforeUnmount() {
    // clearInterval(window.timer1);
  },
  methods: {
    init() {
      var userInfo = JSON.parse(localStorage.getItem("userInfo"));

      this.uploadLogoUrl =
        Com.baseURL +
        "/game/v1.0/app/gameteam/manager/uploadLogo?manageMemberId=" +
        userInfo.id +
        "&type=2";
    },

    clickCreate() {
      if (this.battleName.length <= 0) {
        ElMessage("请输入联盟名称");
        return;
      }
      if (this.battleDes.length <= 0) {
        ElMessage("请输入联盟描述");
        return;
      }
      var userInfo = JSON.parse(localStorage.getItem("userInfo"));
      var data =
        this.logoID && this.logoID.length > 0
          ? {
              descriptionInfo: this.battleDes,
              name: this.battleName,
              logoUrl:
                Com.baseURL + "/game/v1.0/app/gameteam/manager" + this.logoID,
              createMemberId: userInfo.id,
              maxTeamNum: 100,
            }
          : {
              descriptionInfo: this.battleDes,
              name: this.battleName,
              createMemberId: userInfo.id,
              maxTeamNum: 100,
            };
      var getURL = "app/gameteam/manager/createLeague";
      cPost(getURL, data, true, false).then((res) => {
        if (res.code == 10000) {
          if (res.result) {
            var battleInfo = res.result;
            localStorage.setItem("leaguename", battleInfo.name);
            localStorage.setItem("leagueid", battleInfo.id);
            localStorage.setItem("leagueInfo", JSON.stringify(res.result));

            this.$router.push({ name: "Game" });
          }
        } else {
          ElMessage(res.message);
        }
      });
    },
    uploadSuccess(response, uploadFile, uploadFiles) {
      if (response.code == 10000) {
        this.logoID = response.result.url;
      }
    },
    beforeAvatarUpload(rawFile) {
      if (
        rawFile.type !== "image/jpeg" &&
        rawFile.type !== "image/jpg" &&
        rawFile.type !== "image/png" &&
        rawFile.type !== "image/glf"
      ) {
        ElMessage.error("请选择正确的图片格式!");
        return false;
      } else if (rawFile.size / 1024 / 1024 > 10) {
        ElMessage.error("图片不能超过10MB!");
        return false;
      }
      return true;
    },
  },
};
</script>

<style scoped="scoped" lang="less">
.gamelist {
  width: 100%;
  height: 100%;
  position: relative;
}

.gamelist-c {
  height: calc(100%);
  margin: 0 auto;
  background-color: rgba(32, 32, 32, 0.6);
  border-radius: 5px;
  border: 1px solid #676666;
  box-sizing: border-box;
  position: relative;
}

.g-top {
  text-align: left;
  color: #f4f4f4;
  font-size: 1.1rem;
  background-image: linear-gradient(#161515, #2b2a2a);
  height: 3.2rem;
  line-height: 3.2rem;
  box-sizing: border-box;
  padding-left: 1.5rem;
}

.create-div {
  width: 90%;
  height: 100%;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 1%;
}

.create-span {
  color: white;
  width: 20%;
  margin-top: 1%;
  font-size: 1.1rem;
}

.create-input {
  width: 80%;
}

.create-btn {
  margin-left: 10%;
  margin-right: 10%;
  width: 80%;
}

.el-button--primary { //需要更改的按钮类型
  background: #07b43c !important;
  border-color: #07b43c !important;
}
//移入时按钮样式
.el-button--primary:hover {
  background: #07b43c !important;
  border-color: #07b43c !important;
  color: #FFF !important;
}

</style>
