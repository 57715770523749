

//创建队伍常用参数
const LEVEL = ['青铜', '白银', '黄金', '铂金', '钻石', '大师'];
const SERVE = ['亚服', '东南亚服', '美服', '澳服', '欧服', '俄服'];
const NORM = ['TPP', 'FPP'];
const MODEL = ['普通', '竞技'];


//获取随机数

export function randomNum(minNum, maxNum) {
	switch (arguments.length) {
		case 1:
			return parseInt(Math.random() * minNum + 1, 10);
			break;
		case 2:
			return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
			break;
		default:
			return 0;
			break;
	}
}

export function timeChange(cjsj) {
	cjsj = cjsj.substr(6, 13);
	var date = new Date(parseInt(cjsj)) //时间戳为10位需*1000，时间戳为13位的话不需乘1000

	var Y = date.getFullYear() + '-'
	var M = padLeftZero((parseInt(date.getMonth()) + 1 < 10 ? '0' + (parseInt(date.getMonth()) + 1) : date.getMonth() + 1))
	var D = padLeftZero(date.getDate()) + ' '
	var h = padLeftZero(date.getHours()) + ':'
	var m = padLeftZero(date.getMinutes()) + ':'
	var s = padLeftZero(date.getSeconds())
	return Y + M + '-' + D + h + m + s
}

export function timeD(cjsj) {
	cjsj = cjsj.substr(6, 13);
	var date = new Date(parseInt(cjsj)) //时间戳为10位需*1000，时间戳为13位的话不需乘1000

	var D = padLeftZero(date.getDate())

	return D
}

export function timeYM(cjsj) {
	cjsj = cjsj.substr(6, 13);
	var date = new Date(parseInt(cjsj)) //时间戳为10位需*1000，时间戳为13位的话不需乘1000

	var Y = date.getFullYear() + '-'
	var M = padLeftZero((date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1))
	return Y + M
}

function padLeftZero(str) {
	return parseInt(str) < 10 ? '0' + parseInt(str) : str;
}


export function getMinF(num) {
	var date = new Date();     //1. js获取当前时间
	var min = date.getMinutes();  //2. 获取当前分钟
	date.setMinutes(min + num);  //3. 设置当前时间+10分钟：把当前分钟数+10后的值重新设置为date对象的分钟数
	var y = date.getFullYear();
	var m = (date.getMonth() + 1) < 10 ? ("0" + (date.getMonth() + 1)) : (date.getMonth() + 1);
	var d = date.getDate() < 10 ? ("0" + date.getDate()) : date.getDate();
	var h = date.getHours() < 10 ? ('0' + date.getHours()) : date.getHours()
	var f = date.getMinutes() < 10 ? ('0' + date.getMinutes()) : date.getMinutes()
	var s = date.getSeconds() < 10 ? ('0' + date.getseconds()) : date.getSeconds()
	return y + '-' + m + '-' + d + " " + h + ":" + f + ":" + s;
}

export function timeFormat(time) {

	var year = time.getFullYear();
	var month = time.getMonth() + 1;
	var date = time.getDate();
	var hours = time.getHours();
	var minutes = time.getMinutes();
	var seconds = time.getSeconds();
	return (
		year +
		"-" +
		month +
		"-" +
		date +
		" " +
		hours +
		":" +
		minutes +
		":" +
		seconds
	);

}


export default {
	randomNum,
	timeChange,
	timeD,
	timeYM,
	getMinF,
	timeFormat,
	LEVEL,
	SERVE,
	MODEL,
	NORM
}
