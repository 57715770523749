<template>
  <div class="usercenter">
    <Head></Head>
	<Left></Left>
	
	<div class="contain-user">
		<Top></Top>
		<div class="user-con">
			<el-table :data="tableData"  style="width: 100%" height="90%"
			element-loading-background="rgba(0, 0, 0, 0.8)"
			v-loading="loading">
				<el-table-column label="时间" >
					<template #default="scope">
						
						<span class="ym">{{scope.row.YMD}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="gameName" label="游戏名" />
				<el-table-column label="队伍排名" >
					<template #default="scope">
						<span class="pm">#{{scope.row.pm}}</span>
						<!--span>{{scope.row.jspm}}</span-->
					</template>
				</el-table-column>
				<el-table-column label="击杀" >
					<template #default="scope">
						<span class="bt">{{scope.row.js}}</span><br>
						<span class="desc">击杀</span>
					</template>
				</el-table-column>
				<el-table-column label="伤害" >
					<template #default="scope">
						<span class="bt">{{scope.row.sh}}</span><br>
						<span class="desc">伤害</span>
					</template>
				</el-table-column>
				<el-table-column label="步行/驾驶" >
					<template #default="scope">
						<span class="bt">{{scope.row.jl1}}km / {{scope.row.jl2}}km</span><br>
						<span class="desc">移动距离</span>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination
			small
			:page-size="10" 
			@current-change="getListInfo()" 
			v-model:currentPage="pageIndex" 
			layout="total,prev, pager, next" 
			:total="pageAll"></el-pagination>
		</div>
		
	</div>
  </div>
</template>
<script>
// @ is an alias to /src
import Head from '@/components/HeadUser.vue'
import Left from '@/components/LeftUser.vue'
import Top from '@/components/TopUser.vue'

import { cGet } from "@/request/http-util.js";
import { timeD,timeYM,timeChange } from "@/request/common.js";
import { ElMessage } from 'element-plus'
export default {
	name: 'UserCenter',
	components: {
		Head,
		Left,
		Top
	}, 
	data(){
		return {
			tableData:[],
			pageIndex:1,
			pageAll:10,
			loading:false
		}
	},
	created() {
		this.getListInfo();
	},
	methods:{
		//获取积分
		getListInfo() {
			var _this = this;
			this.loading = true;
			cGet("GetMyPlayRecords",{pageIndex:this.pageIndex,pageSize:10},true,false).then(res => {
				this.loading = false;
				if (res.code == 1) {
					res.data.list = res.data.list.filter(function(item,i){

						var da = JSON.parse(item['data'].replace(/\n/g,"\\n").replace(/\r/g,"\\r"));
						item['pm'] = da.WinPlace;
						item['jspm'] = da.KillPlace;
						item['gameName'] = "绝地求生";
						item['js'] = da.Kills;
						item['sh'] = (da.DamageDealt).toFixed(2);
						item['jl1'] = (da.WalkDistance/1000).toFixed(2);
						item['jl2'] = (da.RideDistance/1000).toFixed(2);
						item['YMD'] = timeChange(item['createAt']);
						return item;
					})
					_this.tableData = res.data.list;
					_this.pageAll = res.data.count;
				}
			})
		}
		
	},
}
</script>

<style lang="less" scoped="scoped">
	
	.usercenter {
		text-align: left;
		.top {
			img{width: 100%;}
		}
		.user-con {
			
			.bt {
				font-size: 16px;
				color: #fff;
			}
			.day {
				display: inline-block;
				font-size: 22px;
				font-weight: 600;
			}
			.pm {
				font-size: 18px;
				font-weight: 600;
				color:#fff
			}
			
			.desc {
				font-size: 12px;
			}
		}
	}
	
	.user-con::v-deep {
		.el-table {
			width: 70% !important;
			background-color: #2F2F2F !important;
			text-align: center;
			border: 1px solid #494949 !important;
		}
		.el-table::before {
		    display: none;
		}
		.el-table th.el-table__cell {
		    background-color: #2F2F2F;
			padding: 16px 0;
			text-align: center;
			color: #fff;
			border-bottom: 1px solid #494949;
		}
		.el-table td.el-table__cell{
			padding: 15px 0;
			text-align: center;
			color:#A7A7A7;
			background-color: #181818;
			border-bottom: 1px solid #494949;
		}
		.el-table__body-wrapper {
			background-color: #181818 !important;
		}
		
		.el-table tr:hover td {
			background-color: #282828 !important;
		}
		
		.el-pagination {margin-top: 15px;}
		.btn-prev,.btn-next ,.el-pager li{
			color: #fff;
			background: none;
		}
		.el-pagination .active {
			color:var(--el-pagination-hover-color);
		} 
	}
</style>
