<template>
	<div class="left">
		<div v-if="img" class="top">
			<img :src="img" />
		</div>
		<div class="con">
			<div v-if="teamId > 0" @click="naToDy" class="list">
				<img src="../../static/left-0.png" />
				我的队伍
			</div>
			<div @click="naToSL" :class="isLeft==1?'list active':'list'">
				<img src="../../static/left-1.png" />
				寻找组队
			</div>
			<div @click="naToZdy" :class="isLeft==0?'list active':'list'">
				<img src="../../static/left-2.png" />
				自定义游戏
			</div>	
			<div @click="naToTeamPlatform" :class="isLeft==2?'list active':'list'">
				<img :src=battlelogo style="vertical-align:middle;" />
				{{this.battleTeam}}
			</div>
			<div @click="naToMatchPlatform" :class="isLeft==3?'list active':'list'">
				<img src="../../static/left-2.png" />
				比赛平台
			</div>
			<div @click="naToUnionPlatform" :class="isLeft==4?'list active':'list'">
				<img :src=leaguelogo style="vertical-align:middle;" />
				{{this.league}}
			</div>
			<!--div @click="naToUrl" class="list">
				<img src="../../static/left-3.png" />
				论坛
			</div-->
		</div>
	</div>
</template>

<script>	
	export default {
		name:"Left",
		props:{
			img:String
		},
		computed:{
			isLeft() {
				return this.$store.state.isLeft;
			},
			isbattleChange() {
				return this.$store.state.isCreateBattle;
			},
		},
		watch:{
			isbattleChange(newVal, oldVal){
				this.init();
			},
		},
		data(){
			return {
				teamId:0,
				roomId:0,
				battleTeam:"",
				league:"",
				battlelogo:"",
				leaguelogo:"",
			}
		},
		created() {
			this.init();
			clearInterval(window.timel);
			window.timel = setInterval(()=>{
				this.init();
			},2000);
		},
		methods:{
			init() {
				this.teamId = localStorage.getItem("teamId")?localStorage.getItem("teamId"):0;
				this.roomId = localStorage.getItem("roomId")?localStorage.getItem("roomId"):0;

				var battleName = localStorage.getItem("battlename");
				this.battleTeam = battleName? battleName :"战队平台";
				var blogo = localStorage.getItem("battlelogo");
				this.battlelogo = blogo ? blogo : "../../static/left-2.png";

				var leagueName = localStorage.getItem("leaguename");
				this.league = leagueName?leagueName:"联盟平台";
				var llogo = localStorage.getItem("leaguelogo");
				this.leaguelogo = llogo ? llogo : "../../static/left-2.png";
			},
			//寻找队友
			naToSL() {
				this.$store.commit("leftChange",1);
				this.$router.push({name:"Game"});
			},
			//自定义游戏
			naToZdy() {
				this.$store.commit("leftChange",0);
				this.$router.push({name:"Game"});
			},
			//我的队伍
			naToDy() {
				if (this.roomId > 0) {
					this.$router.push({name:"GameRoom"});
				} else {
					this.$router.push({name:"GameDetail"});
				}
			},
			//战队平台
			naToTeamPlatform() {
				this.$store.commit("leftChange",2);
				this.$router.push({name:"Game"});
				// this.$router.push({name:"TeamPlatform"});
				
			},
			//比赛平台
			naToMatchPlatform() {
				this.$store.commit("leftChange",3);
				this.$router.push({name:"Game"});
			},
			//联盟平台
			naToUnionPlatform() {
				this.$store.commit("leftChange",4);
				this.$router.push({name:"Game"});
			}
		}
	}
</script>

<style lang="less" scoped="scoped">
.left{
	position: absolute;
	width: 240px;
	left: 0;
	top:60px;
	height: calc(100% - 60px);
	min-height: 700px;
	background: #202020;
	z-index: 9;
	
	.con {
		width: 100%;
		margin-top: 40px;
		text-align: left;
		
		
		.list {
			height: 50px;
			line-height: 50px;
			background: #2f2f2f;
			color:#fff;
			margin-bottom: 2px;
			cursor: pointer;
			align-items: center;
	
			
			img {
				width: 14px;
				margin-left: 40px;
				margin-right: 15px;
			}
		}
		.active {
			background: #4f4f4f;
		}
	}
}
</style>
