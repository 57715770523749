<template>
  <div class="game">
    <Head></Head>
	<Left></Left>
	<div v-if="min || sec" class="zz1"></div>
	<div v-if="min || sec" class="zz2"></div>
	<div
	 element-loading-background="rgba(0, 0, 0, 0.8)"
	 v-loading="loading" class="contain">
		<div class="room-top">
			房间号：<span>********</span> 
			<span @click="copy(roominfo.roomNum)" title="复制" class="icon iconfont icon-fuzhi"></span> 
			密码：<span>********</span> 
			<span @click="copy(roominfo.pass)" title="复制" class="icon iconfont icon-fuzhi"></span> 
			<span class="fwq">{{SERVE[roominfo.serve]}}</span> <span class="ms">{{NORM[roominfo.model]}}</span>
			<el-tooltip
				class="box-item"
				effect="dark"
				v-if="tancon"
				:content="tancon"
				placement="top">
			  <img class="gth" src="../../static/icon-1.png" />
			 </el-tooltip>
		</div>
		<el-row gutter="40">
			<el-col :span="8">
				<Teamlist @uC="uC" @getGameList="getGameList" ref="ch1"></Teamlist>
			</el-col>
			<el-col :span="8">
				<Chat @countdown="getGameList" :roominfo="roominfo" ref="ch2"></Chat>
			</el-col>
			<el-col :span="8">
				<Player :roominfo="roominfo" ref="ch3" @uC="uC"></Player>
			</el-col>
		</el-row>
	</div>
	<TanMsg ref="tan1" width="25%" title="队伍提示" :con="tancon"></TanMsg>
  </div>
</template>
<script>
// @ is an alias to /src
import { beforeUnmount } from 'vue'
import {reSet} from "@/request/common-api.js";
import {cPost,cGet} from "@/request/http-util.js";
import Head from '@/components/Head.vue'
import Left from '@/components/Left.vue'
import Teamlist from '@/components/gameroom/Teamlist.vue'
import Chat from '@/components/gameroom/Chat.vue'
import Player from '@/components/gameroom/Player.vue'
import Com from "@/request/common.js";
import { ElMessage } from 'element-plus'
import TanMsg from '@/components/common/TanMsg.vue'

import Clipboard from 'v-clipboard'

export default {
	name: 'GameDetail',
	components: {
		Head,
		Left,
		Teamlist,
		Chat,
		Player,
		TanMsg
	}, 
	computed: {
	  isFollow () {
	    return this.$store.state.isRc;　　//需要监听的数据
	  },
	  isFollow1 () {
	    return this.$store.state.isToTm;　　//需要监听的数据
	  },
	  isFollow2 () {
	    return this.$store.state.isToGame;　　//需要监听的数据
	  }
	},
	watch: {
	  isFollow (newVal, oldVal) {
	    this.uC();
	  },
	  isFollow1 (newVal, oldVal) {
	    this.$router.push({name:"GameDetail"});
	  },
	  isFollow2 (newVal, oldVal) {
	    this.$router.push({name:"GameStart"});
	  }
	},
	data () {
	    return {
			isShow:1,
			dwlist:[],
			loading:false,
			NORM:Com.NORM,
			SERVE:Com.SERVE,
			roominfo:{},
			startTime:'',
			roomId:0,
			min:0,
			sec:0,
			teamId:0,
			tancon:''
	    }
	},
	beforeUnmount() {
		clearInterval(window.timer);
	},
	created() {
		this.init();
	},
	methods:{
		init() {
			this.roomId = localStorage.getItem("roomId")?localStorage.getItem("roomId"):0;
			this.teamId = localStorage.getItem("teamId")?localStorage.getItem("teamId"):0;
			
			this.$store.commit("leftChange",0);
			if(this.roomId == 0) {
				reSet().then(res => {
					this.$router.push("game");
				})
			}

			this.getGameList(true);
			clearInterval(window.timer);
			window.timer = setInterval(()=>{
				this.getGameList();
			},5000);
		},
		getGameList (bloo = false) {
			this.loading = bloo;
			cGet("getroominfo",{id:this.roomId},bloo,false).then(res => {
				this.loading = false;
				if (res.code == 1) {
					
					this.dwlist = res.data.dwlist;
					this.roominfo = res.data.roominfo;
					this.$refs.ch1.init(this.dwlist,this.roominfo);
					this.tancon = this.roominfo.note;
					//弹窗
					/*if (bloo && this.roominfo.note) {
						
						this.$refs.tan1.open();
					}*/

					if (res.data.roominfo.roomstate == 1) {
						if (!this.startTime) {
							this.startTime = res.data.roominfo.startTime;
							this.countdown(this.startTime);
						}
					}
					
					if (res.data.roominfo.roomstate == 3) {
						this.$store.commit("totmChange");
					}
					
				} else {
					reSet().then(res => {
						this.$router.push("game");
					})
				}
			})
		},
		uC() {
			this.$refs.ch1.init(this.dwlist,this.roominfo);
			this.$refs.ch2.init();
			this.$refs.ch3.init();
		},
		copy(Val) {
		  var _input = document.createElement('input')
		  _input.value = Val
		  document.body.appendChild(_input)
		  _input.select()
		  document.execCommand('Copy')
		  ElMessage('复制成功');
		  _input.remove()
		  return false;
		},
		//倒计时
		countdown (time) {

		  const end = Date.parse(new Date(time)) + 180000; 
		  const now = Date.parse(new Date())
		  const msec = end - now

		  if(msec<0) return;
		  
		  let min = parseInt(msec / 1000 / 60 % 60)
		  let sec = parseInt(msec / 1000 % 60)
		  
		  this.min = min > 9 ? min : '0' + min
		  this.sec = sec > 9 ? sec : '0' + sec

		  const that = this
		  if(min>=0 && sec>=0){
			//倒计时结束关闭订单
			if(parseInt(min)<=0 && parseInt(sec)<=1){
				this.$router.push("gamestart");
				return ;
			}
			this.$refs.ch1.mC(this.min,this.sec);
			this.$refs.ch2.mC(this.min,this.sec);
			this.$refs.ch3.mC(this.min,this.sec);
			
			setTimeout(function () {
			  that.countdown(time)
			}, 1000);
		  }
		}
	},
}
</script>

<style lang="less" scoped="scoped">
	.contain{
		padding: 20px 0;
	}
	.game {
		width: 100%;
		height: 100%;
		margin:0;
		padding: 0;
	}
	.top {
		position: absolute;
		top: 2rem;
		left: 50%;
		margin-left: -95px;
	}
	.zz1 {
		position: absolute;
		width: 240px;
		left: 0;
		top: 60px;
		height: calc(100% - 60px);
		min-height: 700px;
		z-index: 10;
		background: rgba(0, 0, 0, 0.3);
	}
	.zz2 {
		position: absolute;
		height: 60px;
		width: 100%;
		min-width: 1480px;
		left: 0;
		top: 0px;
		z-index: 11;
		background: rgba(0, 0, 0, 0.3);
	}
	.room-top {
		color: #fff;
		text-align: left;
		font-size: 1.3rem;
		width: 96%;
		margin-left: calc(2% + 20px);
		margin: 1.2rem calc(2% + 20px);
		span{
			margin-right:2rem;
			color:#07b43c;
		}
	}
	
	.box-item {
		position: relative;
		top: .3rem;
	}
	
	.el-row {
		width: 96%;
		margin-left: 2% !important;
		height: calc(100% - 10rem);
		margin-top: 1.5rem;
		.el-col {
			height: 100%;
		} 	
	}
	
	.fwq {
		    font-size: .8rem;
		    color: rosybrown;
		    font-weight: 600;
		    margin-left: 20px;
		    background: #333;
		    padding: 3px 5px;
		    border-radius: 5px;
	}
	.ms {
		    font-size: .8rem;
		    color: beige;
		    font-weight: 600;
		    margin-left: 20px;
		    background: #333;
		    padding: 3px 5px;
		    border-radius: 5px;
	}
	
	::v-deep{
		.dialog2 {
			height: 34%;
		}
	}
	
</style>
