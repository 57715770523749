<template>
  <div class="game">
    <Head></Head>
	<Left></Left>
	
	<div class="contain">
		<span class="top">{{gameName}}-自定义游戏</span>
		<el-row gutter="40">
			<el-col :span="8">
				<Gamelist ref="gl" @naToAddTeam="naToAddTeam" :gameId="gameId"></Gamelist>
			</el-col>
			<el-col :span="8">
				<Chat ref="ct"></Chat>
			</el-col>
			<el-col :span="8">
				<Player ref="pl" @uC="uC"></Player>
			</el-col>
		</el-row>
	</div>
	
	<el-dialog
	v-model="dialogVisible"
	title="提示信息"
	width="30%"
	:before-close="handleClose">
		<span>未匹配到对应队伍,是否前往创建</span>
		<template #footer>
		  <span class="dialog-footer">
			<el-button class="qxBtn" @click="dialogVisible = false">取消</el-button>
			<el-button class="qdBtn" type="primary" @click="dialogVisible = false">确定</el-button>
		  </span>
		</template>
	</el-dialog>
  </div>
</template>
<script>
// @ is an alias to /src
import Head from '@/components/Head.vue'
import Left from '@/components/Left.vue'
import Gamelist from '@/components/gamedetail/Gamelist.vue'
import Chat from '@/components/gamedetail/Chat.vue'
import Player from '@/components/gamedetail/Player.vue'

export default {
	name: 'GameDetail',
	components: {
		Head,
		Left,
		Gamelist,
		Chat,
		Player
	}, 
	
	computed: {
	  isFollow () {
	    return this.$store.state.isTm;　　//需要监听的数据
	  },
	  isFollow1 () {
	    return this.$store.state.isToRc;　　//需要监听的数据
	  }
	},
	watch: {
	  isFollow (newVal, oldVal) {
	    this.uC();
	  },
	  isFollow1 (newVal, oldVal) {
	    this.$router.push({name:'GameRoom'});
	  },
	},
	data () {
	    return {
			isShow:1,
			gameName:localStorage.getItem("gameName"),
			gameId:localStorage.getItem("gameId"),
			dialogVisible:false
	    }
	},
	methods:{
		//进入添加队伍
		naToAddTeam() {
			this.dialogVisible = true;
		},
		
		//取消按钮
		qxBtn() {
			this.dialogVisible = false;
		},
		
		//确定按钮
		qdBtn() {
			this.$router.push({name:'TeamAdd'});
		},
		
		//更新组件
		uC() {
			this.$refs.gl.init();
			this.$refs.ct.init();
			this.$refs.pl.init();
		}
	},
}
</script>

<style lang="less" scoped="scoped">
	.contain{
		padding: 20px 0;
	}
	.game {
		width: 100%;
		height: 100%;
		margin:0;
		padding: 0;
	}
	.top {
		position: absolute;
		top: 2rem;
		left: 50%;
		margin-left: -95px;
		font-size: 2.2rem;
		font-weight: 600;
		color: #fff;
		letter-spacing: 2px;
		background: linear-gradient(to right, #ada59b, #d1d0cf);
		  -webkit-background-clip: text;
		  color: transparent;
	}
	
	.el-row {
		width: 96%;
		margin-left: 2% !important;
		height: calc(100% - 10rem);
		margin-top: 6.5rem;
		.el-col {
			height: 100%;
		} 	
	}
	
	::v-deep {
		.el-dialog {
			.el-dialog__title {color:#fff;}
			.el-dialog__body span {color:#fff;}
			.qdBtn{background-color: #07b43c;border-color: #07b43c;color:#fff;}
			.qxBtn{background-color: #ff5602;border-color: #ff5602;color:#fff;}
		}
	}
	
	
</style>
