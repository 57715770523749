<template>
	<router-view/>
	<!-- <Yq></Yq> -->
</template>


<script type="text/javascript">
	import Yq from '@/components/Yq.vue';
	export default {
		components:{
			Yq
		}
	}
</script>
<style>

</style>
