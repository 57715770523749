<template>
  <div
    class="gamelist"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    v-loading="loading"
  >
    <div class="gamelist-c">
      <div class="g-top">比赛信息</div>
      <div class="t-con">
        <div class="m-c-top">
          <button
            @click="btChange(1)"
            :class="isShow == 1 ? 'ctop-button' : ''"
          >
            预约查询
          </button>
          <button
            @click="btChange(2)"
            :class="isShow == 2 ? 'ctop-button' : ''"
          >
            赛况快递
          </button>
          <button
            @click="btChange(3)"
            :class="isShow == 3 ? 'ctop-button' : ''"
          >
            我的比赛
          </button>
          <button
            @click="btChange(4)"
            :class="isShow == 4 ? 'ctop-button' : ''"
          >
            比赛详情
          </button>
        </div>

        <div v-if="isShow == 1" class="t-message-con">
          <el-table
            class="t-message-table"
            height="clac(100%)"
            :data="specialMatchInfoList"
            :show-header="false"
            :cell-style="{ color: '#fff' }"
            :header-cell-style="{ color: '#fff' }"
          >
            <el-table-column>
              <template v-slot="scope">
                <el-row :gutter="24">
                  <el-col :span="12">
                    <div class="m-info-checkdiv">
                      <TeamMatch :matchInfo="scope.row" :isInfo=true></TeamMatch>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <el-table
                      class="m-check-table"
                      :data="scope.row.detail"
                      :show-header="false"
                      :row-style="{ height: '0' }"
                      :cell-style="{ padding: '1px' }"
                    >
                      <el-table-column prop="text" label="text" width="180" />
                    </el-table>
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div v-if="isShow == 2" class="t-message-con">
          <el-table
            class="t-message-table"
            height="clac(100%)"
            :data="matchRecordList"
            :show-header="false"
            :cell-style="{ color: '#fff' }"
            :header-cell-style="{ color: '#fff' }"
          >
            <el-table-column width="50px">
              <el-image class="m-message-img" :src="img"></el-image>
            </el-table-column>
            <el-table-column label="消息">
              <template v-slot="scope">
                <div>{{ scope.row.defMatchName}} - {{ scope.row.gameTime}}</div>
              </template>
            </el-table-column>
            <el-table-column>
              <template v-slot="scope">
                <el-button size="mini">{{
                  scope.row.status == 2
                    ? "已结束"
                    : scope.row.status == 1
                    ? "进行中"
                    : "未开始"
                }}</el-button>
                <el-button
                  size="mini"
                  :disabled="scope.row.status != 2"
                  type="primary"
                  @click="clickDetail(scope.row)"
                  >详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div v-if="isShow == 3" class="t-message-con">
          <div class="t-rank-div">比赛历史</div>
          <el-table
            class="m-cash-table"
            height="clac(90%)"
            :data="matchhistorylist"
            :show-header="false"
            :cell-style="{ color: '#fff' }"
            :header-cell-style="{ color: '#fff' }"
          >
            <el-table-column label="消息">
              <template v-slot="scope">
                <el-row>
                  <el-col :span="18">
                    <div>{{scope.row.defMatchName}} {{scope.row.gameTime}} 排名第{{scope.row.index ? scope.row.index : 0}}</div>
                  </el-col>
                  <el-col :span="3">
                    <el-button v-if="scope.row.isLike == 1" size="small">已赞</el-button>
                    <el-button v-if="scope.row.isLike == -1" size="small">已踩</el-button>
                    <el-button  v-if="scope.row.isLike == 0" size="small" @click="clickZan(scope.row, 1)">赞</el-button>
                  </el-col>
                  <el-col :span="3">
                    <el-button v-if="scope.row.isLike == 0" @click="clickZan(scope.row, -1)" size="small">踩</el-button>
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div v-if="isShow == 4" class="t-message-con">
          <div class="m-cash">
            <el-input
              v-model="inputMatch"
              placeholder="请输入比赛信息"
              class="input-search"
            >
              <template #append>
                <el-button @click="clickSearchMatch"
                  ><el-icon><search /></el-icon
                ></el-button>
              </template>
            </el-input>
          </div>
          <el-table
            class="m-cash-table"
            height="clac(90%)"
            :data="matchlist"
            :show-header="false"
            :cell-style="{ color: '#fff' }"
            :header-cell-style="{ color: '#fff' }"
          >
            <el-table-column label="消息">
              <template v-slot="scope">
                <el-row>
                  <el-col :span="20">
                    <div style="text-align: center">
                      {{ scope.row.defMatchName }} {{ scope.row.gameTime }} 第{{scope.row.index}}场
                    </div>
                  </el-col>
                  <el-col :span="4">
                    <el-button type="primary" size="small" @click="getSearchMatchDetial(scope.row)">详情</el-button>
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
  <el-dialog v-model="dialogDetailVisible" title="比赛详细">
    <MatchDetail ref='matchdetail' :matchDetailList="matchDetailList" :inputDisable="inputDisable"></MatchDetail>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogDetailVisible = false">取消</el-button>
        <el-button type="primary" @click="dialogDetailVisible = false"
          >确定</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import Com from "@/request/common.js";
import imghead from "../../../static/head.jpg";
import img1 from "../../../static/teamlist-1.png";
import img2 from "../../../static/teamlist-2.png";
import TeamMatch from "@/components/teamplatform/TeamMatch.vue";
import { Search } from "@element-plus/icons";
import MatchDetail from "./MatchDetail.vue";
import { cGet, cPostParam } from "@/request/http-util.js";
import { ElMessage } from "element-plus";

export default {
  name: "MatchInfo",
  components: {
    TeamMatch,
    Search,
    MatchDetail,
    // TeamList,
  },
  props: {
    gameId: Number,
  },
  data() {
    return {
      inputMatch:'',
      dialogDetailVisible: false,
      inputDisable: false,
      specialMatchInfoList: [],
      matchRecordList: [],
      matchDetailList: [],
      isShow: 1,
      loading: false,
      teamId: 0,
      imghead: imghead,
      img1: img1,
      img2: img2,
      //   gameId: 0,
      img: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      matchhistorylist: [],
      matchlist: [],
      userlist: [],
      mber: [],
      userInfo: {},
      NORM: Com.NORM,
      SERVE: Com.SERVE,
    };
  },
  created() {
    this.init();
  },
  beforeUnmount() {
    // clearInterval(window.timer1);
  },
  methods: {
    init() {
      this.clickGetMatchInfo();
    },
    //列表切换
    btChange(num) {
      this.isShow = num;
      if (num == 1) {
        this.clickGetMatchInfo();
      } else if (num == 2) {
        this.clickGetMatchRecordInfo();
      }else if (num == 3) {
        this.clickGetMyMatch();
      }
    },
    radioChange(val) {
      if (val == "充值") {
        this.isdepoist = true;
      } else {
        this.isdepoist = false;
      }
    },
    commandValue(type, command) {
      return {
        type: type,
        command: command,
      };
    },
    dropdownClick(item) {
      const { type, command } = item;
      if (type === "baoming") {
        alert(1 + command);
      } else if (type === "quxiaobao") {
        alert(2 + command);
      } else if (type === "guanzhu") {
        alert(3 + command);
      }
    },
    clickDetail(val) {
      console.log(val);
       var par = {
        index: val.index,
        defMatchId: val.defMatchId,};
  
      var getURL = "app/matches/PUBG/Manager/getPUBGMatchesByDefMatchId";

      cGet(getURL, par, true, false).then((res) => {
        if (res.code == 10000) {
          this.matchDetailList = [];
          this.matchDetailList = res.result;
          this.inputDisable = false;
          this.dialogDetailVisible = true;
          this.$refs.matchdetail.changeDetail(this.matchDetailList);
        } else {
          ElMessage(res.message);
        }
      });
    },
    //获取赛况速递
    clickGetMatchRecordInfo(){
      var userInfo = JSON.parse(localStorage.getItem("userInfo"));
      var teamId = localStorage.getItem("battleid");
      var par = {
        memberId:userInfo.id,
        pageNum: 0,
        pageSize: 1000,
        teamId: teamId ? teamId: 0,
      };
      var getURL = "app/matches/PUBG/Manager/getRunningPUBGMatchesByOrderId";
      var __this = this;
      cGet(getURL, par, true, false).then((res) => {
        if (res.code == 10000) {
            __this.matchRecordList = [];
            __this.matchRecordList = res.result;
        } else {
          ElMessage(res.message);
        }
      });
    },
    clickGetMatchInfo() {
      var userInfo = JSON.parse(localStorage.getItem("userInfo"));
      var par = {
        memberId: userInfo.id,
        pageNum: 0,
        pageSize: 1000,
        status: 1,
      };
      var getURL = "app/matches/DefMatchOrder/getMatchesPageByMemberId";
      var __this = this;
      cGet(getURL, par, true, false).then((res) => {
        if (res.code == 10000) {
          __this.specialMatchInfoList = [];
          res.result.forEach((element) => {
            var detail = [];
            detail.push({ text: "比赛名称：    " + element.name });
            var arr = element.gameStartTime.split(" ");
            if (arr.length > 0) {
              detail.push({ text: "比赛时间：    " + arr[0] });
            }
            detail.push({ text: "比赛模式：    " + element.gamePeople + "人" });
            detail.push({ text: "对局数量：    " + element.gameNum });
            detail.push({ text: "比赛队伍数：   " + element.gameTeamNum });
            detail.push({ text: "比赛报名费：   " + (element.gameBill == 0 ? '免费' : element.gameBill) });
            detail.push({ text: "比赛地图：   " + (element.gameMap == '1' ? '艾伦格' :'沙漠')});
            var info = {
              gameStartTime: element.gameStartTime,
              name: element.name,
              gameLogo: element.gameLogo,
              detail: detail,
            };
            __this.specialMatchInfoList.push(info);
          });
        } else {
          ElMessage(res.message);
        }
      });

      var battleid = localStorage.getItem("battleid");
      var par2 = {
        teamId: battleid,
        pageNum: 0,
        pageSize: 1000,
        status: 1,
      };
      getURL = "app/matches/DefMatchOrder/getMatchesPageByTeamId";
      cGet(getURL, par2, true, false).then((res) => {
        if (res.code == 10000) {
          res.result.forEach((element) => {
            var detail = [];
            detail.push({ text: "比赛名称：    " + element.name });
            var arr = element.gameStartTime.split(" ");
            if (arr.length > 0) {
              detail.push({ text: "比赛时间：    " + arr[0] });
            }
            detail.push({ text: "比赛模式：    " + element.gamePeople + "人" });
            detail.push({ text: "对局数量：    " + element.gameNum });
            detail.push({ text: "比赛队伍数：   " + element.gameTeamNum });
            detail.push({ text: "比赛报名费：   " + (element.gameBill == 0 ? '免费' : element.gameBill) });
            detail.push({ text: "比赛地图：   " + (element.gameMap == '1' ? '艾伦格' :'沙漠')});
            var info = {
              gameStartTime: element.gameStartTime,
              name: element.name,
              gameLogo: element.gameLogo,
              detail: detail,
            };
            __this.specialMatchInfoList.push(info);
          });
        } else {
          ElMessage(res.message);
        }
      });
    },
    clickGetMyMatch() {
      var userInfo = JSON.parse(localStorage.getItem("userInfo"));
      var teamId = localStorage.getItem("battleid");
      var par = {
        memberId: userInfo.id,
        pageNum: 0,
        pageSize: 1000,
        matchType: "squad",
        teamId:  teamId ? teamId : "",
      };
      var getURL = "app/matches/PUBG/Manager/getPUBGMatchesSortByMatchType";
      var __this = this;
      cGet(getURL, par, true, false).then((res) => {
        if (res.code == 10000) {
            __this.matchhistorylist = res.result;
        } else {
          ElMessage(res.message);
        }
      });
    },
    clickZan(row, islike) {
      var userInfo = JSON.parse(localStorage.getItem("userInfo"));
      var battleid = localStorage.getItem("battleid");
      var par = {
        matchId: row.defMatchId,
        memberId: userInfo.id,
        teamId: battleid ? battleid : "",
        isLike: islike,
      };
      var getURL = "app/matches/TeamOrder/putTeamOrderLike";
      var data = {};
      cPostParam(getURL, par,data, true, false).then((res) => {
        ElMessage(res.message);
        if (res.code == 10000) {
          this.clickGetMyMatch();
        }
      });
    },
    clickSearchMatch() {
      var par = {
        key:this.inputMatch,
        pageNum: 0,
        pageSize: 1000,
      };
      var getURL = "app/matches/PUBG/Manager/getPUBGMatchesByKey";
      var __this = this;
      cGet(getURL, par, true, false).then((res) => {
        if (res.code == 10000) {
            __this.matchlist = [];
            __this.matchlist = res.result;
        } else {
          ElMessage(res.message);
        }
      });
    },
    getSearchMatchDetial(val){

      var par = {
        index: val.index,
        defMatchId: val.defMatchId,
      };
      var getURL = "app/matches/PUBG/Manager/getPUBGMatchesByDefMatchId";

      cGet(getURL, par, true, false).then((res) => {
        if (res.code == 10000) {
          this.matchDetailList = [];
          this.matchDetailList = res.result;
          this.inputDisable = true;
          this.dialogDetailVisible = true;
          this.$refs.matchdetail.changeDetail(this.matchDetailList);
        } else {
          ElMessage(res.message);
        }
      });
    },
  },
};
</script>

<style scoped="scoped" lang="less">
.gamelist {
  width: 100%;
  height: 100%;
  position: relative;
}

.gamelist-c {
  height: calc(100%);
  margin: 0 auto;
  background-color: rgba(32, 32, 32, 0.6);
  border-radius: 5px;
  border: 1px solid #676666;
  box-sizing: border-box;
  position: relative;
}

.g-top {
  text-align: left;
  color: #f4f4f4;
  font-size: 1.1rem;
  background-image: linear-gradient(#161515, #2b2a2a);
  height: 3.2rem;
  line-height: 3.2rem;
  box-sizing: border-box;
  padding-left: 1.5rem;
}

.t-bot {
  width: 100%;
  left: 0;

  position: absolute;
  bottom: 0;
}
.tbutton {
  margin-top: 1em;
  background: #2f2f2f;
  border: 1px solid #676666;
  width: 100%;
  height: 2.7rem;
  line-height: 2.7rem;
  color: #efefef;
  letter-spacing: 2px;
  border-radius: 5px;
  cursor: pointer;

  img {
    position: relative;
    top: 0.4rem;
    height: 1.4rem;
  }
}
.t-con {
  padding: 0 0.6rem;
  margin-top: 2rem;
  height: 80%;
  .m-c-top {
    text-align: left;
    border-bottom: 1px solid #808080;
    button {
      background: #1f1e1f;
      color: #fff;
      padding: 0.4rem 1.2rem;
      border-left: 1px solid #808080;
      border-top: 1px solid #808080;
      border-right: 1px solid #808080;
      border-bottom: none;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      margin-right: 10px;
    }
    .ctop-button {
      background: #07b43c;
    }
  }

  .pl0 {
    padding-left: 0 !important;
  }
  .pr0 {
    padding-right: 0 !important;
  }
  .t-c-con {
    height: 90%;
    overflow-y: auto;
    position: relative;

    .el-col {
      box-sizing: border-box;
      padding: 5%;
      .g-con-list1 {
        border: 1px solid #363637;
        border-radius: 20px;
        overflow: hidden;
        .gcon_top {
          height: 2.4rem;
          background: #161617;
          line-height: 2.4rem;
          color: #fff;
          .active {
            color: #07b43c;
            overflow: hidden;
          }
        }
        .gcon_con {
          width: 100%;
          background: #222224;
          overflow: hidden;
          .gcon_con-l {
            width: 50%;
            float: left;
            box-sizing: border-box;
            padding: 5%;
            img {
              width: 90%;
              margin-left: 5%;
              width: 3.6rem;
              height: 3.6rem;
              border-radius: 50%;
            }
          }
          .text {
            width: 100%;
            color: #fff;
            height: 1.2rem;
            line-height: 1.2rem;
            font-size: 0.6rem;
          }
        }
      }
    }
  }

  .g-con-list {
    width: 100%;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 2rem;
    height: 7vw;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    .bgimg {
      width: 100%;
      height: 100%;
    }
    .p11 {
      color: #fff;
      position: absolute;
      left: 12%;
      top: 30%;
      margin-top: -13.5px;
      font-size: 1.3rem;
      width: 85%;
      text-align: left;
    }
    .p12 {
      color: #fff;
      position: absolute;
      left: 12%;
      top: 60%;
      margin-top: -13.5px;
      font-size: 1.3rem;
      width: 85%;
      text-align: left;
    }
    .zz {
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: rgba(32, 32, 32, 0.3);
      top: 0;
    }
    button {
      background: none;
      border: none;
      padding: 0.25rem 1rem;
      color: #fff;
      letter-spacing: 2px;
      border-radius: 5px;
      cursor: pointer;
      position: absolute;
      right: 12%;
      top: 50%;
      font-size: 1.2rem;
      margin-top: -15.5px;
      span {
        color: #07b43c;
      }
    }
  }

  .g-con-list div {
    width: 100%;
    height: 55em;
  }

  .t-message-con {
    height: 100%;
    // overflow-y: auto;
    position: relative;
  }
  .t-message {
    margin-top: 1em;
    margin-left: 1em;
    margin-right: 1em;
    height: 45%;
    // background: white;
    .t-message-b {
      margin-top: 0.5em;
      margin-left: 19em;
    }
  }

  .t-message-table {
    margin: 0%;
    width: 100%;
    height: 100%;
  }

  /*最外层透明*/
  ::v-deep .el-table,
  ::v-deep .el-table__expanded-cell {
    background-color: transparent !important;
  }
  /* 表格内背景颜色 */
  ::v-deep .el-table th,
  ::v-deep .el-table tr,
  ::v-deep .el-table td {
    background-color: transparent !important;
    // border: 0px;
  }

  .el-table::before {
    height: 0px;
  }
  .el-table--border::after {
    width: 0px;
  }
  .el-table--border {
    border: none;
  }

  .m-message-img {
    width: 2em;
    height: 2em;
    background: #07b43c;
  }

  .t-message-div {
    margin-left: 3em;
    margin-top: -2em;
  }

  .m-cash {
    margin-top: 0.5em;
    height: 10%;
    width: 100%;
  }
  .t-cash-div {
    font-size: 25px;
    color: white;
  }

  .t-match-div {
    width: 100%;
    color: white;
    background: #07b43c;
  }

  .m-check-table {
    color: white;
    width: 100%;
  }

  .t-rank-div {
    margin-top: 1em;
    background: #07b43c;
    color: white;
    font-size: 20px;
    height: 1.5em;
  }

  .m-cash-table {
    width: 100%;
    height: 90%;
  }

  .m-info-checkdiv {
    width: 100%;
    height: 100%;
  }
}


.el-button--primary { //需要更改的按钮类型
  background: #07b43c !important;
  border-color: #07b43c !important;
}
//移入时按钮样式
.el-button--primary:hover {
  background: #07b43c !important;
  border-color: #07b43c !important;
  color: #FFF !important;
}


</style>
