<template>
  <div class="game">
    <Head></Head>
	<Left></Left>
	
	<div class="contain">
		<span class="top">{{gameName}}</span>
		<AddTeam></AddTeam>
	</div>
  </div>
</template>
<script>
// @ is an alias to /src
import Head from '@/components/Head.vue'
import Left from '@/components/Left.vue'
import AddTeam from '@/components/AddTeam.vue'

export default {
	name: 'GameDetail',
	components: {
		Head,
		Left,
		AddTeam
	}, 
	data () {
	    return {
			isShow:1,
			gameName:"",
			gameId:0
	    }
	},
	created() {
		this.gameName = localStorage.getItem("gameName");
		this.gameId = localStorage.getItem("gameId");
	},
	methods:{
		
	},
}
</script>

<style lang="less" scoped="scoped">
	.game {
		width: 100%;
		height: 100%;
		margin:0;
		padding: 0;
	}
	.top {
		position: absolute;
		top: 2rem;
		left: 50%;
		margin-left: -95px;
		font-size: 2.2rem;
		font-weight: 600;
		color: #fff;
		letter-spacing: 2px;
		background: linear-gradient(to right, #ada59b, #d1d0cf);
		  -webkit-background-clip: text;
		  color: transparent;
	}
	
	
</style>
