<template>
  <div class="usercenter">
    <Head></Head>
    <Left></Left>

    <div
      element-loading-background="rgba(0, 0, 0, 0.8)"
      v-loading="loading"
      class="contain-user"
    >
      <Top></Top>
      <div class="user-con">
        <div class="list">
          <div class="list-con1">账户信息</div>
          <div class="list-con2">{{ userInfo.phone }}</div>
        </div>
        <div class="list">
          <div class="list-con1">Steam用户名</div>
          <div class="list-con2">
            {{ userInfo.name }}
            <span v-if="userInfo.steamId" style="color: red">已绑定</span>
          </div>
        </div>
        <div class="list">
          <div class="list-con1">绝地求生用户名</div>
          <div class="list-con2">
            {{ userInfo.pubgName }}
            <span v-if="userInfo.pubgName" style="color: red">已绑定</span>
          </div>
        </div>
        <div class="list">
          <div class="list-con1">手机号</div>
          <div class="list-con2">
            {{ userInfo.phone
            }}<!--span>编辑</span-->
          </div>
        </div>
        <!--div class="list">
				<div class="list-con1">昵称</div>
				<div class="list-con2"><input v-model="userInfo.name" /></div>
			</div-->
        <div class="list">
          <div class="list-con1">电子邮件</div>
          <div class="list-con2">
            <input v-model="userInfo.email" /><!--span>编辑</span-->
          </div>
        </div>
        <div class="list">
          <div class="list-con1">账户余额</div>
          <div class="list-con2">
            {{ userCash }}
            <el-button size="mini" @click="clickRecharge" type="success"
              >充 值</el-button
            >
			<span> </span>
			<el-button size="mini" @click="clickDetailBtn" type="success"
              >详 细</el-button
            >
          </div>
        </div>
        <el-button size="mini" @click="editBtn" type="success">确 认</el-button>
      </div>
    </div>
  </div>
  <el-dialog
    class="recharge"
    width="30%"
    v-model="dialogRechargeVisible"
    title="充值"
  >
    <el-row>
      <el-col :span="8">
        <span style="color: white">充值金额：</span>
      </el-col>
      <el-col :span="12">
        <el-input
          v-model="rechargeValue"
          @input="formatNum(rechargeValue)"
        ></el-input>
      </el-col>
      <el-col :span="2">
        <span style="color: white">元</span>
      </el-col>
    </el-row>

    <div style="margin-top: 2%">
      <el-radio v-model="rechargeRadio" label="10" @change="clickRechargeRadio"
        >10元</el-radio
      >
      <el-radio v-model="rechargeRadio" label="30" @change="clickRechargeRadio"
        >30元</el-radio
      >
      <el-radio v-model="rechargeRadio" label="50" @change="clickRechargeRadio"
        >50元</el-radio
      >
    </div>
    <el-row style="margin-top: 4%">
      <el-col :span="8" style="margin-top: 2%">
        <span style="color: white">充值方式：</span>
      </el-col>
      <el-col :span="10">
        <el-radio
          v-model="rechargeTypeRadio"
          label="1"
          @change="clickRechargeTypeRadio"
          >支付宝</el-radio
        >
        <el-radio
          v-model="rechargeTypeRadio"
          label="2"
          @change="clickRechargeTypeRadio"
          >微信</el-radio
        >
      </el-col>
    </el-row>
    <el-row style="margin-top: 4%">
      <el-col :span="8" style="margin-top: 2%">
        <span style="color: white">充值比例：</span>
      </el-col>
      <el-col :span="10" style="margin-top: 2%">
        <span style="color: white;float: left">1:10</span>
      </el-col>
    </el-row>
    <el-row style="margin-top: 4%">
      <el-col :span="8" style="margin-top: 2%">
        <span style="color: white">充值游戏币：</span>
      </el-col>
      <el-col :span="10" style="margin-top: 2%">
        <span style="color: white; float: left">{{ rechargeValue * 10 + (rechargeValue == 100 ? 5 : (rechargeValue == 200 ? 15 : 0)) }}</span>
      </el-col>
    </el-row>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogRechargeVisible = false">取消</el-button>
        <el-button type="primary" @click="clickRechargeConfirm">确定</el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog
    class="recharge"
    width="30%"
    v-model="dialogDetailVisible"
    title="充值消费详细"
  >
  <el-radio-group v-model="depoistLable" @change="radioChange">
                  <el-radio-button label="充值"></el-radio-button>
                  <el-radio-button
                    label="消费"
                  ></el-radio-button> </el-radio-group
              >
		<div class="t-cash-table" v-if="isdepoist">
            <TeamList
              :listData="depoistList"
              :titleData="depoistTitleList"
            ></TeamList>
          </div>
          <div class="t-cash-table" v-if="!isdepoist">
            <TeamList
              :listData="consumeList"
              :titleData="consumeTitleList"
            ></TeamList>
          </div>
  </el-dialog>
</template>
<script>
// @ is an alias to /src
import Head from "@/components/HeadUser.vue";
import Left from "@/components/LeftUser.vue";
import Top from "@/components/TopUser.vue";
import TeamList from "@/components/teamplatform/TeamList.vue";

import { cPost, cGet } from "@/request/http-util.js";
import { ElMessage } from "element-plus";
import { reSet, UI } from "@/request/common-api.js";
export default {
  name: "UserCenter",
  components: {
    Head,
    Left,
    Top,
	TeamList,
  },
  data() {
    return {
      userInfo: {},
      pass: "",
      loading: false,
      cpass: "",
      userCash: 0,
	  dialogRechargeVisible: false,
      rechargeRadio: "",
      rechargeTypeRadio: "1",
      rechargeValue: "",
	  depoistTitleList: ["充值人", "充值日期", "游戏币"],
      consumeTitleList: ["消费人", "消费日期", "游戏币"],
      depoistList: [],
      consumeList: [],
	  dialogDetailVisible: false,
	  depoistLable: "充值",
      isdepoist: true,
    };
  },
  created() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    UI().then((res) => {
      this.userInfo = res;
    });
  },
  mounted() {
	this.getRechargeAndCashList();
  },
  methods: {
    editBtn() {
      var par = {
        email: this.userInfo.email,
        name: this.userInfo.name,
      };

      cGet("editme", par).then((res) => {
        if (res.code == 1) {
          localStorage.setItem("userInfo", JSON.stringify(res.data));
          ElMessage("修改成功");
          this.$store.commit("headChange");
        }
      });
    },
	getRechargeAndCashList() {
		var userInfo = JSON.parse(localStorage.getItem("userInfo"));
      var par = {
        memberId: userInfo.id,
      };
      var getURL = "paycenter/check/queryMemberAllPayInfo/";
      cPost(getURL, par, true, false).then((res) => {
        if (res.code == 10000) {
		this.depoistList = res.result.payVoList;
          this.consumeList = res.result.costVoList;
          this.userCash = res.result.fundsVO.fundsFee;
        } else {
          ElMessage(res.message);
        }
      });
    },
    clickRecharge() {
      this.dialogRechargeVisible = true;
    },
    clickRechargeConfirm() {
      var userInfo = JSON.parse(localStorage.getItem("userInfo"));
      var par = {
        teamId: 0,
        memberId: userInfo.id,
        tradeFee: this.rechargeValue,
      };

      if (this.rechargeTypeRadio == "1") {
        var getURL = "paycenter/createTrade/ali/totalFee/";

        cPost(getURL, par, true, false).then((res) => {
          if (res.code == 10000) {
            window.open(res.result);
          }
        });
      } else {
        var getWxURL = "paycenter/createTrade/wx/totalFee";

        cPost(getWxURL, par, true, false).then((res) => {
          if (res.code == 10000) {
            window.open(res.result);
          }
        });
      }
    },
    formatNum(val) {
      let temp = val.toString();
      temp = temp.replace(/。/g, ".");
      temp = temp.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
      temp = temp.replace(/^\./g, ""); //验证第一个字符是数字
      temp = temp.replace(/\.{2,}/g, ""); //只保留第一个, 清除多余的
      temp = temp.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      temp = temp.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); //只能输入两个小数
      this.rechargeValue = temp;
      this.rechargeRadio = 0;
    },
	clickRechargeRadio(val) {
      this.rechargeValue = val;
    },
    clickRechargeTypeRadio(val) {
      this.rechargeTypeValue = val;
    },
	clickDetailBtn() {
		this.dialogDetailVisible = true;
		this.getRechargeAndCashList();
	},
	radioChange(val) {
      if (val == "充值") {
        this.isdepoist = true;
      } else {
        this.isdepoist = false;
      }
    },
  },
};
</script>

<style lang="less" scoped="scoped">
.usercenter {
  text-align: left;
  .user-con {
    .list {
      width: 70%;
      border-bottom: 1px solid #4a4a4a;
      color: #fff;
      height: 50px;
      margin-bottom: 10px;
      box-sizing: border-box;
      padding: 0 10px;
      .list-con1 {
        width: 160px;
        float: left;
        line-height: 50px;
        text-align: left;
      }

      .list-con2 {
        line-height: 50px;
        float: left;
        width: calc(100% - 160px);
        height: 100%;
        text-align: left;
        span {
          float: right;
          color: #9a9a9a;
        }
        input {
          background: none;
          border: none;
          color: #fff;
          padding: 10px 0;
        }
        .el-button {
          margin-top: 10px;
		  margin-left: 10px;
          float: right;
        }
      }
    }
  }
}


/*最外层透明*/
::v-deep .el-table,
::v-deep .el-table__expanded-cell {
  background-color: transparent !important;
}
/* 表格内背景颜色 */
::v-deep .el-table th,
::v-deep .el-table tr,
::v-deep .el-table td {
  background-color: transparent !important;
  // border: 0px;
}

.el-table::before {
  height: 0px;
}
.el-table--border::after {
  width: 0px;
}
.el-table--border {
  border: none;
}
.usercenter::v-deep {
  .el-button {
    padding: 2px 40px;
    margin-top: 40px;
  }
}
</style>
