<template>
	<el-dialog
	v-model="isShow"
	custom-class="dialogbd"
	title="支付方式"
	width="25%">
		<view class="vipz-zf">
			<el-row>
				<el-col :span="12">
					<img @click="wxG" src="../../../static/user/zf-wx.png">
					<div class="zf-c">
						微信
					</div>
				</el-col>
				<el-col :span="12">
					<img @click="zfbG" src="../../../static/user/zf-zfb.png">
					<div class="zf-c">
						支付宝
					</div>
				</el-col>
			</el-row>
		</view>
		<TanVipZf ref="tan1"></TanVipZf>
	</el-dialog>
</template>

<script>
	import Com from "@/request/common.js";
	import {cGet} from "@/request/http-util.js";
	import { ElMessage } from 'element-plus';
	
	import TanVipZf from "@/components/common/TanVipZf.vue"
	export default{
		name:"TanVip",
		components:{
			TanVipZf
		},
		data(){
			return {
				id:0,
				isShow:false,
				userInfo:{}
			}
		},
		created() {
			this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
		},
		methods:{
			open(id){
				this.id = id;
				this.isShow = true;
			},
			//微信购买
			wxG() {
				cGet("WXPay",{vipId:this.id}).then(res => {
					if (res.code == 1) {
						this.$refs.tan1.open(res.data.orderNum,"<img style='width:160px' src='"+ res.data.qrCode +"' />",1);
					}
				})
			},
			zfbG() {
				cGet("ALPay",{vipId:this.id}).then(res => {
					if (res.code == 1) {
						var form = res.data.form;
						this.$refs.tan1.open(res.data.orderNum,form.replace('form','form target="_bland"'),2);
					}
				})
			}
		}
	}
</script>

<style lang="less" scoped="scoped">
	.vipz-zf {
		text-align: center;
		
		img {
			width: 60px;
			height: 60px;
			cursor: pointer;
		}
		
		.zf-c {
			width: 100%;
			text-align: center;
			margin: 15px 0;
			color: #fff;
			font-size: 16px;
		}
	}
</style>
