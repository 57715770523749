<template>
  <div
    class="gamelist"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    v-loading="loading"
  >
    <div class="gamelist-c">
      <div class="g-top">加入联盟</div>
      <div class="m-con">
        <div class="join-div">
            <el-input
              v-model="inputBattle"
              placeholder="输入联盟信息"
              class="input-search"
            >
              <template #append>
                <el-button @click="clickSearch"
                  ><el-icon><search /></el-icon
                ></el-button>
              </template>
            </el-input>
          </div>
          <el-table class="join-table" height="clac(90)" :data="teamlist">
            <!-- <el-table-column prop="teamID" align="center" label="ID">
              <template #default="scope">
                {{ scope.row.teamID ? scope.row.teamID : "" }}
              </template>
            </el-table-column> -->

            <el-table-column prop="teamName" align="center" label="联盟名称">
              <template #default="scope">
                {{ scope.row.name ? scope.row.name : "" }}
              </template>
            </el-table-column>

            <!-- <el-table-column prop="teamLeader" align="center" label="战队队长">
              <template #default="scope">
                {{ scope.row.teamLeader ? scope.row.teamLeader : "" }}
              </template>
            </el-table-column> -->
            <el-table-column prop="Score" align="center" label="战队描述">
              <template #default="scope">
                {{ scope.row.descriptionInfo ? scope.row.descriptionInfo : "" }}
              </template>
            </el-table-column>
            <el-table-column align="center">
                <template v-slot="scope">
                     <el-button type="primary" style="background-color:#07b43c;" size="small" @click="clickJoin(scope.row)">加入联盟</el-button>
                </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  
</template>

<script>
import { Search } from "@element-plus/icons";
import { cGet, cPost } from "@/request/http-util.js";
import { ElMessage } from "element-plus";

export default {
  name: "TeamJoin",
  components: {
      Search,
  },
  props: {
    gameId: Number,
  },
  data() {
    return {
      inputBattle:"",
       teamlist: [],
    };
  },
  created() {
    this.init();
  },
  beforeUnmount() {
    // clearInterval(window.timer1);
  },
  methods: {
    init() {
      
    },
    clickJoin(val) {
        var userInfo = JSON.parse(localStorage.getItem("userInfo"));
        var teamId = localStorage.getItem("battleid");
          var par = {
            leagueId:val.id,
            fromMemberId:userInfo.id,
            fromTeamId:teamId,
            type:1,
      };
      var getURL = "app/gameteam/manager/sendLeagueRequest";
      cPost(getURL, par, true, false).then((res) => {
        ElMessage(res.message);
        // if (res.code == 10000) {
        //     this.updateBattleInfo();
        // }
      });
    },
    clickSearch(){
      var userInfo = JSON.parse(localStorage.getItem("userInfo"));
      var par = {
        key: this.inputBattle,
        pageNum: 0,
        pageSize: 1000,
        currentMemberId:userInfo.id,
      };
      var getURL = "app/gameteam/manager/queryLeagues";
      var __this = this;
      cGet(getURL, par, true, false).then((res) => {
        if (res.code == 10000) {
          
          __this.teamlist = res.result;
        }
        else{
          ElMessage(res.message);
        }
      });
    }
  },
};
</script>

<style scoped="scoped" lang="less">
.gamelist {
  width: 100%;
  height: 100%;
  position: relative;
}

.gamelist-c {
  height: calc(100%);
  margin: 0 auto;
  background-color: rgba(32, 32, 32, 0.6);
  border-radius: 5px;
  border: 1px solid #676666;
  box-sizing: border-box;
  position: relative;
}

.g-top {
  text-align: left;
  color: #f4f4f4;
  font-size: 1.1rem;
  background-image: linear-gradient(#161515, #2b2a2a);
  height: 3.2rem;
  line-height: 3.2rem;
  box-sizing: border-box;
  padding-left: 1.5rem;
}

.t-bot {
  width: 100%;
  left: 0;

  position: absolute;
  bottom: 0;
}

.m-con {
  padding: 0 0.6rem;
  // margin-top: 0.5rem;
  height: 100%;
  .m-c-top {
    text-align: left;
    border-bottom: 1px solid #808080;
    button {
      background: #1f1e1f;
      color: #fff;
      padding: 0.4rem 1.2rem;
      border-left: 1px solid #808080;
      border-top: 1px solid #808080;
      border-right: 1px solid #808080;
      border-bottom: none;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      margin-right: 10px;
    }
    .ctop-button {
      background: #07b43c;
    }
  }

 

  /*最外层透明*/
  ::v-deep .el-table,
  ::v-deep .el-table__expanded-cell {
    background-color: transparent !important;
  }
  /* 表格内背景颜色 */
  ::v-deep .el-table th,
  ::v-deep .el-table tr,
  ::v-deep .el-table td {
    background-color: transparent !important;
    // border: 0px;
  }

  .el-table::before {
    height: 0px;
  }
  .el-table--border::after {
    width: 0px;
  }
  .el-table--border {
    border: none;
  }

  
  .t-message-con {
    height: 100%;
    // overflow-y: auto;
    position: relative;
  }
   .join-div {
    height: 8%;
    width: 100%;
  }
  .join-table {
    width: 100%;
    height: 85%;
    color: white;
    //  ::v-deep .is-scrolling-none {
    //   overflow: hidden;
    //   overflow-y: auto;
    //   overflow-x: scroll;
    // }
    // ::v-deep .is-scrolling-none::-webkit-scrollbar {
    //   display: none;
    // }
    // ::v-deep.el-table--scrollable-y ::-webkit-scrollbar {
    //   display: none;
    // }
    // ::v-deep.el-table--scrollable-x ::-webkit-scrollbar {
    //   display: none;
    // }
  }
    .input-search {
    margin-top: 3px;
  }
}
.el-button--primary { //需要更改的按钮类型
  background: #07b43c !important;
  border-color: #07b43c !important;
}
//移入时按钮样式
.el-button--primary:hover {
  background: #07b43c !important;
  border-color: #07b43c !important;
  color: #FFF !important;
}
</style>
