<template>
  <div class="game">
    <Head></Head>
	<Left></Left>
	
	<div class="contain">
		<img class="top" src="../../static/game-2.png" />
		<el-row gutter="40">
			<el-col :span="8">
				<Teamlist></Teamlist>
			</el-col>
			<el-col :span="8">
				<Chat></Chat>
			</el-col>
			<el-col :span="8">
				<Player></Player>
			</el-col>
		</el-row>
	</div>
  </div>
</template>
<script>
// @ is an alias to /src
import Head from '@/components/Head.vue'
import Left from '@/components/Left.vue'
import Teamlist from '@/components/searchteam/Teamlist.vue'
import Chat from '@/components/gamedetail/Chat.vue'
import Player from '@/components/gamedetail/Player.vue'

export default {
	name: 'GameDetail',
	components: {
		Head,
		Left,
		Teamlist,
		Chat,
		Player
	}, 
	data () {
	    return {
			isShow:1
	    }
	},
	created() {
		
	},
	methods:{
		
	},
}
</script>

<style lang="less" scoped="scoped">
	.game {
		width: 100%;
		height: 100%;
		margin:0;
		padding: 0;
	}
	.top {
		position: absolute;
		top: 2rem;
		left: 50%;
		margin-left: -95px;
	}
	
	.el-row {
		width: 96%;
		margin-left: 2% !important;
		height: calc(100% - 10rem);
		margin-top: 6.5rem;
		.el-col {
			height: 100%;
		} 	
	}
	
</style>
