<template>
  <div class="start">
    <Head></Head>
	<Left></Left>
	
	<div class="contain" 
	element-loading-background="rgba(0, 0, 0, 0.8)"
	v-loading="loading">
		<div class="top">
			<span>队伍玩家</span>
			<div class="seach">
				<el-input v-model="keyword"
				      placeholder="搜索玩家"
				      class="input-with-select">
				  <template #append>
					<el-button @click="sBtn">搜索</el-button>
				  </template>
				</el-input>
			</div>
			<button class="jsyxBtn">结束游戏</button>
		</div>
		<div class="con">
			<el-row>
				<el-col v-for="(item,index) in teamList" :span="8">
					<div class="list-con">
						<div class="list-con-head">
							<img class="headimg" :src="item.avatar?item.avatar:img1" />
							<img class="headicon" src="../../static/qz.png" />
							<span>{{index+1}}</span>
						</div>
						<div class="list-c-l" style="padding-top: 1rem; margin-left: 5px;">
							{{item.name?item.name:"××××"}}<span>已玩:</span> {{item.playCount}}次 
						</div>
						<div class="list-c-l">
							<img v-if="jb.indexOf(item.id) == -1" @click="jbBtn(item.id)" src="../../static/start-2.png" />
							<img v-if="hy.indexOf(item.id) == -1" @click="hyBtn(item.id)" src="../../static/start-4.png" />
							<!--img @click="dzBtn(item.id)" src="../../static/start-2.png" />
							<img @click="dzBtn(item.id)" src="../../static/start-3.png" /-->
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
	</div>
  </div>
</template>
<script>
// @ is an alias to /src
import { beforeUnmount } from 'vue'
import Head from '@/components/Head.vue'
import Left from '@/components/Left.vue'
import img1 from '../../static/head.jpg'
import { ElMessage } from 'element-plus'
import {cPost,cGet} from "@/request/http-util.js";
export default {
	name: 'GameDetail',
	components: {
		Head,
		Left
	}, 
	computed: {
	  isFollow () {
	    return this.$store.state.isToTm;
	  }
	},
	watch: {
	  isFollow (newVal, oldVal) {
	    this.$router.push({name:'TeamList'});
	  }
	},
	data(){
		return {
			teamId:0,
			keyword:"",
			img1:img1,
			teamList:[],
			teaminfo:{},
			jb:[],
			hy:[],
			loading:false,
			userInfo:{}
		}
	},
	beforeUnmount() {
		clearInterval(window.timer);
	},
	created () {
		this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
		this.teamId = localStorage.getItem("teamId")?localStorage.getItem("teamId"):0;
		
		if (this.teamId == 0) {
			this.$store.commit("totmChange");
		}
		
		this.init();
	},
	methods:{
		init() {
			this.getMember(1);
			clearInterval(window.timer);
			window.timer = setInterval(()=>{
				this.getMember();
			},5000);
		},
		getMember(num = 0) {
			var _this = this; 
			var par = {
				id :this.userInfo.id,
				mode : this.isShow - 1,
				gameId:localStorage.getItem("gameId")
			}
			
			if (num > 1) this.loading = true;
			cGet("getteammems",{teamid:this.teamId},false,false).then(res => {
				if (num > 0) this.loading = false;
				if (res.code == 1) {
					_this.teamList = res.data.list;
					console.log(res.data.list);
					_this.teaminfo = res.data.teammod;
				}
			})
		},
		hyBtn(id){
			if (id == this.userInfo.id) {
				ElMessage("请勿添加自己为好友！");
				return false;
			}
			cGet("addfriend",{fuid:id}).then(res => {
				if (res.code == 1) {
					ElMessage("邀请发送成功，等待同意");
					hy.push(id);
				}
			})
		},
		//举报
		jbBtn(id) {
			if (id == this.userInfo.id) {
				ElMessage("请勿举报自己！");
				return false;
			}
			cGet("ReportOne",{beReporter:id}).then(res => {
				if (res.code == 1) {
					ElMessage("举报成功");
					jb.push(id);
				}
			})
		},
		//搜索
		sBtn() {
			if (!this.keyword) {
				ElMessage("搜索条件不能为空！");
				return false;
			}
			this.getMember(1);
		}
	},
}
</script>

<style lang="less" scoped="scoped">
	.start {
		width: 100%;
		height: 100%;
		margin:0;
		padding: 0;
	}
	.con {
		width: 100%;
		height: calc(100% - 10rem);
		background-color: rgba(32, 32, 32, 0.6);
		border: 1px solid #676767;
		border-radius: 5px;
		margin-top: 5rem;
		box-sizing: border-box;
		padding: 3rem 2rem;
		.el-col{
			margin-bottom:2rem;
		}
		.list-con {
			width: 100%;
			position: relative;
			.list-con-head {
				position: absolute;
				left: 0;
				top: 0;
				width: 5.2rem;
				height: 5.2rem;
				border-radius: 50%;
				overflow: hidden;
				margin: 1rem;
				.headimg {width: 100%;height: 100%;}
				.headicon{top: .3rem; position: absolute; right: .2rem;}
				span {
					position: absolute;
					top: .9rem;
					right: .5rem;
					color: #fff;
					font-size: .8rem;
				}
			}
			.list-c-l {
				width: 100%;
				box-sizing: border-box;
				padding-left: 7.4rem;
				text-align: left;
				color: #fff;
				margin-bottom: .6rem;
				font-size: 1rem;
				span {
					color:#999;
					margin-left: 1rem;
				}
				img {
					cursor: pointer;
					margin-right: .4rem;
				}
			}
		}
	}
	.top {
		position: relative;
		top: 2rem;
		left: 0;
		width: 100%;
		height: 3rem;
		span {
			position: absolute;
			top: 0rem;
			left: 50%;
			margin-left: -95px;
			font-size: 2.2rem;
			font-weight: 600;
			color: #fff;
			letter-spacing: 2px;
			background: linear-gradient(to right, #ada59b, #d1d0cf);
			  -webkit-background-clip: text;
			  color: transparent;
		}
		
		.seach {
			width: 20rem;
			top: .2rem;
			position: relative;
		}
		
		.jsyxBtn {
			background-color: #ff5500;
			height: 2rem;
			width: 6rem;
			text-align: center;
			color: #fff;
			border-radius: 4px;
			margin-top: -1.6rem;
			border: none;
			line-height: 2rem;
			position: absolute;
			right: 0;
			bottom: .3rem;
		}
	}
	
	.start::v-deep {
		.el-input-group__append {
			background-color: #07b43c !important;
			border:none !important;
			.el-button {
				
				height: 2.4rem;
				width: 5rem;
				color: #fff !important;
			}
		}
		
	}
</style>
