<template>
	<div class="gamelist" 
	element-loading-background="rgba(0, 0, 0, 0.8)"
	v-loading="loading">
		<div class="gamelist-c">
			<div class="g-top">
				房间/队伍列表
			</div>
			<div class="g-con">
				<div class="g-c-top">
					<button @click="btChange(1)" :class="isShow == 1?'ctop-button':''">大厅房间</button>
					<button @click="btChange(2)" :class="isShow == 2?'ctop-button':''">大厅队伍</button>
				</div>
				<div v-if="isShow == 1" class="g-c-con">
					<div v-for="(item,index) in roomlist" class="g-con-list">
						<img class="bgimg" :src="item.coverUrl" />
						<div class="zz"></div>
						<p class="p11">
							<span style="color: #fff;font-size: 1rem;letter-spacing: 2px;" class="ms">房主：{{item.leaderName}}</span>
							 <el-tooltip
								class="box-item"
								effect="dark"
								v-if="item.note"
								:content="item.note"
								placement="top">
							   <img class="gth" style="margin-left: 10px;" src="../../../static/icon-2.png" />
							</el-tooltip>
						</p>
						<p class="p12">
							<span class="fwq">{{SERVE[(item.serve?item.serve:0)]}}</span> 
							<span class="ms">{{NORM[(item.norm?item.norm:0)]}}</span>
						</p>
						<button><span>{{item.hasNum}}</span>/{{item.maxNum}}</button>
					</div>
				</div>
				<div v-if="isShow == 2" class="g-c-con">
					<el-row>
						<el-col v-for="(item,index) in mber" class="pl0" :span="12">
							<template  v-if="item.hasRoom <= 0">
								<div class="g-con-list1">
									<div class="gcon_top">
										队伍{{index+1}} 
										 <span class="fwq">{{SERVE[(item.serve?item.serve:0)]}}</span> <span class="ms">{{NORM[(item.tmods.model?item.tmods.model:0)]}}</span>
										
										 <el-tooltip
										        class="box-item"
										        effect="dark"
												v-if="item.note"
										        :content="item.note"
										        placement="top"
										      >
										       <img class="gth" src="../../../static/icon-1.png" />
										      </el-tooltip>
									</div>
									<div class="gcon_con">
										<div v-for="n in item.maxNum" @click="rdBtn(item.rylist[0].teamid)" class="gcon_con-l">
											<img :src="typeof(item.rylist[(n-1)]) != 'undefined'?(item.rylist[(n-1)]['avatar']?item.rylist[(n-1)]['avatar']:imghead):img2" />
											<div class="text onesl">{{typeof(item.rylist[(n-1)]) != 'undefined'?item.rylist[(n-1)]['pubgName']:"暂无"}}</div>
										</div>
									</div>
								</div>
							</template>
						</el-col>
					</el-row>
				</div>
			</div>
		</div>
		<div class="g-bot">
			<button v-if="teamId <= 0" @click="sjBtn">
				<img src="../../../static/gamedetail-4.png" />
				随机匹配加入房间
			</button>
			<button v-if="teamId > 0">
				已匹配小队
			</button>
		</div>
	</div>
</template>

<script>
	import { beforeUnmount } from 'vue'
	import {cPost,cGet} from "@/request/http-util.js";
	import Com from "@/request/common.js";
	import { ElMessage } from 'element-plus';
	import imghead from '../../../static/head.jpg'
	import img1 from '../../../static/teamlist-1.png'
	import img2 from '../../../static/teamlist-2.png'
	export default{
		name:"Gamelist",
		props:{
			gameId:Number
		},
		data() {
			return {
				isShow:1,
				loading:false,
				teamId:0,
				imghead:imghead,
				img1:img1,
				img2:img2,
				gameId:0,
				roomlist:[],
				mber:[],
				userInfo:{},
				
				NORM:Com.NORM,
				SERVE:Com.SERVE
			}
		},
		created() {
			this.init();
		},
		beforeUnmount() {
			clearInterval(window.timer1);
		},
		methods:{
			init(){
				this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
				this.gameId = localStorage.getItem("gameId");
				this.teamId = localStorage.getItem("teamId")?localStorage.getItem("teamId"):0;
				this.isShow = localStorage.getItem("teamId")?2:1
					
				this.getGameList(1);
				this.getTeamList(1);
				clearInterval(window.timer1);
				window.timer1 = setInterval(()=>{
					this.getTeamList();
					this.getGameList();
				},5000);
			},
			//列表切换
			btChange(num) {
				this.isShow = num;
				if (num == 1) {
					this.getGameList(1);
					clearInterval(window.timer1);
					window.timer1 = setInterval(()=>{
						this.getGameList()
					},5000);
				}else{
					this.getTeamList(1);
					clearInterval(window.timer1);
					window.timer1 = setInterval(()=>{
						this.getTeamList()
					},5000);
				}
			},
			getGameList (num = 0) {
				if(num == 1)this.loading = true;
				cGet("GetRooms",{gameid:this.gameId},this.loading,false).then(res => {
					if(num == 1)this.loading = false;
					if (res.code == 1) {
						this.roomlist = res.data.filter(function(item,index){
							if (item.hasNum > 0) return item;
						})
						//res.data;
					}
				})
			},
			//获取
			getTeamList(num = 0) {
				if(num == 1)this.loading = true;
				var par = {
					id :this.userInfo.id,
					gameId:this.gameId
				}
				var _this = this;
				cGet("getteams",par,this.loading,false).then(res => {
					if(num == 1)this.loading = false;
					if(res.code == 1) {
						this.mber = res.data;
					}
				})
			},
			//随机加入小队
			sjBtn(){
				var par = {
					id:this.userInfo.id,
					gameId:this.gameId,
					serve:this.userInfo.ser,
					level:Com.randomNum(0,Com.LEVEL.length-1),
					
					model:Com.randomNum(0,Com.MODEL.length-1),
					norm:Com.randomNum(0,Com.NORM.length-1)
				};
				var _this = this;
				this.loading = true;
				cGet("RandomJoin",par,true,false).then(res => {
					this.loading = false;
					if (res.code == 1) {
						localStorage.setItem("teamId",res.data.teamId);
						localStorage.setItem("roomId",res.data.roomId);
						this.$router.push({name:"GameRoom"});
					}
				})
			},
			//点击加入小队
			rdBtn(teamid) {
				
				var par = {
					teamid:teamid
				};
				
				cGet("jointoteam",par,true,false).then(res => {
					if (res.code == 1) {
						ElMessage("加入成功");
						localStorage.setItem("teamId",teamid);
						this.$store.commit("tmChange");
					}
				})
			}
		}
	}
</script>

<style scoped="scoped" lang="less">
	.gamelist {
		width: 100%;
		height: 100%;
		position: relative;
	}
	
	.gamelist-c {
		height: calc(100% - 5rem);
		margin: 0 auto;
		background-color: rgba(32, 32, 32, 0.6);
		border-radius: 5px;
		border:1px solid #676666;
		box-sizing: border-box;
		position: relative;
	}
	
	.g-top {
		text-align: left;
		color: #f4f4f4;
		font-size: 1.1rem;
		background-image: linear-gradient( #161515, #2b2a2a);
		height: 3.2rem;
		line-height: 3.2rem;
		box-sizing: border-box;
		padding-left: 1.5rem;
	}
	
	.g-bot {
		width: 100%;
		left: 0;
		position: absolute;
		bottom: 0;
		button {
			background: #2f2f2f;
			border: 1px solid #676666;
			width: 100%;
			height: 2.7rem;
			line-height: 2.7rem;
			color: #efefef;
			letter-spacing: 2px;
			border-radius: 5px;
			cursor: pointer;
			
			img {
				position: relative;
				top: .4rem;
				height: 1.4rem;
			}
		}
	}
	
	.g-con {
		padding: 0 2rem;
		margin-top: 2rem;
		height: 100%;
		.g-c-top {
			text-align: left;
			border-bottom:1px solid #808080;
			button {
				background: #1f1e1f;
				color: #fff;
				padding: .4rem 1.2rem;
				border-left: 1px solid #808080;
				border-top: 1px solid #808080;
				border-right: 1px solid #808080;
				border-bottom: none;
				border-top-left-radius: 5px;
				border-top-right-radius: 5px;
				margin-right: 10px;
			}
			.ctop-button {
				background: #07b43c;
			}
		}
		
		
		.pl0 {padding-left: 0 !important;}
		.pr0 {padding-right: 0 !important;}
		.g-c-con {
			height: 70%;
			overflow-y: auto;
			position: relative;
			
			
			.el-col {
				box-sizing: border-box;
				padding: 5%;
				.g-con-list1 {
						border: 1px solid #363637;
					    border-radius: 20px;
						overflow: hidden;
					.gcon_top {
						height: 2.4rem;
						background: #161617;
						line-height: 2.4rem;
						color: #fff;
						.active {
							color:#07b43c;
							overflow: hidden;
						}
					}
					.gcon_con {
						width: 100%;
						background: #222224;
						overflow: hidden;
						.gcon_con-l {
							width: 50%;
							float: left;
							box-sizing: border-box;
							padding: 5%;
							img {width: 90%;margin-left: 5%;width: 3.6rem;height: 3.6rem;border-radius: 50%;}
						}
						.text {
							width: 100%;
							color: #fff;
							height: 1.2rem;
							line-height: 1.2rem;
							font-size: .6rem;
						}
					}
				}
			}
		}
		    
		.g-con-list {
			width: 100%;
			position: relative;
			border-radius: 5px;
			overflow: hidden;
			margin-bottom: 2rem;
			height: 6vw;
			.bgimg {
				width: 100%;
				height:100%;
			}
			.p11 {
				color: #fff;
				position: absolute;
				left: 12%;
				top: 30%;
				margin-top:-13.5px;
				font-size: 1.3rem;
				width: 85%;
				text-align: left;
			}
			.p12 {
				color: #fff;
				position: absolute;
				left: 12%;
				top: 60%;
				margin-top:-13.5px;
				font-size: 1.3rem;
				    width: 85%;
				    text-align: left;
			}
			.zz {
				width: 100%;
				height: 100%;
				position: absolute;
				background-color: rgba(32, 32, 32, 0.3);
				top: 0;
			}
			button {
				background:none;
				border: none;
				padding: .25rem 1rem;
				color: #fff;
				letter-spacing: 2px;
				border-radius: 5px;
				cursor: pointer;
				position: absolute;
				right: 12%;
				top: 50%;
				font-size: 1.2rem;
				margin-top:-15.5px;
				span {
					color:#07b43c;
				}
			}
		}
		
		.fwq {
			    font-size: .8rem;
			    color: rosybrown;
			    font-weight: 600;
			    margin-left: 2%;
			    background: #333;
			    padding: 3px 5px;
			    border-radius: 5px;
		}
		.ms {
			    font-size: .8rem;
			    color: beige;
			    font-weight: 600;
			    margin-left: 2%;
			    background: #333;
			    padding: 3px 5px;
			    border-radius: 5px;
		}
		
		.gth {
			margin: 0 5px;
			top: 6px;
			position: relative;
		}
	}
</style>
