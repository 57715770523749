import axios from 'axios' // 引入axios
import { ElMessage,ElLoading  } from 'element-plus'

const requestTime = 30;  //请求响应时间，默认为10s
const _axios = axios.create();

// const baseURL = 'http://localhost:8001/';
// const wsURL = "https://www.nocheater.cn/server/";
// const baseURL = 'http://47.100.104.163:80/server';
// const wsURL = "ws://47.100.104.163:8081/game/v1.0/";

const baseURL = 'https://www.nocheater.cn/server';
// const wsURL = "ws://101.132.148.174:8081/game/v1.0/";
const wsURL = "wss:www.nocheater.cn/game/v1.0/";


Object.assign(_axios.defaults,{
  //baseURL: '/ccc?act=',
	// baseURL: 'https://api.nocheater.cn/api/api.aspx?act=',
//    baseURL: 'http://172.27.238.31:8081/game/v1.0/app/gameteam/manager',
   baseURL: baseURL,
  timeout: 1000*requestTime,
  withCredentials: false,
});


//请求拦截器
_axios.interceptors.request.use(config=>{
   //config是配置
   //第一步：查看有没有token
    const token = localStorage.getItem('token'); 

	var urlApi = ['login','SteamLogin','register','sendmsgcode','BindSuccess'];
    if(token || urlApi.indexOf(config.url) != -1){
		if (config.method == "get") {
			config.params['token'] = token;
		} else {
			config.data['token'] = token;
		}
		if(config.url.indexOf("app/") != -1 || config.url.indexOf("paycenter") != -1) {
			config.url = "game/v1.0/" + config.url;
		} else {
			config.url = "api/api.aspx?act=" + config.url;
		}
    }else{
      // token不存在，返回登录页
      localStorage.removeItem('token');
	  localStorage.removeItem("userInfo");
    //   window.location.href= "/";
    }
   return config;
},err => Promise.reject(err));

//响应拦截器
_axios.interceptors.response.use(res=>{
   //res是响应的结果
    if(res.data.code == -101){
      //登录失效
	  ElMessage("登录失效");
      localStorage.removeItem('token');
      window.location.href= "/";
      return 
    }
   
    /*if (typeof(res.data.token) != 'undefined') {
	  localStorage.setItem('token',res.data.token); 
    }*/
	return res.data
},err => Promise.reject(err));

//返回一个Promise(发送post请求)
export function cPost(url, params,show1=true,show2=true) {
    return new Promise((resolve, reject) => {
		if(show2) var loadingInstance = ElLoading.service({ fullscreen: true });
        _axios.post(url, params)
		.then(response => {
			if(show2) loadingInstance.close()
			if (show1 ) {
				if (response.code == -1) {
					ElMessage(response.msg);
					resolve({})
				} else {
					resolve(response)
				}
			} else {
				resolve(response)
			}
		}, err => {
			if(show2) loadingInstance.close()
			if (show1 )ElMessage("超时请重试");
			console.log("err",err);
			reject(err);
		})
		.catch((error) => {
			if(show2) loadingInstance.close()
			if (show1 )ElMessage("超时请重试");
			console.log("error",error);
			reject(error)
		})
    })
}

export function cPostParam(url, params, data, show1=true,show2=true) {
    return new Promise((resolve, reject) => {
		if(show2) var loadingInstance = ElLoading.service({ fullscreen: true });
        _axios.post(url, data, {params: params})
		.then(response => {
			if(show2) loadingInstance.close()
			if (show1 ) {
				if (response.code == -1) {
					ElMessage(response.msg);
					resolve({})
				} else {
					resolve(response)
				}
			} else {
				resolve(response)
			}
		}, err => {
			if(show2) loadingInstance.close()
			if (show1 )ElMessage("超时请重试");
			console.log("err",err);
			reject(err);
		})
		.catch((error) => {
			if(show2) loadingInstance.close()
			if (show1 )ElMessage("超时请重试");
			console.log("error",error);
			reject(error)
		})
    })
}
////返回一个Promise(发送get请求)
export function cGet(url, param,show1=true,show2=true) {
    return new Promise((resolve, reject) => {
		if(show2)console.log(url);
		if(show2) var loadingInstance = ElLoading.service({ fullscreen: true });
	
        _axios.get(url, {params: param})
		.then(response => {
			if(show2) loadingInstance.close()
			if (show1 ) {
				if (response.code == -1) {
					ElMessage(response.msg);
					resolve({})
				} else {
					resolve(response)
				}
			} else {
				resolve(response)
			}
		}, err => {
			if(show2) loadingInstance.close()
			if (show1 )ElMessage("超时请重试");
			reject(err)
		})
		.catch((error) => {
			if(show2) loadingInstance.close()
			if (show1 )ElMessage("超时请重试");
			reject(error)
		})
    })
}
export default {
    cPost,
    cGet,
	cPostParam,
	baseURL,
	wsURL,
}