<template>
  <div class="game">
    <Head></Head>
    <Left></Left>

    <div class="contain">
      <span class="top">战队平台</span>
      <el-row v-if="isHaveBattle" gutter="40">
        <el-col :span="8">
          <TeamManager ref="tm"
            :battleInfo="battleInfo"
          ></TeamManager>
        </el-col>
        <el-col :span="8">
          <TeamChat></TeamChat>
        </el-col>
        <el-col :span="8">
          <TeamSearch></TeamSearch>
        </el-col>
      </el-row>
      <el-row v-if="!isHaveBattle" gutter="40">
        <el-col :span="8">
          <TeamCreate></TeamCreate>
        </el-col>
        <el-col :span="8">
          <TeamJoin></TeamJoin>
        </el-col>
        <el-col :span="8">
          <TeamInvite></TeamInvite>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import Head from "@/components/Head.vue";
import Left from "@/components/Left.vue";
import TeamManager from "@/components/teamplatform/TeamManager.vue";
import TeamChat from "@/components/teamplatform/TeamChat.vue";
import TeamSearch from "@/components/teamplatform/TeamSearch.vue";
import TeamCreate from "@/components/teamplatform/TeamCreate.vue";
import TeamJoin from "@/components/teamplatform/TeamJoin.vue"
import TeamInvite from "@/components/teamplatform/TeamInvite.vue"
import { cPost, cGet } from "@/request/http-util.js";
import { ElMessage } from "element-plus";

export default {
  name: "TeamPlatform",
  components: {
    Head,
    Left,
    TeamManager,
    TeamChat,
    TeamSearch,
    TeamCreate,
    TeamJoin,
    TeamInvite,
  },
  computed: {
    // isFollow() {
    //   return this.$store.state.isTm; //需要监听的数据
    // },
    // isFollow1() {
    //   return this.$store.state.isToRc; //需要监听的数据
    // },
  },
  watch: {
    // isFollow(newVal, oldVal) {
    //   this.uC();
    // },
    // isFollow1(newVal, oldVal) {
    //   this.$router.push({ name: "GameRoom" });
    // },
  },
  data() {
    return {
      isHaveBattle:false,
      battleInfo:{},
      gameName: localStorage.getItem("gameName"),
    };
  },
  created() {
    var battleID = localStorage.getItem("battleid");
    if(battleID){
      this.isHaveBattle = true;
      this.battleInfo = JSON.parse(localStorage.getItem("battleinfo"));
    } 
  },
  methods: {

    //更新组件
    uC() {
      this.$refs.tm.init();
      
    },
  },
};
</script>

<style lang="less" scoped="scoped">
.contain {
  padding: 20px 0;
}
.game {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
.top {
  position: absolute;
  top: 2rem;
  left: 50%;
  margin-left: -95px;
  font-size: 2.2rem;
  font-weight: 600;
  color: #fff;
  letter-spacing: 2px;
  background: linear-gradient(to right, #ada59b, #d1d0cf);
  -webkit-background-clip: text;
  color: transparent;
}

.el-row {
  width: 96%;
  margin-left: 2% !important;
  height: calc(100% - 10rem);
  margin-top: 6.5rem;
  .el-col {
    height: 100%;
  }
}

::v-deep {
  .el-dialog {
    .el-dialog__title {
      color: #fff;
    }
    .el-dialog__body span {
      color: #fff;
    }
    .qdBtn {
      background-color: #07b43c;
      border-color: #07b43c;
      color: #fff;
    }
    .qxBtn {
      background-color: #ff5602;
      border-color: #ff5602;
      color: #fff;
    }
  }
}
</style>
