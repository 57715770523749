<template>
	<div class="login">
		<div class="con">
			<span @click="close" class="close icon iconfont icon-guanbi"></span>
			<div class="top">登录</div>
			<div class="mid">
				<el-row>
					<el-col class="title" :span="24">手机号</el-col>
					<el-col class="marginb" :span="24">
						<input v-model="username" autocomplete="off" placeholder="请输入您的手机号" type="number" />
					</el-col>
					<el-col class="title" :span="24">密码</el-col>
					<el-col :span="24">
						<input v-model="password" autocomplete="new-password" placeholder="请输入您的密码" :type="ptype"/>
						<span @click="eyeChange" :class="'eye icon iconfont icon-in_'+eye"></span>
					</el-col>
					<el-col class="wj" @click="wjBtn" :span="24">忘记密码</el-col>
					<el-col class="tj" @click="loginBtn" :span="24"><button >登录</button></el-col>
					
					<el-col class="reg" :span="24">
						您有账户吗？<button @click="reg">立刻注册</button>
					</el-col>
				</el-row>
			</div>
		</div>
		<LoginWjmm v-if="isWj"></LoginWjmm>
	</div>
</template>

<script>
	import { ElMessage } from 'element-plus'
	import {cGet, cPostParam} from "@/request/http-util.js";
	
	import LoginWjmm from "@/components/login/LoginWjmm.vue";
import { cPost } from '../../request/http-util';
	export default {
	  name: 'Login',
	  components:{
		  LoginWjmm
	  },
	  props: {
	    msg: String
	  },
	  data(){
		  return {
			  username:'',
			  password:'',
			  ptype:"password",
			  eye:'biyan',
			  isWj:false
		  }
	  },
	  methods:{
		  //登录
		  loginBtn() {
			  //ElementPlus.ElMessage('this is a message.');
				
			  cGet("login",{phone:this.username,pass:this.password}).then(res => {
				  if(res.code == 1){
					  localStorage.setItem("userInfo",JSON.stringify(res.data.res));
					  localStorage.setItem("token",res.token);
					  //this.$store.commmit("headChange");
					  this.$router.push('userzhu');
					this.getMemberCreate();
					this.getTeamLeaguInfo();


				  }
			  })

			//   	cGet("app/gameteam/manager/getMember", {memberId:this.username}).then(res => {
			// 	  if(res.code == "10000"){
			// 		  localStorage.setItem("userInfo",JSON.stringify(res.result));
			// 		  localStorage.setItem("token",res.result.token);
			// 		  //this.$store.commmit("headChange");
			// 		   this.$router.push('Game');
			// 	  }
			//   })
		  },
		  getMemberCreate(){
      var userInfo = JSON.parse(localStorage.getItem("userInfo"));
        var par = {
          memberId: userInfo.id,
        };
       var data= {};
      var getURL = "app/matches/UIControl/checkMemberPermission";
        cPostParam(getURL, par, data, true, false).then((res) => {
          if (res.code == 10000) {
            localStorage.setItem("showCreateVue", res.result);
          } 
        });
    },
	getTeamLeaguInfo() {
		var userInfo = JSON.parse(localStorage.getItem("userInfo"));
    var par = {
      pageNum: "0",
      pageSize: "1000",
      memberId:userInfo.id,
    };
    var getURL = "app/gameteam/manager/getTeamPageByPlayerId";
    cGet(getURL, par, true, false).then((res) => {
      if (res.code == 10000) {
       
        if (res.result && res.result.length > 0) {
          var battleInfo = res.result[0];
          localStorage.setItem("battlename", battleInfo.name);
          localStorage.setItem("battlelogo", battleInfo.logoUrl);
          localStorage.setItem("battleid", battleInfo.id);
          localStorage.setItem("battleinfo", JSON.stringify(res.result[0]));
          this.$store.commit("battleChange", 1);
        } else {
          localStorage.removeItem("battlename");
          localStorage.removeItem("battlelogo");
          localStorage.removeItem("battleid");
          this.$store.commit("battleChange", 0);
        }
      }
    });

    var getLURL = "app/gameteam/manager/getLeaguesByMember";
    cGet(getLURL, par, true, false).then((res) => {
      if (res.code == 10000) {
        if (res.result && res.result.length > 0) {
          var leagueInfo = res.result[0];
          localStorage.setItem("leaguename", leagueInfo.name);
          localStorage.setItem("leagueid", leagueInfo.id);
          localStorage.setItem("leaguelogo", leagueInfo.logoUrl);
        } else {
          localStorage.removeItem("leaguename");
          localStorage.removeItem("leagueid");
          localStorage.removeItem("leaguelogo");
        }
      }
    });
	},
		  eyeChange() {
			  if (this.ptype == 'password') {
				  this.ptype = 'text';
				  this.eye = "zhengyan";
			  } else {
				  this.ptype = 'password';
				  this.eye = "biyan";
			  }
		  },
		  close() {
			  this.$parent.$parent.clogin();
		  },
		  wjBtn() {
			  this.isWj = true;
		  },
		  cwjmm() {
			 this.isWj = false; 
		  },
		  reg () {
			  this.$parent.$parent.clogin();
			  this.$parent.$parent.reg();
		  }
	  }
	}
</script>

<style scoped="scoped" lang="less">
	.login {

		/**
		* 解决el-input设置类型为number时，去掉输入框后面上下箭头
		**/
		input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {  
			-webkit-appearance: none;  
			margin: 0;  
		}

		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background:rgba(0,0,0,.1);
		z-index: 11;
		.close {
			position: absolute;
			right: 20px;
			top: 17px;
			color: #fff;
		}
		.con {
			width: 480px;
			height: 400px;
			background: #fff;
			position: absolute;
			top: 50%;
			left: 50%;
			margin-left: -240px;
			margin-top: -230px;
			border-radius: 12px;
			overflow: hidden;
			
			.top {
				width: 100%;
				background: #6dba44;
				color: #fff;
				text-align: center;
				height: 50px;
				line-height: 50px;
				font-size: 18px;
				letter-spacing: 6px;
				font-weight: 600;
			}
			
			.mid {
				margin-top: 35px;
				.el-row {
					width: calc(100% - 70px);
					margin-left: 35px;
					text-align: left;
					.el-col {
						position: relative;
					}
					.title {
						color: #6dbb44;
						width: 100%;
						box-sizing: border-box;
						padding-left: 10px;
						font-size: 16px;
						font-weight: 600;
						margin-bottom: 5px;
					}
					
					input {
						height: 36px;
						line-height: 36px;
						border: 1px solid #dfdfdf;
						background: #f2f2f2;
						margin-bottom: 5px;
						width: 100%;
						border-radius: 5px;
						box-sizing: border-box;
						padding-left: 12px;
					}
					
					input:focus {border:none !important}
					
					.eye {
					    position: absolute;
					    right: 15px;
					    top: 8px;
					    font-size: 22px;	
					}
					.wj {
						color: #aeaeae;
						text-align: right;
					}
					
					.tj button {
						width: 100%;
						height: 36px;
						background: #6dba44;
						border: none;
						color: #fff;
						border-radius: 5px;
						margin-top: 30px;
						letter-spacing: 2px;
						font-size: 15px;
					}
					
					.marginb {
						margin-bottom: 20px;
					}
					
					.reg {
						margin-top: 24px;
						color: #aeaeae;
					}
					
					.reg button {
						padding: 7px 15px;
						color: #6dbb44;
						border: 2px solid #6dbb44;
						background: #ededed;
						border-radius: 5px;
						float: right;
						margin-top: -10px;
					}
				}
			}
		}
	}

</style>
