<template>
	<div class="chat"
	element-loading-background="rgba(0, 0, 0, 0.8)"
	v-loading="loading">
		<div class="chat-c">
			<div class="g-top">
				队伍聊天窗口 {{teamId<=0?"暂未加入小队":""}}
			</div>
			<div class="g-con" id="chatContainer">
				<div v-for="(item,index) in chatList" :class="item.id == userInfo.id?'g-r-list':'g-l-list'">
					<img :src="item.avatar?item.avatar:himg" />
					<div :class="item.id == userInfo.id?'gr-con':'gl-con'">
						{{item.msg}}
					</div>
				</div>
			</div>
			<div class="send">
				<img @click="Send" src="../../../static/gamedetail-5.png" />
				<input @keyup.enter="Send" v-model="info" placeholder="发送消息" />
			</div>
		</div>
		<div class="g-bot">
			<button @click="yxBtn">
				<img :src="isAllow=='允许他人邀请'?timg1:timg2" />
				{{isAllow}}
			</button>
		</div>
		
		
	</div>
</template>

<script>
	import img1 from "../../../static/gamedetail-3.png";
	import img2 from "../../../static/gamedetail-6.png";
	import imghead from '../../../static/head.jpg';
	import {cPost,cGet} from "@/request/http-util.js";
	import { ElMessage } from 'element-plus';

	export default {
		data() {
			return {
				info:'',
				text:'',
				
				chatList:[],
				userInfo:'',
				timg1:img1,
				timg2:img2,
				himg:imghead,
				isAllow:'允许他人邀请',
				loading:true,
				
				userInfo:{},
				teamId:0
			}
		},
		created() {
			this.init();
		},
		methods:{
			init() {
				var _this = this;
				_this.chatList = [];
				_this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
				_this.teamId = localStorage.getItem("teamId")?localStorage.getItem("teamId"):0;
				_this.isAllow = this.userInfo.canInvite == 0?'不允许他人邀请':'允许他人邀请';
						
				var wsImpl = window.WebSocket || window.MozWebSocket;
				
				// create a new websocket and connect
				// window.ws = new wsImpl('wss://zapi.nocheater.cn:5000','https');//ws://23.224.230.222:5000
				// window.ws = new wsImpl('ws://23.224.230.222:5000');	
				window.ws = new wsImpl('wss://zapi.nocheater.cn:5000');
				// when data is comming from the server, this metod is called
				ws.onmessage = function (evt) {
					console.log(evt);
					_this.loading = false;
					var json = (evt.data.replace("$$","")).replace("$$","");
					var data = JSON.parse(json);
					if (data.act == 'getmsglistok') {
						_this.chatList = data.data;
					} else if (data.act != 'regok'){
						_this.chatList.push(data);
					}
					
					_this.$nextTick(() => {
					   var container = _this.$el.querySelector("#chatContainer");
					   container.scrollTop = container.scrollHeight;
					})
				};
					
				// when the connection is established, this method is called
				ws.onopen = function () {
					_this.loading = false;
					if (_this.teamId > 0) {
						//注册房间
						ws.send('$${"act":"reg","utoken":"'+localStorage.getItem('token')+'","roomid":'+_this.teamId+'}$$');
						//拉取历史消息
						ws.send('$${"act":"getmsglist"}$$');
					}
				   
				};
					
				// when the connection is closed, this method is called
				ws.onclose = function () {
					_this.loading = false;
					console.log('.. connection closed');
				}
			},
			Send() {
				if (this.teamId > 0) {
					var _this = this;
					if (!this.info) {
						ElMessage("不能发送空消息");
						return false;
					}
					var reg = new RegExp('"',"g");
					_this.info = _this.info.replace(reg,"")
					ws.send('$${"act":"sendmsg","msgcon":"'+_this.info+'"}$$');
					_this.info = '';
				} else {
					ElMessage("请先加入小队");
				}
			},
			//更改组队状态
			yxBtn() {
				this.loading = true;
				cGet("SetInvite",{},true,false).then(res => {
					this.loading = false;
					if (res.code == 1) {
						if (res.data.canInvite == 0) {
							this.isAllow = '不允许他人邀请';
						} else {
							this.isAllow = '允许他人邀请';
						}
						this.userInfo.canInvite = res.data.canInvite;
						localStorage.setItem("userInfo",JSON.stringify(this.userInfo));
					}
				})
			}
		}
	}
</script>

<style scoped="scoped" lang="less">
	
	.chat {
		width: 100%;
		height: 100%;
		position: relative;
	}
	
	.chat-c {
		height: calc(100% - 5rem);
		margin: 0 auto;
		background-color: rgba(32, 32, 32, 0.6);
		border-radius: 5px;
		border:1px solid #676666;
		box-sizing: border-box;
		position: relative;
	}
	
	.g-top {
		text-align: left;
		color: #f4f4f4;
		font-size: 1.1rem;
		background-image: linear-gradient( #161515, #2b2a2a);
		height: 3.2rem;
		line-height: 3.2rem;
		box-sizing: border-box;
		padding-left: 1.5rem;
	}
	
	.send {
		width: 100%;
		background: #505051;
		position: absolute;
		bottom: 0;
		height: 11%;
		box-sizing: border-box;
		padding-right: 4rem;
		
		input {
			height: 57%;
			margin-top: 4%;
			width: 88%;
			background: #151515;
			border: none;
			border-radius: 5px;
			padding-left: 1rem;
			color:#fff;
		}
		img {
			position: absolute;
			left: 88%;
			top: 19%;
			width: 9.5%;
		}
	}
	
	.g-bot {
		width: 100%;
		left: 0;
		position: absolute;
		bottom: 0;
		button {
			background: #2f2f2f;
			border: 1px solid #676666;
			width: 100%;
			height: 2.7rem;
			line-height: 2.7rem;
			color: #efefef;
			letter-spacing: 2px;
			border-radius: 5px;
			cursor: pointer;
			
			img {
				position: relative;
				top: .4rem;
				height: 1.4rem;
			}
		}
	}
	
	
	.g-con {
		height: 74%;
		overflow-y: auto;
		position: relative;
		padding: 0 2rem;
		margin-top: 2rem;
		    
		.g-l-list {
			text-align: left;
			width: 100%;
			position: relative;
			margin: .5rem 0 2rem;
			img {
				width: 2.6rem;
				height: 2.6rem;
				border-radius: 50%;
				position: absolute;
			}
			.gl-con {
				color: #fff;
				line-height: 1.6rem;
				background: #000;
				display: inline-block;
				margin-left: 3.2rem;
				border-radius: 5px;
				padding: .6rem 1rem .6rem .6rem;
			}
		}
		.g-r-list {
			text-align: right;
			width: 100%;
			position: relative;
			margin: .5rem 0 2rem;
			img {
				width: 2.6rem;
				height: 2.6rem;
				border-radius: 50%;
				position: absolute;
				right:0
			}
			.gr-con {
				text-align: left;
				color: #fff;
				line-height: 1.6rem;
				background: #07b43c;
				margin-right: 3.2rem;
				display: inline-block;
				border-radius: 5px;
				padding: .6rem 1rem .6rem .6rem;
			}
		}
	}
</style>
