<template>
	<div class="login">
		<div class="con">
			<span @click="close" class="close icon iconfont icon-guanbi"></span>
			<div class="top">平台注册</div>
			<div class="mid">
				<el-row>
					<!--el-col :span="24">
						<img class="mimg" src="../../../static/reg-1.png" />
					</el-col-->
					
					<el-col class="qh" :span="5">
						<el-select v-model="qh" popper-append-to-body="false">
							<el-option :value="+ 86">+ 86</el-option>
							<el-option :value="+ 87">+ 87</el-option>
							<el-option :value="+ 88">+ 88</el-option>
						</el-select>
					</el-col>
					<el-col :offset="1" :span="18">
						<input v-model="phone" autocomplete="off"/>
					</el-col>
					
					<el-col :span="16">
						<input v-model="code" placeholder="请输入短信验证码" autocomplete="off"/>
					</el-col>
					<el-col class="reg"  :offset="1" :span="7">
						<button @click="sendCode" :class="yzmtext=='获取验证码'?'':'reg-yzm-active'">{{yzmtext}}</button>
					</el-col>

					<el-col :span="24">
						<input v-model="password" autocomplete="off" type="password" placeholder="请输入密码" />
					</el-col>
					
					<el-col :span="24">
						<input v-model="cpassword" autocomplete="new-password" placeholder="请再次输入密码" type="password"/>
					</el-col>
				
					<el-col class="tj" :span="24"><button @click="regBtn">提交</button></el-col>
					<el-col class="yy" :span="24"><button @click="login">已有账号立即登录</button></el-col>	
					<el-col class="tk" :span="24">注册代表你同意 <span>《服务条款》</span> 和<span>《个人信息保护政策》</span></el-col>
				</el-row>
			</div>
		</div>
	</div>
</template>

<script>
	import {cPost,cGet} from "@/request/http-util.js";
	import { ElMessage } from 'element-plus'
	export default {
	  name: 'Login',
	  data(){
		  return {
			  qh:'+ 86',
			  phone:'',
			  code:'',
			  password:'',
			  cpassword:'',
			  yzmtext:'获取验证码',
			  dm:{}
		  }
	  },
	  created() {
		  if (localStorage.getItem("msgTime")) this.dumiao();
	  },
	  methods:{
		  //发送验证码
		  sendCode() {
			  var _this = this;

				if (localStorage.getItem("msgTime")) return false;
				
				if (!this.phone) {
					ElMessage("手机号不能为空");
					return false;
				}
			  
				
				cGet("sendmsgcode",{phone:this.phone}).then(res => {
					console.log("验证码",res);
					if(res.code == 1) {
						localStorage.setItem("msgTime",60);
						_this.dumiao();
					}
				}) 
		  },
		  
		  //注册
		  regBtn () {
		  			  
			  if (!this.phone) {
				  ElMessage("手机号不能为空");
				  return false;
			  }
			  
			  if (!this.code) {
				  ElMessage("验证码不能为空");
				  return false;
			  }
			  
			  if (!this.password || this.password != this.cpassword) {
				  ElMessage("两次密码输入不一致");
				  return false;
			  }
			  
			  var par = {
				  phone:this.phone,
				  code:this.code,
				  pass:this.password,
				  repass:this.cpassword,
			  };
			  cGet("register",par).then(res => {
				  if(res.code == 1) {
					localStorage.setItem("token",res.token);  
					localStorage.setItem("userInfo",JSON.stringify(res.data));
				  	this.$router.push("UserCenter");
				  }
			  })
		  },
		  eyeChange() {
			  if (this.ptype == 'password') {
				  this.ptype = 'text';
				  this.eye = "zhengyan";
			  } else {
				  this.ptype = 'password';
				  this.eye = "biyan";
			  }
		  },
		  close() {
			  this.$parent.$parent.creg();
		  },
		  login () {
			  this.$parent.$parent.creg();
			  this.$parent.$parent.login();
		  },
		  //验证码读秒
		  dumiao() {
			 var _this = this;
			 clearInterval(this.dm);
			 var sec = localStorage.getItem("msgTime");
			 _this.dm = setInterval(()=>{
			 	sec --;
			 	if (sec == 0) {
			 		_this.yzmtext = '获取验证码';
			 		localStorage.removeItem("msgTime");
			 		clearInterval(_this.dm);
			 	} else {
			 		_this.yzmtext = sec+"秒";
			 		localStorage.setItem("msgTime",sec);
			 	}
			 },1000); 
		  }
	  }
	}
</script>

<style scoped="scoped" lang="less">
	.login {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background:rgba(0,0,0,.1);
		z-index: 11;
		.close {
			position: absolute;
			right: 20px;
			top: 17px;
			color: #fff;
		}
		.con {
			width: 480px;
			height: 500px;
			background: #fff;
			position: absolute;
			top: 50%;
			left: 50%;
			margin-left: -240px;
			margin-top: -300px;
			border-radius: 12px;
			overflow: hidden;
			
			.top {
				width: 100%;
				background: #6dba44;
				color: #fff;
				text-align: center;
				height: 50px;
				line-height: 50px;
				font-size: 18px;
				letter-spacing: 6px;
				font-weight: 600;
			}
			
			.mid {
				margin-top: 35px;
				.mimg {
					width: 100%;
					height: auto;
				}
				
				.el-row {
					width: calc(100% - 70px);
					margin-left: 35px;
					text-align: left;
					.el-col {
						position: relative;
						margin-bottom: 18px;
					}

					input {
						height: 36px;
						line-height: 36px;
						border: 1px solid #dfdfdf;
						background: #f2f2f2;
						margin-bottom: 5px;
						width: 100%;
						border-radius: 5px;
						box-sizing: border-box;
						padding-left: 12px;
					}
					
					input:focus {border:none !important}
					
					.tj button {
						width: 100%;
						height: 36px;
						background: #6dba44;
						border: none;
						color: #fff;
						border-radius: 5px;
						margin-top: 20px;
						letter-spacing: 2px;
						font-size: 15px;
					}
					
					.reg {
						color: #aeaeae;
					}
					
					.reg button {
						width: 100%;
						height: 36px;
						color: #6dbb44;
						border: 2px solid #6dbb44;
						background: #ededed;
						border-radius: 5px;
						float: right;
					}
					
					.reg-yzm-active {
						color: #666 !important;
						border: 2px solid #ededed !important;
					}
					
					.yy button {
						width: 100%;
						height: 36px;
						color: #6dbb44;
						border: 2px solid #6dbb44;
						background: #ededed;
						border-radius: 5px;
						float: right;
						margin-top: -10px;
					}
					
					.tk {
						color:#a1a1a1;
						text-align: center;
						span {
							color:#6dbb44;
							cursor: pointer;
						}
					}
				}
			}
		}
	}
	
	.con1 {
		width: 480px;
		height: 440px;
		background: #fff;
		position: absolute;
		top: 50%;
		left: 50%;
		margin-left: -240px;
		margin-top: -220px;
		border-radius: 12px;
		overflow: hidden;
		
		.top {
			width: 100%;
			background: #6dba44;
			color: #fff;
			text-align: center;
			height: 50px;
			line-height: 50px;
			font-size: 18px;
			letter-spacing: 6px;
			font-weight: 600;
		}
		
		.mid {
			margin-top: 35px;
			.mimg {
				width: 100%;
				height: auto;
			}
			
			.title {
				color: #6dba44;
				text-align: center;
				font-size: 24px;
				letter-spacing: 4px;
				font-weight: 600;
				margin-top: 30px;
				margin-bottom: 5px;
			}
			
			.title_tip {
				color: #a1a1a1;
				text-align: center;
				margin-bottom: 30px;
			}
			
			.bd {
				text-align: center;
				margin-top: 16px;
				font-size: 16px;
				font-weight: 600;
				color: #6dba44;
				margin-bottom:10px;
			}
			
			.bd button{
				height: 34px;
				padding:0 30px;
				background: #6dba44;
				border: none;
				color: #fff;
				border-radius: 5px;
				margin-top: 10px;
				letter-spacing: 2px;
			}
			
			.bts {
				font-size: 12px;
				color: #a1a1a1;
				text-align: center;
			}
			
			.el-row {
				width: calc(100% - 70px);
				margin-left: 35px;
				text-align: left;
	
			}
		}
	}
	
	::v-deep{
		.el-select .el-input__inner{
			width: 100%;
			height: 36px;
			border: 1px solid #dfdfdf;
			background: #f2f2f2;
			margin-bottom: 5px;
			border-radius: 5px;
			box-sizing: border-box;
			padding: 0 12px;
			color: #999;
		}
		
		.el-select .el-input__icon{
			color:#6dbb44 !important;
		}
	}
	

</style>
