<template>
	<div class="gamelist" 
	element-loading-background="rgba(0, 0, 0, 0.8)"
	v-loading="loading">
		<button class="fjcy">房间成员</button>
		<div class="gamelist-c">
			
			<div class="g-con">
				<div class="g-con-list" v-for="(item,index) in ddlist">
					<div v-if="min || sec" class="zz"></div>
					<div class="gconlist-top">
						队伍 {{item.num}} <i class="iconfont icon-xiala" ></i>
					</div>
					<div class="gconlist-bottom">
						<el-row>
							<el-col v-for="n in 4" :span="4">
								<template v-if="item.rylist[n-1]">
									<el-popover v-if="ddinfo.leaderId == userInfo.id || (item.leaderId == userInfo.id && ddinfo.leaderId != item.rylist[n-1].id)" placement="top" :width="160">
										<div style="text-align: center; margin: 0">
										  <el-button 
										  v-if="ddinfo.leaderId == userInfo.id" 
										  @click="ydBtn(item.rylist[n-1].id,item.rylist[n-1].teamid,item.rylist[n-1].avatar)" 
										  type="text" size="mini">移动队伍</el-button>
										  <el-button 
										  v-if="ddinfo.leaderId == userInfo.id || (item.leaderId == userInfo.id && ddinfo.leaderId != item.rylist[n-1].id)" 
										  @click="tcBtn(item.rylist[n-1].id,item.rylist[n-1].teamid)" type="text" size="mini">踢出队伍</el-button>
										</div>
										<template #reference>
											<img :src="item.rylist[n-1].avatar?item.rylist[n-1].avatar:img1" />
										</template>
									</el-popover>
									<img v-else :src="item.rylist[n-1].avatar?item.rylist[n-1].avatar:img1" />
									<div class="name onesl">{{item.rylist[n-1].pubgName?item.rylist[n-1].pubgName:"××××"}}</div>
									<div class="mark" v-if="ddinfo.leaderId == item.rylist[n-1].id">房</div>
									<div class="mark" v-else-if="item.leaderId == item.rylist[n-1].id">队</div>
								</template>
								<template v-else>
									<el-popover placement="top" :width="160">
										<div style="text-align: center; margin: 0">
										  <el-button @click="qhBtn(item.num)" type="text" size="mini">切换到此队</el-button>
										</div>
										<template #reference>
											<img :src="img1" />
										</template>
									</el-popover>
									<div class="name onesl">空位</div>
								</template>
							</el-col>
						</el-row>
					</div>
				</div>
			</div>
		</div>
		
		<el-dialog
		v-model="isPp"
		title="选择队伍"
		custom-class="dialog2"
		width="25%">
			<el-row>
				<el-col :span="24" style="text-align: center;">
					<img class="user-head" :src="nowImg?nowImg:img1"/>
				</el-col>
				<el-col class="con-title" :span="6">
					移动到
				</el-col>
				<el-col class="con-nr" :span="18">
					<el-select v-model="dw" placeholder="Select">
					    <el-option
					      v-for="n in 25"
					      :key="n"
					      :label="'队伍' + n"
					      :value="n">
					    </el-option>
					</el-select>
				</el-col>
				<el-col class="con-title" :span="6">
					
				</el-col>
				<el-col class="con-nr" style="margin-top: 20px;" :span="18">
					<el-button @click="subBtn()" type="primary">确认</el-button>
				</el-col>
			</el-row>
		</el-dialog>
	</div>
</template>

<script>
	import {cPost,cGet} from "@/request/http-util.js";
	import img1 from '../../../static/head.jpg'
	import { ElMessage } from 'element-plus'
	export default{
		name:"Gamelist",
		emits:['uC','getGameList'],
		data() {
			return {
				loading:false,
				img1:img1,
				ddlist:[],
				ddinfo:{},
				userInfo:{},
				isTi:false,
				nowId:0,
				nowImg:0,
				teamId:0,
				isPp:false,
				dw:1,
				min:'',
				sec:''
			}
		},
		methods:{
			init(dwlist,dwinfo) {
				this.ddinfo = dwinfo;
				
				var newArr= [];
				for(var i= 1;i<=25;i++) {
					var ite = {num:i,rylist:[]};
					dwlist.filter(function(item,index){
						if (item.num == i) ite = item; 
					})
					
					newArr.push(ite);
				}
				this.ddlist = newArr;
				
				this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
			},
			naToRoom() {
				this.$router.push({name:"GameRoom"});
			},
			isCao(id,teamid) {
				this.nowId = id;
				this.teamId = teamid;
				this.isTi = true;
			},
			//移动队伍
			ydBtn(id,teamid,img) {
				this.isPp = true;
				this.nowId = id;
				this.nowImg = img;
				this.teamId = teamid;
			},
			//踢出队伍
			tcBtn(id,teamid){
				this.loading = true;
				cGet("tcteam",{teamid:teamid,uid:id},true,false).then(res => {
					this.loading = false;
					if (res.code == 1){
						ElMessage("踢出成功！");
						this.$emit("getGameList");
					}
				})
			},
			//移动队伍
			subBtn() {
				this.loading = true;
				cGet("changteamm",{num:this.dw,uid:this.nowId,roomId:localStorage.getItem("roomId")},true,false).then(res => {
					this.loading = false;
					if (res.code == 1){
						ElMessage("移动成功！");
						this.isPp = false;
						this.$emit('getGameList');
					}
				})
			},
			//切换队伍
			qhBtn(iteNum){
				this.loading = true;
				cGet("changteamm",{num:iteNum,uid:this.userInfo.id,roomId:localStorage.getItem("roomId")},true,false).then(res => {
					this.loading = false;
					if (res.code == 1){
						ElMessage("切换成功！");
						this.$emit('getGameList');
					}
				})
			},
			mC(min,sec){
				this.min = min;
				this.sec = sec;
			}
		}
	}
</script>

<style scoped="scoped" lang="less">
	.gamelist {
		width: 100%;
		height: 100%;
		position: relative;
		text-align: left;
		
		.fjcy {
			background-color: #07b43c;
			height: 2.2rem;
			line-height: 2.2rem;
			width: 8rem;
			text-align: center;
			color:#fff;
			border:none;
			border-radius: 2px;
		}
	}
	
	
	.dialog2 {
		height: 34%;
	}
	
	.user-head {
		width: 3.4rem;
		height: 3.4rem;
		border-radius: 50%;
		margin-bottom: 15px;
	}
	.gamelist-c {
		height: calc(100% - 2rem);
		margin: 0 auto;
		background-color: rgba(32, 32, 32, 0.6);
		border-radius: 5px;
		border:1px solid #676666;
		box-sizing: border-box;
		position: relative;
	}
	
	.g-top {
		text-align: left;
		color: #f4f4f4;
		font-size: 1.1rem;
		height: 3.2rem;
		line-height: 3.2rem;
		box-sizing: border-box;
		padding-left: 1.5rem;
	}
	
	.g-con {
		height: 90%;
		overflow-y: auto;
		position: relative;
		margin-top: 7%;
		    
		.g-con-list {
			width: 100%;
			position: relative;
			border-radius: 5px;
			overflow: hidden;
			margin-bottom: 15px;
			.gconlist-top {
				color: #fff;
				background-color: #1e1d1e;
				width: 100%;
				height: 2.2rem;
				line-height: 2.2rem;
				box-sizing: border-box;
				padding-left: 2rem;
				
				i {
					margin-left: .5rem;
					color:#07b43c;
				}
				
			}
			
			.gconlist-bottom {
				width: 100%;
				box-sizing: border-box;
				padding: 0 1rem;
				min-height: 87px;
				.mark {
					position: absolute;
					top: 2.7rem;
					left: 3rem;
					width: 1.2rem;
					height: 1.2rem;
					background: #000;
					border-radius: 50%;
					font-size: 0.8rem;
					color: #fff;
					box-shadow: 0 0 2px #fff;
					cursor: pointer;
				}
				.el-col {
					text-align: center;
					height: 4rem;
					margin-bottom: 2rem;
					position: relative;
				}
				img {
					width: 2.7rem;
					height: 2.7rem;
					border-radius: 50%;
					margin-top:1rem;
					cursor: pointer;
				}
				.name {
					height: 2rem;
					line-height: 2rem;
					color: #fff;
				}
			}
			
			
		}
	}
	.zz {
	    position: absolute;
	    top: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
	    z-index: 100;
	    background: rgba(0,0,0,.3);
	}
</style>
