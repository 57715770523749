<template>
  <div
    class="chat"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    v-loading="loading"
  >
    <div class="t-chat">
      <div class="g-top">联盟聊天</div>
      <div class="t-con">
        <div class="g-c-top" v-for="(item, index) in buttonList" :key="index">
          <button
            @click="btChange(index)"
            :class="isShow == index ? 'ctop-button' : ''"
          >
            {{ item.name }}

            <el-icon
              v-if="index != 0"
              size="13"
              class="t-chat-icon"
              color="red"
              @click="deleteChat(index)"
              ><close
            /></el-icon>
          </button>
        </div>
        <div class="g-con" id="chatContainer">
          <div
            v-for="(item, index) in chatList"
            :class="item.fromId == userInfo.id ? 'g-r-list' : 'g-l-list'"
            :key="index"
          >
            <el-tooltip effect="dark" :content="item.fromName" placement="top">
              <img :src="item.fromAvatar ? item.fromAvatar : himg" />
            </el-tooltip>
            <div :class="item.fromId == userInfo.id ? 'gr-con' : 'gl-con'">
              {{ item.content }}
            </div>
          </div>
        </div>
        <div class="send">
          <img @click="Send" src="../../../static/gamedetail-5.png" />
          <input @keyup.enter="Send" v-model="info" placeholder="发送消息" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import imghead from "../../../static/head.jpg";
import { cPost, cGet } from "@/request/http-util.js";
import { getMinF } from "@/request/common.js";
import { ElMessage } from "element-plus";
import { Close } from "@element-plus/icons";
import Com from "@/request/http-util.js";
export default {
  components: {
    Close,
  },
  emits: ["countdown"],
  data() {
    return {
      isShow: 0,
      teamId: 0,
      chatList: [],
      chatSumList: [],
      buttonList: [{ name: "战队聊天大厅", key: "0" }],
      userInfo: {},
      loading: false,
      himg: imghead,
      info: "",
      text: "",
      min: "",
      sec: "",
      leagueid: "",
    };
  },
  props: {
    roominfo: Object,
  },
  mounted() {
    this.init();
  },
  updated() {
    var container = this.$el.querySelector("#chatContainer");
    container.scrollTop = container.scrollHeight;
  },
  methods: {
    init() {
      var _this = this;
      _this.chatList = [];
      _this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
      _this.leagueid = localStorage.getItem("leagueid");
      this.btChange(0);
      if (window.ws) {
        window.ws.close();
      }

      var wsImpl = window.WebSocket || window.MozWebSocket;
      // create a new websocket and connect
      window.ws = new wsImpl(
        Com.wsURL +
          "app/gameteam/manager/league/" +
          _this.leagueid +
          "/" +
          _this.userInfo.id
      );
      window.ws.binaryType = "arraybuffer";

      // this.loading = true;
      // when data is comming from the server, this metod is called
      window.ws.onmessage = function (evt) {
        var data = JSON.parse(evt.data);
        if (data.type == 3) {
          if (data.fromId != _this.userInfo.id) {
            var findArr = _this.buttonList.filter(
              (item) => item.key == data.fromId
            );
            if (findArr.length <= 0) {
              _this.buttonList.push({ name: data.fromName, key: data.fromId });
              _this.$store.commit("chatListChange", _this.buttonList);
            }
          }
        }
        _this.chatSumList.push(data);
        _this.updateChatList();
        _this.$nextTick(() => {
          var container = _this.$el.querySelector("#chatContainer");
          container.scrollTop = container.scrollHeight;
        });
      };
      // // when the connection is established, this method is called
      window.ws.onopen = function () {};
      // // when the connection is closed, this method is called
      window.ws.onclose = function () {};
    },
    Send() {
      if (this.isShow == 0) {
        var msgData = {
          leagueId: this.leagueid,
          fromId: this.userInfo.id,
          type: 4,
          content: this.info,
          fromAvatar: this.userInfo.avatar,
        };
        var msg = JSON.stringify(msgData);
        window.ws.send(msg);
        this.chatSumList.push(msgData);
        this.updateChatList();
        this.info = "";
      } else {
        var member = this.buttonList[this.isShow];
        var msgMemberData = {
          teamId: this.teamId,
          fromId: this.userInfo.id,
          type: 3,
          content: this.info,
          toId: member.key,
          fromAvatar: this.userInfo.avatar,
        };
        var msgMember = JSON.stringify(msgMemberData);
        window.ws.send(msgMember);

        this.chatSumList.push(msgMemberData);
        this.updateChatList();
        this.info = "";
      }
    },
    btChange(num) {
      if (this.buttonList.length - 1 < num) {
        num = 0;
      }
      this.isShow = num;
      if (this.isShow != 0) {
        this.getSingleChatHistory();
      } else {
        this.getGroupChatHistory();
      }
    },
    deleteChat(index) {
      this.buttonList.splice(index, 1);
      this.$store.commit("chatListChange", this.buttonList);
    },
    updateChatList() {
      var btn = this.buttonList[this.isShow];
      if (btn.key == 0) {
        this.chatList = this.chatSumList.filter((item) => item.type == 4);
      } else {
        this.chatList = this.chatSumList.filter(
          (item) =>
            item.type == 3 &&
            ((item.fromId == btn.key && item.toId == this.userInfo.id) ||
              (item.toId == btn.key && item.fromId == this.userInfo.id))
        );
      }
    },
    getGroupChatHistory() {
      var par = {
        leagueId: this.leagueid,
        pageNum: 0,
        pageSize: 1000,
      };
      var getURL = "app/gameteam/manager/getLeagueGroupChatMsgs";
      cGet(getURL, par, true, false).then((res) => {
        if (res.code == 10000) {
          this.chatSumList = res.result;
          this.updateChatList();
        } else {
          ElMessage(res.message);
        }
      });
    },
    getSingleChatHistory() {
      var btn = this.buttonList[this.isShow];
      var par = {
        teamId: this.teamId,
        pageNum: 0,
        pageSize: 1000,
        fromId: this.userInfo.id,
        toId: btn.key,
      };
      var getURL = "app/gameteam/manager/getPrivateChatMsgs";
      cGet(getURL, par, true, false).then((res) => {
        if (res.code == 10000) {
          this.chatSumList = res.result;
          this.updateChatList();
        } else {
          ElMessage(res.message);
        }
      });
    },
  },
};
</script>

<style scoped="scoped" lang="less">
.chat {
  width: 100%;
  height: 100%;
  text-align: right;
  position: relative;

  .ksyx {
    background-color: #07b43c;
    height: 2.2rem;
    line-height: 2.2rem;
    width: 8rem;
    text-align: center;
    color: #fff;
    border: none;
    border-radius: 2px;
  }

  .tcfj {
    background-color: #ff5602;
    height: 2.2rem;
    line-height: 2.2rem;
    width: 8rem;
    text-align: center;
    color: #fff;
    border: none;
    border-radius: 2px;
    margin-left: 0.2rem;
  }
}

.t-chat {
  height: calc(100%);
  margin: 0 auto;
  background-color: rgba(32, 32, 32, 0.6);
  border-radius: 5px;
  border: 1px solid #676666;
  box-sizing: border-box;
  position: relative;
}
.t-con {
  padding: 0 0.6rem;
  margin-top: 2rem;
  height: 80%;
  .g-c-top {
    text-align: left;

    float: left;
    button {
      background: #1f1e1f;
      color: #fff;
      padding: 0.4rem 1.2rem;
      border-left: 1px solid #808080;
      border-top: 1px solid #808080;
      border-right: 1px solid #808080;
      border-bottom: none;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      margin-right: 10px;
    }
    .ctop-button {
      background: #07b43c;
    }
  }
}
.g-top {
  text-align: left;
  color: #f4f4f4;
  font-size: 1.1rem;
  background-image: linear-gradient(#161515, #2b2a2a);
  height: 3.2rem;
  line-height: 3.2rem;
  box-sizing: border-box;
  padding-left: 1.5rem;
}

.send {
  width: 100%;
  margin-left: -0.7em;
  background: #505051;
  position: absolute;
  bottom: 0;
  height: 11%;
  box-sizing: border-box;
  padding-right: 20%;

  input {
    height: 57%;
    margin-top: 4%;
    width: 88%;
    background: #151515;
    border: none;
    border-radius: 5px;
    padding-left: 1rem;
    color: #fff;
  }
  img {
    position: absolute;
    left: 88%;
    top: 16%;
    width: 11%;
  }
}

.g-bot {
  width: 100%;
  left: 0;
  position: absolute;
  bottom: 0;
  button {
    background: #2f2f2f;
    border: 1px solid #676666;
    width: 100%;
    height: 2.7rem;
    line-height: 2.7rem;
    color: #efefef;
    letter-spacing: 2px;
    border-radius: 5px;
    cursor: pointer;

    img {
      position: relative;
      top: 0.4rem;
      height: 1.4rem;
    }
  }
}

.g-con {
  height: 85%;
  overflow-y: auto;
  position: relative;
  padding: 0 2rem;
  margin-top: 7%;
  clear: both;
  border-top: 1px solid #808080;
  .g-l-list {
    text-align: left;
    width: 100%;
    position: relative;
    margin: 0.5rem 0 2rem;
    img {
      width: 2.6rem;
      height: 2.6rem;
      border-radius: 50%;
      position: absolute;
    }
    .gl-con {
      color: #fff;
      line-height: 1.6rem;
      background: #000;
      display: inline-block;
      margin-left: 3.2rem;
      border-radius: 5px;
      padding: 0.6rem 1rem 0.6rem 0.6rem;
    }
  }
  .g-r-list {
    text-align: right;
    width: 100%;
    position: relative;
    margin: 0.5rem 0 2rem;
    img {
      width: 2.6rem;
      height: 2.6rem;
      border-radius: 50%;
      position: absolute;
      right: 0;
    }
    .gr-con {
      text-align: left;
      color: #fff;
      line-height: 1.6rem;
      background: #07b43c;
      margin-right: 3.2rem;
      display: inline-block;
      border-radius: 5px;
      padding: 0.6rem 1rem 0.6rem 0.6rem;
    }
  }
}

.t-chat-icon {
  top: 0.1em;
  // right: 0.1em;
  z-index: 10;
}
</style>
