import { createStore } from 'vuex'

export default createStore({
	state: {
		isTm: 0,
		isToTm: 0,
		isRc: 0,
		isToRc: 0,
		isToGame: 0,
		isHead: 0,
		isLeft: localStorage.getItem('isLeft') ? localStorage.getItem('isLeft') : 0,
		Model: 0,
		isCreateBattle: 0,
		isChangeMember: 0,
		isChangeLeague: 0,
		chatList:[{name:"战队聊天大厅", key:"0"}],
	},
	getters: {
	},
	mutations: {
		tmChange(state) {
			state.isTm = state.isTm + 1;
		},
		totmChange(state) {
			state.isToTm = state.isToTm + 1;
		},
		rcChange(state) {
			state.isRc = state.isRc + 1;
		},
		torcChange(state) {
			state.isToRc = state.isToRc + 1;
		},
		togameChange(state) {
			state.isToGame = state.isToGame + 1;
		},
		headChange(state) {
			state.isHead = state.isHead + 1;
		},
		leftChange(state, num) {
			state.isLeft = num;
			localStorage.setItem('isLeft', num)
		},
		mChange(state, num) {
			state.Model = num;
		},
		battleChange(state, num) {
			state.isCreateBattle = num;
		},
		memberChage(state) {
			state.isChangeMember = state.isChangeMember + 1;
		},
		chatListChange(state, val) {
			state.chatList = val;
		},
		leagueChange(state) {
			state.isChangeLeague = state.isChangeLeague + 1;
		}
	},
	actions: {
	},
	modules: {
	}
})
