<template>
  <div class="mt-table">
    <el-row>
      <el-col :span="12">
        <el-row>
          <el-col :span="24">
            <div class="div-white">比赛名称</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <div class="div-red">{{ matchDetail.name }}</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <div class="div-white">比赛类型</div>
          </el-col>
          <el-col :span="12">
            <div class="div-white">服务器</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <div class="div-red">{{ matchDetail.matchType }}</div>
          </el-col>
          <el-col :span="12">
            <div class="div-red">{{ matchDetail.serverType }}</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <div class="div-white">比赛模式</div>
          </el-col>
          <el-col :span="12">
            <div class="div-white">参加比赛队伍数</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <div class="div-red">
              {{ matchDetail.gameMode == 0 ? "单人" : "战队" }}({{
                matchDetail.gamePeople
              }}人)
            </div>
          </el-col>
          <el-col :span="12">
            <div class="div-red">{{ matchDetail.gameTeamNum }}队</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <div class="div-white">比赛场次</div>
          </el-col>
          <el-col :span="12">
            <div class="div-white">比赛时间</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <div class="div-red">{{ matchDetail.gameNum }}</div>
          </el-col>
          <el-col :span="12">
            <div class="div-red">{{ matchDetail.gameStartTime }}</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <div class="div-white">小比赛时长</div>
          </el-col>
          <el-col :span="12">
            <div class="div-white">小比赛间隔</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <div class="div-red">{{ matchDetail.gameTime }}分钟</div>
          </el-col>
          <el-col :span="12">
            <div class="div-red">{{ matchDetail.gameDert }}分钟</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <div class="div-white">比赛地图</div>
          </el-col>
          <el-col :span="12">
            <div class="div-white">比赛币</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <div class="div-red">{{ matchDetail.gameMap == '1' ? '艾伦格Erangel' :'沙漠Miramar' }}</div>
          </el-col>
          <el-col :span="12">
            <div class="div-red">{{ matchDetail.gameBill == 0 ? '免费' : matchDetail.gameBill }}</div>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="12" style="border: 1px solid gray;">
        <el-table
          :data="memberList"
          :cell-style="{ color: '#fff' }"
          :header-cell-style="{ color: '#fff',fontSize:'large'}"
        >
          <el-table-column label="参加比赛的人/队伍" prop="name" align="center">
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "MatchPreDetail",
  props: {
    matchDetail: {},
    memberList: [],
  },
  data() {
    return {
    };
  },
  watch: {},
  computed: {},
  created() {
  },
  methods: {},
};
</script>

<style scoped="scoped" lang="less">
.mt-table {
  height: 35vh;
  width: 100%;
  position: relative;
  ::v-deep .el-range-input {
    background: black;
    color: white;
  }
}

/*最外层透明*/
::v-deep .el-table,
::v-deep .el-table__expanded-cell {
  background-color: transparent !important;
}
/* 表格内背景颜色 */
::v-deep .el-table th,
::v-deep .el-table tr,
::v-deep .el-table td {
  background-color: transparent !important;
  // border: 0px;
}

.el-table::before {
  height: 0px;
}
// .el-table--border::after {
//   width: 0px;
// }
// .el-table--border {
//   border: none;
// }

.div-white {
  color: white;
  font-size: large;
}

.div-red {
  color: rgb(230, 88, 130);
  font-size: large;
}
</style>
