<template>
  <div class="game">
    <Head></Head>
    <Left></Left>

    <div
      element-loading-background="rgba(0, 0, 0, 0.8)"
      v-loading="loading"
      class="contain"
    >
      <span class="top">{{ titleName }}</span>
      <!--img class="bottom"  @click="naToGame" src="../../static/home-1.png" /-->
      <el-row gutter="20">
        <el-col
          @click="naToGame(item.id, item.name)"
          v-for="(item, i) in list"
          :span="6"
        >
          <div :class="i == ind ? 'g-list active' : 'g-list'">
            <div class="title">
              {{ item.name }}
              <button>{{ item.onLineCount }}人在线</button>
            </div>
            <img :src="item.coverUrl" />
          </div>
        </el-col>
      </el-row>
    </div>

    <!--修改服务器-->
    <TanXgfwq ref="tan1" @enterG="enterG" :show="isShow"></TanXgfwq>
    <!--绑定steam-->
    <Bsteam v-if="isB"></Bsteam>
  </div>
</template>
<script>
// @ is an alias to /src
import Head from "@/components/Head.vue";
import Left from "@/components/Left.vue";
import { ElMessageBox } from "element-plus";
import { cPost, cGet } from "@/request/http-util.js";
import { reSet } from "@/request/common-api.js";
import { ElMessage } from "element-plus";
import TanXgfwq from "@/components/common/TanXgfwq.vue";
import Bsteam from "@/components/login/Bsteam.vue";

export default {
  name: "Game",
  components: {
    Head,
    Left,
    Bsteam,
    TanXgfwq,
  },
  data() {
    return {
      list:{},
      ind: -1,
      userInfo: {},
      isTan: false,
      isB: false,
      isShow: 1,
      loading: false,

      titleName: "游戏大厅",

      gid: 0,
      gname: "",
      teamId: localStorage.getItem("teamId")
        ? localStorage.getItem("teamId")
        : 0,
    };
  },
  computed: {
    isLeft() {
      return this.$store.state.isLeft;
    },
  },
  watch: {
    isLeft(n, o) {
      this.getS();
      switch (this.$store.state.isLeft) {
        case 0:
          this.titleName = "游戏大厅-自定义游戏";
          break;
        case 1:
          this.titleName = "游戏大厅-寻求组队";
          break;
        case 2:
          this.titleName = "游戏大厅-战队平台";
          break;
        case 3:
          this.titleName = "游戏大厅-比赛平台";
          break;
        case 4:
          this.titleName = "游戏大厅-联盟平台";
          break;
        default:
          break;
      }
    },
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.getS();
  },
  created() {

    var userInfo = JSON.parse(localStorage.getItem("userInfo"));
    var par = {
      pageNum: "0",
      pageSize: "1000",
      memberId:userInfo.id,
    };
    var getURL = "app/gameteam/manager/getTeamPageByPlayerId";
    cGet(getURL, par, true, false).then((res) => {
      if (res.code == 10000) {
       
        if (res.result && res.result.length > 0) {
          var battleInfo = res.result[0];
          localStorage.setItem("battlename", battleInfo.name);
          localStorage.setItem("battlelogo", battleInfo.logoUrl);
          localStorage.setItem("battleid", battleInfo.id);
          localStorage.setItem("battleinfo", JSON.stringify(res.result[0]));
          this.$store.commit("battleChange", 1);
        } else {
          localStorage.removeItem("battlename");
          localStorage.removeItem("battlelogo");
          localStorage.removeItem("battleid");
          this.$store.commit("battleChange", 0);
        }
      }
    });

    var getLURL = "app/gameteam/manager/getLeaguesByMember";
    cGet(getLURL, par, true, false).then((res) => {
      if (res.code == 10000) {
        if (res.result && res.result.length > 0) {
          var leagueInfo = res.result[0];
          localStorage.setItem("leaguename", leagueInfo.name);
          localStorage.setItem("leagueid", leagueInfo.id);
          localStorage.setItem("leaguelogo", leagueInfo.logoUrl);
        } else {
          localStorage.removeItem("leaguename");
          localStorage.removeItem("leagueid");
          localStorage.removeItem("leaguelogo");
        }
      }
    });
  },
  methods: {
    getS() {
    
      if (this.$store.state.isLeft == 0 || this.$store.state.isLeft == 1) {
        if (localStorage.getItem("teamId")) {
          this.getGame();
        } else {
          reSet().then((res) => {
            this.getGame();
          });
        }
      } else {
        if (this.$store.state.isLeft == 2) {
          this.gotoTeamplat();
        } else if (this.$store.state.isLeft == 3) {
          this.$router.push({ name: "MatchPlatform" });
        } else if (this.$store.state.isLeft == 4) {
          this.gotoLeagueplat();
        }
      }
    },
    getGame() {
      this.loading = true;
      cGet("getgames", { gamelx: this.isLeft }, true, false).then((res) => {
        this.loading = false;
        if (res.code == 1) {
          this.list = res.data;
        }
      });
    },
    naToGame(id, name) {
      
      
      this.gid = id;
      this.gname = name;

      cGet("GetCurrentTeam", {}, false, false).then((res) => {
        if (res.data && res.data.team) {
          if (res.data.team.teamtype == this.isLeft) {
            this.$router.push("teamlist");
          } else {
            if (this.teamId > 0) {
              ElMessageBox.confirm("当前已有小队,点击确定则取消当前组队状态", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
                .then(() => {
                  this.getGameInfo();
                })
                .catch(() => {});
            } else {
              this.getGameInfo();
            }
          }
        } else {
          if (this.teamId > 0) {
            ElMessageBox.confirm("当前已有小队,点击确定则取消当前组队状态", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.getGameInfo();
              })
              .catch(() => {});
          } else {
            this.getGameInfo();
          }
        }
      });
    },
    //获取当前人物是否绑定游戏
    getGameInfo() {
      var _this = this;
      this.loading = true;
      cGet("CheckPubgBind", { gameId: this.gid }, false, false).then((res) => {
        this.loading = false;
        if (res.code == 1) {
          if (res.isband > 0 && res.isbandsteamid <= 0) {
            _this.isB = true;
          } else {
            _this.tanC();
          }
        } else if (res.code == 0) {
          if (res.isband > 0 && res.isbandsteamid <= 0) {
            _this.isB = true;
          } else {
            _this.$refs.tan1.open();
          }
        }
      });
    },
    enterG() {
      this.tanC();
    },
    tanC() {
      if (
        !localStorage.getItem("isFwq") ||
        localStorage.getItem("isFwq") <= 0
      ) {
        this.isShow = 3;
        this.$refs.tan1.open();
        localStorage.setItem("isFwq", 1);
      } else {
        this.tanD();
      }
    },

    fwqEnd() {
      this.tanD();
    },

    tanD() {
      /*if (typeof(this.list[this.ind]) == 'undefined') {
				ElMessage('请选择游戏');
				return ;
			}*/
      var _this = this;
      var item = this.list[this.ind];
      var par = {
        id: this.userInfo.id,
        gameId: this.gid,
        gamelx: this.isLeft,
      };
      this.loading = true;
      cGet("EnterGame", par, true, false).then((res) => {
        this.loading = false;
        localStorage.removeItem("teamId", 0); //推出队伍
        localStorage.removeItem("roomId", 0);
        localStorage.setItem("gameId", _this.gid);
        localStorage.setItem("gameName", _this.gname);
        localStorage.setItem("GameData", JSON.stringify(res.data.GameData));
        if (this.isLeft == 0) {
          this.$router.push({ name: "GameDetail" });
        } else if (this.isLeft == 1) {
          this.$router.push({ name: "TeamList" });
        } else if (this.isLeft == 2) {
          this.gotoTeamplat();
        } else if (this.isLeft == 3) {
          this.$router.push({ name: "MatchPlatform" });
        } else if (this.isLeft == 4) {
          this.gotoLeagueplat();
        }
      });
    },
    bclose() {
      this.isB = false;
    },
    gotoTeamplat() {
    
      var battleID = localStorage.getItem("battleid");
      if (!battleID) {
        var userInfo = JSON.parse(localStorage.getItem("userInfo"));
        var par = {
          pageNum: "0",
          pageSize: "1000",
          memberId: userInfo.id,
        };
        var getURL = "app/gameteam/manager/getTeamPageByPlayerId";
        cGet(getURL, par, true, false).then((res) => {
          if (res.code == 10000) {
            if (res.result && res.result.length > 0) {
              var battleInfo = res.result[0];
              localStorage.setItem("battlename", battleInfo.name);
              localStorage.setItem("battlelogo", battleInfo.logoUrl);
              localStorage.setItem("battleid", battleInfo.id);
              localStorage.setItem("battleinfo", JSON.stringify(res.result[0]));
              this.$store.commit("battleChange", 1);
            } else {
              localStorage.removeItem("battlename");
              localStorage.removeItem("battlelogo");
              localStorage.removeItem("battleid");
              this.$store.commit("battleChange", 0);
            }
          }
          this.$router.push({ name: "TeamPlatform" });
        });
      } else {
        var getURL2 = "app/gameteam/manager/getTeamByTeamId/";
        var par2 = {
          teamId: battleID,
        };
        // this.$router.push({ name: "TeamPlatform" });
        this.loading = true;
        cGet(getURL2, par2, true, false).then((res) => {
          this.loading = false;
          if (res.code == 10000) {
            if (res.result) {
              localStorage.setItem("battleinfo", JSON.stringify(res.result));
            }
          } else {
            ElMessage(res.message);
          }

          this.$router.push({ name: "TeamPlatform" });
        });
      }
    },
    gotoLeagueplat() {
   
      var leagueid = localStorage.getItem("leagueid");
      if (!leagueid) {
        var userInfo = JSON.parse(localStorage.getItem("userInfo"));
        var par = {
          pageNum: "0",
          pageSize: "1000",
          memberId: userInfo.id,
        };
        var getLURL = "app/gameteam/manager/getLeaguesByMember";
        cGet(getLURL, par, true, false).then((res) => {
          if (res.code == 10000) {
            if (res.result && res.result.length > 0) {
              var leagueInfo = res.result[0];
              localStorage.setItem("leaguename", leagueInfo.name);
              localStorage.setItem("leagueid", leagueInfo.id);
              localStorage.setItem("leaguelogo", leagueInfo.logoUrl);

              localStorage.setItem("leagueInfo", JSON.stringify(leagueInfo));
            } else {
              localStorage.removeItem("leaguename");
              localStorage.removeItem("leagueid");
              localStorage.removeItem("leaguelogo");
            }
          }
          this.$router.push({ name: "LeaguePlatform" });
        });
      } else {
        var getURL2 = "app/gameteam/manager/getLeague";
        var par2 = {
          leagueId: leagueid,
        };
        // this.$router.push({ name: "TeamPlatform" });
        this.loading = true;
        cGet(getURL2, par2, true, false).then((res) => {
          this.loading = false;
          if (res.code == 10000) {
            if (res.result) {
              localStorage.setItem("leagueInfo", JSON.stringify(res.result));
            }
          } else {
            ElMessage(res.message);
          }

          this.$router.push({ name: "LeaguePlatform" });
        });
      }
    },
  },
};
</script>

<style lang="less" scoped="scoped">
.game {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.contain {
  .top {
    position: absolute;
    top: 3rem;
    left: 50%;
    margin-left: -95px;
    font-size: 2.2rem;
    font-weight: 600;
    color: #fff;
    letter-spacing: 2px;
    background: linear-gradient(to right, #ada59b, #d1d0cf);
    -webkit-background-clip: text;
    color: transparent;
  }

  .bottom {
    position: absolute;
    top: 80%;
    left: 50%;
    margin-left: -95px;
  }

  .el-row {
    width: 100%;
    margin-top: 13rem;
    .el-col {
      .g-list {
        max-width: 20rem;
        margin: 0 auto;

        border-radius: 5px;
        background-color: #202021;
        box-sizing: border-box;
        padding: 20px 25px 35px;

        img {
          width: 100%;
        }
      }
      .active {
        border: 1px solid #c6c5c6;
      }

      .title {
        width: 100%;
        text-align: left;
        margin-bottom: 2rem;
        margin-top: 0.5rem;
        font-size: 1.5rem;
        color: #fff;

        button {
          background: #07b43c;
          border: none;
          padding: 4px 15px;
          color: #fff;
          letter-spacing: 2px;
          border-radius: 2px;
          cursor: pointer;
          float: right;
          margin-top: 3px;
        }
      }
    }
  }
}
</style>
