<template>
	<div class="left">
		<div class="top">
			<img src="../../static/user/user-1.png" />
		</div>
		<div class="con">
			<div class="title">会员中心</div>
			
			<div class="c-lc">
				<div @click="urlNaTo('UserZhu')" :class="rName == 'UserZhu'?'list active':'list'">
					<img src="../../static/user/icon-9.png" />
					个人主页
				</div>
				<div @click="urlNaTo('UserCenter')" :class="rName == 'UserCenter'?'list active':'list'">
					<img src="../../static/user/icon-1.png" />
					个人信息
				</div>
				<div @click="urlNaTo('UserFriend')" :class="rName == 'UserFriend'?'list active':'list'">
					<img src="../../static/user/icon-2.png" />
					好友管理
				</div>
				<!--div @click="urlNaTo('UserVip')" :class="rName == 'UserVip'?'list active':'list'">
					<img src="../../static/user/icon-3.png" />
					升级会员
				</div-->
				<div @click="urlNaTo('UserGame')" :class="rName == 'UserGame'?'list active':'list'">
					<img src="../../static/user/icon-4.png" />
					平台记录
				</div>
				<div @click="urlNaTo('UserScore')" :class="rName == 'UserScore'?'list active':'list'">
					<img src="../../static/user/icon-4.png" />
					积分记录
				</div>
				<div @click="urlNaTo('UserMsg')" :class="rName == 'UserMsg'?'list active':'list'">
					<img src="../../static/user/icon-5.png" />
					站内消息
				</div>
				<div @click="urlNaTo('UserSuggest')" :class="rName == 'UserSuggest'?'list active':'list'">
					<img src="../../static/user/icon-6.png" />
					意见反馈
				</div>
				<div @click="urlNaTo('UserPwd')" :class="rName == 'UserPwd'?'list active':'list'">
					<img src="../../static/user/icon-8.png" />
					修改密码
				</div>
				<div @click="Logout" class="list">
					<img src="../../static/user/icon-7.png" />
					退出登录
				</div>
			</div>
			<!--div @click="naToUrl" class="list">
				<img src="../../static/left-3.png" />
				论坛
			</div-->
		</div>
	</div>
</template>

<script>
	import {reSet} from "@/request/common-api.js";
	import { ElMessageBox } from 'element-plus'
	export default {
		name:"LeftUser",
		props:{
			img:String
		},
		data(){
			return {
				teamId:0,
				roomId:0,
				rName:this.$route.name
			}
		},
		/*watch: {
		  $route: {
		    handler: function(val, oldVal){
		      this.rName = this.$route.name;
			  this.getGame();
		    },
		    // 深度观察监听
		    deep: true
		  }
		},*/
		created() {
			this.init();
		},
		methods:{
			init() {
				this.teamId = localStorage.getItem("teamId")?localStorage.getItem("teamId"):0;
				this.roomId = localStorage.getItem("roomId")?localStorage.getItem("roomId"):0;
			},
			urlNaTo(str) {
				this.$router.push({name:str});
			},
			Logout(){
				var _this = this;
				ElMessageBox.confirm("确定退出登录吗？",{
				  confirmButtonText: '确定',
				  cancelButtonText: '取消',
				  type: 'warning'
				}).then(() => {
					reSet().then(res=> {
						localStorage.removeItem("userInfo");
						localStorage.removeItem("token");
						clearInterval(window.timer);
						clearInterval(window.timer1);
						this.$router.push({name:"Home"});
						localStorage.removeItem("battlename");
          				localStorage.removeItem("battlelogo");
          				localStorage.removeItem("battleid");
						localStorage.removeItem("battleInfo");
						localStorage.removeItem("leaguename");
          				localStorage.removeItem("leagueid");
          				localStorage.removeItem("leaguelogo");
						localStorage.removeItem("leagueInfo");
						localStorage.removeItem("showCreateVue");
					})
				})
				.catch(() => {})
			}
		}
	}
</script>

<style lang="less" scoped="scoped">
.left{
	position: absolute;
	width: 240px;
	left: 0;
	top:60px;
	height: calc(100% - 60px);
	min-height: 700px;
	background: #202020;
	z-index: 9;
	
	.con {
		width: 100%;
		margin-top: 15px;
		text-align: left;
		.title {
			color:#fff;
			font-size: 16px;
			text-align: center;
			margin-bottom: 15px;
		}
		.c-lc {
			background-color: #2F2F2F;
			width: 100%;
			box-sizing: border-box;
			padding: 0px 30px;
			overflow: hidden;
			.list {
				height: 24px;
				line-height: 24px;
				margin: 20px 0;
				background: #2f2f2f;
				color:#848587;
				cursor: pointer;
				width: 80%;
				font-size: 12px;
				border-radius: 5px;
				img {
					height: 16px;
					margin-left: 20px;
					margin-right: 15px;
					position: relative;
					top:4px;
				}
			}
			
			.active {
				background:#222222;
				border:1px solid #606060;
				
			}
		}
		
	}
}
</style>
