<template>
	<el-dialog
	v-model="isShow"
	custom-class="dialogbd"
	:title="title?title:'消息详情'"
	:width="width?width:'25%'"
	:height="height?height:'auto'">
		<div class="msg" v-html="con"></div>
	</el-dialog>
</template>

<script>
	import img1 from '../../../static/head.jpg'
	import Com from "@/request/common.js";
	import {cGet} from "@/request/http-util.js";
	import { ElMessage } from 'element-plus';
	export default{
		name:"TanXgfwq",
		props:{
			con:String,
			title:String,
			width:String,
			height:String
		},
		data(){
			return {
				isShow:false,
				userInfo:{}
			}
		},
		created() {
			this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
		},
		methods:{
			open(){
				this.isShow = true;
			}
		}
	}
</script>

<style lang="less" scoped="scoped">
	.xg-top {
		height: 3.2rem;
		margin-bottom: 1rem;
		line-height: 3.2rem;
		text-align: center;
		.user-head{
			border-radius: 50%;
			width: 3.2rem;
			position: absolute;
			left: 50%;
			margin-left: -4.2rem;	
		}
		span{
			margin-left: 2rem;
		}
	}
	.msg {
		color:#ddd;
		text-align: left;
	}
	
	
	.con-title {
		line-height: 2.4rem;
		margin-bottom: 1.5rem;
	}
	
	.con-nr {
		text-align: left;
		margin-bottom: 1.5rem;
		.con-nr-div {display: inline-block;}
		button{
			height: 2rem;
			width: 10rem;
			background-color: #07b43c;
			color:#ededed;
			border:none;
			letter-spacing: 3px;
			border-radius: 2px;
		}
	}
</style>
