import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Game from '../views/Game.vue'
import GameDetail from '../views/GameDetail.vue'
import GameRoom from '../views/GameRoom.vue'
import GameStart from '../views/GameStart.vue'

import TeamList from '../views/TeamList.vue'
import TeamAdd from '../views/TeamAdd.vue'
import TeamStart from '../views/TeamStart.vue'
import SearchTeam from '../views/SearchTeam.vue'
import SteamCallback from '../views/SteamCallback.vue'

import UserCenter from '../views/user/Center.vue';
import UserScore from '../views/user/Score.vue';
import UserVip from '../views/user/Vip.vue';
import UserFriend from '../views/user/Friend.vue';
import UserMsg from '../views/user/Msg.vue';
import UserSuggest from '../views/user/Suggest.vue';
import UserGame from '../views/user/Game.vue';
import UserPwd from '../views/user/Pwd.vue';
import UserZhu from '../views/user/Zhu.vue';

import TeamPlatform from '../components/teamplatform/TeamPlatform.vue'
import MatchPlatform from '../components/matchplatform/MatchPlatform.vue'
import LeaguePlatform from '../components/leagueplatform/LeaguePlatform.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/game',
    name: 'Game',
    component: Game
  },
  {
    path: '/gamedetail',
    name: 'GameDetail',
    component: GameDetail
  },
  {
    path: '/gameroom',
    name: 'GameRoom',
    component: GameRoom
  },
  {
    path: '/gamestart',
    name: 'GameStart',
    component: GameStart
  },
  {
    path: '/searchteam',
    name: 'SearchTeam',
    component: SearchTeam
  },
  {
    path: '/teamlist',
    name: 'TeamList',
    component: TeamList
  },
  {
    path: '/teamadd',
    name: 'TeamAdd',
    component: TeamAdd
  },
  {
    path: '/teamstart',
    name: 'TeamStart',
    component: TeamStart
  },
  {
    path: '/Steam/Profile',
    name: 'SteamCallback',
    component: SteamCallback
  },

  {
    path: '/usercenter',
    name: 'UserCenter',
    component: UserCenter
  },
  {
    path: '/userscore',
    name: 'UserScore',
    component: UserScore
  },
  {
    path: '/uservip',
    name: 'UserVip',
    component: UserVip
  },
  {
    path: '/userfriend',
    name: 'UserFriend',
    component: UserFriend
  },
  {
    path: '/usermsg',
    name: 'UserMsg',
    component: UserMsg
  },
  {
    path: '/usersuggest',
    name: 'UserSuggest',
    component: UserSuggest
  },
  {
    path: '/usergame',
    name: 'UserGame',
    component: UserGame
  },
  {
    path: '/userpwd',
    name: 'UserPwd',
    component: UserPwd
  },
  {
    path: '/userzhu',
    name: 'UserZhu',
    component: UserZhu
  },
  {
    path: '/teamplatform',
    name: 'TeamPlatform',
    component: TeamPlatform
  },
  {
    path: '/matchplatform',
    name: 'MatchPlatform',
    component: MatchPlatform
  },
  {
    path: '/leagueplatform',
    name: 'LeaguePlatform',
    component: LeaguePlatform
  }
  /*{
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/About.vue')
  }*/
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
