<template>
  <div
    class="gamelist"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    v-loading="loading"
  >
    <div class="gamelist-c">
      <div class="g-top">比赛预告</div>
      <div class="m-con">
        <div class="m-c-con">
          <div class="m-rank-div">TPP比赛预告</div>
          <div
            class="m-pre-list"
            v-for="(item, index) in matchlist"
            :key="index"
          >
            <div>
              <el-dropdown
                  trigger="click"
                  placement="bottom-end"
                  @command="dropdownClick"
                >
                  <TeamMatch :matchInfo="item[0]"></TeamMatch>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item
                        :command="commandValue('baoming', item[0])"
                        >报名比赛</el-dropdown-item
                      >
                       <el-dropdown-item
                        :command="commandValue('xiangxi', item[0])"
                        >比赛详情</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
            </div>
            <div v-if="item.length == 3 || item.length == 2">
              <el-dropdown
                  trigger="click"
                  placement="bottom-end"
                  @command="dropdownClick"
                >
                  <TeamMatch :matchInfo="item[1]"></TeamMatch>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item
                        :command="commandValue('baoming', item[1])"
                        >报名比赛</el-dropdown-item
                      >
                      <el-dropdown-item
                        :command="commandValue('xiangxi', item[1])"
                        >比赛详情</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
            </div>
            <div v-if="item.length == 3">
              <el-dropdown
                  trigger="click"
                  placement="bottom-end"
                  @command="dropdownClick"
                >
                  <TeamMatch :matchInfo="item[2]"></TeamMatch>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item
                        :command="commandValue('baoming', item[2])"
                        >报名比赛</el-dropdown-item
                      >
                      <el-dropdown-item
                        :command="commandValue('xiangxi', item[2])"
                        >比赛详情</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
            </div>
          </div>
          <div class="m-rank-div">FPP比赛预告</div>
          <div
            class="m-pre-list"
            v-for="(item, index) in specailmatchlist"
            :key="index"
          >
            <div>
              <el-dropdown
                  trigger="click"
                  placement="bottom-end"
                  @command="dropdownClick"
                >
                  <TeamMatch :matchInfo="item[0]"></TeamMatch>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item
                        :command="commandValue('baoming', item[0])"
                        >报名比赛</el-dropdown-item
                      >
                      <el-dropdown-item
                        :command="commandValue('xiangxi', item[0])"
                        >比赛详情</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
            </div>
            <div v-if="item.length == 3 || item.length == 2">
            <el-dropdown
                  trigger="click"
                  placement="bottom-end"
                  @command="dropdownClick"
                >
                  <TeamMatch :matchInfo="item[1]"></TeamMatch>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item
                        :command="commandValue('baoming', item[1])"
                        >报名比赛</el-dropdown-item
                      >
                      <el-dropdown-item
                        :command="commandValue('xiangxi', item[1])"
                        >比赛详情</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
            </div>
            <div v-if="item.length == 3">
            <el-dropdown
                  trigger="click"
                  placement="bottom-end"
                  @command="dropdownClick"
                >
                  <TeamMatch :matchInfo="item[2]"></TeamMatch>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item
                        :command="commandValue('baoming', item[2])"
                        >报名比赛</el-dropdown-item
                      >
                      <el-dropdown-item
                        :command="commandValue('xiangxi', item[2])"
                        >比赛详情</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
   <el-dialog v-model="dialogVisible" title="比赛详细">
    <MatchPreDetail
      :matchDetail="matchDetail"
      :memberList="memberList"
    ></MatchPreDetail>
  </el-dialog>
  <el-dialog v-model="dialogBMVisible" title="报名提示">
    <span>{{dialogBMTip}}</span>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogBMVisible = false">取消</el-button>
        <el-button type="primary" @click="clickBMButton"
          >确定</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import imghead from "../../../static/head.jpg";
import img1 from "../../../static/teamlist-1.png";
import img2 from "../../../static/teamlist-2.png";
import TeamMatch from "@/components/teamplatform/TeamMatch.vue";
import { ElMessage } from "element-plus";
import { cGet,cPostParam } from "@/request/http-util.js";
import MatchPreDetail from "@/components/matchplatform/MatchPreDetail.vue";
import bus from "@/bus/mittbus.js"

export default {
  name: "MatchPre",
  components: {
    TeamMatch,
    MatchPreDetail,
  },
  props: {
    gameId: Number,
  },
  data() {
    return {
      dialogBMVisible: false,
      dialogBMTip:'',
      dialogBMCommand: {},
      isShow: 1,
      loading: false,
      imghead: imghead,
      img1: img1,
      img2: img2,
    
      img: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",

      matchlist: [],
      specailmatchlist: [],
      
      userInfo: {},
      dialogVisible: false,
      matchDetail:{},
      memberList:[],
    };
  },
  created() {
    this.init();
    bus.on('refreshMatchList', res=>{
      this.init();
    });
  },
  mounted(){
    
  },
  beforeUnmount() {
    // clearInterval(window.timer1);
  },
  methods: {
    init() {
      var par = {
        matchType: "TPP",
        pageNum: 0,
        pageSize: 1000,
      };
      var getURL = "app/matches/DefMatch/getDefMatchesPageByMatchType";
      var __this = this;
      cGet(getURL, par, true, false).then((res) => {
        if (res.code == 10000) {
          var specialMatchInfoList = res.result;
          __this.matchlist = [];

          for (var i = 0; i < specialMatchInfoList.length; i++) {
            if (i + 1 > 2 && (i + 1) % 3 == 0) {
              var tmplist = [
                specialMatchInfoList[i - 2],
                specialMatchInfoList[i - 1],
                specialMatchInfoList[i],
              ];
              __this.matchlist.push(tmplist);
            }
          }

          if (specialMatchInfoList.length % 3 == 1) {
            var tmplist2 = [
              specialMatchInfoList[
                specialMatchInfoList.length - 1
              ],
            ];
            __this.matchlist.push(tmplist2);
          } else if (specialMatchInfoList.length % 3 == 2) {
            var tmplist3 = [
              specialMatchInfoList[
                specialMatchInfoList.length - 2
              ],
              specialMatchInfoList[
                specialMatchInfoList.length - 1
              ],
            ];
            __this.matchlist.push(tmplist3);
          }
        } else {
          ElMessage(res.message);
        }
      });

      var parF = {
        matchType: "FPP",
        pageNum: 0,
        pageSize: 1000,
      };
      var getURLF = "app/matches/DefMatch/getDefMatchesPageByMatchType";
      cGet(getURLF, parF, true, false).then((res) => {
        if (res.code == 10000) {
          var specialMatchInfoList = res.result;

          for (var i = 0; i < specialMatchInfoList.length; i++) {
            if (i + 1 > 2 && (i + 1) % 3 == 0) {
              var tmplist = [
                specialMatchInfoList[i - 2],
                specialMatchInfoList[i - 1],
                specialMatchInfoList[i],
              ];
              __this.specailmatchlist.push(tmplist);
            }
          }

          if (specialMatchInfoList.length % 3 == 1) {
            var tmplist2 = [
              specialMatchInfoList[
                specialMatchInfoList.length - 1
              ],
            ];
            __this.specailmatchlist.push(tmplist2);
          } else if (specialMatchInfoList.length % 3 == 2) {
            var tmplist3 = [
              specialMatchInfoList[
                specialMatchInfoList.length - 2
              ],
              specialMatchInfoList[
                specialMatchInfoList.length - 1
              ],
            ];
            __this.specailmatchlist.push(tmplist3);
          }
        } else {
          ElMessage(res.message);
        }
      });
    },
    //列表切换
    btChange(num) {
      this.isShow = num;
      // if (num == 1) {
      //   this.getGameList(1);
      //   clearInterval(window.timer1);
      //   window.timer1 = setInterval(() => {
      //     this.getGameList();
      //   }, 5000);
      // } else {
      //   this.getTeamList(1);
      //   clearInterval(window.timer1);
      //   window.timer1 = setInterval(() => {
      //     this.getTeamList();
      //   }, 5000);
      // }
    },
    radioChange(val) {
      if (val == "充值") {
        this.isdepoist = true;
      } else {
        this.isdepoist = false;
      }
    },
    commandValue(type, command) {
      return {
        type: type,
        command: command,
      };
    },
    clickBMButton(){
      var userInfo = JSON.parse(localStorage.getItem("userInfo"));
        var battleid = localStorage.getItem("battleid");
          var data = {
            id:0,
            orderId: this.dialogBMCommand.gameMode == 1 ? battleid: userInfo.id,
            matchId: this.dialogBMCommand.id,
            status: 0,
            mode: this.dialogBMCommand.gameMode,
          };
          var par = {
            memberId: userInfo.id,
          };
          var getURL = "app/matches/DefMatchOrder/create";
          cPostParam(getURL, par,data, true, false).then((res) => {
            ElMessage(res.message);
          });
          this.dialogBMVisible = false;
    },
    dropdownClick(item) {
      const { type, command } = item;
      if (type === "baoming") {
        if(command.gameBill != 0) {
          this.dialogBMTip = '报名比赛需要使用' + command.gameBill + '游戏币。请确定是否报名？'
          this.dialogBMVisible = true;
          this.dialogBMCommand = command;
        }
        else{
          this.dialogBMCommand = command;
          this.clickBMButton();
        }
      } 
      else if(type == "xiangxi") {
       
        this.matchDetail = command;

        var parF = {
        matchId: command.id,
        pageNum: 0,
        pageSize: 1000,
      };
      var getURLF = "app/matches/DefMatchOrder/getMatchOrdersSuccessPageByMatchId";
      cGet(getURLF, parF, true, false).then((res) => {
        if (res.code == 10000) {
          this.memberList = res.result;
          this.dialogVisible = true;
        } else {
          ElMessage(res.message);
           this.dialogVisible = true;
        }
      });
     }
    },
  },
};
</script>

<style scoped="scoped" lang="less">
.gamelist {
  width: 100%;
  height: 100%;
  position: relative;
}

.gamelist-c {
  height: calc(100%);
  margin: 0 auto;
  background-color: rgba(32, 32, 32, 0.6);
  border-radius: 5px;
  border: 1px solid #676666;
  box-sizing: border-box;
  position: relative;
}

.g-top {
  text-align: left;
  color: #f4f4f4;
  font-size: 1.1rem;
  background-image: linear-gradient(#161515, #2b2a2a);
  height: 3.2rem;
  line-height: 3.2rem;
  box-sizing: border-box;
  padding-left: 1.5rem;
}

.t-bot {
  width: 100%;
  left: 0;

  position: absolute;
  bottom: 0;
}
.tbutton {
  margin-top: 1em;
  background: #2f2f2f;
  border: 1px solid #676666;
  width: 100%;
  height: 2.7rem;
  line-height: 2.7rem;
  color: #efefef;
  letter-spacing: 2px;
  border-radius: 5px;
  cursor: pointer;

  img {
    position: relative;
    top: 0.4rem;
    height: 1.4rem;
  }
}
.m-con {
  padding: 0 0.6rem;
  // margin-top: 0.5rem;
  height: 100%;
  .m-c-top {
    text-align: left;
    border-bottom: 1px solid #808080;
    button {
      background: #1f1e1f;
      color: #fff;
      padding: 0.4rem 1.2rem;
      border-left: 1px solid #808080;
      border-top: 1px solid #808080;
      border-right: 1px solid #808080;
      border-bottom: none;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      margin-right: 10px;
    }
    .ctop-button {
      background: #07b43c;
    }
  }

  .pl0 {
    padding-left: 0 !important;
  }
  .pr0 {
    padding-right: 0 !important;
  }
  .m-c-con {
    height: 90%;
    overflow-y: auto;
    position: relative;

    .el-col {
      box-sizing: border-box;
      padding: 5%;
      .g-con-list1 {
        border: 1px solid #363637;
        border-radius: 20px;
        overflow: hidden;
        .gcon_top {
          height: 2.4rem;
          background: #161617;
          line-height: 2.4rem;
          color: #fff;
          .active {
            color: #07b43c;
            overflow: hidden;
          }
        }
        .gcon_con {
          width: 100%;
          background: #222224;
          overflow: hidden;
          .gcon_con-l {
            width: 50%;
            float: left;
            box-sizing: border-box;
            padding: 5%;
            img {
              width: 90%;
              margin-left: 5%;
              width: 3.6rem;
              height: 3.6rem;
              border-radius: 50%;
            }
          }
          .text {
            width: 100%;
            color: #fff;
            height: 1.2rem;
            line-height: 1.2rem;
            font-size: 0.6rem;
          }
        }
      }
    }
  }

  .g-con-list {
    width: 100%;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 2rem;
    height: 7vw;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    .bgimg {
      width: 100%;
      height: 100%;
    }
    .p11 {
      color: #fff;
      position: absolute;
      left: 12%;
      top: 30%;
      margin-top: -13.5px;
      font-size: 1.3rem;
      width: 85%;
      text-align: left;
    }
    .p12 {
      color: #fff;
      position: absolute;
      left: 12%;
      top: 60%;
      margin-top: -13.5px;
      font-size: 1.3rem;
      width: 85%;
      text-align: left;
    }
    .zz {
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: rgba(32, 32, 32, 0.3);
      top: 0;
    }
    button {
      background: none;
      border: none;
      padding: 0.25rem 1rem;
      color: #fff;
      letter-spacing: 2px;
      border-radius: 5px;
      cursor: pointer;
      position: absolute;
      right: 12%;
      top: 50%;
      font-size: 1.2rem;
      margin-top: -15.5px;
      span {
        color: #07b43c;
      }
    }
  }

  .g-con-list div {
    width: 100%;
    height: 55em;
  }

  .t-message-con {
    height: 100%;
    // overflow-y: auto;
    position: relative;
  }
  .t-message {
    margin-top: 1em;
    margin-left: 1em;
    margin-right: 1em;
    height: 45%;
    // background: white;
    .t-message-b {
      margin-top: 0.5em;
      margin-left: 19em;
    }
  }

  .t-message-table {
    margin: 0%;
    width: 100%;
    height: 100%;
  }

  /*最外层透明*/
  ::v-deep .el-table,
  ::v-deep .el-table__expanded-cell {
    background-color: transparent !important;
  }
  /* 表格内背景颜色 */
  ::v-deep .el-table th,
  ::v-deep .el-table tr,
  ::v-deep .el-table td {
    background-color: transparent !important;
    // border: 0px;
  }

  .el-table::before {
    height: 0px;
  }
  .el-table--border::after {
    width: 0px;
  }
  .el-table--border {
    border: none;
  }

  .m-message-img {
    width: 2em;
    height: 2em;
    background: #07b43c;
  }

  .t-message-div {
    margin-left: 3em;
    margin-top: -2em;
  }

  .m-cash {
    margin-top: 0.5em;
    height: 10%;
    width: 100%;
  }
  .t-cash-div {
    font-size: 25px;
    color: white;
  }

  .t-match-div {
    width: 100%;
    color: white;
    background: #07b43c;
  }

  .m-check-table {
    color: white;
    width: 100%;
  }

  .m-rank-div {
    margin-top: 1em;
    background: #07b43c;
    color: white;
    font-size: 20px;
    height: 1.5em;
  }

  .m-cash-table {
    width: 100%;
    height: 90%;
  }

  .m-pre-list {
    border: 1px solid #676666;
    width: 99.5%;
    position: relative;
    // border-radius: 5px;
    overflow: hidden;
    margin-bottom: 2rem;
    height: 15em;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .s-con-list-special {
    border: 1px solid #676666;
    width: 99.5%;
    position: relative;
    // border-radius: 5px;
    overflow: hidden;
    margin-bottom: 2rem;
    height: 13em;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .m-pre {
    width: 10em;
    height: 10em;
  }
}
</style>
