<template>
  <div class="mt-table">
    <el-table
      :data="matchList"
      :cell-style="{ color: '#fff' }"
      :header-cell-style="{ color: '#fff' }"
    >
      <el-table-column
        label="比赛ID"
        prop="mactchID"
        min-width="10%"
        align="center"
      >
      </el-table-column>
      <el-table-column
        label="预计开始时间--预计结束时间"
        min-width="70%"
        align="center"
      >
        <template v-slot="scope">
          <el-date-picker
            v-model="scope.row.timeDate"
            type="datetimerange"
            range-separator="--"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="changeValue(scope.row)"
            :clearable="false"
          >
          </el-date-picker>
        </template>
      </el-table-column>
      <el-table-column label="休息时间" min-width="20%" align="center">
        <template v-slot="scope">
          <!-- <el-input v-model="scope.row.interval" placeholder=""/> -->
          <el-input
            size="small"
            onkeyup="this.value=this.value.replace(/\D/g,'')"
            onafterpaste="this.value=this.value.replace(/\D/g,'')"
            v-model.number="scope.row.interval"
            maxlength="2"
            @change="changeValue(scope.row)"
          ></el-input>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {timeFormat} from "@/request/common.js";
export default {
  name: "MatchTimeTable",
  props: {
    matchTimeDetailList: [],
  },
  emits: ["changeTimeList"],
  data() {
    return {
      matchList: this.matchTimeDetailList,
    };
  },
  watch: {
    matchTimeDetailList(oldval, newvl) {
      this.matchList = this.matchTimeDetailList;
    }
  },
  computed: {
    // ismatchTimeDetailList() {
    //   return this.matchList;
    // }
    //   userMemberList(){
    //       return this.userList;
    //   },
    // {mactchID:i, startTime:startDate, endTime:endDate, interval:this.intervalvalue, onceTime:this.oncetimevalue};
  },
  created() {
    // this.labelO = this.textArray[0];
    //     this.labelT = this.textArray[1];
  
  },
  methods: {
    changeValue(val) {
      var startDate = new Date(val.timeDate[1]);
      startDate.setMinutes(startDate.getMinutes() + parseInt(val.interval));
      var endDate = new Date(startDate);
    
      this.matchList = this.matchList.map(function (item){
         if(item.mactchID > val.mactchID)
         {
           var startDateTmp = new Date(startDate); 
           endDate.setMinutes(endDate.getMinutes() + parseInt(item.onceTime));
           var endDateTmp = new Date(endDate);
           var timeDate = [timeFormat(startDateTmp), timeFormat(endDateTmp)];
           startDate.setMinutes(startDate.getMinutes() + parseInt(item.onceTime) + parseInt(item.interval));
           return {mactchID:item.mactchID, timeDate:timeDate, interval:item.interval, onceTime:item.onceTime};
         }
         else{
           return {mactchID:item.mactchID, timeDate:[item.timeDate[0],item.timeDate[1]], interval:item.interval, onceTime:item.onceTime};;
         }

      });

      this.$emit("changeTimeList",this.matchList);
    },
  },
};
</script>

<style scoped="scoped" lang="less">
.mt-table {
  height: 60vh;
  width: 100%;
  position: relative;
  ::v-deep .el-range-input {
    background: black;
    color: white;
  }
}

/*最外层透明*/
::v-deep .el-table,
::v-deep .el-table__expanded-cell {
  background-color: transparent !important;
}
/* 表格内背景颜色 */
::v-deep .el-table th,
::v-deep .el-table tr,
::v-deep .el-table td {
  background-color: transparent !important;
  // border: 0px;
}

.el-table::before {
  height: 0px;
}
.el-table--border::after {
  width: 0px;
}
.el-table--border {
  border: none;
}
</style>
