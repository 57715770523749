<template>
	<div class="head">
		<div class="left" @click="gameNato">
			<img alt="logo" src="../../static/logo.png" />
		</div>
		<div class="menu">
			<div @click="naToHome" class="list">首页</div>
			<div @click="gameNato" class="list">游戏</div>
		</div>
		<div class="right">
			<!--ComHeadImg :isTop="1"></ComHeadImg-->
			<img class="head-img" :src="userInfo.avatar?userInfo.avatar:imgHead" />
			<span @click="naToUser">{{userInfo&&userInfo.name?userInfo.name:userInfo.phone}}</span>
			<img class="bg" alt="logo" src="../../static/game-1.png" />
			<span @click="naToUser" class="iconfont icon-yonghu"></span>
			<span @click="naToXi" class="iconfont icon-xiaoxitongzhi"></span>
		</div>
	</div>
</template>

<script>
	import ComHeadImg from '@/components/common/ComHeadImg.vue'
	import {cPost,cGet} from "@/request/http-util.js";
	import {UI} from "@/request/common-api.js";
	export default{
		name:'Head',
		components:{
			ComHeadImg
		},
		data(){
			return{
				userInfo:{}
			}
		},
		computed: {
		  isFollow () {
		    return this.$store.state.isHead;　　//需要监听的数据
		  }
		},
		watch: {
		  isFollow (newVal, oldVal) {
		    this.init();
		  }
		},
		created() {
			this.init();
		},
		methods:{
			init(){
				this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
				UI().then(res=> {this.userInfo = res;})
			},
			gameNato() {
				this.$router.push({name:'Game'});
			},
			naToHome(){
				this.$router.push({name:'Home'});
			},
			naToXi() {
				this.$router.push('usermsg');
			},
			naToUser(){
				this.$router.push("userzhu");
			}
		}
	}
</script>

<style lang="less" scoped="scoped">
	.head{
		position: absolute;
		height: 60px;
		width: 100%;
		min-width: 1480px;
		left: 0;
		top:0px;
		background: #171717;
		z-index: 10;
		
		.left {
			float: left;
			cursor: pointer;
			width: 240px;
			p {
				height: 60px;
				line-height: 60px;
				float: left;
				margin: 0;
				margin-left: 10px;
				color:#f1f1f1;
			}
			img {
				height: 40px;
				margin-top:10px;
				margin-left: 15px;
				float: left;
				display: block;
			}
		}
		
		.menu {
			float:left ;
			
			.list {
				width: 80px;
				text-align: center;
				float: left;
				height: 60px;
				line-height: 60px;
				color:#999;
				cursor: pointer;
			}
			.active {
				color:#bbb;
				border-bottom:2px solid #ff5602;
				height: 58px;
			}

		}
		
		.right{
			float: right;
			margin-top:5px;
			cursor: pointer;
			position: relative;
			overflow: hidden;
			.bg {
				height: 40px;
				margin-top:7px;
				margin-right:15px;
			}
			.head-img {
				width: 30px;
				height: 30px;
				border-radius: 50%;
				position: absolute;
				top: 11px;
			}
			span{
				position: absolute;
				top: 18px;
				left: 38px;
				color: #fff;
				width: 82px;
				text-align: left;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
			
			.icon-yonghu {
				    left: 120px;
				    top: 18px;
			}
			
			.icon-xiaoxitongzhi {
				    left: 145px;
				    top: 18px;
			}
		}
	}
</style>