<template>
  <div class="teamlist">
   <el-table
              class="t-message-table"
              height="calc(95%)"
              :data="listData"
              :cell-style="{ color: '#fff' }"
              :header-cell-style="{ color: '#fff' }"
              
            >
              <el-table-column prop="memberName" :label="firstHeader">
              <template #default="scope">
                {{ scope.row.memberName ? scope.row.memberName : "" }}
              </template>
               </el-table-column>
              <el-table-column prop="time" :label="secondHeader"> </el-table-column>
              <el-table-column prop="fee" :label="thirdHeader"> </el-table-column>
            </el-table>
  </div>
</template>

<script>
export default {
  name: "TeamList",
  props: {
    listData: Array,
    titleData: Array,
  },
  data() {
    return {
        titleArray: this.titleData,
        firstHeader: "",
        secondHeader: "",
        thirdHeader: "",
    };
  },
  computed() {

  },
  created() {
      if(this.titleArray.length == 3){
        this.firstHeader = this.titleArray[0];
        this.secondHeader = this.titleArray[1];
        this.thirdHeader = this.titleArray[2];
      }
     

  },
  methods: {},
};
</script>

<style scoped="scoped" lang="less">
.teamlist {
  height: 100%;
  width: 100%;
  // position: relative;
}


</style>
