<template>
	<div 
	element-loading-background="rgba(0, 0, 0, 0.8)"
	v-loading="loading"
	v-if="unHandleInvites.length > 0 || finvites.length > 0 " class="teamtan">
		<div v-if="unHandleInvites.length > 0">
			<el-row v-for="(item,index) in unHandleInvites">
				<el-col :span="24">
					<p>
						<div class="p1">{{item.name}}邀请您一起组队</div>
						<div class="btn">
							<button class="btn1" @click="Qdbtn(item.id,item.teamtype)">确定</button> <button @click="Qxbtn(item.id,item.teamtype)" class="btn2">取消</button>
						</div>
					</p>
				</el-col>
			</el-row>
		</div>
		<div v-if="finvites.length > 0">
			<el-row v-for="(item,index) in finvites">
				<el-col :span="24">
					<p>
						<div class="p1">{{item.name}}请求成为你的好友</div>
						<div class="btn">
							<button class="btn1" @click="Qdbtn1(item.id)">确定</button> <button @click="Qxbtn1(item.id)" class="btn2">取消</button>
						</div>
					</p>
				</el-col>
			</el-row>
		</div>
	</div>
	
</template>

<script>
	import {cPost,cGet} from "@/request/http-util.js";
	import { ElMessage } from 'element-plus';
	import router from '../router';
	export default {
		name:"Yq",
		data(){
			return {
				hz:localStorage.getItem("isLeft") != 1?"":"_dy",
				unHandleInvites:[],
				finvites:[],
				loading:false
			}
		},
		watch: {
		  $route: {
		    handler: function(val, oldVal){
		      window.clearInterval(window.timer);
			  window.clearInterval(window.timer1);
			  this.zzLogin();
		    },
		    // 深度观察监听
		    deep: true
		  }
		},
		created() {
			/*var ishttps = 'https:' == document.location.protocol ? true: false;
			if(ishttps){
				location.href = location.href.replace('https','http');
			}*/
		},
		mounted() {
			
		},
		methods:{
			
			zzLogin() {
				
				var _this = this;
				var userInfo = localStorage.getItem("userInfo");
				
				window.clearInterval(window.ter);
				if (localStorage.getItem('token')) {
					if (!userInfo && router.currentRoute.value.name != "Home") {
						ElMessage("请先登录");
						localStorage.setItem("token",'');
						this.$router.push({name:"Home"});
						return false;
					}
					
					if (!localStorage.getItem("gameId") && router.currentRoute.value.name != "Game") {
						this.$router.push({name:"Game"});
					}
					
					//定时更新登录状态
					
					window.ter = setInterval(function(){
						var userInfo = localStorage.getItem("userInfo");
						userInfo = userInfo?JSON.parse(userInfo):'';
						if ( typeof(router.currentRoute.value.name) != 'undefined' && userInfo && router.currentRoute.value.name != "Home") {
							cGet("KeepLogin",{id:userInfo.id},false,false).then(res => {
								
								_this.changeGa(res.data.uinfo.gameId,res.data.uinfo.teamId,res.data.uinfo.roomId);
								
								//判断是否有邀请组队存在
								if (typeof(res.data.unHandleInvites) != 'undefined' && res.data.unHandleInvites) {
									_this.unHandleInvites = res.data.unHandleInvites;
								}
								
								//判断是否有邀请好友存在
								if (typeof(res.data.finvites) != 'undefined' && res.data.finvites.length > 0) {
									res.data.finvites.filter(function(item,index){
										_this.finvites.push(item);
									})
									
								}
							});
						} 
					},5000);
					//定时更新登录结束
				}
			},
			Qdbtn(id,type) {
				this.loading = true;
				cGet("tyinvite"+(type == 1?"_dy":""),{id:id},true,false).then(res => {
					this.loading = false;
					if (res.code == 1) {
						this.changeGa(res.data.gameId,res.data.teamId,res.data.roomId);
						if (typeof(res.teamid) != 'undefined') {
							localStorage.setItem("teamId",res.teamid);
						}
						if (typeof(res.roomid) != 'undefined') {
							localStorage.setItem("roomId",res.roomid);
						}
						this.Del(id);
						
						if (type != 1) {
							this.$router.push({name:"GameDetail"});
						} else {
							this.$router.push({name:"TeamList"});
						}
						
					}
				})
			},
			Qxbtn(id,type) {
				this.loading = true;
				cGet("jyinvite"+(type == 1?"_dy":""),{id:id},false,false).then(res => {
					this.loading = false;
					if (res.code == 1) this.Del(id);
				});
			},
			
			Qdbtn1(id) {
				this.loading = true;
				cGet("agreefriend",{sqid:id},true,false).then(res => {
					this.loading = false;
					if (res.code == 1) {
						ElMessage("添加成功");
						this.Del(id,2);
					}
				})
			},
			Qxbtn1(id) {
				if (res.code == 1) this.Del(id,2);
			},
			
			Del(id,type = 1) {
				if (type == 1) {
					this.unHandleInvites = this.unHandleInvites.filter( (item,i) =>{
						if (item.id != id )return item;
					});
				} else if(type == 2) {
					this.finvites = this.finvites.filter( (item,i) =>{
						if (item.id != id )return item;
					});
				}
				
			},
			changeGa(gameId,teamId,roomId){
				if (localStorage.getItem('gameId') != gameId && gameId != 0) {
					localStorage.setItem('gameId',gameId);
				} 
				
				var tId = localStorage.getItem('teamId')?localStorage.getItem('teamId'):0;
				if (tId !=teamId ) {
					localStorage.setItem('teamId',teamId);
					if (teamId) {
						if (roomId) {
							localStorage.setItem('roomId',roomId);
							this.$store.commit("torcChange");
							this.$store.commit("rcChange");
						} else {
							this.$store.commit("totmChange");
							this.$store.commit("tmChange");
						}
					} else {
						this.$store.commit("totmChange");
						this.$store.commit("tmChange");
					}
				}
				
				var rId = localStorage.getItem('roomId')?localStorage.getItem('roomId'):0;
				if(rId != roomId) {
					localStorage.setItem('roomId',roomId);
					
					if (roomId) {
						this.$store.commit("torcChange");
						this.$store.commit("rcChange");
					} else {
						this.$store.commit("totmChange");
					}
				}
			},
		}
	}
</script>

<style lang="less" scoped="scoped">
	.teamtan{
		position: absolute;
		right: 0;
		top: 70px;
		width: 15%;
		overflow: hidden;
		z-index: 15;
	}
	.el-col {
		background: rgba(22,22,22,.8);
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
		color: #fff;
		text-align: left;
		box-sizing: border-box;
		padding: 0 2rem;
		margin-bottom: .5rem;
		.btn {
			    margin-top: .3rem;
		}
		.btn1{
		    border: none;
		    background: #07b43c;
		    color: #fff;
		    border-radius: 2px;
		    font-size: .8rem;
		    padding: .15rem .5rem;
		}
		
		.btn2{
		    border: none;
		    background: #ff5602;
		    color: #fff;
		    border-radius: 2px;
		    font-size: .8rem;
		    padding: .15rem .5rem;
		}
	}
</style>
