<template>
  <div class="md">
    <el-input
      v-if="isJoin"
      v-model="inputPlayer"
      placeholder="输入战队信息"
      class="input-search"
    >
      <template #append>
        <el-button @click="clickSearchPlayer"
          ><el-icon><search /></el-icon
        ></el-button>
      </template>
    </el-input>
  </div>
  <div class="mt-table">
    <el-table
      :cell-style="{ color: '#fff' }"
      :header-cell-style="{ color: '#fff' }"
      :data="playerlist"
    >
      <el-table-column prop="logoUrl" align="center" label="战队logo">
        <template #default="scope">
          <el-image
            class="user-img"
            :src="scope.row.logoUrl ? scope.row.logoUrl : img1"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="teamName" align="center" label="战队名称">
        <template #default="scope">
          {{ scope.row.name ? scope.row.name : "" }}
        </template>
      </el-table-column>
      <el-table-column prop="Score" align="center" label="战队积分">
        <template #default="scope">
          {{ scope.row.totalScore > 0 ? scope.row.totalScore : "0" }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作">
        <template #default="scope">
          <el-button
            type="primary"
            v-if="isJoin"
            @click="clickJoinMember(scope.row.id)"
          >
            添加战队
          </el-button>
          <el-button
            type="primary"
            v-if="!isJoin"
            @click="clickDelMember(scope.row.id)"
          >
            删除战队
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { cPostParam, cGet, cPost } from "@/request/http-util.js";
import { ElMessage, ElMessageBox } from "element-plus";
import { Search } from "@element-plus/icons";

export default {
  name: "LeagueMemberDel",
  props: {
    isJoin: Boolean,
    teamMemberList: [],
  },
  components: {
    Search,
  },
  data() {
    return {
      playerlist: this.teamMemberList,
      inputPlayer: "",
    };
  },
  computed() {
    //   userMemberList(){
    //       return this.userList;
    //   },
    // {mactchID:i, startTime:startDate, endTime:endDate, interval:this.intervalvalue, onceTime:this.oncetimevalue};
  },
  created() {
    // this.labelO = this.textArray[0];
    //     this.labelT = this.textArray[1];
  },
  methods: {
    clickSearchPlayer() {
      // if (this.inputPlayer) {
      var userInfo = JSON.parse(localStorage.getItem("userInfo"));
      var par = {
        key: this.inputPlayer,
        pageNum: 0,
        pageSize: 1000,
        currentMemberId: userInfo.id,
      };
      var getURL = "app/gameteam/manager/queryTeam";
      var __this = this;
      cGet(getURL, par, true, false).then((res) => {
        if (res.code == 10000) {
          __this.playerlist = res.result;
        } else {
          ElMessage(res.message);
        }
      });
      // } else {
      //   this.playerlist = [];
      // }
    },
    clickJoinMember(val) {
      var userInfo = JSON.parse(localStorage.getItem("userInfo"));
      var leagueid = localStorage.getItem("leagueid");
      var par = {
        leagueId: leagueid,
        fromId: userInfo.id,
        toTeamId: val,
        type: 1,
      };

      var getURL = "app/gameteam/manager/sendLeagueTeamRequest";
      cPost(getURL, par, true, false).then((res) => {
        ElMessage(res.message);
        if (res.code == 10000) {
          this.updateBattleInfo();
        }
      });
    },
    clickDelMember(val) {
      ElMessageBox.confirm("您确定要删除该战队吗?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var userInfo = JSON.parse(localStorage.getItem("userInfo"));
          var leagueid = localStorage.getItem("leagueid");
          var data = {
            teamId: val,
            leagueId: leagueid,
          };
          var par = {
            manageMemberId: userInfo.id,
          };

          var getURL = "app/gameteam/manager/deleteLeagueTeam";
          cPostParam(getURL, par, data, true, false).then((res) => {
            ElMessage(res.message);
            if (res.code == 10000) {
              this.updateBattleInfo();
            }
          });
        })
        .catch(() => {});
    },
    updateBattleInfo() {
      var leagueID = localStorage.getItem("leagueid");
      var getURL = "app/gameteam/manager/getLeague";
      var par = {
        leagueId: leagueID,
      };
      // this.$router.push({ name: "TeamPlatform" });
      cGet(getURL, par, true, false).then((res) => {
        if (res.code == 10000) {
          if (res.result) {
            localStorage.setItem("leagueInfo", JSON.stringify(res.result));
          }
        } else {
          ElMessage(res.message);
        }
        this.$store.commit("leagueChange");
      });
    },
  },
};
</script>

<style scoped="scoped" lang="less">
.md {
  .el-input-group__append button.el-button {
    background-color: #07b43c;
  }
  //     ::v-deep .el-input-group__append button.el-button {
  //     background-color: black;
  //   }
}
.mt-table {
  height: 60vh;
  width: 100%;
  position: relative;
  ::v-deep .el-range-input {
    background: black;
    color: white;
  }
}

/*最外层透明*/
::v-deep .el-table,
::v-deep .el-table__expanded-cell {
  background-color: transparent !important;
}
/* 表格内背景颜色 */
::v-deep .el-table th,
::v-deep .el-table tr,
::v-deep .el-table td {
  background-color: transparent !important;
  // border: 0px;
}

.el-table::before {
  height: 0px;
}
.el-table--border::after {
  width: 0px;
}
.el-table--border {
  border: none;
}

.user-img {
  width: 4em;
  height: 4em;
  border-radius: 50%;
}

.el-button--primary {
  //需要更改的按钮类型
  background: #07b43c !important;
  border-color: #07b43c !important;
}
//移入时按钮样式
.el-button--primary:hover {
  background: #07b43c !important;
  border-color: #07b43c !important;
  color: #fff !important;
}
</style>
