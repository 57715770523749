<template>
	<div class="gamelist" 
	element-loading-background="rgba(0, 0, 0, 0.8)"
	v-loading="loading">
		<div class="gamelist-c">
			<div class="g-top">
				队伍列表
			</div>
			<div class="g-con">
				<div class="g-c-top">
					<button @click="btChange" :class="isShow == 1?'ctop-button':''">普通模式</button>
					<button @click="btChange" :class="isShow == 2?'ctop-button':''">竞技模式</button>
				</div>
				<div class="g-c-con">
					<el-row>
						<el-col v-for="(item,index) in mber" class="pl0" :span="12">
							<div class="g-con-list">
								<div class="gcon_top">
									队伍{{index+1}}
									<span class="fwq">{{SERVE[(item.dymod.serve?item.dymod.serve:0)]}}</span> <span class="ms">{{NORM[(item.dymod.norm?item.dymod.norm:0)]}}</span>
									<span v-if="isShow == 2" class="fwq">{{LEVEL[(item.dymod.lv?item.dymod.lv:0)]}}</span>
									<el-tooltip
									       class="box-item"
									       effect="dark"
										   v-if="item.note"
									       :content="item.note"
									       placement="top"
									     >
									      <img class="gth" src="../../../static/icon-1.png" />
									     </el-tooltip>
								</div>
								<div class="gcon_con">
									<div v-for="n in item.maxNum" @click="rdBtn(item.rylist[0].teamid)" class="gcon_con-l">
										<img :src="typeof(item.rylist[(n-1)]) != 'undefined'?(item.rylist[(n-1)]['avatar']?item.rylist[(n-1)]['avatar']:imghead):img2" />
										<div class="text onesl">{{typeof(item.rylist[(n-1)]) != 'undefined'?item.rylist[(n-1)]['pubgName']:"暂无"}}</div>
									</div>
								</div>
							</div>
						</el-col>
					</el-row>
				</div>
			</div>
		</div>
		<div class="g-bot">
			<button v-if="teamId <= 0" @click="sjBtn">
				<img src="../../../static/gamedetail-4.png" />
				创建队伍
			</button>
			<button v-if="teamId > 0">
				我的队伍
			</button>
		</div>
	</div>
</template>

<script>
	import { beforeUnmount } from 'vue'
	import {cPost,cGet} from "@/request/http-util.js";
	import Com from "@/request/common.js";
	import { ElMessage } from 'element-plus'
	import imghead from '../../../static/head.jpg'
	import img1 from '../../../static/teamlist-1.png'
	import img2 from '../../../static/teamlist-2.png'
	export default{
		name:"Gamelist",
		props:{
			gameId:Number
		},
		data() {
			return {
				loading:false,
				imghead:imghead,
				img1:img1,
				img2:img2,
				teamId:0,
				isShow:0,
				
				hallList:[],
				friendList:[],
				mber:[],
				NORM:Com.NORM,
				SERVE:Com.SERVE,
				LEVEL:Com.LEVEL,
				
				userInfo:{}
			}
		},
		computed:{
			isLeft() {
				return this.$store.state.isLeft;
			},
			md(){
				return this.$store.state.Model;
			}
		},
		created() {
			this.init();
		},
		beforeUnmount() {
			clearInterval(window.timer1);
		},
		methods:{
			init() {
				this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
				this.teamId = localStorage.getItem("teamId")?localStorage.getItem("teamId"):0;
				this.fw = this.isLeft != 1?"":"_dy";
				this.isShow = localStorage.getItem("md")?localStorage.getItem("md"):1;
				
				this.getTeamList(1);
				clearInterval(window.timer1);
				window.timer1 = setInterval(()=>{
					this.getTeamList()
				},5000);
			},
			//列表切换
			btChange() {
				this.isShow = this.isShow == 1?2:1;
				localStorage.setItem("md",this.isShow);
				
				this.getTeamList(1);
			},
			//获取
			getTeamList(num = 0) {
				if(num == 1)this.loading = true;
				var par = {
					id :this.userInfo.id,
					mode : this.isShow - 1,
					gameId:localStorage.getItem("gameId")
				}
				var _this = this;
				cGet("teamlist_dy",par,this.loading,false).then(res => {
					if(num == 1)this.loading = false;
					if(res.code == 1) {
						this.mber = res.data.list;
					}
				})
			},
			rdBtn(teamId) {
				//退出队伍
				if (this.teamId) {
					ElMessage("您已有小队");
					return ;
				}
				cGet("jointoteamdy",{teamid:teamId}).then(res => {
					if (res.code == 1){
						localStorage.setItem("teamId",teamId);
						this.$store.commit("tmChange");
					}
				})
				
			},
			//随机加入小队
			sjBtn(){
				this.$router.push({name:'TeamAdd'});
			}
		}
	}
</script>

<style scoped="scoped" lang="less">
	.gamelist {
		width: 100%;
		height: 100%;
		position: relative;
	}
	
	.gamelist-c {
		height: calc(100% - 5rem);
		margin: 0 auto;
		background-color: rgba(32, 32, 32, 0.6);
		border-radius: 5px;
		border:1px solid #676666;
		box-sizing: border-box;
		position: relative;
	}
	
	.g-top {
		text-align: left;
		color: #f4f4f4;
		font-size: 1.1rem;
		background-image: linear-gradient( #161515, #2b2a2a);
		height: 3.2rem;
		line-height: 3.2rem;
		box-sizing: border-box;
		padding-left: 1.5rem;
	}
	
	
	
	.g-bot {
		width: 100%;
		left: 0;
		position: absolute;
		bottom: 0;
		button {
			background: #2f2f2f;
			border: 1px solid #676666;
			width: 100%;
			height: 2.7rem;
			line-height: 2.7rem;
			color: #efefef;
			letter-spacing: 2px;
			border-radius: 5px;
			cursor: pointer;
			
			img {
				position: relative;
				top: .4rem;
				height: 1.4rem;
			}
		}
	}
	
	.g-con {
		height: 85%;
		overflow-y: auto;
		position: relative;
		padding: 0 2rem;
		margin-top: 2rem;
		
		.g-c-top {
			text-align: left;
			border-bottom:1px solid #808080;
			button {
				background: #1f1e1f;
				color: #fff;
				padding: .4rem 1.2rem;
				border-left: 1px solid #808080;
				border-top: 1px solid #808080;
				border-right: 1px solid #808080;
				border-bottom: none;
				border-top-left-radius: 5px;
				border-top-right-radius: 5px;
				margin-right: 10px;
			}
			.ctop-button {
				background: #07b43c;
			}
		}
		.pl0 {padding-left: 0 !important;}
		.pr0 {padding-right: 0 !important;}
		.g-c-con {
			width: 100%;
			
			.el-col {
				box-sizing: border-box;
				padding: 5%;
				.g-con-list {
						border: 1px solid #363637;
					    border-radius: 20px;
						overflow: hidden;
					.gcon_top {
						height: 2.4rem;
						background: #161617;
						line-height: 2.4rem;
						color: #fff;
						overflow: hidden;
						.active {
							color:#07b43c;
							overflow: hidden;
						}
					}
					.gcon_con {
						width: 100%;
						background: #222224;
						overflow: hidden;
						.gcon_con-l {
							width: 50%;
							float: left;
							box-sizing: border-box;
							padding: 5%;
							img {width: 90%;margin-left: 5%;max-width: 3.6rem;max-height: 3.6rem;border-radius: 50%;}
						}
						.text {
							width: 100%;
							color: #fff;
							height: 1.2rem;
							line-height: 1.2rem;
							font-size: .6rem;
						}
					}
				}
			}
		} 
		
	}
	
	.fwq {
		    font-size: .8rem;
		    color: rosybrown;
		    font-weight: 600;
		    margin-left: 2%;
		    background: #333;
		    padding: 3px 5px;
		    border-radius: 5px;
	}
	.ms {
		    font-size: .8rem;
		    color: beige;
		    font-weight: 600;
		    margin-left: 2%;
		    background: #333;
		    padding: 3px 5px;
		    border-radius: 5px;
	}
	.gth {
		margin: 0 5px;
		top: 6px;
		position: relative;
	}
</style>
