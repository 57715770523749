import { cGet } from "@/request/http-util.js"

//个人信息
export function UI(){ 
    return new Promise((resolve, reject) => {
		cGet("GetInfo",{gameId:localStorage.getItem('gameId')},false,false).then(res => {
			if (res.code == 1) {
				res.data.me.lv = res.data.playInfo.lv;
				res.data.me.ser = res.data.playInfo.ser;
				res.data.me.tpporfpp = res.data.playInfo.tpporfpp;
				res.data.me.playCount = res.data.playInfo.playCount;
				localStorage.setItem("userInfo",JSON.stringify(res.data.me));
				resolve(res.data.me);
			} else {
				reject({});
			}
		})
	})
}
//人物初始化
export function reSet(){ 
    return new Promise((resolve, reject) => {
		cGet("clearmyroom",{},false,false).then(res => {
			if (res.code == 1) {
				clearInterval(window.timer);
				clearInterval(window.timer1);
				
				localStorage.removeItem("roomId");
				localStorage.removeItem("teamId");
				//localStorage.removeItem("gameId");
				resolve({});
			} else {
				resolve({});
			}
		})
	})
}

export default {
    reSet,
	UI
}
