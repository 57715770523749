<template>
  <div
    class="gamelist"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    v-loading="loading"
  >
    <div class="gamelist-c">
      <div class="g-top">联盟管理</div>
      <div class="t-con">
        <div class="g-c-top">
          <button
            @click="btChange(1)"
            :class="isShow == 1 ? 'ctop-button' : ''"
          >
            战队管理
          </button>
          <button
            @click="btChange(2)"
            :class="isShow == 2 ? 'ctop-button' : ''"
          >
            联盟快递
          </button>
          <button
            @click="btChange(3)"
            :class="isShow == 3 ? 'ctop-button' : ''"
          >
            联盟管理
          </button>
        </div>

        <div v-if="isShow == 1" class="t-c-con">
             <div class="l-con-list" v-for="(item, index) in battlelist"
            :key="index">
      
                    <div>
              <BattleTeam :matchInfo="item[0]"></BattleTeam>
            </div>
            <div v-if="item.length == 5 || item.length == 4 || item.length == 3 || item.length == 2">
              <BattleTeam :matchInfo="item[1]"></BattleTeam>
            </div>
            <div v-if="item.length == 5 || item.length == 4 || item.length == 3">
              <BattleTeam :matchInfo="item[2]"></BattleTeam>
            </div>
             <div v-if="item.length == 5 || item.length == 4">
              <BattleTeam :matchInfo="item[3]"></BattleTeam>
            </div>
             <div v-if="item.length == 5">
              <BattleTeam :matchInfo="item[4]"></BattleTeam>
            </div>
            
          </div>
        </div>
        <div v-if="isShow == 1" calss="t-bot">
          <el-row>
            <el-col :span="11">
              <button class="tbutton" v-on:click="clickAddMember" v-if="showManager">
                <img src="../../../static/gamedetail-4.png" />
                添加战队
              </button>
            </el-col>
            <el-col :offset="2" :span="11">
              <button class="tbutton" v-on:click="clickDeleteMember" v-if="showManager">
                <img />
                删除战队
              </button>
            </el-col>
          </el-row>
        </div>

        <div v-if="isShow == 2" class="t-message-con">
          <div class="t-message">
            <el-table
              class="t-message-table"
              height="clac(100%)"
              :data="messagedata"
              :show-header="false"
              size="mini"
              :cell-style="{ color: '#fff' }"
              :header-cell-style="{ color: '#fff' }"
            >
              <el-table-column prop="teamTaskContent" label="消息"> </el-table-column>
              <el-table-column prop="createTime" label="时间"> </el-table-column>
            </el-table>
          </div>
          <div class="t-message">
            <el-table
              class="t-message-table"
              height="clac(100%)"
              :data="messagereaddata"
              :show-header="false"
              :cell-style="{ color: '#fff' }"
              :header-cell-style="{ color: '#fff' }"
            >
              <el-table-column label="消息">
                <template v-slot="scope">
                  <el-image
                    class="t-message-img"
                    :src="scope.row.fromTeamAvatar"
                  ></el-image>
                  <div class="t-message-div">
                    {{ scope.row.fromTeamName }}申请加入联盟
                  </div>
                </template>
              </el-table-column>
              <el-table-column>
                <template v-slot="scope">
                  <el-button
                    size="mini"
                    @click="clickTaskRequest(scope.row, 0)"
                    >{{ scope.row.status != 1 ? "已读" : "拒绝" }}</el-button
                  >
                  <el-button
                    size="mini"
                    type="primary"
                    @click="clickTaskRequest(scope.row, 1)"
                    >{{
                      scope.row.status == 1
                        ? "接受"
                        : scope.row.status == 2
                        ? "已接受"
                        : "已拒绝"
                    }}</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <el-button size="mini" type="primary" class="t-message-b"  @click="clickDeleteTaskRequest"
              >清空已读</el-button
            >
          </div>
        </div>
         <div v-if="isShow == 3" class="t-message-con">
          <el-row :gutter="20" style="margin-top: 2%">
            <span class="create-span">联盟名称：</span>
            <el-input
              v-model="leagueName"
              placeholder=""
              class="create-input"
            />
          </el-row>
          <el-row :gutter="20" style="margin-top: 2%">
            <span class="create-span">联盟描述：</span>
            <el-input
              v-model="leagueDes"
              :autosize="{ minRows: 2, maxRows: 4 }"
              type="textarea"
              placeholder=""
              class="create-input"
            />
          </el-row>
          <el-row :gutter="20" style="margin-top: 2%">
            <el-upload
              class="create-btn" 
              list-type="picture"
              :action="uploadLogoUrl"
              :limit="1"
              :on-exceed="handleExceed"
              :on-success="uploadSuccess"
              :file-list="fileList"
              :before-upload="beforeAvatarUpload"
            >
              <el-button>请上传联盟Logo</el-button>
            </el-upload>
          </el-row>
          <el-row :gutter="20" style="margin-top: 2%">
            <el-button class="create-btn" type="primary" @click="clickChange"
              >修改信息</el-button
            >
          </el-row>
          <el-row :gutter="20" style="margin-top: 2%">
            <el-button class="create-btn" type="primary" @click="clickQuit"
              >战队退出</el-button
            >
          </el-row>
          <el-row :gutter="20" style="margin-top: 2%">
            <el-button class="create-btn" type="danger" @click="clickDelete"
              >解散联盟</el-button
            >
          </el-row>
        </div>
      </div>
    </div>
  </div>
    <el-dialog v-model="dialogDeleteVisible" title="删除战队">
    <LeagueMemberDel :isJoin="false" :teamMemberList="teamMemberList"></LeagueMemberDel>
  </el-dialog>
  <el-dialog v-model="dialogAddVisible" title="添加战队">
    <LeagueMemberDel :isJoin="true" :teamMemberList="teamMemberList"></LeagueMemberDel>
  </el-dialog>
</template>

<script>
import imghead from "../../../static/head.jpg";
import img1 from "../../../static/teamlist-1.png";
import img2 from "../../../static/teamlist-2.png";
import BattleTeam from "@/components/matchplatform/BattleTeam.vue";
import { cPost, cGet, cPostParam } from "@/request/http-util.js";
import { ElMessage, ElMessageBox } from "element-plus";
import LeagueMemberDel from "@/components/leagueplatform/LeagueMemberDel.vue";
import Com from "@/request/http-util.js";
export default {
  name: "LeagueManager",
  components: {
    BattleTeam,
    LeagueMemberDel,
  },
  props: {
    leagueInfo: {},
  },
  data() {
    return {
      dialogDeleteVisible: false,
      dialogAddVisible: false,
      isShow: 1,
      loading: false,
      teamId: 0,
      imghead: imghead,
      img1: img1,
      img2: img2,
      //   gameId: 0,
      img: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      messagedata: [],
      messagereaddata: [],
      battlelist:[],
      userlist: [],
      mber: [],
      userInfo: {},
      logoID: "",
      leagueDetail: this.leagueInfo,
      leagueid: "",
      leagueName: "",
      leagueDes: "",
      uploadLogoUrl:"",
      showManager:false,
      teamMemberList:[],
    };
  },
  created() {
    this.init();
  },
  beforeUnmount() {
    // clearInterval(window.timer1);
  },
  computed: {
    isFollow() {
      return this.$store.state.isChangeLeague;
    },
  },
  watch: {
    isFollow(newVal, oldVal) {
      this.leagueDetail = JSON.parse(localStorage.getItem("leagueInfo"));
      window.location.reload();
    },
  },
  methods: {
    init() {
  
      var userInfo = JSON.parse(localStorage.getItem("userInfo"));
      
      this.uploadLogoUrl = Com.baseURL +"/game/v1.0/app/gameteam/manager/uploadLogo?manageMemberId=" + userInfo.id + "&type=2";

      if(this.leagueDetail) {
        if( userInfo.id == this.leagueDetail.createMemberId) {
        this.showManager = true;
      }
      else {
        this.showManager = false;
      }
      if(this.leagueDetail.teamVoLst && this.leagueDetail.teamVoLst.length > 0)
      {
for (var i = 0; i < this.leagueDetail.teamVoLst.length; i++) {
        if (i + 1 > 4 && (i + 1) % 5 == 0) {
          var tmplist = [
             this.leagueDetail.teamVoLst[i - 4],
            this.leagueDetail.teamVoLst[i - 3],
            this.leagueDetail.teamVoLst[i - 2],
            this.leagueDetail.teamVoLst[i - 1],
            this.leagueDetail.teamVoLst[i],
          ];
          this.battlelist.push(tmplist);
        }
      }

      if (this.leagueDetail.teamVoLst.length % 5 == 1) {
        var tmplist2 = [
          this.leagueDetail.teamVoLst[this.leagueDetail.teamVoLst.length - 1],
        ];
        this.battlelist.push(tmplist2);
      } else if (this.leagueDetail.teamVoLst.length % 5 == 2) {
        var tmplist3 = [
          this.leagueDetail.teamVoLst[this.leagueDetail.teamVoLst.length - 2],
          this.leagueDetail.teamVoLst[this.leagueDetail.teamVoLst.length - 1],
        ];
        this.battlelist.push(tmplist3);
      }
      else if (this.leagueDetail.teamVoLst.length % 5 == 3) {
        var tmplist4 = [
          this.leagueDetail.teamVoLst[this.leagueDetail.teamVoLst.length - 3],
          this.leagueDetail.teamVoLst[this.leagueDetail.teamVoLst.length - 2],
          this.leagueDetail.teamVoLst[this.leagueDetail.teamVoLst.length - 1],
        ];
        this.battlelist.push(tmplist4);
      }
      else if (this.leagueDetail.teamVoLst.length % 5 == 4) {
        var tmplist5 = [
          this.leagueDetail.teamVoLst[this.leagueDetail.teamVoLst.length - 4],
          this.leagueDetail.teamVoLst[this.leagueDetail.teamVoLst.length - 3],
          this.leagueDetail.teamVoLst[this.leagueDetail.teamVoLst.length - 2],
          this.leagueDetail.teamVoLst[this.leagueDetail.teamVoLst.length - 1],
        ];
        this.battlelist.push(tmplist5);
      }
      }
      }
      

    //   var getLURL = "app/gameteam/manager/getLeaguesByMember";
    //   var par = {
    //   pageNum: "0",
    //   pageSize: "1000",
    //   memberId:userInfo.id,
    // };
    //   cGet(getLURL, par, true, false).then((res) => {
    //   if (res.code == 10000) {
    //     if (res.result && res.result.length > 0) {
    //       var leagueInfo = res.result[0];
    //       localStorage.setItem("leaguename", leagueInfo.name);
    //       localStorage.setItem("leagueid", leagueInfo.id);
    //       localStorage.setItem("leaguelogo", leagueInfo.logoUrl);

    //       if( userInfo.id == leagueInfo.createMemberId) {
    //         this.showManager = true;
    //       }
    //       else {
    //         this.showManager = false;
    //       }

    //       this.leagueName = leagueInfo.name;
    //       this.leagueDes = leagueInfo.descriptionInfo;
    //       this.leagueid = leagueInfo.id;

         
    //     }
    //   }
    //   });

      
    },
    //列表切换
    btChange(num) {
      this.isShow = num;
      if(num == 2) {
        this.clickGetLeagueTask();
        this.clickGetTaskRequest();
      } 
    },
    commandValue(type, command) {
      return {
        'type': type,
        'command': command
      }
    },
    dropdownClick(item) {
const { type, command } = item
      if (type === 'baoming') {
        alert(1 + command);
      } else if (type === 'quxiaobao') {
        alert(2 + command);
      } else if (type === 'guanzhu') {
        alert(3 + command);
      }
    },
    clickChange() {
      var userInfo = JSON.parse(localStorage.getItem("userInfo"));
      var data = this.logoID.length > 0 ? {
        descriptionInfo: this.leagueDes,
        name: this.leagueName,
        logoUrl: Com.baseURL + "/game/v1.0/app/gameteam/manager" + this.logoID,
        leagueId: this.leagueid,
      } : {
        descriptionInfo: this.leagueDes,
        name: this.leagueName,
        leagueId: this.leagueid,
      };
      var par = {
        manageMemberId: userInfo.id,
      };
      var getURL = "app/gameteam/manager/updateLeagueInfo";
      cPostParam(getURL, par, data, true, false).then((res) => {
        ElMessage(res.message);
        if (res.code == 10000) {
          if (res.result) {
            var battleInfo = res.result;
            localStorage.setItem("leaguename", battleInfo.name);
            localStorage.setItem("leagueid", battleInfo.teamid);
            this.$store.commit("battleChange", 1);
          }
          else {
            localStorage.setItem("leaguename", this.leagueName);
            localStorage.setItem("leagueid", this.leagueid);
            localStorage.setItem("leaguelogo", Com.baseURL + "/game/v1.0/app/gameteam/manager" + this.logoID);
            this.$store.commit("battleChange", 1);
          }
        }
      });
    },
    clickDelete() {
      ElMessageBox.confirm("您确定要解散联盟吗?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var userInfo = JSON.parse(localStorage.getItem("userInfo"));
          var par = {
            leagueId: this.leagueid,
            managerId: userInfo.id,
          };
          var getURL = "app/gameteam/manager/deleteLeague";
          cGet(getURL, par, true, false).then((res) => {
            ElMessage(res.message);
            if (res.code == 10000) {
              localStorage.removeItem("leaguename");
              localStorage.removeItem("leagueid");
              this.$router.push({ name: "Game" });
            }
          });
        })
        .catch(() => {});
    },
    clickQuit() {
ElMessageBox.confirm("您确定要退出联盟吗?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var teamId = localStorage.getItem("battleid");
          var userInfo = JSON.parse(localStorage.getItem("userInfo"));
          var data = {
            leagueId: this.leagueid,
            teamId: teamId,
          };
          var par = {
             manageMemberId: userInfo.id
          }
          var getURL = "app/gameteam/manager/quitLeague";
          cPostParam(getURL, par, data, true, false).then((res) => {
            ElMessage(res.message);
            if (res.code == 10000) {
              localStorage.removeItem("leaguename");
              localStorage.removeItem("leagueid");
              this.$router.push({ name: "Game" });
            }
          });
        })
        .catch(() => {});
    },
    clickDeleteMember() {
      this.dialogDeleteVisible = true;
      this.teamMemberList = this.leagueDetail.teamVoLst;

    },
    clickAddMember() {
      this.dialogAddVisible = true;
      this.teamMemberList = [];
    },
    clickGetLeagueTask() {
      var par = {
        leagueId: this.leagueid,
        pageNum: 0,
        pageSize: 1000,
      };
      var getURL = "app/gameteam/manager/queryLeagueTasks";
      cGet(getURL, par, true, false).then((res) => {
        if (res.code == 10000) {
          this.messagedata = res.result;
        } else {
          ElMessage(res.message);
        }
      });
    },
    clickGetTaskRequest() {
      var userInfo = JSON.parse(localStorage.getItem("userInfo"));
      var par = {
        leagueId: this.leagueid,
        pageNum: 0,
        pageSize: 1000,
        manageMemberId: userInfo.id,
      };
      var getURL = "app/gameteam/manager/getLeagueRequests";
      cGet(getURL, par, true, false).then((res) => {
        if (res.code == 10000) {
          this.messagereaddata = res.result;
        } else {
          ElMessage(res.message);
        }
      });
    },
    clickTaskRequest(val, flag) {
      if (flag == 0) {
        if (val.status != 1) {
          return;
        }
      } else {
        if (val.status != 1) {
          return;
        }
      }
      var userInfo = JSON.parse(localStorage.getItem("userInfo"));
      var par = {
        managerId:userInfo.id,
        messageId: val.id,
        flg: flag,
      };
      var data = {};
      var getURL = "app/gameteam/manager/doJoinLeagueRequest";
      cPostParam(getURL, par, data, true, false).then((res) => {
        if (res.code == 10000) {
          this.clickGetTaskRequest();
          this.clickGetLeagueTask();
        } else {
          ElMessage(res.message);
        }
      });
    },
    clickDeleteTaskRequest() {
      var userInfo = JSON.parse(localStorage.getItem("userInfo"));
      var par = {
        leagueId: this.leagueid,
        manageMemberId: userInfo.id,
      };
      var getURL = "app/gameteam/manager/deleteAllDoneLeagueRequest";
      cGet(getURL, par, true, false).then((res) => {
        if (res.code == 10000) {
          this.clickGetTaskRequest();
          this.clickGetTeamTask();
        } else {
          ElMessage(res.message);
        }
      });
    },
    beforeAvatarUpload(rawFile) {
      if (
        rawFile.type !== "image/jpeg" &&
        rawFile.type !== "image/jpg" &&
        rawFile.type !== "image/png" &&
        rawFile.type !== "image/glf"
      ) {
        ElMessage.error("请选择正确的图片格式!");
        return false;
      } else if (rawFile.size / 1024 / 1024 > 10) {
        ElMessage.error("图片不能超过10MB!");
        return false;
      }
      return true;
    },
    uploadSuccess(response, uploadFile, uploadFiles) {
      if (response.code == 10000) {
        this.logoID = response.result.url;
      }
    },
  },
};
</script>

<style scoped="scoped" lang="less">
.gamelist {
  width: 100%;
  height: 100%;
  position: relative;
}

.gamelist-c {
  height: calc(100%);
  margin: 0 auto;
  background-color: rgba(32, 32, 32, 0.6);
  border-radius: 5px;
  border: 1px solid #676666;
  box-sizing: border-box;
  position: relative;
}

.g-top {
  text-align: left;
  color: #f4f4f4;
  font-size: 1.1rem;
  background-image: linear-gradient(#161515, #2b2a2a);
  height: 3.2rem;
  line-height: 3.2rem;
  box-sizing: border-box;
  padding-left: 1.5rem;
}

.t-bot {
  width: 100%;
  left: 0;

  position: absolute;
  bottom: 0;
}
.tbutton {
  margin-top: 1em;
  background: #2f2f2f;
  border: 1px solid #676666;
  width: 100%;
  height: 2.7rem;
  line-height: 2.7rem;
  color: #efefef;
  letter-spacing: 2px;
  border-radius: 5px;
  cursor: pointer;

  img {
    position: relative;
    top: 0.4rem;
    height: 1.4rem;
  }
}
.t-con {
  padding: 0 0.6rem;
  margin-top: 2rem;
  height: 80%;
  .g-c-top {
    text-align: left;
    border-bottom: 1px solid #808080;
    button {
      background: #1f1e1f;
      color: #fff;
      padding: 0.4rem 1.2rem;
      border-left: 1px solid #808080;
      border-top: 1px solid #808080;
      border-right: 1px solid #808080;
      border-bottom: none;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      margin-right: 10px;
    }
    .ctop-button {
      background: #07b43c;
    }
  }

  .pl0 {
    padding-left: 0 !important;
  }
  .pr0 {
    padding-right: 0 !important;
  }
  .t-c-con {
    height: 90%;
    overflow-y: auto;
    position: relative;

    .el-col {
      box-sizing: border-box;
      padding: 5%;
      .g-con-list1 {
        border: 1px solid #363637;
        border-radius: 20px;
        overflow: hidden;
        .gcon_top {
          height: 2.4rem;
          background: #161617;
          line-height: 2.4rem;
          color: #fff;
          .active {
            color: #07b43c;
            overflow: hidden;
          }
        }
        .gcon_con {
          width: 100%;
          background: #222224;
          overflow: hidden;
          .gcon_con-l {
            width: 50%;
            float: left;
            box-sizing: border-box;
            padding: 5%;
            img {
              width: 90%;
              margin-left: 5%;
              width: 3.6rem;
              height: 3.6rem;
              border-radius: 50%;
            }
          }
          .text {
            width: 100%;
            color: #fff;
            height: 1.2rem;
            line-height: 1.2rem;
            font-size: 0.6rem;
          }
        }
      }
    }
  }

  .l-con-list {
    width: 100%;
    position: relative;
    // border-radius: 5px;
    overflow: hidden;
    margin-bottom: 2rem;
    height: 7vw;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    .bgimg {
      width: 100%;
      height: 100%;
    }
    .p11 {
      color: #fff;
      position: absolute;
      left: 12%;
      top: 30%;
      margin-top: -13.5px;
      font-size: 1.3rem;
      width: 85%;
      text-align: left;
    }
    .p12 {
      color: #fff;
      position: absolute;
      left: 12%;
      top: 60%;
      margin-top: -13.5px;
      font-size: 1.3rem;
      width: 85%;
      text-align: left;
    }
    .zz {
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: rgba(32, 32, 32, 0.3);
      top: 0;
    }
    button {
      background: none;
      border: none;
      padding: 0.25rem 1rem;
      color: #fff;
      letter-spacing: 2px;
      border-radius: 5px;
      cursor: pointer;
      position: absolute;
      right: 12%;
      top: 50%;
      font-size: 1.2rem;
      margin-top: -15.5px;
      span {
        color: #07b43c;
      }
    }
  }

  .g-con-list div {
    width: 100%;
    height: 55em;
  }

  .t-message-con {
    height: 100%;
    // overflow-y: auto;
    position: relative;
  }
  .t-message {
    margin-top: 1em;
    margin-left: 1em;
    margin-right: 1em;
    height: 45%;
    // background: white;
    .t-message-b {
      margin-top: 0.5em;
      margin-left: 19em;
    }
  }

  .t-message-table {
    margin: 0%;
    width: 100%;
    height: 100%;
  }

  /*最外层透明*/
  ::v-deep .el-table,
  ::v-deep .el-table__expanded-cell {
    background-color: transparent !important;
  }
  /* 表格内背景颜色 */
  ::v-deep .el-table th,
  ::v-deep .el-table tr,
  ::v-deep .el-table td {
    background-color: transparent !important;
    // border: 0px;
  }

  .el-table::before {
    height: 0px;
  }
  .el-table--border::after {
    width: 0px;
  }
  .el-table--border {
    border: none;
  }

  .t-message-img {
    width: 2em;
    height: 1.5em;
  }

  .t-message-div {
    margin-left: 3em;
    margin-top: -2em;
  }

  .t-cash {
    height: 10%;
    width: 100%;
  }
  .t-cash-div {
    font-size: 25px;
    color: white;
  }
  .t-cash-table {
    width: 100%;
    height: 90%;
  }

  .t-match-div {
    width: 100%;
    color: white;
    background: #07b43c;
  }
}

.create-div {
  width: 90%;
  height: 100%;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 1%;
}

.create-span {
  color: white;
  width: 20%;
  margin-top: 1%;
  font-size: 1.1rem;
}

.create-input {
  width: 80%;
}

.create-btn {
  margin-left: 10%;
  margin-right: 10%;
  width: 80%;
}

 .el-button--primary { //需要更改的按钮类型
  background: #07b43c !important;
  border-color: #07b43c !important;
}
//移入时按钮样式
.el-button--primary:hover {
  background: #07b43c !important;
  border-color: #07b43c !important;
  color: #FFF !important;
}
</style>
