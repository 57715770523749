<template>
  <div class="teammatch">
  
<el-image
      :class="isInfo ? 'teammatch-img-info' : 'teammatch-img'"
      :src="matchlogo"
      fit="fill"
    ></el-image>


    
    <div v-if="!isInfo" class="teammatch-text">
      {{ matchtext1 }}<br />{{ matchtext2 }}<br />{{ macthtext3 }}
    </div>
    <TeamCutdown v-if="isInfo" class="teammatch-text" :endTimeProp="matchInfo.gameStartTime"></TeamCutdown>
  </div>
</template>

<script>
import TeamCutdown from "@/components/teamplatform/TeamCutdown.vue";
export default {
  name: "TeamMatch",
  components: {
    TeamCutdown,
  },
  props: {
    matchInfo: {},
    isInfo: Boolean,
  },
  data() {
    return {
      match: this.matchInfo,
      matchtext1: "",
      matchtext2: "",
      macthtext3: "",
      matchlogo: "./image/u22.png",
      //   img: "./image/u22.png",
      //"https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      //   "@assets/image/u22.png"
    };
  },
  computed() {
    //   userMemberList(){
    //       return this.userList;
    //   },
  },
  created() {
    this.matchtext1 = this.match.name;

    if (this.match.gameLogo && this.match.gameLogo.length > 0) {
      this.matchlogo = this.match.gameLogo;
    }
    if (this.match.gameStartTime) {
      var arr = this.match.gameStartTime.split(" ");
      for (var i = 0; i < arr.length; i++) {
        if (i == 0) {
          this.matchtext2 = arr[i];
        } else if (i == 1) {
          this.macthtext3 = arr[i];
        }
      }
    } else if (this.match.gameIndex) {
      this.matchtext2 = "第" + this.match.gameIndex + "名";
    }
  },
  methods: {},
};
</script>

<style scoped="scoped" lang="less">
.teammatch {
  height: 100%;
  width: 100%;
  line-height: 100%;
  vertical-align: middle;
}

.teammatch-img-info {
  display: block;
  margin: 0 auto;
  // margin-top: 0.5rem;
  // margin-left: 3%;
  // margin-right: 3%;
  // margin-bottom: 0.1rem;
  // vertical-align: middle;
  // height: 9em;
  // width: 14em;
}

.teammatch-img {
  display: inline-block;
  margin-top: 0.5rem;
  height: 8em;
  width: 9.6em;
  vertical-align: middle;
}

.teammatch-text {
  margin-top: 0.5rem;
  width: 100%;
  font-size: 16px;
  color: white;
  text-align: center;
}
</style>
