<template>
  <div class="teammatch">
    
      <el-image
        class="teammatch-img"
        :src="matchInfo.logoUrl"
        fit="fill"
      ></el-image>
    
    <div class="teammatch-text">{{matchInfo.name}}</div>
  </div>
</template>

<script>
export default {
  name: "BattleTeam",
  props: {
    matchInfo: {},
  },
  data() {
    return {
      user: this.matchInfo,
    //   img: "./image/u22.png",
      //"https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
    //   "@assets/image/u22.png"
    };
  },
  computed() {
    //   userMemberList(){
    //       return this.userList;
    //   },
  },
  created() {
    // console.log(this.user);
  },
  methods: {},
};
</script>

<style scoped="scoped" lang="less">
.teammatch {
  height: 100%;
  width: 100%;
  position: relative;
}

.teammatch-img {
  // margin-top: 0.5rem;
  //  margin-left: 3%;
  //  margin-right: 3%;
  
  // height: 65%;
//   border-radius: 50%;
display: inline-block;
  margin-top: 0.5rem;
  height: 8.5em;
  width: 7em;
  vertical-align: middle;
}

.teamuser-btn {
  position: absolute;
  top: 5em;
  left: 7em;
  z-index: 10;
}

.teammatch-text {
  width: 100%;
  height: 30%;
  font-size: 12px;
  color: white;
  text-align: center;
}
</style>
