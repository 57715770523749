<template>
	<div class="chat"
	element-loading-background="rgba(0, 0, 0, 0.8)"
	v-loading="loading">
		<div v-if="min || sec" class="zz">
			{{min}}:{{sec}}
		</div>
		<button @click="naToStart" v-if="roominfo.leaderId == userInfo.id" class="ksyx">开始游戏</button>
		<button @click="tcdwBtn" class="tcfj">退出房间</button>
		<div class="chat-c">
			<div class="g-top">
				队伍聊天窗口
			</div>
			<div class="g-con" id="chatContainer">
				<div v-for="(item,index) in chatList" :class="item.id == userInfo.id?'g-r-list':'g-l-list'">
					<img :src="item.avatar?item.avatar:himg" />
					<div :class="item.id == userInfo.id?'gr-con':'gl-con'">
						{{item.msg}}
					</div>
				</div>
			</div>
			<div class="send">
				<img @click="Send" src="../../../static/gamedetail-5.png" />
				<input @keyup.enter="Send" v-model="info" placeholder="发送消息" />
			</div>
		</div>
	</div>
</template>

<script>
	import imghead from '../../../static/head.jpg';
	import {cPost,cGet} from "@/request/http-util.js";
	import { getMinF } from "@/request/common.js";
	import { ElMessage } from 'element-plus'
	export default {
		emits:['countdown'],
		data() {
			return {
				teamId:0,
				roomId:localStorage.getItem("roomId"),
				chatList:[],
				userInfo:{},
				loading:false,
				himg:imghead,
				info:'',
				text:'',
				min:"",
				sec:""
			}
		},
		props:{
			roominfo:Object
		},
		mounted() {
			this.init();
		},
		methods:{
			init() {
				var _this = this;
				_this.chatList = [];
				_this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
				_this.teamId = localStorage.getItem("teamId")?localStorage.getItem("teamId"):0;
						
						
				if(window.ws)window.ws.close();
				var wsImpl = window.WebSocket || window.MozWebSocket;
				
				// create a new websocket and connect
				window.ws = new wsImpl('wss://zapi.nocheater.cn:5000');//192.168.0.17:5000
				// window.ws = new wsImpl('ws://23.224.230.222:5000');	
				this.loading = true;
				// when data is comming from the server, this metod is called
				ws.onmessage = function (evt) {
					_this.loading = false;
					var json = (evt.data.replace("$$","")).replace("$$","");
					var data = JSON.parse(json);
					if (data.act == 'getmsglistok') {
						_this.chatList = data.data;0
					} else if (data.act != 'regok'){
						_this.chatList.push(data);
					}
					
					_this.$nextTick(() => {
					   var container = _this.$el.querySelector("#chatContainer");
					   container.scrollTop = container.scrollHeight;
					})
				};
					
				// when the connection is established, this method is called
				ws.onopen = function () {
					_this.loading = false;
					if (_this.teamId > 0) {
						//注册房间
						ws.send('$${"act":"reg","utoken":"'+localStorage.getItem('token')+'","roomid":'+_this.teamId+'}$$');
						//拉取历史消息
						ws.send('$${"act":"getmsglist"}$$');
					}
				   
				};
					
				// when the connection is closed, this method is called
				ws.onclose = function () {
					_this.loading = false;
					console.log('.. connection closed');
				}
			},
			Send() {
				if (this.teamId > 0) {
					var _this = this;
					if (!this.info) {
						ElMessage("不能发送空消息");
						return false;
					}
					var reg = new RegExp('"',"g");
					_this.info = _this.info.replace(reg,"")
					ws.send('$${"act":"sendmsg","msgcon":"'+_this.info+'"}$$');
					_this.info = '';
				} else {
					ElMessage("请先加入小队");
				}
			},
			
			//退出组队
			tcdwBtn() {
				this.loading = true;
				cGet("outteam",{teamid:this.teamId},true,false).then(res => {
					this.loading = false;
					if (res.code == 1){
						ElMessage("退出成功！");
						clearInterval(window.timer);
						clearInterval(window.timer1);
						
						localStorage.removeItem("roomId");
						localStorage.removeItem("teamId");
						this.$router.push("gamedetail");
					}
				})
			},
			
			//进入游戏
			naToStart () {
				this.loading = true;
				cGet("stargame",{id:this.roomId},true,false).then(res => {
					this.loading = false;
					if (res.code == 1){
						this.$emit('countdown',(new Date()));
					};
				})
			},
			mC(min,sec){
				this.min = min;
				this.sec = sec;
			}
		}
	}
</script>

<style scoped="scoped" lang="less">
	
	.chat {
		width: 100%;
		height: 100%;
		text-align: right;
		position: relative;
		
		.ksyx {
			background-color: #07b43c;
			height: 2.2rem;
			line-height: 2.2rem;
			width: 8rem;
			text-align: center;
			color:#fff;
			border:none;
			border-radius: 2px;
		}
		
		.tcfj {
			background-color: #ff5602;
			height: 2.2rem;
			line-height: 2.2rem;
			width: 8rem;
			text-align: center;
			color:#fff;
			border:none;
			border-radius: 2px;
			margin-left: .2rem;
		}
	}
	
	.chat-c {
		height: calc(100% - 2rem);
		margin: 0 auto;
		background-color: rgba(32, 32, 32, 0.6);
		border-radius: 5px;
		border:1px solid #676666;
		box-sizing: border-box;
		position: relative;
	}
	
	.g-top {
		text-align: left;
		color: #f4f4f4;
		font-size: 1.1rem;
		height: 3.2rem;
		line-height: 3.2rem;
		box-sizing: border-box;
		padding-left: 1.5rem;
	}
	
	.send {
		width: 100%;
		background: #505051;
		position: absolute;
		bottom: 0;
		height: 11%;
		box-sizing: border-box;
		padding-right: 20%;
		
		input {
			height: 57%;
			margin-top: 4%;
			width: 88%;
			background: #151515;
			border: none;
			border-radius: 5px;
			padding-left: 1rem;
			color:#fff;
		}
		img {
			position: absolute;
			left: 88%;
			top: 16%;
			width: 11%;
		}
	}
	
	.g-bot {
		width: 100%;
		left: 0;
		position: absolute;
		bottom: 0;
		button {
			background: #2f2f2f;
			border: 1px solid #676666;
			width: 100%;
			height: 2.7rem;
			line-height: 2.7rem;
			color: #efefef;
			letter-spacing: 2px;
			border-radius: 5px;
			cursor: pointer;
			
			img {
				position: relative;
				top: .4rem;
				height: 1.4rem;
			}
		}
	}
	
	
	.g-con {
		height: 75%;
		overflow-y: auto;
		position: relative;
		padding: 0 2rem;
		margin-top: 7%;

		.g-l-list {
			text-align: left;
			width: 100%;
			position: relative;
			margin: .5rem 0 2rem;
			img {
				width: 2.6rem;
				height: 2.6rem;
				border-radius: 50%;
				position: absolute;
			}
			.gl-con {
				color: #fff;
				line-height: 1.6rem;
				background: #000;
				display: inline-block;
				margin-left: 3.2rem;
				border-radius: 5px;
				padding: .6rem 1rem .6rem .6rem;
			}
		}
		.g-r-list {
			text-align: right;
			width: 100%;
			position: relative;
			margin: .5rem 0 2rem;
			img {
				width: 2.6rem;
				height: 2.6rem;
				border-radius: 50%;
				position: absolute;
				right:0
			}
			.gr-con {
				text-align: left;
				color: #fff;
				line-height: 1.6rem;
				background: #07b43c;
				margin-right: 3.2rem;
				display: inline-block;
				border-radius: 5px;
				padding: .6rem 1rem .6rem .6rem;
			}
		}
	}
	
	.zz {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 180px;
		z-index: 100;
		text-align: center;
		font-size: 80px;
		color: #fff;
		font-weight: 600;
		line-height: 240px;
		opacity: 0.5;
	}
</style>
