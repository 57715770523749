<template>
  <div class="md-table">
  <div class="md-div">
      {{matchInfo.teamName}}-战队-排名{{matchInfo.index}}
  </div>
    <el-table
      :data="matchInfo.teamMembers"
      :cell-style="{ color: '#fff' }"
      :header-cell-style="{ color: '#fff' }"
      :show-overflow-tooltip="true"
    >
      <el-table-column
        label="名字"
        prop="pubgPlayerName"
        align="center"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column label="杀人数" align="center">
        <template v-slot="scope">
          <el-input
            :disabled="inputDisable"
            size="small"
            onkeyup="this.value=this.value.replace(/\D/g,'')"
            onafterpaste="this.value=this.value.replace(/\D/g,'')"
            v-model.number="scope.row.kills"
            maxlength="3"
            @change="changeValue(scope.row)"
          ></el-input>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "MatchDetailTable",
  props: {
    matchInfo: {},
    inputDisable: Boolean,
  },
  data() {
    return {
    };
  },
  computed() {
    //   userMemberList(){
    //       return this.userList;
    //   },
    // {mactchID:i, startTime:startDate, endTime:endDate, interval:this.intervalvalue, onceTime:this.oncetimevalue};
  },
  created() {
    // this.labelO = this.textArray[0];
    //     this.labelT = this.textArray[1];
  },
  methods: {
    changeValue(val) {

        console.log(val);
    },
  },
};
</script>

<style scoped="scoped" lang="less">
.md-table {
  height: 99%;
  width: 95%;
  position: relative;
  border: 1px solid #676666;
//   border-radius: 5px;
  ::v-deep .el-range-input {
    background: black;
    color: white;
  }
}

.md-div{
    height: 10%;
    width: 100%;
    color: white;
    font-size: 18px;
    background: #07b43c;
}
/*最外层透明*/
::v-deep .el-table,
::v-deep .el-table__expanded-cell {
  background-color: transparent !important;
}
/* 表格内背景颜色 */
::v-deep .el-table th,
::v-deep .el-table tr,
::v-deep .el-table td {
  background-color: transparent !important;
  // border: 0px;
}

.el-table::before {
  height: 0px;
}
.el-table--border::after {
  width: 0px;
}
.el-table--border {
  border: none;
}
</style>
