<template>
    <div class = "sc-table">
        <el-table :data="dataScore" :cell-style="{ color: '#fff' }"
            :header-cell-style="{ color: '#fff' }">
      <el-table-column :label="labelArray[0]" prop="rank">
      </el-table-column>
      <el-table-column :label="labelArray[1]">
        <template v-slot="scope">
          <el-input v-model="scope.row.score" onkeyup="this.value=this.value.replace(/\D/g,'')"
            onafterpaste="this.value=this.value.replace(/\D/g,'')" placeholder="" @change="changeValue(scope.row)"/>
        </template>
      </el-table-column>
    </el-table>
    </div>
</template>

<script>
export default {
  name: "ScoreTable",
  props: {
    dataScore: {},
    labelArray: {},
  },
  data() {
    return {
     dataList: this.dataScore,
    };
  },
  computed() {
  },
  created() {
  },
  methods: {
    changeValue(val) {

    
      this.dataList = this.dataList.map(function (item){
        
         if(item.rank == val.rank)
         {
          
           return {rank:item.rank, score:val.score};
         }
         else{
           return {rank:item.rank, score:item.score};
         }

      });
  
      this.$emit("changeTimeList",this.matchList);
    },
  },
};
</script>

<style scoped="scoped" lang="less">
.sc-table {
  height: 60vh;
  width: 100%;
  position: relative;
}

 /*最外层透明*/
  ::v-deep .el-table,
  ::v-deep .el-table__expanded-cell {
    background-color: transparent !important;
  }
  /* 表格内背景颜色 */
  ::v-deep .el-table th,
  ::v-deep .el-table tr,
  ::v-deep .el-table td {
    background-color: transparent !important;
    // border: 0px;
  }

  .el-table::before {
    height: 0px;
  }
  .el-table--border::after {
    width: 0px;
  }
  .el-table--border {
    border: none;
  }

</style>
