<template>
  <div class="usercenter">
    <Head></Head>
	<Left></Left>
	
	<div class="contain-user">
		<Top></Top>
		<div class="user-con">
			<div class="friend">
				<div class="friend-top">
					<span class="iconfont">&#xe632;</span>
					<input v-model="keywords" placeholder="搜索好友" />
					<button>搜 索</button>
				</div>
				<div class="list l-one">
					<div class="list-con1">好友列表</div>
					<div class="list-con2"></div>
				</div>
				<div class="list-table"
				element-loading-background="rgba(0, 0, 0, 0.8)"
				v-loading="loading">
					<div v-for="(item,index) in friendList" class="list">
						<div class="list-con1" style="width:10%"><img class="head" :src="item.avatar?item.avatar:imghead" /></div>
						<div class="list-con1" style="width:10%">{{item.isonline?"在线":"离线"}}</div>
						<div class="list-con1" style="width:10%">{{item.name?item.name:""}}</div>
						<!--div class="list-con1" style="width:10%">
							<img class="dj-img" src="../../../static/dj.png" />
							<span class="dj-span">{{item.level}}</span>
						</div-->
						<div class="list-con1" style="width:15%">被点赞次数: <span style="color:red">{{item.likes}}</span> </div>
						<div class="list-con1" style="width:15%">被举报次数: <span style="color:#999">{{item.reports}}</span> </div>
						<div class="list-con2">
							<div class="list-btn" @click="delBtn(item.id)">
								<img src="../../../static/user/user-7.png"/>
								<span>删除好友</span>
							</div>
							&emsp;
							<div class="list-btn" @click="yqBtn(item.id)">
								<img src="../../../static/user/user-8.png"/>
								<span>邀请组队</span>
							</div>
						</div>
					</div>
				</div>
				
				
			</div>
		</div>
	</div>
  </div>
</template>
<script>
// @ is an alias to /src
import Head from '@/components/HeadUser.vue'
import Left from '@/components/LeftUser.vue'
import Top from '@/components/TopUser.vue'

import imghead from '../../../static/head.jpg'
import {cGet} from "@/request/http-util.js";

import { ElMessage } from 'element-plus'
export default {
	name: 'UserCenter',
	components: {
		Head,
		Left,
		Top
	}, 
	data(){
		return {
			userInfo:{},
			imghead:imghead,
			loading:false,
			friendList:[],
			btnArr:[],
			teamId:0
		}
	},
	created() {
		this.teamId = localStorage.getItem("teamId")?localStorage.getItem("teamId"):0;
		this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
		this.getFriend();
	},
	methods:{
		getFriend() {
			var par = {
				id :this.userInfo.id,
				gameId:0
			}
			this.loading = true;
			var _this = this;
			cGet("getmyfrind",par,false,false).then(res => {
				this.loading = false;
				if (res.code == 1) {
					_this.friendList = res.data.myflist;
				}
			})
		},
		//删除好友
		delBtn(id) {
			
			cGet("delefriends",{id:id}).then(res => {
				if (res.code == 1) {
					this.getFriend();
					ElMessage("删除成功");
				}
			})
		},
		//邀请组队
		yqBtn(id) {
			if (this.teamId <= 0) {
				ElMessage("您还没有队伍！");
				return false;
			} 
			
			if (this.btnArr.indexOf(id) != -1) {
				ElMessage("此用户已被邀请过！");
				return false;
			}
			
			var par = {
				id:this.userInfo.id,
				toId:id,
				teamId:this.teamId
			};
			
			cGet("Invite"+(localStorage.getItem("isLeft") != 1?"":"_dy"),par).then(res => {
				if (res.code == 1){
					this.btnArr.push(id);
					ElMessage("邀请成功！");
				}
			})
		},
	},
}
</script>

<style lang="less" scoped="scoped">
	
	.usercenter {
		text-align: left;
		.top {
			img{width: 100%;}
		}
		.user-con {
			.friend {
				width: 70%;
				.friend-top {
					height: 40px;
					line-height: 40px;
					width: 400px;
					position: relative;
					margin-bottom: 40px;
					input {
						background: none;
						border: 1px solid #4A4A4A;
						color: #fff;
						padding: 7px 40px;
						border-radius: 4px;
						width: 300px;
						box-sizing: border-box;
					}
					span {
						color:#fff;
						position: absolute;
						left:15px
					}
					
					button {
						background-color: #07B53C;
						border:1px solid #07B53C;
						color: #fff;
						margin-left: 20px;
						height: 30px;
						padding: 0 20px;
						border-radius: 5px;
					}
					
				}
				
				.list{
				    width: 100%;
				    border-bottom: 1px solid #4A4A4A;
				    color: #fff;
					height: 50px;
					margin-bottom: 10px;
					box-sizing: border-box;
					padding: 0 10px;
					overflow: hidden;
					.list-con1 {
						float: left;
						line-height: 50px;
						text-align: left;
						.head {
							width: 40px;
							height: 40px;
							border-radius: 50%;
						}
					}
					
					.list-con2 {
						line-height: 50px;
						float: left;
						width: calc(100% - 160px);
						height: 100%;
						width: 40%;
						.list-btn {
							float: left;
							line-height: 50px;
							cursor: pointer;
							margin-right: 15px;
							img {
								position: relative;
								top: 5px;
							}
							span {
							    position: relative;
							    top: -7px;
							    margin-left: 5px;	
							}
						}
					}
					
				}
				
				.l-one {
					font-size: 16px;
					margin-bottom: 20px;
				}
			}
		}
	}
</style>
