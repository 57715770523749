<template>
  <div class="loginmall">
    <div class="con">
      <span @click="close" class="close icon iconfont icon-guanbi"></span>
      <div class="top">充值中心</div>
      <div class="mid">
        <el-row>
          <el-col class="title" :span="8">特惠商城</el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-row>
              <el-image
                style="width: 190px; height: 190px"
                src="./image/sc.png"
              ></el-image>
            </el-row>
            <el-row>
				<div style="width:100%; height: 100%; text-align: center; margin-top: 10px;">
              <el-button type="primary" @click="recharge100">充值100元</el-button>
			</div>
            </el-row>
          </el-col>
		  <el-col :span="12">
            <el-row>
              <el-image
                style="width: 190px; height: 190px"
                src="./image/sc.png"
              ></el-image>
            </el-row>
            <el-row>
				<div style="width:100%; height: 100%; text-align: center; margin-top: 10px;">
              <el-button type="primary" @click="recharge200">充值200元</el-button>
			</div>
            </el-row>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { ElMessageBox } from "element-plus";
import { cGet, cPostParam } from "@/request/http-util.js";

import { cPost } from "../../request/http-util";
export default {
  name: "Login",
  components: {
  },
  props: {
    msg: String,
  },
  data() {
    return {
      rechargeTypeRadio: "1",
      rechargeRadio: "",
      rechargeValue: "",
    };
  },
  methods: {
    formatNum(val) {
      let temp = val.toString();
      temp = temp.replace(/。/g, ".");
      temp = temp.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
      temp = temp.replace(/^\./g, ""); //验证第一个字符是数字
      temp = temp.replace(/\.{2,}/g, ""); //只保留第一个, 清除多余的
      temp = temp.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      temp = temp.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); //只能输入两个小数
      this.rechargeValue = temp;
      this.rechargeRadio = 0;
    },
    clickRechargeRadio(val) {
      this.rechargeValue = val;
    },
    clickRechargeTypeRadio(val) {
      this.rechargeTypeValue = val;
    },
	recharge100() {
		ElMessageBox.confirm("请先登录账号，再进行充值操作！",{
				  confirmButtonText: '确定',
				  cancelButtonText: '取消',
				  type: 'warning'
				}).then(() => {
				
					})
				.catch(() => {})
	},
	recharge200() {
		ElMessageBox.confirm("请先登录账号，再进行充值操作！",{
				  confirmButtonText: '确定',
				  cancelButtonText: '取消',
				  type: 'warning'
				}).then(() => {
				
					})
				.catch(() => {})
	},
	clickOK() {
		if(this.rechargeValue <= 0) {
			return;
		}
		var userInfo = JSON.parse(localStorage.getItem("userInfo"));
		var battleid = localStorage.getItem("battleid");
		if(battleid && battleid.length > 0) {
		}
		else {
			battleid = '0';
		}
          var par = {
            teamId: battleid,
            memberId: userInfo.id,
            tradeFee: this.rechargeValue,
          };

          if (this.rechargeTypeRadio == "1") {
            var getURL = "paycenter/createTrade/ali/totalFee/";

            cPost(getURL, par, true, false).then((res) => {
              if (res.code == 10000) {
                window.open(res.result);
              }
            });
          } else {
            var getWxURL = "paycenter/createTrade/wx/totalFee";

            cPost(getWxURL, par, true, false).then((res) => {
              if (res.code == 10000) {
                window.open(res.result);
              }
            });
          }
	},
    //登录
    close() {
      this.$parent.$parent.closemall();
    },

  },
};
</script>

<style scoped="scoped" lang="less">
.loginmall {
  /**
		* 解决el-input设置类型为number时，去掉输入框后面上下箭头
		**/
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 11;
  .close {
    position: absolute;
    right: 20px;
    top: 17px;
    color: #fff;
  }
  .con {
    width: 520px;
    height: 400px;
    background: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -260px;
    margin-top: -200px;
    border-radius: 12px;
    overflow: hidden;

    .top {
      width: 100%;
      background: #6dba44;
      color: #fff;
      text-align: center;
      height: 50px;
      line-height: 50px;
      font-size: 18px;
      letter-spacing: 6px;
      font-weight: 600;
    }

    .mid {
      margin-top: 35px;
      .el-row {
        width: calc(100% - 70px);
        margin-left: 35px;
        text-align: left;
        .el-col {
          position: relative;
        }
        .title {
          color: #6dbb44;
          width: 100%;
          box-sizing: border-box;
          padding-left: 10px;
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 5px;
        }

        .ainput {
          height: 36px;
          line-height: 36px;
          border: 1px solid #dfdfdf;
          background: #f2f2f2;
          margin-bottom: 5px;
          width: 100%;
          border-radius: 5px;
          box-sizing: border-box;
          padding-left: 12px;
        }

        input:focus {
          border: none !important;
        }

        .eye {
          position: absolute;
          right: 15px;
          top: 8px;
          font-size: 22px;
        }
        .wj {
          color: #aeaeae;
          text-align: right;
        }

        .tj button {
          width: 100%;
          height: 36px;
          background: #6dba44;
          border: none;
          color: #fff;
          border-radius: 5px;
          margin-top: 30px;
          letter-spacing: 2px;
          font-size: 15px;
        }

        .marginb {
          margin-bottom: 20px;
        }

        .reg {
          margin-top: 24px;
          color: #aeaeae;
        }

        .reg button {
          padding: 7px 15px;
          color: #6dbb44;
          border: 2px solid #6dbb44;
          background: #ededed;
          border-radius: 5px;
          float: right;
          margin-top: -10px;
        }
      }
    }
  }
}

.el-button--primary {
  //需要更改的按钮类型
  background: #6dba44 !important;
  border-color: #6dba44 !important;
}
//移入时按钮样式
.el-button--primary:hover {
  background: #6dba44 !important;
  border-color: #6dba44 !important;
  color: #fff !important;
}
//   //修改每一个label文字以及边距
//    ::v-deep .el-radio__label{
//       color: red
//       }
// //修改选中label文字
//    ::v-deep .el-radio__input.is-checked + .el-radio__label {
//        color: red !important;
//    }
// //修改选中radio背景色、边框
//   ::v-deep .el-radio__input.is-checked .el-radio__inner {
//        background: red !important;
//        border-color: red !important;
//    }

//     // 添加颜色类
// 	::v-deep .el-radio__input.is-checked + .el-radio__label {
//        color: #fd7624 !important;
//    }
//    ::v-deep .el-radio__input.is-checked .el-radio__inner {
//        background: #fd7624 !important;
//        border-color: #fd7624 !important;
//    }

::v-deep .el-radio__input.is-checked .el-radio__inner {
  background: #6dba44;
  border-color: #575757;
}
::v-deep .el-radio__inner::after {
  width: 8px;
  height: 8px;
  background-color: #6dba44;
}
.el-radio__inner {
  background-color: rgba(32, 32, 32, 0.6);
  border-color: #575757;
}
::v-deep .el-radio__label {
  color: #575757 !important;
}

::v-deep .el-input__inner {
  background: white;
  color: #575757;
}
</style>
