<template>
  <div class="md">
    <el-input
      v-model="inputPlayer"
      placeholder="输入玩家信息"
      class="input-search"
      v-if="isJoin"
    >
      <template #append>
        <el-button @click="clickSearchPlayer"
          ><el-icon><search /></el-icon
        ></el-button>
      </template>
    </el-input>
  </div>
  <div class="mt-table">
    <el-table
      :cell-style="{ color: '#fff' }"
      :header-cell-style="{ color: '#fff' }"
      :data="playerlist"
    >
      <el-table-column prop="avatar" align="center" label="头像">
        <template #default="scope">
          <ComHeadImg
            :id="scope.row.gameId"
            :tf="scope.row.tpporfpp"
            :img="scope.row.avatar ? scope.row.avatar : img1"
            :isTop="2"
          ></ComHeadImg>
        </template>
      </el-table-column>
      <el-table-column prop="name" align="center" label="名称">
        <template #default="scope">
          {{ scope.row.name ? scope.row.name : "" }}
        </template>
      </el-table-column>
      <el-table-column prop="status" align="center" label="等级/服务器/模式">
        <template #default="scope">
          {{ scope.row.level }}<br />
          {{ scope.row.serve }}<br />
          {{ scope.row.tpporfp }}
        </template>
      </el-table-column>
      <el-table-column label="">
        <template #default="scope">
          <el-button type='primary' v-if="isJoin" @click="clickJoinMember(scope.row.id)">
            添加
          </el-button>
          <el-button type='primary' v-if="!isJoin" @click="clickDelMember(scope.row.id)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { cPostParam, cGet, cPost } from "@/request/http-util.js";
import { ElMessage, ElMessageBox } from "element-plus";
import ComHeadImg from "@/components/common/ComHeadImg.vue";
import { Search } from "@element-plus/icons";

export default {
  name: "TeamMemberDel",
  props: {
    isJoin: Boolean,
    memberList: [],
  },
  components: {
    ComHeadImg,
    Search,
  },
  data() {
    return {
      playerlist: this.memberList,
      inputPlayer: "",
    };
  },
  computed() {
    //   userMemberList(){
    //       return this.userList;
    //   },
    // {mactchID:i, startTime:startDate, endTime:endDate, interval:this.intervalvalue, onceTime:this.oncetimevalue};
  },
  created() {
    // this.labelO = this.textArray[0];
    //     this.labelT = this.textArray[1];
  },
  methods: {
    clickSearchPlayer() {
      
        var userInfo = JSON.parse(localStorage.getItem("userInfo"));
        var par = {
          key: this.inputPlayer,
          pageNum: 0,
          pageSize: 1000,
          currentMemberId: userInfo.id,
        };
        var getURL = "app/gameteam/manager/queryMember";
        var __this = this;
        cGet(getURL, par, true, false).then((res) => {
          if (res.code == 10000) {
            __this.playerlist = res.result;
          } else {
            ElMessage(res.message);
          }
        });
    },
    clickJoinMember(val) {
      var userInfo = JSON.parse(localStorage.getItem("userInfo"));
      var teamid = localStorage.getItem("battleid");
      var par = {
        teamId: teamid,
        fromId: userInfo.id,
        toId: val,
        type: 1,
      };

      var getURL = "app/gameteam/manager/sendMemberRequest";
      cPost(getURL, par, true, false).then((res) => {
        ElMessage(res.message);
        if (res.code == 10000) {
          this.updateBattleInfo();
        }
      });
    },
    clickDelMember(val) {
      ElMessageBox.confirm("您确定要删除该队员吗?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var userInfo = JSON.parse(localStorage.getItem("userInfo"));
      var teamid = localStorage.getItem("battleid");
      var data = {
        teamId: teamid,
        memberId: val,
      };
      var par = {
        manageMemberId: userInfo.id,
      };

      var getURL = "app/gameteam/manager/deleteMember";
      cPostParam(getURL, par, data, true, false).then((res) => {
        ElMessage(res.message);
        if (res.code == 10000) {
          this.updateBattleInfo();
        }
      });
        })
        .catch(() => {});
      
    },
    updateBattleInfo() {
      var battleID = localStorage.getItem("battleid");
      var getURL = "app/gameteam/manager/getTeamByTeamId";
      var par = {
        teamId: battleID,
      };

      cGet(getURL, par, true, false).then((res) => {
        if (res.code == 10000) {
          if (res.result) {
            localStorage.setItem("battleinfo", JSON.stringify(res.result));
          }
        } else {
          ElMessage(res.message);
        }
        this.$store.commit("memberChage");
      });
    },
  },
};
</script>

<style scoped="scoped" lang="less">
.md {
  .el-input-group__append button.el-button {
    background-color: #07b43c;
  }
  //     ::v-deep .el-input-group__append button.el-button {
  //     background-color: black;
  //   }
}
.mt-table {
  height: 60vh;
  width: 100%;
  position: relative;
  ::v-deep .el-range-input {
    background: black;
    color: white;
  }
}

/*最外层透明*/
::v-deep .el-table,
::v-deep .el-table__expanded-cell {
  background-color: transparent !important;
}
/* 表格内背景颜色 */
::v-deep .el-table th,
::v-deep .el-table tr,
::v-deep .el-table td {
  background-color: transparent !important;
  // border: 0px;
}

.el-table::before {
  height: 0px;
}
.el-table--border::after {
  width: 0px;
}
.el-table--border {
  border: none;
}
.el-button--primary { //需要更改的按钮类型
  background: #07b43c !important;
  border-color: #07b43c !important;
}
//移入时按钮样式
.el-button--primary:hover {
  background: #07b43c !important;
  border-color: #07b43c !important;
  color: #FFF !important;
}
</style>
