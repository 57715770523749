<template>
  <div
    class="gamelist"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    v-loading="loading"
  >
    <div class="gamelist-c">
      <div class="g-top">战队管理</div>
      <div class="t-con">
        <div class="g-c-top">
          <button
            @click="btChange(1)"
            :class="isShow == 1 ? 'ctop-button' : ''"
          >
            我的
          </button>
          <button
            @click="btChange(2)"
            :class="isShow == 2 ? 'ctop-button' : ''"
          >
            队务
          </button>
          <button
            @click="btChange(3)"
            :class="isShow == 3 ? 'ctop-button' : ''"
          >
            金库
          </button>
          <button
            @click="btChange(4)"
            :class="isShow == 4 ? 'ctop-button' : ''"
          >
            报名
          </button>
          <button
            @click="btChange(5)"
            :class="isShow == 5 ? 'ctop-button' : ''"
          >
            管理
          </button>
        </div>

        <div v-if="isShow == 1" class="t-c-con">
          <div
            v-for="(item, index) in userlist"
            :key="index"
            class="g-con-list"
          >
            <div>
              <TeamSingleUser
                :userInfo="item[0]"
                :userJob="job"
                :battleID="battleid"
              ></TeamSingleUser>
            </div>
            <div v-if="item.length == 3 || item.length == 2">
              <TeamSingleUser
                :userInfo="item[1]"
                :userJob="job"
                :battleID="battleid"
              ></TeamSingleUser>
            </div>
            <div v-if="item.length == 3">
              <TeamSingleUser
                :userInfo="item[2]"
                :userJob="job"
                :battleID="battleid"
              ></TeamSingleUser>
            </div>
          </div>
        </div>
        <div v-if="isShow == 1 && (job == 1 || job == 2)" calss="t-bot">
          <el-row>
            <el-col :span="11">
              <button class="tbutton" v-on:click="clickAddMember">
                <img src="../../../static/gamedetail-4.png" />
                添加队友
              </button>
            </el-col>
            <el-col :offset="2" :span="11">
              <button
                id="deleteMember"
                class="tbutton"
                v-on:click="clickDeleteMember"
              >
                <img />
                删除队友
              </button>
            </el-col>
          </el-row>
        </div>

        <div v-if="isShow == 2" class="t-message-con">
          <div class="t-message">
            <el-table
              class="t-message-table"
              height="clac(100%)"
              :data="messagedata"
              :show-header="false"
              size="mini"
              :cell-style="{ color: '#fff' }"
              :header-cell-style="{ color: '#fff' }"
            >
              <el-table-column prop="teamTaskContent" label="消息">
              </el-table-column>
              <el-table-column prop="createTime" label="时间">
              </el-table-column>
            </el-table>
          </div>
          <el-select
            v-model="selectValue"
            class="selectC"
            placeholder=""
            size="small"
            @change="clickSelectValue"
          >
            <el-option
              v-for="item in selectOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
          <div class="t-message">
            <el-table
              class="t-message-table"
              height="clac(100%)"
              :data="messagereaddata"
              :show-header="false"
              :cell-style="{ color: '#fff' }"
              :header-cell-style="{ color: '#fff' }"
            >
              <el-table-column label="消息">
                <template v-slot="scope">
                  <el-image
                    class="t-message-img"
                    :src="scope.row.fromAvatar"
                  ></el-image>
                  <div class="t-message-div">
                    {{
                      selectValue == 1
                        ? scope.row.fromName + "申请入队"
                        : scope.row.fromName + "邀请加入联盟"
                    }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column>
                <template v-slot="scope">
                  <el-button
                    size="mini"
                    style="width: 70px"
                    @click="clickTaskRequest(scope.row, 0)"
                    >{{ scope.row.status != 1 ? "已读" : "拒绝" }}</el-button
                  >
                  <el-button
                    size="mini"
                    style="width: 70px"
                    type="primary"
                    @click="clickTaskRequest(scope.row, 1)"
                    >{{
                      scope.row.status == 1
                        ? "接受"
                        : scope.row.status == 2
                        ? "已接受"
                        : "已拒绝"
                    }}</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <el-button
              size="mini"
              type="primary"
              class="t-message-b"
              @click="clickDeleteTaskRequest"
              >清空已读</el-button
            >
          </div>
        </div>

        <div v-if="isShow == 3" class="t-message-con">
          <div class="t-cash">
            <el-row style="margin-top: 0.5em">
              <el-col :span="12"
                ><div class="t-cash-div">战队余额：{{ cashsum }}</div></el-col
              >
              <el-col :span="12"
                ><el-radio-group
                  v-model="depoistLable"
                  @change="radioChange"
                  fill="#07b43c"
                >
                  <el-radio-button label="充值"></el-radio-button>
                  <el-radio-button
                    label="消费"
                  ></el-radio-button> </el-radio-group
              ></el-col>
            </el-row>
          </div>

          <div class="t-cash-table" v-if="isdepoist">
            <TeamList
              :listData="depoistList"
              :titleData="depoistTitleList"
            ></TeamList>
          </div>
          <div class="t-cash-table" v-if="!isdepoist">
            <TeamList
              :listData="consumeList"
              :titleData="consumeTitleList"
            ></TeamList>
          </div>
          <el-button class="recharge-btn" type="primary" @click="clickRecharge"
            >充值</el-button
          >
        </div>
        <div v-if="isShow == 4" class="t-message-con">
          <div class="t-message">
            <el-table
              class="t-message-table"
              height="clac(100%)"
              :data="matchlist"
              :show-header="false"
              :cell-style="{ color: '#fff' }"
              :header-cell-style="{ color: '#fff' }"
            >
              <el-table-column label="消息" align="center">
                <template v-slot="scope">
                  <el-dropdown
                    trigger="click"
                    placement="bottom-end"
                    @command="dropdownClick"
                  >
                    <div style="color: white">
                      {{ scope.row.name }}{{ scope.row.gameStartTime }}
                    </div>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item
                          :command="commandValue('baoming', scope.row)"
                          >报名</el-dropdown-item
                        >
                        <el-dropdown-item
                          :command="commandValue('quxiaobao', scope.row)"
                          >取消报名</el-dropdown-item
                        >
                        <!-- <el-dropdown-item
                        :command="commandValue('guanzhu', scope.row)"
                        >关注</el-dropdown-item
                      > -->
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <el-row>
            <el-col :span="10">
              <el-select
                v-model="selectMatchValue"
                class="selectC"
                placeholder="请选择比赛"
                size="small"
                @change="clickSelectMatchValue"
              >
                <el-option
                  v-for="item in selectMatchOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-col>
            <el-col :span="14">
              <el-button
                v-if="selectMatchValue != null && isShowBirthBtn"
                size="mini"
                @click="clickSelectBirth"
                style="margin-top: 10px"
              >
                落伞点
              </el-button>
            </el-col>
          </el-row>

          <div class="t-message">
            <el-table
              class="t-message-table"
              height="clac(100%)"
              :data="messagematchdata"
              :show-header="false"
              :cell-style="{ color: '#fff' }"
              :header-cell-style="{ color: '#fff' }"
            >
              <el-table-column label="消息">
                <template v-slot="scope">
                  <el-image
                    class="t-message-img"
                    :src="scope.row.avatar"
                  ></el-image>
                  <div class="t-message-div">
                    {{ scope.row.name + "申请加入比赛" }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column>
                <template v-slot="scope">
                  <el-button
                    size="mini"
                    @click="clickHandleMatchReqeust(scope.row, 0)"
                    >{{ scope.row.status != 0 ? "已读" : "拒绝" }}</el-button
                  >
                  <el-button
                    size="mini"
                    type="primary"
                    @click="clickHandleMatchReqeust(scope.row, 1)"
                    >{{
                      scope.row.status == 0
                        ? "接受"
                        : scope.row.status == 1
                        ? "已接受"
                        : "已拒绝"
                    }}</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div v-if="isShow == 5" class="t-message-con">
          <el-row :gutter="20" style="margin-top: 2%">
            <span class="create-span">战队名称：</span>
            <el-input
              v-model="battleName"
              placeholder=""
              class="create-input"
            />
          </el-row>
          <el-row :gutter="20" style="margin-top: 2%">
            <span class="create-span">战队描述：</span>
            <el-input
              v-model="battleDes"
              :autosize="{ minRows: 2, maxRows: 4 }"
              type="textarea"
              placeholder=""
              class="create-input"
            />
          </el-row>
          <el-row :gutter="20" style="margin-top: 2%">
            <el-upload
              class="create-btn"
              :action="uploadLogoUrl"
              :limit="1"
              :on-exceed="handleExceed"
              :on-success="uploadSuccess"
              :file-list="fileList"
              :before-upload="beforeAvatarUpload"
              list-type="picture"
            >
              <el-button>请上传战队Logo</el-button>
            </el-upload>
          </el-row>
          <el-row :gutter="20" style="margin-top: 2%">
            <el-button class="create-btn" type="primary" @click="clickChange"
              >修改信息</el-button
            >
          </el-row>
          <el-row :gutter="20" style="margin-top: 2%">
            <el-button class="create-btn" type="primary" @click="clickQuit"
              >退出战队</el-button
            >
          </el-row>
          <el-row :gutter="20" style="margin-top: 2%">
            <el-button class="create-btn" type="danger" @click="clickDelete"
              >解散战队</el-button
            >
          </el-row>
        </div>
      </div>
    </div>
  </div>
  <el-dialog v-model="dialogDeleteVisible" title="删除队友">
    <TeamMemberDel :isJoin="false" :memberList="teamMembers"></TeamMemberDel>
  </el-dialog>
  <el-dialog v-model="dialogAddVisible" title="添加队友">
    <TeamMemberDel :isJoin="true" :memberList="teamMembers"></TeamMemberDel>
  </el-dialog>
  <el-dialog
    class="recharge"
    width="30%"
    v-model="dialogRechargeVisible"
    title="充值"
  >
    <el-row>
      <el-col :span="8">
        <span style="color: white">充值金额：</span>
      </el-col>
      <el-col :span="12">
        <el-input
          v-model="rechargeValue"
          @input="formatNum(rechargeValue)"
        ></el-input>
      </el-col>
      <el-col :span="2">
        <span style="color: white">元</span>
      </el-col>
    </el-row>

    <div style="margin-top: 2%">
      <el-radio v-model="rechargeRadio" label="10" @change="clickRechargeRadio"
        >10元</el-radio
      >
      <el-radio v-model="rechargeRadio" label="30" @change="clickRechargeRadio"
        >30元</el-radio
      >
      <el-radio v-model="rechargeRadio" label="50" @change="clickRechargeRadio"
        >50元</el-radio
      >
    </div>
    <el-row style="margin-top: 4%">
      <el-col :span="8" style="margin-top: 2%">
        <span style="color: white">充值方式：</span>
      </el-col>
      <el-col :span="10">
        <el-radio
          v-model="rechargeTypeRadio"
          label="1"
          @change="clickRechargeTypeRadio"
          >支付宝</el-radio
        >
        <el-radio
          v-model="rechargeTypeRadio"
          label="2"
          @change="clickRechargeTypeRadio"
          >微信</el-radio
        >
      </el-col>
    </el-row>
    <el-row style="margin-top: 4%">
      <el-col :span="8" style="margin-top: 2%">
        <span style="color: white">充值比例：</span>
      </el-col>
      <el-col :span="10" style="margin-top: 2%">
        <span style="color: white; float: left">1:10</span>
      </el-col>
    </el-row>
    <el-row style="margin-top: 4%">
      <el-col :span="8" style="margin-top: 2%">
        <span style="color: white">充值游戏币：</span>
      </el-col>
      <el-col :span="10" style="margin-top: 2%">
        <span style="color: white; float: left">{{ rechargeValue * 10 + (rechargeValue == 100 ? 5 : (rechargeValue == 200 ? 15 : 0)) }}</span>
      </el-col>
    </el-row>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogRechargeVisible = false">取消</el-button>
        <el-button type="primary" @click="clickRechargeConfirm">确定</el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog
    v-model="dialogSelectVisible"
    title="落伞点详情"
    width="1800px"
    class="dialogBirth"
  >
    <TeamBirth
      :matchDetail="matchDetail"
      :myPos="selectbirthPos"
      :battlePosList="battlePosList"
      :job="job"
      @setBirthPosition="setBirthPosition"
      @closeBirthDialog="closeBirthDialog"
    ></TeamBirth>
  </el-dialog>
</template>

<script>
import TeamSingleUser from "./TeamSingleUser.vue";
import TeamList from "./TeamList.vue";
import { cPost, cGet, cPostParam } from "@/request/http-util.js";
import { ElMessage, ElMessageBox } from "element-plus";
import TeamMemberDel from "./TeamMemberDel.vue";
import Com from "@/request/http-util.js";
import TeamBirth from "./TeamBirth.vue";

export default {
  name: "TeamManager",
  components: {
    TeamSingleUser,
    TeamList,
    TeamMemberDel,
    TeamBirth,
  },
  props: {
    battleInfo: {},
  },
  data() {
    return {
      battleDetail: this.battleInfo,
      job: 3, //1--队长  2--副队长  3--队员
      isShow: 1,
      loading: false,
      dialogDeleteVisible: false,
      dialogAddVisible: false,
      dialogRechargeVisible: false,
      rechargeRadio: "",
      rechargeTypeRadio: "1",
      rechargeValue: "",
      uploadLogoUrl: "",
      messagedata: [],
      messagereaddata: [],
      messagematchdata: [],
      cashsum: 0,
      depoistLable: "充值",
      isdepoist: true,
      depoistTitleList: ["充值人", "充值日期", "游戏币"],
      consumeTitleList: ["消费人", "消费日期", "游戏币"],
      depoistList: [],
      consumeList: [],
      matchlist: [],
      userlist: [],
      mber: [],
      userInfo: {},
      selectValue: 1,
      selectOptions: [
        {
          value: 1,
          label: "战队请求",
        },
        {
          value: 2,
          label: "联盟请求",
        },
      ],
      selectMatchOptions: [],
      selectMatchValue: null,
      matchDetail: {},
      dialogSelectVisible: false,
      battleid: "",
      battleName: "",
      battleDes: "",
      logoID: "",
      selectbirthPos: {},
      battlePosList: [],
      isShowBirthBtn: false,
      teamMembers: [],
    };
  },
  created() {
    this.init();
  },
  computed: {
    isFollow() {
      return this.$store.state.isChangeMember;
    },
    isbattleChange() {
      return this.$store.state.isCreateBattle;
    },
  },
  watch: {
    isFollow(newVal, oldVal) {
      this.battleDetail = JSON.parse(localStorage.getItem("battleinfo"));
      window.location.reload();
    },
    isbattleChange() {
      this.init();
    },
  },
  methods: {
    init() {
      var userInfo = JSON.parse(localStorage.getItem("userInfo"));

      this.uploadLogoUrl =
        Com.baseURL +
        "/game/v1.0/app/gameteam/manager/uploadLogo?manageMemberId=" +
        userInfo.id +
        "&type=1";

      this.userlist = [];
      if (this.battleDetail) {
        this.battleName = this.battleDetail.name;
        this.battleDes = this.battleDetail.descriptionInfo;
        if (this.battleDetail.teamMembers) {
          for (var i = 0; i < this.battleDetail.teamMembers.length; i++) {
            if (userInfo.id == this.battleDetail.teamMembers[i].member.id) {
              this.job = this.battleDetail.teamMembers[i].job;
            }
            if (i + 1 > 2 && (i + 1) % 3 == 0) {
              var tmplist = [
                this.battleDetail.teamMembers[i - 2],
                this.battleDetail.teamMembers[i - 1],
                this.battleDetail.teamMembers[i],
              ];
              this.userlist.push(tmplist);
            }
          }

          if (this.battleDetail.teamMembers.length % 3 == 1) {
            var tmplist2 = [
              this.battleDetail.teamMembers[
                this.battleDetail.teamMembers.length - 1
              ],
            ];
            this.userlist.push(tmplist2);
          } else if (this.battleDetail.teamMembers.length % 3 == 2) {
            var tmplist3 = [
              this.battleDetail.teamMembers[
                this.battleDetail.teamMembers.length - 2
              ],
              this.battleDetail.teamMembers[
                this.battleDetail.teamMembers.length - 1
              ],
            ];
            this.userlist.push(tmplist3);
          }
        }
      }

      this.battleid = localStorage.getItem("battleid");
    },
    clickDeleteMember() {
      if (this.job != 1) {
        return;
      }

      this.teamMembers = [];
      this.battleDetail.teamMembers.forEach((element) => {
        if (element.member) {
          this.teamMembers.push(element.member);
        }
      });
      this.dialogDeleteVisible = true;
    },
    clickAddMember() {
      if (this.job != 1) {
        return;
      }
      this.dialogAddVisible = true;
      this.teamMembers = [];
    },
    //列表切换
    btChange(num) {
      this.isShow = num;
      if (num == 1) {
        this.updateBattleMember();
      } else if (num == 2) {
        this.clickGetTeamTask();
        this.clickGetTaskRequest();
      } else if (num == 3) {
        this.getRechargeAndCashList();
      } else if (num == 4) {
        this.getMatchInfo();
      }
    },
    updateBattleMember() {
      var userInfo = JSON.parse(localStorage.getItem("userInfo"));

      var par = {
        pageNum: "0",
        pageSize: "1000",
        memberId: userInfo.id,
      };
      var getURL = "app/gameteam/manager/getTeamPageByPlayerId";
      cGet(getURL, par, true, false).then((res) => {
        if (res.code == 10000) {
          if (res.result && res.result.length > 0) {
            var battleInfo = res.result[0];
            localStorage.setItem("battlename", battleInfo.name);
            localStorage.setItem("battlelogo", battleInfo.logoUrl);
            localStorage.setItem("battleid", battleInfo.id);
            localStorage.setItem("battleinfo", JSON.stringify(res.result[0]));
            this.$store.commit("battleChange", 1);

            this.battleDetail = JSON.parse(localStorage.getItem("battleinfo"));
            this.userlist = [];
            if (this.battleDetail) {
              this.battleName = this.battleDetail.name;
              this.battleDes = this.battleDetail.descriptionInfo;
              this.battleid = localStorage.getItem("battleid");
              if (this.battleDetail.teamMembers) {
                for (var i = 0; i < this.battleDetail.teamMembers.length; i++) {
                  if (
                    userInfo.id == this.battleDetail.teamMembers[i].member.id
                  ) {
                    this.job = this.battleDetail.teamMembers[i].job;
                  }
                  if (i + 1 > 2 && (i + 1) % 3 == 0) {
                    var tmplist = [
                      this.battleDetail.teamMembers[i - 2],
                      this.battleDetail.teamMembers[i - 1],
                      this.battleDetail.teamMembers[i],
                    ];
                    this.userlist.push(tmplist);
                  }
                }

                if (this.battleDetail.teamMembers.length % 3 == 1) {
                  var tmplist2 = [
                    this.battleDetail.teamMembers[
                      this.battleDetail.teamMembers.length - 1
                    ],
                  ];
                  this.userlist.push(tmplist2);
                } else if (this.battleDetail.teamMembers.length % 3 == 2) {
                  var tmplist3 = [
                    this.battleDetail.teamMembers[
                      this.battleDetail.teamMembers.length - 2
                    ],
                    this.battleDetail.teamMembers[
                      this.battleDetail.teamMembers.length - 1
                    ],
                  ];
                  this.userlist.push(tmplist3);
                }
              }
            }
          }
        }
      });
    },
    //获取充值和消费列表
    getRechargeAndCashList() {
      var par = {
        teamId: this.battleid,
      };
      var getURL = "paycenter/check/queryTeamAllPayInfo/";
      cPost(getURL, par, true, false).then((res) => {
        if (res.code == 10000) {
          this.depoistList = res.result.payVoList;
          this.consumeList = res.result.costVoList;
          this.cashsum = res.result.fundsVO.fundsFee;
        } else {
          ElMessage(res.message);
        }
      });
    },
    getMatchInfo() {
      var par = {
        teamId: this.battleid,
        pageNum: 0,
        pageSize: 1000,
        status: 1,
      };
      var getURL = "app/matches/DefMatchOrder/getMatchesPageByTeamId";
      cGet(getURL, par, true, false).then((res) => {
        if (res.code == 10000) {
          this.matchlist = res.result;
          this.selectMatchOptions = [];
          for (var i = 0; i < this.matchlist.length; i++) {
            var v = this.matchlist[i];
            var matchOp = { label: v.name, value: v.id };
            this.selectMatchOptions.push(matchOp);
          }
        } else {
          ElMessage(res.message);
        }
      });
    },
    radioChange(val) {
      if (val == "充值") {
        this.isdepoist = true;
      } else {
        this.isdepoist = false;
      }
    },
    commandValue(type, command) {
      return {
        type: type,
        command: command,
      };
    },
    dropdownClick(item) {
      const { type, command } = item;
      if (type === "baoming") {
        var userInfo = JSON.parse(localStorage.getItem("userInfo"));
        var data = {
          teamId: this.battleid,
          memberId: userInfo.id,
          matchId: command.id,
          status: 0,
          id: 0,
        };
        var par = {
          memberId: userInfo.id,
        };
        var getURL = "app/matches/TeamOrder/create";
        cPostParam(getURL, par, data, true, false).then((res) => {
          this.getMatchInfo();
          ElMessage(res.message);
        });
      } else if (type === "quxiaobao") {
        var userInfoQ = JSON.parse(localStorage.getItem("userInfo"));
        var dataQ = {
          teamId: this.battleid,
          memberId: userInfoQ.id,
          matchId: command.id,
          status: -2,
          id: 0,
        };
        var parQ = {
          memberId: userInfoQ.id,
        };
        var getURLQ = "app/matches/TeamOrder/update";
        cPostParam(getURLQ, parQ, dataQ, true, false).then((res) => {
          this.getMatchInfo();
          ElMessage(res.message);
        });
      } else if (type === "guanzhu") {
        alert(3 + command);
      }
    },
    uploadSuccess(response, uploadFile, uploadFiles) {
      if (this.job != 1) {
        return;
      }
      if (response.code == 10000) {
        this.logoID =
          Com.baseURL + "/game/v1.0/app/gameteam/manager" + response.result.url;
      }
    },
    clickChange() {
      if (this.job != 1) {
        ElMessage("队长才能修改战队信息！");
        return;
      }
      var userInfo = JSON.parse(localStorage.getItem("userInfo"));
      var data =
        this.logoID.length > 0
          ? {
              descriptionInfo: this.battleDes,
              name: this.battleName,
              logoUrl: this.logoID,
              teamId: this.battleid,
            }
          : {
              descriptionInfo: this.battleDes,
              name: this.battleName,
              teamId: this.battleid,
            };
      var par = {
        manageMemberId: userInfo.id,
      };
      var getURL = "app/gameteam/manager/updateTeamInfo";
      cPostParam(getURL, par, data, true, false).then((res) => {
        ElMessage(res.message);
        if (res.code == 10000) {
          if (res.result) {
            var battleInfo = res.result;
            localStorage.setItem("battlename", battleInfo.name);
            localStorage.setItem("battleid", battleInfo.teamid);
            localStorage.setItem("battlelogo", battleInfo.logoUrl);
            this.$store.commit("battleChange", 1);
          }
        }
      });
    },
    clickQuit() {
      ElMessageBox.confirm("您确定要退出战队吗?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var userInfo = JSON.parse(localStorage.getItem("userInfo"));
          var par = {
            teamId: this.battleid,
            memberId: userInfo.id,
          };
          var getURL = "app/gameteam/manager/quitTeam";
          cPost(getURL, par, true, false).then((res) => {
            ElMessage(res.message);
            if (res.code == 10000) {
              localStorage.removeItem("battlename");
              localStorage.removeItem("battleid");
              this.$store.commit("battleChange", 0);
              this.$router.push({ name: "Game" });
            }
          });
        })
        .catch(() => {});
    },
    clickDelete() {
      if (this.job != 1) {
        ElMessage("队长才能解散战队！");
        return;
      }
      ElMessageBox.confirm(
        "您确定要解散战队吗？（解散战队将会导致战队金库余额清零）",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          var userInfo = JSON.parse(localStorage.getItem("userInfo"));
          var par = {
            teamId: this.battleid,
            manageMemberId: userInfo.id,
          };
          var getURL = "app/gameteam/manager/releaseTeam";
          cGet(getURL, par, true, false).then((res) => {
            ElMessage(res.message);
            if (res.code == 10000) {
              localStorage.removeItem("battlename");
              localStorage.removeItem("battleid");
              this.$store.commit("battleChange", 0);
              this.$router.push({ name: "Game" });
            }
          });
        })
        .catch(() => {});
    },
    clickGetTeamTask() {
      var par = {
        teamId: this.battleid,
        pageNum: 0,
        pageSize: 1000,
      };
      var getURL = "app/gameteam/manager/queryTeamTasks";
      cGet(getURL, par, true, false).then((res) => {
        if (res.code == 10000) {
          this.messagedata = res.result;
        } else {
          ElMessage(res.message);
        }
      });
    },
    clickSelectValue() {
      this.clickGetTaskRequest();
    },
    clickSelectMatchValue() {
      if (this.selectMatchValue != null) {
        var findValue = this.matchlist.filter(
          (element) => element.id == this.selectMatchValue
        );
        if (findValue && findValue.length > 0) {
          this.isShowBirthBtn = findValue[0].useGameMapPos;
        }
      }
      this.clickGetMatchMemberRequest();
    },
    clickGetMatchMemberRequest() {
      var userInfo = JSON.parse(localStorage.getItem("userInfo"));
      var par = {
        teamId: this.battleid,
        pageNum: 0,
        pageSize: 1000,
        memberId: userInfo.id,
        defMatchId: this.selectMatchValue,
      };
      var getURLL = "app/matches/TeamOrder/getMatchesPageByDefMatchIdAndTeamId";
      cGet(getURLL, par, true, false).then((res) => {
        if (res.code == 10000) {
          this.messagematchdata = res.result;          
        } else {
          ElMessage(res.message);
        }
      });
    },
    clickHandleMatchReqeust(val, flag) {
      if (flag == 0) {
        if (val.status != 0) {
          return;
        }
      } else {
        if (val.status != 0) {
          return;
        }
      }

      var status = flag == 0 ? 2 : 1;

      var userInfo = JSON.parse(localStorage.getItem("userInfo"));
      var par = {
        memberId: userInfo.id,
      };
      var data = {
        id: val.id,
        matchId: this.selectMatchValue,
        status: status,
        memberId: val.memberId,
        teamId: this.battleid,
      };

      var getURLL = "app/matches/TeamOrder/update";
      cPostParam(getURLL, par, data, true, false).then((res) => {
        if (res.code == 10000) {
          ElMessage("操作成功");
          this.clickGetMatchMemberRequest();
        } else {
          ElMessage(res.message);
        }
      });
    },
    clickGetTaskRequest() {
      var userInfo = JSON.parse(localStorage.getItem("userInfo"));
      var par = {
        teamId: this.battleid,
        pageNum: 0,
        pageSize: 1000,
        memberId: userInfo.id,
      };

      if (this.selectValue == 2) {
        var getURLL = "app/gameteam/manager/getLeagueTeamRequests";
        cGet(getURLL, par, true, false).then((res) => {
          if (res.code == 10000) {
            this.messagereaddata = res.result;
          } else {
            ElMessage(res.message);
          }
        });
      } else {
        var getURL = "app/gameteam/manager/getTeamRequests";
        cGet(getURL, par, true, false).then((res) => {
          if (res.code == 10000) {
            this.messagereaddata = res.result;
          } else {
            ElMessage(res.message);
          }
        });
      }
    },
    clickTaskRequest(val, flag) {
      if (flag == 0) {
        if (val.status != 1) {
          return;
        }
      } else {
        if (val.status != 1) {
          return;
        }
      }

      var userInfo = JSON.parse(localStorage.getItem("userInfo"));
      var par = {
        messageId: val.id,
        flg: flag,
        managerId: userInfo.id,
      };
      var data = {};
      if (this.selectValue == 2) {
        var getURLL = "app/gameteam/manager/doAddTeamRequest";
        cPostParam(getURLL, par, data, true, false).then((res) => {
          if (res.code == 10000) {
            this.clickGetTaskRequest();
            this.clickGetTeamTask();
          } else {
            ElMessage(res.message);
          }
        });
      } else {
        var getURL = "app/gameteam/manager/doTeamRequest";
        cPostParam(getURL, par, data, true, false).then((res) => {
          if (res.code == 10000) {
            this.clickGetTaskRequest();
            this.clickGetTeamTask();
          } else {
            ElMessage(res.message);
          }
        });
      }
    },
    clickDeleteTaskRequest() {
      var userInfo = JSON.parse(localStorage.getItem("userInfo"));
      var par = {
        teamId: this.battleid,
        memberId: userInfo.id,
      };
      if (this.selectValue == 2) {
        var getURLL = "app/gameteam/manager/deleteAllDoneLeagueTeamRequest";
        cGet(getURLL, par, true, false).then((res) => {
          if (res.code == 10000) {
            this.clickGetTaskRequest();
            this.clickGetTeamTask();
          } else {
            ElMessage(res.message);
          }
        });
      } else {
        var getURL = "app/gameteam/manager/deleteAllDoneTeamRequest";
        cGet(getURL, par, true, false).then((res) => {
          if (res.code == 10000) {
            this.clickGetTaskRequest();
            this.clickGetTeamTask();
          } else {
            ElMessage(res.message);
          }
        });
      }
    },
    clickRecharge() {
      this.dialogRechargeVisible = true;
    },
    clickRechargeConfirm() {
      ElMessageBox.confirm("解散战队将会导致战队金库余额清零", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var userInfo = JSON.parse(localStorage.getItem("userInfo"));
          var par = {
            teamId: this.battleid,
            memberId: userInfo.id,
            tradeFee: this.rechargeValue,
          };

          if (this.rechargeTypeRadio == "1") {
            var getURL = "paycenter/createTrade/ali/totalFee/";

            cPost(getURL, par, true, false).then((res) => {
              if (res.code == 10000) {
                window.open(res.result);
              }
            });
          } else {
            var getWxURL = "paycenter/createTrade/wx/totalFee";

            cPost(getWxURL, par, true, false).then((res) => {
              if (res.code == 10000) {
                window.open(res.result);
              }
            });
          }
        })
        .catch(() => {});
    },
    formatNum(val) {
      let temp = val.toString();
      temp = temp.replace(/。/g, ".");
      temp = temp.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
      temp = temp.replace(/^\./g, ""); //验证第一个字符是数字
      temp = temp.replace(/\.{2,}/g, ""); //只保留第一个, 清除多余的
      temp = temp.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      temp = temp.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); //只能输入两个小数
      this.rechargeValue = temp;
      this.rechargeRadio = 0;
    },
    clickRechargeRadio(val) {
      this.rechargeValue = val;
    },
    clickRechargeTypeRadio(val) {
      this.rechargeTypeValue = val;
    },
    beforeAvatarUpload(rawFile) {
      if (
        rawFile.type !== "image/jpeg" &&
        rawFile.type !== "image/jpg" &&
        rawFile.type !== "image/png" &&
        rawFile.type !== "image/glf"
      ) {
        ElMessage.error("请选择正确的图片格式!");
        return false;
      } else if (rawFile.size / 1024 / 1024 > 10) {
        ElMessage.error("图片不能超过10MB!");
        return false;
      }
      return true;
    },
    clickSelectBirth() {
      var findValue = this.matchlist.filter(
        (element) => element.id == this.selectMatchValue
      );
      if (findValue && findValue.length > 0) {
        this.matchDetail = findValue[0];
        this.battlePosList = [];
        this.selectbirthPos = {};
        if (
          this.matchDetail.gameMapPos &&
          this.matchDetail.gameMapPos.length > 0
        ) {
          var posArray = JSON.parse(this.matchDetail.gameMapPos);
          var index = posArray.findIndex(
            (item) => item.teamId == this.battleid
          );
          if (index == -1) {
            this.battlePosList = posArray;
          } else {
            this.selectbirthPos = posArray[index];
            this.battlePosList = posArray;
            this.battlePosList.splice(index, 1);
          }
        }
        this.dialogSelectVisible = true;
      }
    },
    setBirthPosition(posleft, postop) {
      this.selectbirthPos = {
        teamId: this.battleid,
        left: posleft,
        top: postop,
      };

      if (this.job != 3) {
        if (
          this.selectbirthPos.left.length > 0 &&
          this.selectbirthPos.top.length > 0
        ) {
          var getURL = "app/matches/DefMatch/update";

          if (
            this.matchDetail.gameMapPos &&
            this.matchDetail.gameMapPos.length > 0
          ) {
            var posArray = JSON.parse(this.matchDetail.gameMapPos);
            var index = posArray.findIndex(
              (item) => item.teamId == this.battleid
            );
            if (index == -1) {
              posArray.push(this.selectbirthPos);
            } else {
              posArray[index] = this.selectbirthPos;
            }
            this.matchDetail.gameMapPos = JSON.stringify(posArray);
          } else {
            var tmpArray = [];
            tmpArray.push(this.selectbirthPos);
            this.matchDetail.gameMapPos = JSON.stringify(tmpArray);
          }

          cPost(getURL, this.matchDetail, true, false).then((res) => {
            ElMessage(res.message);
            this.dialogSelectVisible = false;
          });
        }
      }
    },
    closeBirthDialog() {
      this.dialogSelectVisible = false;
    },
  },
};
</script>

<style scoped="scoped" lang="less">
.gamelist {
  width: 100%;
  height: 100%;
  position: relative;
}

.dialogBirth {
  margin: 0vh auto !important;
}

.gamelist-c {
  height: calc(100%);
  margin: 0 auto;
  background-color: rgba(32, 32, 32, 0.6);
  border-radius: 5px;
  border: 1px solid #676666;
  box-sizing: border-box;
  position: relative;
}

.g-top {
  text-align: left;
  color: #f4f4f4;
  font-size: 1.1rem;
  background-image: linear-gradient(#161515, #2b2a2a);
  height: 3.2rem;
  line-height: 3.2rem;
  box-sizing: border-box;
  padding-left: 1.5rem;
}

.t-bot {
  width: 100%;
  left: 0;

  position: absolute;
  bottom: 0;
}
.tbutton {
  margin-top: 1em;
  background: #2f2f2f;
  border: 1px solid #676666;
  width: 100%;
  height: 2.7rem;
  line-height: 2.7rem;
  color: #efefef;
  letter-spacing: 2px;
  border-radius: 5px;
  cursor: pointer;

  img {
    position: relative;
    top: 0.4rem;
    height: 1.4rem;
  }
}
.t-con {
  padding: 0 0.6rem;
  margin-top: 2rem;
  height: 80%;
  .g-c-top {
    text-align: left;
    border-bottom: 1px solid #808080;
    button {
      background: #1f1e1f;
      color: #fff;
      padding: 0.4rem 1.2rem;
      border-left: 1px solid #808080;
      border-top: 1px solid #808080;
      border-right: 1px solid #808080;
      border-bottom: none;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      margin-right: 10px;
    }
    .ctop-button {
      background: #07b43c;
    }
  }

  .pl0 {
    padding-left: 0 !important;
  }
  .pr0 {
    padding-right: 0 !important;
  }
  .t-c-con {
    height: 90%;
    overflow-y: auto;
    position: relative;

    .el-col {
      box-sizing: border-box;
      padding: 5%;
      .g-con-list1 {
        border: 1px solid #363637;
        border-radius: 20px;
        overflow: hidden;
        .gcon_top {
          height: 2.4rem;
          background: #161617;
          line-height: 2.4rem;
          color: #fff;
          .active {
            color: #07b43c;
            overflow: hidden;
          }
        }
        .gcon_con {
          width: 100%;
          background: #222224;
          overflow: hidden;
          .gcon_con-l {
            width: 50%;
            float: left;
            box-sizing: border-box;
            padding: 5%;
            img {
              width: 90%;
              margin-left: 5%;
              width: 3.6rem;
              height: 3.6rem;
              border-radius: 50%;
            }
          }
          .text {
            width: 100%;
            color: #fff;
            height: 1.2rem;
            line-height: 1.2rem;
            font-size: 0.6rem;
          }
        }
      }
    }
  }

  .g-con-list {
    width: 100%;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 2rem;
    height: 7vw;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    .bgimg {
      width: 100%;
      height: 100%;
    }
    .p11 {
      color: #fff;
      position: absolute;
      left: 12%;
      top: 30%;
      margin-top: -13.5px;
      font-size: 1.3rem;
      width: 85%;
      text-align: left;
    }
    .p12 {
      color: #fff;
      position: absolute;
      left: 12%;
      top: 60%;
      margin-top: -13.5px;
      font-size: 1.3rem;
      width: 85%;
      text-align: left;
    }
    .zz {
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: rgba(32, 32, 32, 0.3);
      top: 0;
    }
    button {
      background: none;
      border: none;
      padding: 0.25rem 1rem;
      color: #fff;
      letter-spacing: 2px;
      border-radius: 5px;
      cursor: pointer;
      position: absolute;
      right: 12%;
      top: 50%;
      font-size: 1.2rem;
      margin-top: -15.5px;
      span {
        color: #07b43c;
      }
    }
  }

  .g-con-list div {
    width: 100%;
    height: 55em;
  }

  .t-message-con {
    height: 100%;
    // overflow-y: auto;
    position: relative;
  }
  .t-message {
    margin-top: 1em;
    margin-left: 1em;
    margin-right: 1em;
    height: 40%;
    // background: white;
    .t-message-b {
      margin-top: 0.5em;
      margin-left: 19em;
    }
  }

  .t-message-table {
    margin: 0%;
    width: 100%;
    height: 100%;
  }

  /*最外层透明*/
  ::v-deep .el-table,
  ::v-deep .el-table__expanded-cell {
    background-color: transparent !important;
  }
  /* 表格内背景颜色 */
  ::v-deep .el-table th,
  ::v-deep .el-table tr,
  ::v-deep .el-table td {
    background-color: transparent !important;
    // border: 0px;
  }

  .el-table::before {
    height: 0px;
  }
  .el-table--border::after {
    width: 0px;
  }
  .el-table--border {
    border: none;
  }

  .t-message-img {
    width: 2em;
    height: 2em;
  }

  .t-message-div {
    margin-left: 3em;
    margin-top: -2em;
  }

  .t-cash {
    height: 10%;
    width: 100%;
  }
  .t-cash-div {
    font-size: 25px;
    color: white;
  }
  .t-cash-table {
    width: 100%;
    height: 83%;
  }

  .t-match-div {
    width: 100%;
    color: white;
    background: #07b43c;
  }

  .create-span {
    color: white;
    width: 20%;
    margin-top: 1%;
    font-size: 1.1rem;
  }

  .create-input {
    width: 70%;
  }

  .create-btn {
    margin-left: 10%;
    margin-right: 10%;
    width: 80%;
    background-color: 07b43c;
  }

  .el-button--primary {
    //需要更改的按钮类型
    background: #07b43c !important;
    border-color: #07b43c !important;
  }
  //移入时按钮样式
  .el-button--primary:hover {
    background: #07b43c !important;
    border-color: #07b43c !important;
    color: #fff !important;
  }

  .recharge-btn {
    margin-left: 10%;
    margin-right: 10%;
    margin-top: -10px;
    width: 80%;
  }

  .selectC {
    float: left;
    margin-top: 5px;
  }
}
</style>
