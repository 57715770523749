<template>
	<div class="player"
	element-loading-background="rgba(0, 0, 0, 0.8)"
	v-loading="loading">
		<div class="player-c">
			<div class="g-top">
				大厅所有玩家
			</div>
			<div class="g-con">
				<div class="g-c-top">
					<button @click="btChange(1)" :class="isShow == 1?'ctop-button':''">大厅玩家</button>
					<button v-if="teamId > 0" @click="btChange(2)" :class="isShow == 2?'ctop-button':''">组队玩家</button>
				</div>
				<div v-if="isShow == 1" class="g-c-con">
				  <el-table :data="hallList" height="100%" style="width: 100%;">
					<el-table-column prop="avatar" width="50" align= "center" label="头像" >
						<template #default="scope">
							<ComHeadImg :id="scope.row.id" :tf="scope.row.tpporfpp" :img="scope.row.avatar?scope.row.avatar:img1"></ComHeadImg>
							<!--img :src="scope.row.avatar?scope.row.avatar:img1" /-->
						</template>
					</el-table-column>
					<el-table-column prop="name" align="center" width="120" label="名称" >
						<template #default="scope">
							{{scope.row.pubgName?scope.row.pubgName:""}}
						</template>
					</el-table-column>
					<el-table-column prop="status" width="40" align= "center" label="状态" >
						<template #default="scope">
							在线
						</template>
					</el-table-column>
					<el-table-column prop="status" align= "center" label="等级/服务器/模式" >
						<template #default="scope">
							{{LEVEL[scope.row.lv]}}<br>
							{{SERVE[scope.row.ser]}}<br>
							{{NORM[scope.row.tpporfpp]}}
						</template>
					</el-table-column>
					<el-table-column label="" >
					      <template #default="scope">
					        <button v-if="scope.row.id != userInfo.id" class="org-btn" @click="yqClick(scope.row.id)">邀请</button>
							<button v-else class="org-btn" @click="xgClick(scope.row.id)">修改信息</button>
					      </template>
					</el-table-column>
				  </el-table>
				</div>
				<div v-if="isShow == 2" class="g-c-con">
				  <el-table :data="teamList" height="70%" style="width: 100%;">
					<el-table-column prop="head" align= "center" label="头像" >
						<template #default="scope">
							<ComHeadImg :id="scope.row.memid" :img="scope.row.avatar?scope.row.avatar:img1"></ComHeadImg>
							<!--img :src="scope.row.avatar?scope.row.avatar:img1" /-->
						</template>
					</el-table-column>
					<el-table-column prop="name" width="120" align= "center" label="名称" >
						<template #default="scope">
							{{scope.row.pubgName?scope.row.pubgName:""}}
						</template>
					</el-table-column>
					<el-table-column width="120" align= "center" label="添加好友" >
						<template #default="scope">
							<el-button 
							v-if="userInfo.id != scope.row.memid && !scope.row.isFriend" 
							:disabled="hy.indexOf(scope.row.memid) == -1?false:true" 
							type="success"  size="small"
							@click="hyBtn(scope.row.memid)">
							添加
							</el-button>
						</template>
					</el-table-column>
					<el-table-column prop="status" align= "center" label="状态">
						<template #default="scope">
							<span class="green"  v-if="teamId > 0 && teaminfo.leaderId != userInfo.id">{{teaminfo.leaderId != scope.row.memid?'队员':'队长'}}</span>
							<span class="green"  v-if="teamId > 0 && teaminfo.leaderId == userInfo.id">{{teaminfo.leaderId != scope.row.memid?'队员':'队长'}} <span @click="tcBtn(scope.row.memid)" v-if="teaminfo.leaderId != scope.row.memid" class="orange">(踢出)</span></span>
						</template>
					</el-table-column>
				  </el-table>
				  <div class="button">
					  <el-row v-if="teaminfo.leaderId == userInfo.id">
						  <el-col v-if="teamId > 0" :span="8">
							  <div class="btn-one">
								    <button @click="yqhyBtn()">邀请好友</button>
							  </div>
						  </el-col>
						  <el-col v-if="teamId > 0 && roomId <= 0" :span="8">
							  <div class="btn-two">
							  		<button @click="ksppBtn">开始匹配</button>	
							  </div>
						  </el-col>
						  <el-col v-if="teamId > 0 " :span="8">
							  <div class="btn-three">
									<button @click="tcdwBtn">退出队伍</button>						  
							  </div>
						  </el-col>
					  </el-row>
					  
					  <el-row v-if="teaminfo.leaderId != userInfo.id">
						  <el-col v-if="teamId > 0" :span="12">
							  <div class="btn-one">
									<button @click="yqhyBtn()">邀请好友</button>
							  </div>
						  </el-col>
						  <el-col v-if="teamId > 0 " :span="12">
							  <div class="btn-three">
									<button @click="tcdwBtn">退出队伍</button>						  
							  </div>
						  </el-col>
					  </el-row>
				  </div>
				</div>
			</div>
		</div>
		<div class="g-bot">
			
			<button v-if="teamId>0" @click="naToTeam(1)">
				<img v-if="teamId<=0" src="../../../static/gamedetail-4.png" />
				我的队伍
			</button>
			<el-row v-else>
				<el-col :span="11">
					<button @click="naToTeam(1)">
						<img src="../../../static/gamedetail-4.png" />
						自己组队
					</button>
				</el-col>
				<el-col :offset="2" :span="11">
					<button @click="naToTeam(2)">
						<img src="../../../static/gamedetail-4.png" />
						创建房间
					</button>
				</el-col>
			</el-row>
		</div>

		<!--修改服务器-->
		<TanYqhy ref="tan1"></TanYqhy>
		<!--修改服务器-->
		<TanXgfwq ref="tan2"></TanXgfwq>
		
	</div>
</template>

<script>
	import { beforeUnmount } from 'vue'
	import {cPost,cGet} from "@/request/http-util.js";
	import {reSet,UI} from "@/request/common-api.js";
	import { ElMessage } from 'element-plus';
	import img1 from '../../../static/head.jpg'
	import Com from "@/request/common.js";
	

	import TanXgfwq from '@/components/common/TanXgfwq.vue'
	import TanYqhy from '@/components/common/TanYqhy.vue'
	import ComHeadImg from '@/components/common/ComHeadImg.vue'
	export default {
		name:"Player",
		components:{
			TanXgfwq,
			ComHeadImg,
			TanYqhy
		},
		data() {
			return {
				isShow:1,
				loading:false,
				LEVEL:Com.LEVEL,
				SERVE:Com.SERVE,
				NORM:Com.NORM,
				hy:[],
				
				teamId:0,
				roomId:0,
				dw:1,
				img1:img1,
				btnArr:[],
				
				hallList:[],
				teamList:[],
				teaminfo:{},
				
				userInfo:{},
				userGame:{lv:0,ser:0}
			}
		},
		created() {
			this.init();
		},
		beforeUnmount() {
			clearInterval(window.timer);
		},
		methods:{
			init() {
				this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
				this.teamId = localStorage.getItem("teamId")?localStorage.getItem("teamId"):0;
				this.roomId = localStorage.getItem("roomId")?localStorage.getItem("roomId"):0;
				this.isShow = this.teamId > 0?2:1;
				
				this.getPlayList(1);
				if (this.teamId > 0)this.getTeamList(1);
				
				clearInterval(window.timer);
				window.timer = setInterval(()=>{
					if (this.teamId > 0)this.getTeamList();
					this.getPlayList();
				},5000);
				
				var par = {
					gameid:localStorage.getItem("gameId")
				};
				cGet("getmygameinfo",par,false,false).then(res => {
					if (res.code == 1){
						this.userGame = res.data.mygame;
					}
				})
			},
			//跳转队伍添加
			naToTeam(num = 1) {
				if (this.teamId <= 0) {
					this.$router.push({name:'TeamAdd',query:{room:num}});
				} else {
					this.isShow = 2;
				}
			},
			
			//列表切换
			btChange(id) {
				this.isShow = id;
				if (id == 1) {
					this.getPlayList(1);
				} else {
					this.getTeamList(1);
				}
			},
			
			getPlayList (num=0) {
				if (num > 0) this.loading = true;
				var par = {
					id :this.userInfo.id,
					gameId:localStorage.getItem("gameId")
				}
				var _this = this;
				cGet("GetGameMemberList",par,this.loading,false).then(res => {
					if (num > 0) this.loading = false;
					if (res.code == 1) {
						_this.hallList = [];
						_this.hallList.push(_this.userInfo);
						res.data.hallList.filter(function(item,i){
							_this.hallList.push(item);
						})
					}
				})
			},
			
			//获取队员
			getTeamList (num=0) {
				var _this = this;
				
				var par = {
					teamid:_this.teamId
				}
				if (num > 0) this.loading = true;
				cGet("getteammems",par,this.loading,false).then(res => {
					if (num > 0) this.loading = false;
					if (res.code == 1) {
						if (!res.data.gamelxx) {
							_this.teamList = res.data.list;
							_this.teaminfo = res.data.teammod;
							
							if (typeof(res.data.teammod.roomId) != "undefined" && res.data.teammod.roomId > 0) {
								localStorage.setItem("roomId",res.data.teammod.roomId);
								this.$router.push("gameroom");
							}
							this.$store.commit("leftChange",0);
						} else {
							this.$router.push("teamlist");
							this.$store.commit("leftChange",1);
						}
						
					} else {
						reSet().then(res => {
							this.$store.commit("tmChange");
						})
					} 
				})
			},
			//邀请组队
			yqClick(id) {
				if (this.teamId <= 0) {
					ElMessage("您还没有队伍！");
					return false;
				} 
				
				if (this.btnArr.indexOf(id) != -1) {
					ElMessage("此用户已被邀请过！");
					return false;
				}
				
				var par = {
					id:this.userInfo.id,
					toId:id,
					teamId:this.teamId
				};
				this.loading = true;
				cGet("Invite",par,true,false).then(res => {
					this.loading = false;
					if (res.code == 1){
						this.btnArr.push(id);
						ElMessage("邀请成功！");
					}
				})
				
			},
			//邀请好友
			yqhyBtn() {
				this.$refs.tan1.open();
			},
			//修改信息
			xgClick() {
				this.$refs.tan2.open();
			},
			fwqEnd(){
				UI().then(res => {this.userInfo = res;})
			},
			//开始匹配
			ksppBtn() {
				this.loading = true;
				cGet("teamrrandtoroom",{teamid:this.teamId},true,false).then(res => {
					this.loading = false;
					if (res.code == 1) {
						localStorage.setItem("roomId",res.roomid);
						this.$router.push({name:"GameRoom"});
					}
				})
			},
			
			//踢出
			tcBtn(uid){
				this.loading = true;
				cGet("tcteam",{teamid:this.teamId,uid:uid},true,false).then(res => {
					this.loading = false;
					if (res.code == 1){
						ElMessage("踢出成功！");
						this.getTeamList(1);
					}
				})
			},

			//退出组队
			tcdwBtn() {
				var _this = this;
				this.loading = true;
				cGet("outteam",{teamid:this.teamId},true,false).then(res => {
					this.loading = false;
					if (res.code == 1){
						ElMessage("退出成功！");
						reSet().then(res => {
							_this.$store.commit("tmChange");
						})
					}
				})
			},
			//添加好友
			hyBtn(id){
				if (id == this.userInfo.id) {
					ElMessage("请勿添加自己为好友！");
					return false;
				}
				this.loading = true;
				cGet("addfriend",{fuid:id},true,false).then(res => {
					this.loading = false;
					if (res.code == 1) {
						ElMessage("邀请发送成功，等待同意");
						this.hy.push(id);
					}
				})
			}
		}

	}
</script>

<style scoped="scoped" lang="less">
	.player {
		width: 100%;
		height: 100%;
		position: relative;
	}
	.orange {
		color:#ff5500;
		cursor: pointer;
	}
	.player-c {
		height: calc(100% - 5rem);
		margin: 0 auto;
		background-color: rgba(32, 32, 32, 0.6);
		border-radius: 5px;
		border:1px solid #676666;
		box-sizing: border-box;
		position: relative;
	}
	
	.g-top {
		text-align: left;
		color: #f4f4f4;
		font-size: 1.1rem;
		background-image: linear-gradient( #161515, #2b2a2a);
		height: 3.2rem;
		line-height: 3.2rem;
		box-sizing: border-box;
		padding-left: 1.5rem;
	}
	.green {color:#07b43c;}
	.g-bot {
		width: 100%;
		left: 0;
		position: absolute;
		bottom: 0;
		button {
			background: #2f2f2f;
			border: 1px solid #676666;
			width: 100%;
			height: 2.7rem;
			line-height: 2.7rem;
			color: #efefef;
			letter-spacing: 2px;
			border-radius: 5px;
			cursor: pointer;
			
			img {
				position: relative;
				top: .4rem;
				height: 1.4rem;
			}
		}
	}
	
	.g-con {
		position: relative;
		padding: 0 2rem;
		margin-top: 2rem;
		height: 100%;
		.g-c-top {
			text-align: left;
			border-bottom: 1px solid #808080;
			button {
				background: #1f1e1f;
				color: #fff;
				padding: .4rem 1.2rem;
				border-left: 1px solid #808080;
				border-top: 2px solid #808080;
				border-right: 1px solid #808080;
				border-bottom: none;
				border-top-left-radius: 5px;
				border-top-right-radius: 5px;
				margin-right: 10px;
			}
			.ctop-button {
				background: #07b43c;
			}
		}
			
		.g-c-con{
			height: 72%;
			overflow-y: auto;
			.button {
				margin-top:2rem;
				
				.el-col {
					margin-bottom: 20px;
				}
			}
			.btn-one {
				width: 6rem;
				height: 6rem;
				border-radius: 50%;
				border: 1px solid #676667;
				margin: 0 auto;
				button {
					width: 5rem;
					height: 5rem;
					border-radius: 50%;
					margin-top: .5rem;
					background: #2f2f2f;
					border: 1px solid #7f7f7f;
					box-sizing: border-box;
					padding: 1rem;
					font-size: 1.1rem;
					color: #efefef;
				}
				button:hover {
					background: #3f3f3f;
				}
				
			}
			
			.btn-two {
				width: 6rem;
				height: 6rem;
				border-radius: 50%;
				border: 1px solid #07b43c;
				margin: 0 auto;
				button {
					width: 5rem;
					height: 5rem;
					border-radius: 50%;
					margin-top: .5rem;
					background: #07b43c;
					border: 1px solid #07b43c;
					box-sizing: border-box;
					padding: 1rem;
					font-size: 1.1rem;
					color: #efefef;
				}
				button:hover {
					background: #18c54d;
				}
			}
			
			.btn-three {
				width: 6rem;
				height: 6rem;
				border-radius: 50%;
				border: 1px solid #ee4400;
				margin: 0 auto;
				button {
					width: 5rem;
					height: 5rem;
					border-radius: 50%;
					margin-top: .5rem;
					background: #ff5500;
					border: 1px solid #ff5500;
					box-sizing: border-box;
					padding: 1rem;
					font-size: 1.1rem;
					color: #efefef;
				}
				button:hover {
					background: #ff6655;
				}
			}
		}
	}
	
	.g-c-con1{
		height: 72%;
		.org-btn {
			background: #ff5602;
			border: 1px solid #676666;
			width: 80%;
			max-width: 5rem;
			height: 2rem;
			line-height: 2rem;
			color: #efefef;
			letter-spacing: 2px;
			border-radius: 5px;
			cursor: pointer;
			margin-left: 10%;
		}

		.button {
			margin-top:2rem;
		}
	}
	
	.org-btn {
		background: #ff5602;
		border: 1px solid #676666;
		width: 4.4rem;
		line-height: 1.6rem;
		color: #efefef;
		border-radius: 5px;
		cursor: pointer;
		font-size: 12px;
	}
	
	::v-deep {
		.has-gutter {
			border:none;
			margin-bottom: .4rem;
			th {
				border-bottom:1px solid #808080 !important;
				background-color: #000 !important;
				.cell{color:#fff;font-size: .8rem;font-weight: 600;}
			}
		}
		.el-table {
			background: none !important;
			.el-table__row{
				background: none;
				td {border-bottom:1px solid #3f3f42 !important;}
			}
			.el-table__row:hover td{
				width: 20%;
				background-color: rgba(125,125,125,.3) !important;
			}
			.cell {color:#fff;text-align: center;padding-left: 0px;padding-right: 0px;font-size: 12px;};
			img {
				width: 2.6rem;
				height: 2.6rem;
				border-radius: 50%;
			}
			
			.hyimg {
				width: 2rem;
				height: auto;
				cursor: pointer;
				border-radius: 0;
			}
			
		}
		
		.el-table::before {
			display: none;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 1px;
		}
		.el-table__body {
			margin-top: 2rem;
		}
		.el-table__body-wrapper {
			
		}
		//.el-table__header,.el-table__body {width: 100% !important;}
		.dialog1 {
			height: 70%;
		}

	}
</style>
