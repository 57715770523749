<template>
</template>

<script>
	import {cGet} from "@/request/http-util.js";
	export default {
		name:"SteamCallback",
		data(){
			return {
				
			}
		},
		created() {
			this.setData();
		},
		methods:{
			setData(){
				cGet("BindSuccess",this.$route.query).then(res => {
					if(res.code == 1) {
						this.$router.push({name:"UserCenter"});
					} else {
						this.$router.push({name:"Home"});
					}
				})
			}
		}
		
	}
</script>

<style>
</style>
