<template>
  <div class="usercenter">
    <Head></Head>
	<Left></Left>
	
	<div class="contain-user">
		<Top></Top>
		<div class="user-con">
			<div class="vip">
				<div class="top">
					大会员铭牌&nbsp;&nbsp;&nbsp;&nbsp;高调升级
				</div>
				<div class="mid">
					开通立成vip，并可立刻升级
				</div>
				<div class="bot"
				element-loading-background="rgba(0, 0, 0, 0.8)"
				v-loading="loading">
					<div v-for="(item,index) in viplist" class="bot-1">
						<div class="bot-1-con">
							<img :src="item.icon?item.icon:icon" />
							<div>
								<img src="../../../static/user/user-6.png">
								{{item.vipName}} <span class="orange">{{item.amount}}</span> 元
							</div>
						</div>
						<button @click="vipBtn(item.id)">立即开通VIP</button>
					</div>
					<!--div class="bot-1">
						<div class="bot-1-con">
							<img src="../../../static/user/user-4.png" />
							<div>
								<img src="../../../static/user/user-6.png">
								年卡 <span class="green">50</span> 元
							</div>
						</div>
						<button @click="vipBtn">立即开通VIP</button>
					</div>
					<div class="bot-1">
						<div class="bot-1-con">
							<img src="../../../static/user/user-5.png" />
							<div>
								<img src="../../../static/user/user-6.png">
								年卡 <span class="blue">30</span> 元
							</div>
						</div>
						<button @click="vipBtn">立即开通VIP</button>
					</div-->
				</div>
			</div>
		</div>
	</div>
	
	<TanVip ref="tan1"></TanVip>
  </div>
</template>
<script>
// @ is an alias to /src
import Head from '@/components/HeadUser.vue'
import Left from '@/components/LeftUser.vue'
import Top from '@/components/TopUser.vue'
import TanVip from '@/components/common/TanVip.vue'

import icon from '../../../static/user/user-3.png';

import { ElMessage } from 'element-plus'
import {cGet} from "@/request/http-util.js";
export default {
	name: 'UserCenter',
	components: {
		Head,
		Left,
		TanVip,
		Top
	}, 
	data(){
		return {
			loading:false,
			viplist:[],
			icon:icon
		}
	},
	created() {
		this.getInfo();
	},
	methods:{
		getInfo() {
			this.loading = true;
			cGet("GetVips",{},true,false).then(res => {
				this.loading = false;
				if (res.code == 1) {
					this.viplist = res.data;
				}
			})
		},
		vipBtn(id) {
			this.$refs.tan1.open(id);
		}
	},
}
</script>

<style lang="less" scoped="scoped">
	
	.usercenter {
		text-align: left;
		.top {
			img{width: 100%;}
		}
		.user-con {
			.vip {
				width: 70%;
				.top {
					font-size: 16px;
					color:#fff;
					border-bottom: 1px solid #4a4a4a;
					padding: 20px 0 15px;
				}
				.mid {
					color: #86878A;
					margin: 50px 0;
				}
				.bot {
					.bot-1 {
						
						text-align: center;
						float: left;
						margin-right: 40px;
						
						.bot-1-con {
							width: 120px;
							border-radius: 5px;
							border:1px solid #626262;
							padding: 15px 0 35px;
							background-color: #222222;
							position: relative;
							
							div {
								height: 26px;
								line-height: 26px;
								color:#fff;
								position: absolute;
								bottom: 0;
								left: 0;
								width: 100%;
								border-top:1px solid #626262;
							}
						}
						
						button {
							background-color: #222222;
							color: #86878A;
							width: 100%;
							border:1px solid #626262;
							margin-top: 20px;
							padding: 2px 0;
							border-radius: 2px;
						}
						
						button:hover {
							background-color: #07b53c;
							color: #fff;
							border:1px solid #07b53c;
						}
					}
				}
			}
		}
	}
</style>
