<template>
	<div class="player"
	element-loading-background="rgba(0, 0, 0, 0.8)"
	v-loading="loading">
		<div v-if="min || sec" class="zz"></div>
		<div class="player-c">
			<div class="g-top">
				大厅所有玩家
			</div>
			<div class="g-con">
				<div class="g-c-top">
					<button @click="btChange(1)" :class="isShow == 1?'ctop-button':''">大厅玩家</button>
					<button @click="btChange(2)"  v-if="roominfo.leaderId == userInfo.id" :class="isShow == 2?'ctop-button':''">大厅队伍</button>
				</div>
				<div v-if="isShow == 1" class="g-c-con">
				  <el-table :data="hallList" height="100%" style="width: 100%;">
					<el-table-column prop="head" align= "center" label="头像" >
						<template #default="scope">
							<ComHeadImg :id="scope.row.id" :img="scope.row.avatar?scope.row.avatar:imghead"></ComHeadImg>
							<!--img v-else :src="scope.row.avatar?scope.row.avatar:imghead" /-->
						</template>
					</el-table-column>
					<el-table-column prop="pubgName" align= "center" label="名称"  width="150" >
						<template #default="scope">
							{{scope.row.pubgName}}
						</template>
					</el-table-column>
					<el-table-column prop="status" align= "center" label="状态" >
						<template #default="scope">
							<span v-if="scope.row.id != userInfo.id" @click="yqClick(scope.row.id)" :class="btnArr.indexOf(scope.row.id) != -1?'org':''">
								{{btnArr.indexOf(scope.row.id) != -1?"等待同意":"点击邀请"}}
							</span>
							<span v-else style="color:##ff5602;" @click="xgClick">修改信息</span>
						</template>
					</el-table-column>
					<el-table-column prop="playCount" align= "center" label="游戏次数" />
				  </el-table>
				</div>
				<div v-if="isShow == 2" class="g-c-con">
					<el-row>
						<el-col v-for="(item,index) in mber" class="pl0" :span="12">
							<template  v-if="item.hasRoom <= 0">
								<div class="g-con-list1">
									<div class="gcon_top">
										队伍{{index+1}} <span  v-if="roominfo.leaderId == userInfo.id" @click="rdBtn(item.rylist[0].teamid)" class="active"> 邀请</span>
										<span class="fwq">{{SERVE[item.serve]}}</span> <span class="ms">{{NORM[item.norm]}}</span>
										<el-tooltip
										       class="box-item"
										       effect="dark"
											   v-if="item.note"
										       :content="item.note"
										       placement="top"
										     >
										      <img class="gth" src="../../../static/icon-1.png" />
										     </el-tooltip>
									</div>
									<div class="gcon_con">
									<div v-for="n in item.maxNum" class="gcon_con-l">
										<img :src="typeof(item.rylist[(n-1)]) != 'undefined'?(item.rylist[(n-1)]['avatar']?item.rylist[(n-1)]['avatar']:imghead):img2" />
										<div class="text onesl">{{typeof(item.rylist[(n-1)]) != 'undefined'?item.rylist[(n-1)]['pubgName']:"暂无"}}</div>
									</div>
									</div>
								</div>
							</template>
						</el-col>
					</el-row>
				</div>
			</div>
		</div>
		
		<!--修改服务器-->
		<TanXgfwq ref="tan1"></TanXgfwq>
		
	</div>
</template>

<script>
	import { beforeUnmount } from 'vue'
	import {cPost,cGet} from "@/request/http-util.js";
	import {reSet,UI} from "@/request/common-api.js";
	import { ElMessage } from 'element-plus'
	import imghead from '../../../static/head.jpg'
	import img1 from '../../../static/teamlist-1.png'
	import img2 from '../../../static/teamlist-2.png'
	import Com from "@/request/common.js";
	
	import TanXgfwq from '@/components/common/TanXgfwq.vue'
	import ComHeadImg from '@/components/common/ComHeadImg.vue'
	export default {
		name:"Player",
		components:{
			TanXgfwq,
			ComHeadImg
		},
		data() {
			return {
				isShow:1,
				loading:false,
				hallList:[],
				imghead:imghead,
				img1:img1,
				img2:img2,
				teamId:0,
				btnArr:[],
				userInfo:{},
				NORM:Com.NORM,
				SERVE:Com.SERVE,
				mber:[],
				min:'',
				sec:''
			}
		},
		beforeUnmount() {
			clearInterval(window.timer1);
		},
		props:{
			roominfo:Object
		},
		mounted() {
			this.init();
		},
		methods:{
			init() {
				this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
				this.teamId = localStorage.getItem("teamId")?localStorage.getItem("teamId"):0;
				this.getPlayList(1);
				clearInterval(window.timer1);
				window.timer1 = setInterval(()=>{
					this.getPlayList();
				},5000);
			},
			getPlayList (num=0) {
				if(num > 0)this.loading = true;
				var par = {
					id :this.userInfo.id,
					gameId:localStorage.getItem("gameId")
				}
				var _this = this;
				cGet("GetGameMemberList",par,true,false).then(res => {
					if(num > 0)this.loading = false;
					if (res.code == 1) {
						if (_this.hallList.length != res.data.hallList.length) _this.btnArr = [];
						_this.hallList = [];
						_this.hallList.push(_this.userInfo);
						res.data.hallList.filter(function(item,i){
							_this.hallList.push(item);
						})
					}
				})
			},
			//列表切换
			btChange(num) {
				this.isShow = num;
				if (num == 1) {
					this.getPlayList(1);
					clearInterval(window.timer1);
					window.timer1 = setInterval(()=>{
						this.getPlayList();
					},5000);
				} else {
					this.getTeamList(1);
					clearInterval(window.timer1);
					window.timer1 = setInterval(()=>{
						this.getTeamList();
					},5000);
				}
			},
			xgClick() {
				this.$refs.tan1.open();
			},
			fwqEnd(){
				UI().then(res => {this.userInfo = res;})
			},
			//获取
			getTeamList(num = 0) {
				if(num == 1)this.loading = true;
				var par = {
					id :this.userInfo.id,
					gameId:localStorage.getItem("gameId")
				}
				var _this = this;
				cGet("getteams",par,this.loading,false).then(res => {
					if(num == 1)this.loading = false;
					if(res.code == 1) {
						this.mber = res.data;
					}
				})
			},
			//邀请组队
			yqClick(id) {
				if (this.teamId <= 0) {
					ElMessage("您还没有队伍！");
					return false;
				} 
				
				if (this.btnArr.indexOf(id) != -1) {
					ElMessage("此用户已被邀请过！");
					return false;
				}
				
				var par = {
					id:this.userInfo.id,
					toId:id,
					teamId:this.teamId
				};
				this.loading = true;
				cGet("Invite",par,true,false).then(res => {
					this.loading = false;
					if (res.code == 1){
						this.btnArr.push(id);
						ElMessage("邀请成功！");
					}
				})
				
			},
			//邀请队伍
			rdBtn(id) {
				var par = {
					toteamid:id,
					roomid:localStorage.getItem("roomId")
				};
				this.loading = true;
				cGet("inviteteam",par,true,false).then(res => {
					this.loading = false;
					if (res.code == 1){
						ElMessage("邀请成功！");
					}
				})
			},
			mC(min,sec){
				this.min = min;
				this.sec = sec;
			}
		}
	}
</script>

<style scoped="scoped" lang="less">
	.player {
		width: 100%;
		height: calc(100% - 2.2rem);
		position: relative;
		top: 2.2rem;
	}
	
	.player-c {
		height: 100%;
		margin: 0 auto;
		background-color: rgba(32, 32, 32, 0.6);
		border-radius: 5px;
		border:1px solid #676666;
		box-sizing: border-box;
		position: relative;
	}
	.fwq {
		    font-size: .8rem;
		    color: rosybrown !important;
		    font-weight: 600;
		    margin-left: 2%;
		    background: #333;
		    padding: 3px 5px;
		    border-radius: 5px;
	}
	.ms {
		    font-size: .8rem;
		    color: beige !important;
		    font-weight: 600;
		    margin-left: 2%;
		    background: #333;
		    padding: 3px 5px;
		    border-radius: 5px;
	}
	.g-top {
		text-align: left;
		color: #f4f4f4;
		font-size: 1.1rem;
		height: 3.2rem;
		line-height: 3.2rem;
		box-sizing: border-box;
		padding-left: 1.5rem;
	}
	
	.g-con {
		position: relative;
		padding: 0 2rem;
		margin-top: 1rem;
		height: 100%;
		
		.g-c-con{
			height: 80%;
			overflow-y: auto;
			span {
				color:#07b43c;
				cursor: pointer;
			}
			.org {
				color:#999 !important;
			}
			button {
				background: #ff5602;
				border: 1px solid #676666;
				width: 90%;
				height: 2rem;
				line-height: 2rem;
				color: #efefef;
				letter-spacing: 2px;
				border-radius: 5px;
				cursor: pointer;
			}
		}
		
		.g-c-top {
			text-align: left;
			border-bottom:1px solid #808080;
			button {
				background: #1f1e1f;
				color: #fff;
				padding: .4rem 1.2rem;
				border-left: 1px solid #808080;
				border-top: 1px solid #808080;
				border-right: 1px solid #808080;
				border-bottom: none;
				border-top-left-radius: 5px;
				border-top-right-radius: 5px;
				margin-right: 10px;
			}
			.ctop-button {
				background: #07b43c;
			}
		}
		
		.pl0 {padding-left: 0 !important;}
		.pr0 {padding-right: 0 !important;}
		.g-c-con {
			height: 70%;
			overflow-y: auto;
			position: relative;
			margin-top: 2rem;
			
			.el-col {
				box-sizing: border-box;
				padding: 5%;
				.g-con-list1 {
						border: 1px solid #363637;
					    border-radius: 20px;
						overflow: hidden;
					.gcon_top {
						height: 2.4rem;
						background: #161617;
						line-height: 2.4rem;
						color: #fff;
						overflow: hidden;
						.active {
							color:#07b43c;
							overflow: hidden;
						}
					}
					.gcon_con {
						width: 100%;
						background: #222224;
						overflow: hidden;
						.gcon_con-l {
							width: 50%;
							float: left;
							box-sizing: border-box;
							padding: 5%;
							img {width: 90%;margin-left: 5%;max-width: 3.6rem;max-height: 3.6rem;border-radius: 50%;}
						}
						.text {
							width: 100%;
							color: #fff;
							height: 1.2rem;
							line-height: 1.2rem;
							font-size: .6rem;
						}
					}
				}
			}
		}
	}
	.gth {
		margin: 0 5px;
		top: 6px;
		position: relative;
	}
	.zz {
	    position: absolute;
	    top: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
	    z-index: 100;
	    background: rgba(0,0,0,.3);
	}
	::v-deep {
		.has-gutter {
			border:none;
			margin-bottom: .4rem;
			th {
				border-bottom:1px solid #808080 !important;
				background-color: #000 !important;
				.cell{color:#fff;font-size: .8rem;font-weight: 600;}
			}
		}
		.el-table {
			background: none !important;
			.el-table__row{
				background: none;
				td {border-bottom:1px solid #3f3f42 !important;}
			}
			.el-table__row:hover td{
				width: 20%;
				background-color: rgba(125,125,125,.3) !important;
			}
			.cell {color:#fff;text-align: center;};
			img {
				width: 2.6rem;
				height: 2.6rem;
				border-radius: 50%;
			}
			
		}
		
		.el-table::before {
			display: none;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 1px;
		}
		.el-table__body {
			margin-top: 2rem;
		}
		.el-table__body-wrapper {
			
		}
		//.el-table__header,.el-table__body {width: 100% !important;}
		
	}
</style>
