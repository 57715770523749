<template>
  <div class="game">
    <Head></Head>
    <Left></Left>

    <div class="contain">
      <span class="top">比赛平台</span>
      <el-row gutter="24">
        <el-col :span="8">
          <MatchInfo></MatchInfo>
        </el-col>
        <el-col :span="8">
          <MatchPre></MatchPre>
        </el-col>
       <el-col :span="8">
          <MatchCreate :isShowCreateVue="isShowCreateVue"></MatchCreate>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import Head from "@/components/Head.vue";
import Left from "@/components/Left.vue";
import MatchInfo from "@/components/matchplatform/MatchInfo.vue";
import MatchPre from "@/components/matchplatform/MatchPre.vue";
import MatchCreate from "@/components/matchplatform/MatchCreate.vue";
import {cPostParam } from "@/request/http-util.js";
export default {
  name: "TeamPlatform",
  components: {
    Head,
    Left,
    MatchInfo,
    MatchPre,
    MatchCreate,
  },


  computed: {
    // isFollow() {
    //   return this.$store.state.isTm; //需要监听的数据
    // },
    // isFollow1() {
    //   return this.$store.state.isToRc; //需要监听的数据
    // },
  },
  watch: {
    // isFollow(newVal, oldVal) {
    //   this.uC();
    // },
    // isFollow1(newVal, oldVal) {
    //   this.$router.push({ name: "GameRoom" });
    // },
  },
  data() {
    return {
      isShow: 1,
      gameName: localStorage.getItem("gameName"),
      gameId: localStorage.getItem("gameId"),
      dialogVisible: false,
      isShowCreateVue: localStorage.getItem("showCreateVue") == 'true' ? true : false,
    };
  },
  created() {
    this.getMemberCreate();
  },
  methods: {
    getMemberCreate(){
      var userInfo = JSON.parse(localStorage.getItem("userInfo"));
        var par = {
          memberId: userInfo.id,
        };
       var data= {};
      var getURL = "app/matches/UIControl/checkMemberPermission";
        var __this = this;
        cPostParam(getURL, par, data, true, false).then((res) => {
          if (res.code == 10000) {
            __this.isShowCreateVue = res.result;
          } 
        });
    },
    //进入添加队伍
    naToAddTeam() {
      this.dialogVisible = true;
    },

    //取消按钮
    qxBtn() {
      this.dialogVisible = false;
    },

    //确定按钮
    qdBtn() {
      this.$router.push({ name: "TeamAdd" });
    },

    //更新组件
    uC() {
      this.$refs.gl.init();
      this.$refs.ct.init();
      this.$refs.pl.init();
    },
  },
};
</script>

<style lang="less" scoped="scoped">
.contain {
  padding: 20px 0;
}
.game {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
.top {
  position: absolute;
  top: 2rem;
  left: 50%;
  margin-left: -95px;
  font-size: 2.2rem;
  font-weight: 600;
  color: #fff;
  letter-spacing: 2px;
  background: linear-gradient(to right, #ada59b, #d1d0cf);
  -webkit-background-clip: text;
  color: transparent;
}

.el-row {
  width: 96%;
  margin-left: 2% !important;
  height: calc(100% - 10rem);
  margin-top: 6.5rem;
  .el-col {
    height: 100%;
  }
}

::v-deep {
  .el-dialog {
    .el-dialog__title {
      color: #fff;
    }
    .el-dialog__body span {
      color: #fff;
    }
    .qdBtn {
      background-color: #07b43c;
      border-color: #07b43c;
      color: #fff;
    }
    .qxBtn {
      background-color: #ff5602;
      border-color: #ff5602;
      color: #fff;
    }
  }
}
</style>
